import { DeviceConnectApis } from '@kinderlabs-pos/apis/storeinfo';
import { ConnectInfoType, DeviceType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { useMutation, useQuery } from '@tanstack/react-query';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const deviceConnectKeys = createQueryKeys('device-connect', {
	connectInfos: ({ storeId, deviceType }: { storeId: number; deviceType: DeviceType }) => ({
		queryKey: [storeId, deviceType],
		queryFn: async () => {
			const res = await DeviceConnectApis.getConnectInfoByTypeList({ storeId, deviceType });
			return res.reduce<Record<ConnectInfoType['deviceId'], ConnectInfoType>>((arr, val) => {
				arr[val.deviceId] = val;
				return arr;
			}, {});
		},
	}),
	healthCheck: (option: { storeId: number; deviceId: number; deviceType: DeviceType }) => ({
		queryKey: [option],
		queryFn: async () => {
			const res = await DeviceConnectApis.healthCheckDevice(option);
			return res;
		},
	}),
});

const useConnectStatusQuery = ({
	storeId,
	deviceType,
}: {
	storeId: number;
	deviceType: DeviceType;
}) => {
	const result = useQuery({
		...deviceConnectKeys.connectInfos({ storeId, deviceType }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
		refetchInterval: 1000 * 5,
	});

	if (result.isSuccess) return result.data;
	else throw Error('[GET] Connect Store And Type DeviceConnect API Error');
};

const useHealthCheckQuery = (option: {
	storeId: number;
	deviceId: number;
	deviceType: DeviceType;
}) => {
	const result = useQuery({
		...deviceConnectKeys.healthCheck(option),
		// 헬스체크에 필수
		refetchIntervalInBackground: true,
		refetchInterval: 1000 * 5,
	});

	if (result.isSuccess) return result.data;
	else throw Error('[GET] Connect Store And Health-Check DeviceConnect API Error');
};

const useDisconnect = (option: { storeId: number; deviceId: number; deviceType: DeviceType }) => {
	return useMutation(
		async () => {
			DeviceConnectApis.disconnectDevice(option);
		},
		{
			onSuccess: (data) => invalidateQueries(),
		}
	);
};

const DeviceConnector = ({
	storeId,
	deviceId,
	deviceType,
}: {
	storeId: number;
	deviceId: number;
	deviceType: DeviceType;
}) => {
	const disconnectMutation = useDisconnect({ storeId, deviceId, deviceType });
	const setIsConnected = useSetAtom(isConnectedAtom);

	useEffect(() => {
		return () => {
			disconnectMutation.mutate();
			setIsConnected(false);
		};
	}, []);

	const connected = useHealthCheckQuery({ storeId, deviceId, deviceType });

	useEffect(() => {
		if (connected) setIsConnected(true);
	}, [connected, setIsConnected]);

	return null;
};

const isConnectedAtom = atom<boolean>(false);
DeviceConnector.useIsConnected = () => {
	return useAtomValue(isConnectedAtom);
};

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: deviceConnectKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: deviceConnectKeys._def });
	['storeInfo', 'posInfo', 'posStatus', 'vault-cash'].map((key) => {
		QueryClient.origin.invalidateQueries([key]);
	});
};

export const DeviceConnectState = {
	// useGuestvisitAll: useGVStatistics,
	useDisconnect: useDisconnect,
	useLoginStatusQuery: useConnectStatusQuery,
	Connector: DeviceConnector,
	invalidateQueries: invalidateQueries,
	resetHealthCheckError: (props: { storeId: number; deviceId: number; deviceType: DeviceType }) =>
		QueryClient.origin.resetQueries(deviceConnectKeys.healthCheck(props)),
};
