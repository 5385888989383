import {
	FacilityReservationInfo,
	FindUserInfoBody,
	NoticeReqeustType,
	PartyQuestionRequestType,
} from '@kinderlabs-pos/shared-data-type';
import dayjs from 'dayjs';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

const selectMenuTabIdx = atom<number>(0);
const selectNoticeTabIdx = atom<number>(0);
const selectCenterId = atom<number | null>(null);
const selectPgTicketId = atom<number>(0);
const goChildRegisterPage = atom<boolean>(false);
const usageId = atom<number>(0);
const selectRecentUsageId = atom<number | null>(null);
const pushStatus = atom<'Y' | 'N'>('N');
const findUserInfo = atom<FindUserInfoBody>({
	findId: '',
	userName: '',
	masking: '',
});

const facilityInfoAtom = atomWithReset<FacilityReservationInfo>({
	centerId: 49,
	availableType: 'ENTER',
	userArray: [{ name: '', birth: '', relation: 1, gender: 'M' }],
	termsArray: [false, false, false, false],
});

const validateInfos = atom<boolean>((get) => {
	return get(facilityInfoAtom).centerId !== undefined &&
		get(facilityInfoAtom).userArray.length > 0 &&
		get(facilityInfoAtom)
			.userArray.map((user) => user.name)
			.filter((item) => item === '').length === 0 &&
		get(facilityInfoAtom)
			.userArray.map((user) => user.birth)
			.filter((item) => item === '').length === 0
		? true
		: false;
});

const searchOptions = atomWithReset<NoticeReqeustType>({
	page: 0,
	size: 30,
	deleted: 'N',
	searchKeyword: '',
});

const partyQuestionAtom = atomWithReset<PartyQuestionRequestType>({
	centerSelect: 49,
	goods: 'party',
	isSameWithApplicant: false,
	memberinfoName: '',
	memberinfoPhone: '',
	numberOfUsers: '',
	date: dayjs().format('YYYY-MM-DD'),
	hour: '9:00',
	minutes: '0minute',
	hours: '1hour',
	memo: '',
});

export const VaunceState = {
	usageId: usageId,
	selectRecentUsageId: selectRecentUsageId,
	pushStatus: pushStatus,
	findUserInfo: findUserInfo,

	selectPgTicketId: selectPgTicketId,
	searchOptions: searchOptions,
	facilityInfoAtom: facilityInfoAtom,
	validateInfos: validateInfos,

	selectMenuTabIdx: selectMenuTabIdx,
	selectNoticeTabIdx: selectNoticeTabIdx,
	selectCenterId: selectCenterId,
	goChildRegisterPage: goChildRegisterPage,

	partyQuestionRequestInfo: partyQuestionAtom,
};
