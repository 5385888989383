import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VendorOrderReturnInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateToCancelVendorOrderAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.put<VendorOrderReturnInfoType>(`${rootUrl}/vendor-order/cancel`, null, {
			params: {
				id,
			},
		})
	).data;
