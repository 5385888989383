import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PaymentInfoType, ReceiptInfoType } from '@kinderlabs-pos/shared-data-type';
import dayjs from 'dayjs';

export const createPaymentAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, payment, receipt }: PaymentRequestType) =>
		(
			await posAxiosClient.post<PaymentInfoType>(
				`${rootUrl}/payment/store/${storeId}/pos/${posId}/payment`,
				{
					payment,
					// receipt: receipt,
					receipt: receipt
						? {
								...receipt,
								approvalDate: dayjs(receipt.approvalDate).format('YYYY-MM-DDTHH:mm:ss'),
						  }
						: undefined,
				}
			)
		).data;

export type PaymentRequestType = {
	storeId: number;
	posId: number;
	payment: Omit<PaymentInfoType, 'id' | 'orderId'>;
	receipt?: ReceiptInfoType;
};
