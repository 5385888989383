import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminProductBoardInfoType,
	BaseInfoType,
	KioskBoardInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const canDeleteProductVariantInfoAsync =
	(rootUrl: string) =>
	async ({ storeId, productId }: CanDeleteProductVariantRequestType) =>
		(
			await posAxiosClient.get<CanDeleteProductVariantsResponseType>(
				`${rootUrl}/product/variant/can-delete`,
				{
					params: {
						storeId,
						productId,
					},
				}
			)
		).data;

type CanDeleteProductVariantRequestType = {
	storeId?: number;
	productId: number;
};

export type CanDeleteProductVariantsResponseType = {
	posBoardInfos: (AdminProductBoardInfoType & BaseInfoType)[];
	kioskBoardInfos: (KioskBoardInfoType & BaseInfoType)[];
};
