import { AdminStoreInfoApis, DeviceTokenApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	EnterpriseInfoType,
	JungsanInfoType,
	getSalesDeviceInfoName,
} from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { AdminKioskInfoState } from '../Admin/AdminKioskInfoState';
import { AdminKitchenSystemInfoState } from '../Admin/AdminKitchenSystemInfoState';
import { AdminPosInfoState } from '../Admin/AdminPosInfoState';
import { AdminProductInfoState } from '../Admin/AdminProductInfoState';

type StoreAndWaiverDeviceType = {
	storeId: number;
	deviceId: number;
	enterpriseId: string;
} & (
	| {
			deviceType: 'WAIVER';
			isOTPLogined: boolean;
	  }
	| {
			deviceType: 'SIGNUP';
			isOTPLogined: false;
	  }
);

type StoreAndDeviceType =
	| {
			storeId: number;
			deviceId: number;
			deviceType: 'POS' | 'KIOSK';
			enterpriseId: string;
	  }
	| {
			storeId: number;
			deviceId: number;
			deviceType: 'KDS' | 'DISPLAYER' | 'V1_WAIVER'; // JIN 240816 V1_WAIVER 척결!
			enterpriseId: string;
			enterpriseInfo: EnterpriseInfoType;
	  }
	| StoreAndWaiverDeviceType;

type LoginDevicePayloadType =
	| { deviceType: 'POS'; storeId: number; deviceId: number; enterpriseId: string }
	| { deviceType: 'KIOSK'; storeId: number; deviceId: number; enterpriseId: string }
	| { deviceType: 'KDS'; deviceToken: string; deviceUsage: 'KDS' | 'DID' }
	| { deviceType: 'V1_WAIVER'; deviceToken: string; isOTP: boolean } // JIN 240816 바운스 확인 끝나고 없앨겁니다.
	| { deviceType: 'DISPLAYER'; deviceToken: string };

type LocalStorageLoginDevicePayloadType =
	| {
			deviceType: 'WAIVER';
			storeId: number;
			deviceId: number;
			enterpriseId: string;
			isOTPLogined: boolean;
	  }
	| {
			deviceType: 'SIGNUP';
			storeId: number;
			deviceId: number;
			enterpriseId: string;
	  };

const DeviceLoginAtom = atom<LoginDevicePayloadType | undefined>(undefined);
const StorageDeviceLoginAtom = atomWithStorage<LocalStorageLoginDevicePayloadType | undefined>(
	'waiverInfo',
	undefined
);

const curStoreAndDeviceAtom = atom<Promise<StoreAndDeviceType>>(async (get) => {
	const payloadWithToken = get(DeviceLoginAtom);
	const payloadWithLocalStorage = get(StorageDeviceLoginAtom);

	const payload = payloadWithToken || payloadWithLocalStorage;

	if (!payload)
		throw Error('[DEV] Proivider 를 통해 DeviceLoginAtom, WaiverDeviceLoginAtom 을 사용하십시오');

	switch (payload.deviceType) {
		case 'POS':
			return {
				storeId: payload.storeId,
				deviceId: payload.deviceId,
				enterpriseId: payload.enterpriseId,
				deviceType: 'POS',
			};
		case 'KIOSK':
			return {
				storeId: payload.storeId,
				deviceId: payload.deviceId,
				enterpriseId: payload.enterpriseId,
				deviceType: 'KIOSK',
			};
		// TOKEN 기반 로그인 동작
		case 'DISPLAYER':
		case 'KDS': {
			const deviceToken = await DeviceTokenApis.login(
				payload.deviceToken || '',
				payload.deviceType
			);

			if (deviceToken.content.deviceType === payload.deviceType)
				return {
					storeId: deviceToken.content.storeId,
					deviceId: deviceToken.content.deviceId,
					deviceType: deviceToken.content.deviceType,
					enterpriseId: deviceToken.enterpriseInfo.id,
					enterpriseInfo: deviceToken.enterpriseInfo,
				};
			else throw Error();
		}
		// JIN 240816 바운스 확인 끝나면 지웁니다
		case 'V1_WAIVER': {
			if (!payload.isOTP) {
				const deviceToken = await DeviceTokenApis.login(payload.deviceToken || '', 'WAIVER');
				if (deviceToken.content.deviceType === 'WAIVER')
					return {
						storeId: deviceToken.content.storeId,
						deviceId: deviceToken.content.deviceId,
						deviceType: 'V1_WAIVER',
						enterpriseId: deviceToken.enterpriseInfo.id,
						enterpriseInfo: deviceToken.enterpriseInfo,
					};
				else throw Error();
			} else {
				const deviceToken = await DeviceTokenApis.loginByOTP(payload.deviceToken || '', 'WAIVER');
				return {
					storeId: deviceToken.content.storeId,
					deviceId: 0,
					deviceType: 'V1_WAIVER',
					enterpriseId: deviceToken.enterpriseInfo.id,
					enterpriseInfo: deviceToken.enterpriseInfo,
				};
			}
		}
		case 'WAIVER': {
			// 여기는 실제 로그인 방식을 사용합니다.
			return {
				storeId: payload.storeId,
				deviceId: payload.deviceId,
				enterpriseId: payload.enterpriseId,
				deviceType: 'WAIVER',
				isOTPLogined: payload.isOTPLogined,
			};
		}
		case 'SIGNUP': {
			// 여기는 실제 로그인 방식을 사용합니다.
			return {
				storeId: payload.storeId,
				deviceId: payload.deviceId,
				enterpriseId: payload.enterpriseId,
				deviceType: 'SIGNUP',
				isOTPLogined: false,
			};
		}
		default:
			throw Error('잘못된 장치에 대한 로그인 요청입니다.');
	}
});

const curStoreInfoAtom = atom((get) => {
	const storeAndDevice = get(curStoreAndDeviceAtom);
	return AdminStoreInfoApis.getStoreInfoDetail({
		storeId: storeAndDevice.storeId,
	});
});

const curPosInfoSelector = atom((get) => {
	const storeAndDevice = get(curStoreAndDeviceAtom);

	const result = get(curStoreInfoAtom).posInfoList.find(
		(posInfo) => posInfo.id === storeAndDevice.deviceId
	);
	if (result) return result;

	// 나머지
	throw new Error('[NotFound] Pos Info Error');
});

const curWaiverDeviceSelector = atom(async (get) => {
	const storeAndDevice = get(curStoreAndDeviceAtom);

	if (['WAIVER', 'SIGNUP'].includes(storeAndDevice.deviceType))
		return storeAndDevice as StoreAndWaiverDeviceType;

	// 나머지
	throw new Error('[NotFound] Pos Info Error');
});

const useKitchenSystemInfo = () => {
	const posInfo = useAtomValue(curPosInfoSelector);

	const { data: kitchenSystemInfo } = useQuery({
		...AdminKitchenSystemInfoState.keys.detail(posInfo.kitchenSystemId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (posInfo.kitchenSystemId) {
		return kitchenSystemInfo;
	} else {
		return null;
	}
};

const useCurDeviceInfoForOrder = () => {
	const storeAndDevice = useAtomValue(curStoreAndDeviceAtom);

	const { data: posInfo } = useQuery({
		...AdminPosInfoState.keys.detail({
			storeId: storeAndDevice.storeId,
			posId: storeAndDevice.deviceType === 'POS' ? storeAndDevice.deviceId : undefined,
		}),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({
			storeId: storeAndDevice.storeId,
			kioskId: storeAndDevice.deviceType === 'KIOSK' ? storeAndDevice.deviceId : undefined,
		}),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const deviceInfo = storeAndDevice.deviceType === 'POS' ? posInfo : kioskInfo;

	if (!deviceInfo) throw Error('[DEV] POS / KIOSK 가 아닙니다.');

	const deviceLabel = getSalesDeviceInfoName(deviceInfo);

	return {
		id: deviceInfo.id,
		label: deviceLabel,
		deviceType: storeAndDevice.deviceType,
		vanCompany: deviceInfo.vanCompany,
		printMode: deviceInfo.printMode,
		labelPrinterList: deviceInfo.labelPrinterList,
		receiptPrinterList: deviceInfo.receiptPrinterList,
	};
};

const useKioskLoginInfo = () => {
	// JIN: 너무 이러기 싷은데 atom 의 try catch 에러로 인해 어떠한 편법도 통하지가 않는다...
	return useAtomValue(StoreInfoState.deviceLoginInfo) as
		| { deviceType: 'KIOSK'; storeId: number; deviceId: number }
		| undefined;
};

const useCurStoreJungsanInfo = (): JungsanInfoType => {
	const { policy, id, overtimeFee, overtime } = useAtomValue(curStoreInfoAtom);
	const { jungsanProductId } = policy;

	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId: id });

	return jungsanProductId
		? {
				type: 'PRODUCT',
				productInfo: productMap[jungsanProductId],
		  }
		: {
				type: 'POLICY',
				overtimeFeePolicy: overtimeFee,
				overtimeUnit: overtime,
		  };
};

export const StoreInfoState = {
	deviceLoginInfo: DeviceLoginAtom,
	storageDeviceLoginInfo: StorageDeviceLoginAtom,
	curStoreAndDevice: curStoreAndDeviceAtom,
	curStoreInfo: curStoreInfoAtom,
	curPosInfo: curPosInfoSelector,
	curWaiverDevice: curWaiverDeviceSelector,
	useCurDeviceInfoForOrder,
	useKioskLoginInfo,
	useKitchenSystemInfo,
	useCurStoreJungsanInfo,
	// 결제 모듈에선 KIOSK, POS 양측 모두 사용하는 경우가 있어서 아래 로직 사용
};
