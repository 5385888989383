import { Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { PrevNextButton } from '../../components/UserInfoPageComponents/PrevNextButton';
import { TitleWithDesc } from '../../components/UserInfoPageComponents/TitleWithDesc';
import { UserInfoPageTemplate } from './UserInfoPageTemplate';

type NameCheckProps = {
	name?: string;
	setName: (name: string) => Promise<void>;
	goNextStep: () => void;
	goPrevStep: () => void;
};

export const UserNamePage = ({ props }: { props: NameCheckProps }) => {
	const { name, setName, goNextStep, goPrevStep } = props;
	const { values, handleSubmit, handleBlur, handleChange, touched, errors, isValid } = useFormik({
		initialValues: { name: name ?? '' },
		onSubmit: async ({ name }) => {
			await setName(name);
			goNextStep();
		},
		validateOnMount: true,
		enableReinitialize: true,
	});
	return (
		<UserInfoPageTemplate>
			<Stack
				width={'100%'}
				spacing={5}>
				<TitleWithDesc
					title={'이름 입력'}
					desc={'이름을 입력해 주세요.'}
				/>
				<TextField
					fullWidth
					type={'text'}
					name={'name'}
					value={values.name}
					onChange={handleChange}
					onBlur={handleBlur}
					error={!!(touched.name && errors.name)}
					helperText={(touched.name && errors.name) || 'ㅤ'}
					placeholder={'예)홍길동'}
					// autoFocus
				/>
				<PrevNextButton
					nextDisabled={!isValid}
					prevFn={goPrevStep}
					nextFn={handleSubmit}
				/>
			</Stack>
		</UserInfoPageTemplate>
	);
};
