import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { Pageable } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getAllGuestVisitAsync =
	(rootUrl: string) =>
	async ({ from, to, page, size, sort }: RequestType) =>
		(
			await posAxiosClient.get<{ storeId: number; count: number }[]>(
				`${rootUrl}/statistics/store/visit`,
				{
					params: {
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;

type RequestType = {
	from: Dayjs;
	to: Dayjs;
	page: number;
	size?: number;
	sort?: Pageable['sort'];
};
