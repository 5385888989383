import { DdiziApi, OrderAPI } from '@kinderlabs-pos/apis/pos';
import {
	BaseInfoType,
	DdiziTypeToJungsan,
	JungsanInfoType,
	OrderTypeWithDdizisAndExchanges,
	StoreDetailInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { DdiziState, OrderState, QueryClient, StoreInfoState } from '@kinderlabs-pos/state';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';

export const orderForDdiziKeys = createQueryKeys('orderforddizi', {
	order: ({
		posId,
		storeInfo,
		keywordToSearch,
		ticketSharedStoreIdList,
	}: {
		posId: number;
		storeInfo: StoreDetailInfoType & BaseInfoType;
		keywordToSearch: string;
		ticketSharedStoreIdList: number[];
	}) => ({
		queryKey: [keywordToSearch],
		queryFn: async () => {
			const res = await OrderAPI.searchForDdizi({
				storeId: storeInfo.id,
				posId: posId,
				search: keywordToSearch,
				storeIdList: [...ticketSharedStoreIdList, storeInfo.id],
			});
			return res;
		},
	}),
	ddizis: ({
		orderId,
		keyword,
		date,
		curStoreInfo,
	}: {
		orderId: string;
		keyword: string;
		date: Date;
		curStoreInfo: StoreDetailInfoType & BaseInfoType;
	}) => ({
		queryKey: [orderId, keyword],
		queryFn: async () => {
			const res = await DdiziApi.getListInOrder({
				orderId,
			});

			return res.map((ddizi) =>
				DdiziState.utils.getDdiziToJungsan({
					ddizi,
					timeStandard: date,
					overtimePolicy: curStoreInfo.overtime,
				})
			);
		},
	}),
});

const useOrderList = (
	keyword: string | null,
	selectOrder: (order: OrderTypeWithDdizisAndExchanges | undefined) => void
) => {
	const keywordToSearch = keyword || '';
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const posId = useAtomValue(StoreInfoState.curStoreAndDevice).deviceId;
	const ticketSharedStoreIdList = storeInfo.policy.ticketShareStoreIdList || [];

	const result = useQuery({
		...orderForDdiziKeys.order({ posId, storeInfo, keywordToSearch, ticketSharedStoreIdList }),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (!result.data || result.data.length === 0) {
			selectOrder(undefined);
		} else {
			selectOrder(result.data[0]);
		}
	}, [result.data, result.isFetching, keywordToSearch]);

	if (result.isSuccess) {
		return result.data;
	} else {
		throw new Error();
	}
};

const useDdiziListInOrder = (
	orderId: string,
	keyword: string,
	date: Date,
	refetchOnWindowFocus?: boolean
) => {
	const curStoreInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const jungsanInfo = StoreInfoState.useCurStoreJungsanInfo();
	const [ddiziListInOrder, setDdiziListInOrder] = useAtom(ddiziListInOrderAtom);

	const result = useQuery({
		...orderForDdiziKeys.ddizis({ orderId, keyword, date, curStoreInfo }),
		keepPreviousData: true,
		refetchOnWindowFocus: refetchOnWindowFocus || true,
	});

	useEffect(() => {
		if (result.isSuccess) {
			setDdiziListInOrder({
				ddizis: result.data,
				keyword: keyword,
				overTime: curStoreInfo.overtime,
				jungsanInfo,
			});
		}
	}, [result.data, result.isFetching]);

	return ddiziListInOrder;
};

const _prevKeywordAtom = atom<string>('');
const _ddiziListInOrderAtom = atom<DdiziTypeToJungsan[]>([]);
const ddiziListInOrderAtom = atom<
	DdiziTypeToJungsan[],
	{
		ddizis: DdiziTypeToJungsan[];
		keyword: string;
		overTime: number;
		jungsanInfo: JungsanInfoType;
	}
>(
	(get) => get(_ddiziListInOrderAtom),
	(get, set, { ddizis, keyword, jungsanInfo }) => {
		set(_ddiziListInOrderAtom, ddizis);

		const prevKeyword = get(_prevKeywordAtom);
		const targetDdizi = ddizis.find((ddizi) => ddizi.id === keyword);

		if (!targetDdizi) {
			// DdiziId가 아닌 내용으로 검색되었을때 기존 context를 비운다.
			set(OrderState.value, {
				type: 'CART',
				subAction: {
					type: 'EXCESS_CHARGE',
					subAction: {
						type: 'CLEAR',
					},
				},
			});
			return;
		}

		if (prevKeyword === keyword) {
			// 같은 키워드로 검색된 상황에서도 데이터가 변경되었을 것을 대비하여 refresh 한다.
			set(OrderState.value, {
				type: 'CART',
				subAction: {
					type: 'EXCESS_CHARGE',
					subAction: {
						type: 'REFRESH',
						ddizis: ddizis,
						jungsanInfo,
					},
				},
			});
			return;
		}

		set(_prevKeywordAtom, keyword);

		if (!DdiziState.utils.isDdiziSelectableForJungsan(targetDdizi)) return;

		// 최종 Intial Select를 진행한다.
		set(OrderState.value, {
			type: 'CART',
			subAction: {
				type: 'EXCESS_CHARGE',
				subAction: {
					type: 'INITIAL_SELECT',
					ddizi: targetDdizi,
					jungsanInfo,
				},
			},
		});
	}
);

const invalidateDdiziQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: orderForDdiziKeys.ddizis._def });
	QueryClient.origin.invalidateQueries({ queryKey: orderForDdiziKeys.ddizis._def });
};

const invalidateOrderQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: orderForDdiziKeys.order._def });
	QueryClient.origin.invalidateQueries({ queryKey: orderForDdiziKeys.order._def });
};

export const ExitQueryState = {
	ddiziListInOrderAtom,
	useOrderList,
	useDdiziListInOrder,
	invalidateDdiziQueries,
	invalidateOrderQueries,
};

// 1 : Ddizi 를 그냥 가져오는것 = react query
// 1 번 callback onSuccess : keyword 랑 매칭 여부를 해결할 수 있는건

// 2-POS : 1번을감싸고 fixedTime 을 받아서 use Memo 로 DdiziTypeToJungsan 만들어주고 1번 callback 넣어주는 것
// 2-KIOSK : 1번을감싸고 fixedTime 을 받아서 use Memo 로 DdiziTypeToJungsan 만들어주고 1번 callback 넣어주는 것
