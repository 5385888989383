import { GuestMemberInfoType, VisitUserGendersType } from '@kinderlabs-pos/shared-data-type';
import { FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { PrevNextButton } from '../../components/UserInfoPageComponents/PrevNextButton';
import { TitleWithDesc } from '../../components/UserInfoPageComponents/TitleWithDesc';
import { UserInfoPageTemplate } from './UserInfoPageTemplate';

type GenderCheckProps = {
	gender?: VisitUserGendersType;
	setGender: (gender: VisitUserGendersType) => Promise<void>;
	goNextStep: () => void;
	goPrevStep: () => void;
};
export const UserGenderPage = ({ props }: { props: GenderCheckProps }) => {
	const { gender, setGender, goNextStep, goPrevStep } = props;
	const { values, handleChange, handleSubmit } = useFormik({
		initialValues: { gender: gender ?? 'UNKNOWN' },
		onSubmit: async ({ gender }) => {
			await setGender(gender);
			goNextStep();
		},
		validateOnMount: true,
		enableReinitialize: true,
	});

	return (
		<UserInfoPageTemplate>
			<Stack
				sx={{ width: '100%' }}
				spacing={5}>
				<TitleWithDesc
					title={'성별'}
					desc={'성별을 선택해주세요.'}
				/>
				<RadioGroup
					row
					value={values.gender}
					onChange={handleChange}>
					<FormControlLabel
						value={'MAN' as GuestMemberInfoType['gender']}
						sx={{ flex: 1, justifyContent: 'center' }}
						control={
							<Radio
								onChange={handleChange}
								name={'gender'}
								sx={{
									'& .MuiSvgIcon-root': {
										fontSize: 40,
									},
								}}
							/>
						}
						label={'남성'}
					/>
					<FormControlLabel
						value={'WOMAN' as GuestMemberInfoType['gender']}
						sx={{ flex: 1, justifyContent: 'center' }}
						control={
							<Radio
								onChange={handleChange}
								name={'gender'}
								sx={{
									'& .MuiSvgIcon-root': {
										fontSize: 40,
									},
								}}
							/>
						}
						label={'여성'}
					/>
				</RadioGroup>
				<PrevNextButton
					prevFn={goPrevStep}
					nextFn={handleSubmit}
				/>
			</Stack>
		</UserInfoPageTemplate>
	);
};
