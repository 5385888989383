import { SquareButton } from '@kinderlabs-pos/ui-atoms';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { Box, Typography, styled } from '@mui/material';

export interface ITelephoneBoardProps {
	value: string;
	// setValue: React.Dispatch<React.SetStateAction<number>>;
	setValue: (input: string) => void;
	disabled?: boolean;
	mode: 'telephone' | 'number' | 'money';
}
export const TelephoneBoard: React.FC<ITelephoneBoardProps> = ({
	value,
	setValue,
	disabled = false,
	mode,
}) => {
	const isMaxLength = value.length >= 11;

	const handleAddNumber = (val: string) => () => {
		if (isMaxLength || val === '') {
			// ignored
		} else {
			setValue(`${value}${val}`);
		}
	};
	const handleDeleteNumber = () => {
		if (value.length === 0) return;
		setValue(value.slice(0, -1));
	};

	const handleClearNumber = () => {
		setValue(mode === 'telephone' ? '010' : '');
	};

	return (
		<Box
			// width={480}
			display='grid'
			gridTemplateColumns={'repeat(3, 80px)'}
			gridTemplateRows={'repeat(5, 60px)'}>
			<StyledButton
				disabled={disabled}
				onClick={handleClearNumber}
				sx={{ gridColumn: '1 / 3' }}>
				<Typography sx={{ fontSize: 20 }}>{'Clear'}</Typography>
			</StyledButton>
			<StyledButton
				disabled={disabled}
				onClick={handleDeleteNumber}>
				<BackspaceIcon />
			</StyledButton>
			{['1', '2', '3', '4', '5', '6', '7', '8', '9', '', '0', ''].map((val, idx) => (
				<StyledButton
					key={idx}
					sx={{ flex: 1, bgcolor: 'white' }}
					disabled={disabled}
					onClick={handleAddNumber(val)}>
					<Typography sx={{ fontSize: 20 }}>{val}</Typography>
				</StyledButton>
			))}
		</Box>
	);
};

const StyledButton = styled(SquareButton)(({ theme }) => ({
	height: 60,
	border: '1px solid lightgray',
	'& .MuiTypgraphy-root': {
		fontSize: theme.typography.subtitle1.fontSize,
	},
}));
