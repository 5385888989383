import { ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { baseThemeWithFontFamily } from '../base/realBaseTheme';
import { b2cComponentOverrides } from './b2cComponentOverrides';
import { b2cPaletteOptions } from './b2cPaletteOptions';
import { b2cTypographyWithFontFamily } from './b2cTypographyWithFontFamily';
import '@noonnu/spoqahansansneo-regular';

const fontFamily = 'SpoqaHanSansNeo-Regular';
export const b2CMainThemeOption: ThemeOptions = deepmerge(
  baseThemeWithFontFamily(fontFamily),
  {
    palette: b2cPaletteOptions,
    typography: b2cTypographyWithFontFamily(fontFamily),
    components: b2cComponentOverrides,
    transition: {
      easing: {
        // This is the most common easing curve.
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        // Objects enter the screen at full velocity from off-screen and
        // slowly decelerate to a resting point.
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        // Objects leave the screen at full velocity. They do not decelerate when off-screen.
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        // The sharp curve is used by objects that may return to the screen at any time.
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      },
    },
  }
);
