import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { MemoStatusType, MemoType } from '@kinderlabs-pos/shared-data-type';

export const changeMemoStatusAsync =
	(rootUrl: string) => async (params: { memoId: number; status: MemoStatusType }) =>
		(
			await posAxiosClient.put<MemoType>(`${rootUrl}/memo/status`, undefined, {
				params: {
					memoId: params.memoId,
					status: params.status,
				},
			})
		).data;
