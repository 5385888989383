import styled from '@emotion/styled';
import { Box } from '@mui/material';
import defaultKioskItemJpg from 'assets/defaultkioskitem.jpg';

export const ProductImageInKioskBoard = styled(Box)<{ backgroundImage?: string; size: number }>(
	({ backgroundImage, size }) => ({
		backgroundImage: `url(${backgroundImage || defaultKioskItemJpg})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		width: size,
		aspectRatio: '1/1',
	})
);
