import { CartLineInfoType } from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';

/**
 * 타겟 카트라인이 2개이상이라도 반드시 할인이나 옵션이 1개만 들어가도록 조치하기 위한 전초작업
 * 타겟 카트라인의 Quantity 가 2개 이상이면 카트라인을 새로 하나 만듬 + 옵션 복사
 * @param cartLinesInput
 * @param targetCartLine
 * @returns
 */
export const adjustTargetCartLineForOneQuantity = (
	cartLinesInput: CartLineInfoType[],
	targetCartLineId: string
) => {
	const cartLines = JSON.parse(JSON.stringify(cartLinesInput)) as CartLineInfoType[];

	const targetCartLineIndex = cartLines.findIndex((cl) => cl.id === targetCartLineId);
	const targetCartLine = cartLines[targetCartLineIndex];

	if (targetCartLine.quantity > 1) {
		cartLines[targetCartLineIndex].quantity--;
		// 이렇게 조회랑 커맨드를 같이 쓰면 안 되지만...
		const targetOptionCartLines = cartLines.filter((cl, idx) => {
			if (cl.targetCartLineId === cartLines[targetCartLineIndex].id) {
				cartLines[idx].quantity--;
				return true;
			} else return false;
		});
		const newTargetCartLine = {
			...targetCartLine,
			quantity: 1,
			id: getUuidV4(),
		};

		cartLines.push(newTargetCartLine);
		targetOptionCartLines.forEach((cl) => {
			cartLines.push({
				...cl,
				id: getUuidV4(),
				quantity: 1,
				targetCartLineId: newTargetCartLine.id,
			});
		});

		return {
			cartLines,
			targetCartLine: newTargetCartLine,
			targetCartLineIndex: cartLines.findIndex((cl) => cl.id === newTargetCartLine.id),
		};
	} else {
		return {
			cartLines,
			targetCartLine,
			targetCartLineIndex,
		};
	}
};
// if (targetCartLine.quantity > 1) {
//   draft[targetCartLineIndex].quantity--;

//   // 이렇게 조회랑 커맨드를 같이 쓰면 안 되지만...
//   const targetOptionCartLines = draft.filter((cl, idx) => {
//     if (cl.targetCartLineId === draft[targetCartLineIndex].id) {
//       draft[idx].quantity--;
//       return true;
//     } else return false;
//   });

//   const newTargetCartLine = {
//     ...targetCartLine,
//     quantity: 1,
//     id: getUuidV4(),
//   };

//   const inputCartLine = createOptionCartLineInput({
//     cartLineId: action.cartLineId,
//     productInfo: action.productInfo,
//     targetCartLineId: newTargetCartLine.id,
//   });

//   draft.push(newTargetCartLine);
//   draft.push(inputCartLine);

//   targetOptionCartLines.forEach((cl) => {
//     draft.push({
//       ...cl,
//       id: getUuidV4(),
//       quantity: 1,
//       targetCartLineId: newTargetCartLine.id,
//     });
//   });

//   action.onComplete && action.onComplete(newTargetCartLine);
// } else {
//   const inputCartLine = createOptionCartLineInput({
//     cartLineId: action.cartLineId,
//     productInfo: action.productInfo,
//     targetCartLineId: targetCartLine.id,
//   });

//   // 맨뒤에 옵션 추가
//   const targetIndex = targetCartLineIndex + 1;
//   const alreadyExistOptionLength = draft.filter(
//     (cl) => cl.targetCartLineId === targetCartLine.id
//   ).length;

//   draft.splice(targetIndex + alreadyExistOptionLength, 0, inputCartLine);
// }
// });
