import {
	PosBoardEmptyItemType,
	PosBoardInfoType,
	PosBoardItemType,
} from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updatePosBoardItemInfoListAsync =
	(rootUrl: string) =>
	async ({ boardId, itemList }: UpdatePosBoardInfoRequestType) => {
		return (
			await posAxiosClient.put<PosBoardInfoType>(`${rootUrl}/pos-board/${boardId}/item/bulk`, {
				boardItemList: itemList,
			})
		).data;
	};

type UpdatePosBoardInfoRequestType = {
	itemList: (Omit<PosBoardItemType, 'productInfo'> | Omit<PosBoardEmptyItemType, 'productInfo'>)[];
	boardId: number;
};
