import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { DeviceTokenType, LoginResultType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
import { rootUrl } from '../const';

type DeviceTokenRequestType = Pick<
	DeviceTokenType,
	'storeId' | 'deviceId' | 'ipAddress' | 'deviceType'
>;

type GetTokenHistoryRequestType = {
	pageable: Pageable;
	from: Dayjs;
	to: Dayjs;
	columnName?: string;
	id?: number;
};

const publishTokenAsync = (rootUrl: string) => async (payload: DeviceTokenRequestType) =>
	(await posAxiosClient.post<DeviceTokenType>(`${rootUrl}/auth-service/store-token/`, payload))
		.data;

const reissueTokenAsync = (rootUrl: string) => async (payload: DeviceTokenRequestType) =>
	(
		await posAxiosClient.post<DeviceTokenType>(
			`${rootUrl}/authenticate/store-token/reissue`,
			payload
		)
	).data;

const getTokenAsync =
	(rootUrl: string) =>
	async ({
		storeId,
		deviceId,
		deviceType,
	}: Pick<DeviceTokenType, 'storeId' | 'deviceId' | 'deviceType'>) =>
		(
			await posAxiosClient.get<DeviceTokenType>(`${rootUrl}/auth-service/store-token/`, {
				params: {
					storeId,
					deviceId,
					deviceType,
				},
			})
		).data;

const getTokenHistoryAsync =
	(rootUrl: string) =>
	async ({ pageable, from, to, id, columnName }: GetTokenHistoryRequestType) =>
		(
			await posAxiosClient.get<DeviceTokenType>(`${rootUrl}/auth-service/store-token/history`, {
				params: {
					pageable: pageable.getPageableQueryParam(),
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					id: id,
					columnName: columnName,
				},
			})
		).data;

const getLoginAsync =
	(rootUrl: string) => async (storeToken: string, target: 'DISPLAYER' | 'WAIVER' | 'KDS') => {
		const result = await posAxiosClient.post<LoginResultType<DeviceTokenType>>(
			`${rootUrl}/authenticate/store-token/login`,
			{
				storeToken,
				target,
			}
		);

		localStorage.setItem('accessToken', result.data.accessToken);
		localStorage.setItem('refreshToken', result.data.refreshToken);
		localStorage.setItem('enterpriseHash', result.data.enterpriseInfo.hash);

		return result.data;
	};

const getLoginByOTPAsync = (rootUrl: string) => async (storeToken: string, target: 'WAIVER') => {
	const result = await posAxiosClient.post<
		LoginResultType<{
			storeId: number;
			updated: Date;
			prevToken: string;
			nowToken: string;
		}>
	>(`${rootUrl}/authenticate/store-token/otp/login`, {
		storeToken,
		target,
	});

	localStorage.setItem('accessToken', result.data.accessToken);
	localStorage.setItem('refreshToken', result.data.refreshToken);
	localStorage.setItem('enterpriseHash', result.data.enterpriseInfo.hash);

	return result.data;
};

export const DeviceTokenApis = {
	// 발급
	publishToken: publishTokenAsync(rootUrl),
	// 재발급
	reissueToken: reissueTokenAsync(rootUrl),
	// 조회
	getToken: getTokenAsync(rootUrl),
	// 로그인
	login: getLoginAsync(rootUrl),
	// 로그인
	loginByOTP: getLoginByOTPAsync(rootUrl),
	// 내역 조회
	getTokenHistory: getTokenHistoryAsync(rootUrl),
};
