import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { List, ListItem, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAtomValue } from 'jotai';

export const CartSummary = ({}) => {
	const { totalSum, totalDiscount, ticketCount, totalToPay } = useAtomValue(
		OrderState.aggregate.cart
	);

	const { totalPaid, remainingPay } = useAtomValue(OrderState.aggregate.payments);

	return (
		<List disablePadding>
			{[
				{
					label: '총 티켓 수',
					value: `${ticketCount} 개`,
				},
				{
					label: '총 합',
					value: numberWithCommasAndWon(totalSum),
				},
				{
					label: '총 상품 할인',
					value: `- ${numberWithCommasAndWon(totalDiscount)}`,
				},
				{
					label: '이미 지불한 금액',
					value: `${numberWithCommasAndWon(totalPaid)}`,
				},
				{
					label: '남은 금액',
					value: `${numberWithCommasAndWon(remainingPay)}`,
				},
			].map(({ label, value }, idx) => (
				<ListItem
					key={idx}
					sx={{ borderTop: `1px solid ${grey[300]}` }}>
					<Stack
						direction={'row'}
						alignItems={'center'}
						width={'100%'}
						spacing={1}>
						<Typography
							variant={'subtitle1'}
							sx={{ flex: 1 }}>
							{label}
						</Typography>
						<Typography
							variant={'h5'}
							sx={{ textAlign: 'right' }}>
							{value}
						</Typography>
					</Stack>
				</ListItem>
			))}
		</List>
	);
};

CartSummary.OnTable = ({}) => {
	const { totalSum, ticketCount, totalDiscount, totalToPay } = useAtomValue(
		OrderState.aggregate.cart
	);

	const cartLines = useAtomValue(OrderState.cart.selector).lines;
	const totalCount = cartLines.reduce((result, cl) => result + cl.quantity, 0);

	return (
		<List disablePadding>
			<ListItem sx={{ borderTop: `1px solid ${grey[300]}` }}>
				<Stack
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
					spacing={1}>
					<Stack
						flex={1}
						direction={'row'}
						alignItems={'center'}>
						<Typography
							variant={'subtitle1'}
							sx={{ flex: 1 }}>
							{'총 상품 개수'}
						</Typography>
						<Typography
							variant={'h3'}
							sx={{ textAlign: 'right' }}>
							{`${totalCount} 개`}
						</Typography>
					</Stack>
					<Stack
						flex={1}
						direction={'row'}
						alignItems={'center'}>
						<Typography
							variant={'subtitle1'}
							sx={{ flex: 1 }}>
							{'총 티켓 개수'}
						</Typography>
						<Typography
							variant={'h3'}
							sx={{ textAlign: 'right' }}>
							{`${ticketCount} 장`}
						</Typography>
					</Stack>
				</Stack>
			</ListItem>
			<ListItem sx={{ borderTop: `1px solid ${grey[300]}` }}>
				<Stack
					direction={'row'}
					alignItems={'center'}
					width={'100%'}
					spacing={1}>
					<Typography
						variant={'subtitle1'}
						sx={{ flex: 1 }}>
						{'총 결제 금액'}
					</Typography>
					<Stack>
						<Typography
							variant={'h1'}
							sx={{ textAlign: 'right' }}>
							{`${numberWithCommasAndWon(totalToPay)}`}
						</Typography>
						<Typography
							variant={'subtitle2'}
							sx={{ textAlign: 'right' }}
							color={'primary'}>
							{`${numberWithCommasAndWon(totalSum)}  -  ${numberWithCommasAndWon(totalDiscount)}`}
						</Typography>
					</Stack>
				</Stack>
			</ListItem>
		</List>
	);
};

CartSummary.Mini = ({}) => {
	const { totalSum, totalDiscount, ticketCount, totalToPay } = useAtomValue(
		OrderState.aggregate.cart
	);

	const { totalPaid, remainingPay } = useAtomValue(OrderState.aggregate.payments);

	return (
		<List disablePadding>
			{[
				{
					label: '총 합',
					value: numberWithCommasAndWon(totalSum - totalDiscount),
				},
				{
					label: '이미 지불한 금액',
					value: `${numberWithCommasAndWon(totalPaid)}`,
				},
				{
					label: '남은 금액',
					value: `${numberWithCommasAndWon(remainingPay)}`,
				},
			].map(({ label, value }, idx) => (
				<ListItem
					key={idx}
					sx={{ borderTop: `1px solid ${grey[300]}` }}>
					<Stack
						direction={'row'}
						alignItems={'center'}
						width={'100%'}
						spacing={1}>
						<Typography
							variant={'subtitle1'}
							sx={{ flex: 1 }}>
							{label}
						</Typography>
						<Typography
							variant={'h5'}
							sx={{ textAlign: 'right' }}>
							{value}
						</Typography>
					</Stack>
				</ListItem>
			))}
		</List>
	);
};
