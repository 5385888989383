import { canceledStyleUtils } from '@kinderlabs-pos/shared-util';
import { alpha, ListItem, ListItemButton, styled } from '@mui/material';

export const CartListItemButton = styled(ListItemButton, {
	shouldForwardProp: (props) =>
		props !== 'clicked' &&
		props !== 'color' &&
		props !== 'selectable' &&
		props !== 'highlight' &&
		props !== 'size',
})<{
	color?: string;
	clicked?: boolean;
	highlight?: 'quantity-increase' | 'quantity-decrease' | 'canceled';
	size?: 'small';
}>(({ size, color, clicked = false, theme, highlight }) => ({
	height: size === 'small' ? 42 : 52,
	backgroundColor: clicked ? `${alpha(theme.palette.primary.main, 0.2)}` : color,
	'&:hover': {
		backgroundColor: `${alpha(theme.palette.primary.main, clicked ? 0.3 : 0.1)}`,
	},
	'& .MuiTypography-root': {
		...(highlight === 'quantity-increase' && {
			'&.quantity': {
				color: theme.palette.success.main,
			},
			'&.quantitychange': {
				color: theme.palette.success.main,
			},
			'&.price': {
				color: theme.palette.success.main,
			},
		}),
		...(highlight === 'quantity-decrease' && {
			'&.quantity': {
				color: theme.palette.error.main,
			},
			'&.quantitychange': {
				color: theme.palette.error.main,
			},
			'&.price': {
				color: theme.palette.error.main,
			},
		}),
		...(highlight === 'canceled' && {
			'&.name': canceledStyleUtils(true),
			'&.quantity': canceledStyleUtils(true),
			'&.price': canceledStyleUtils(true),
		}),
	},
}));

export const CartListItem = styled(ListItem, {
	shouldForwardProp: (props) =>
		props !== 'clicked' &&
		props !== 'color' &&
		props !== 'selectable' &&
		props !== 'highlight' &&
		props !== 'size',
})<{
	color?: string;
	clicked?: boolean;
	highlight?: 'quantity-increase' | 'quantity-decrease' | 'canceled';
	size?: 'small';
}>(({ size, color, clicked = false, theme, highlight }) => ({
	height: size === 'small' ? 42 : 52,
	backgroundColor: color,
	pointerEvents: 'none',
	'& .MuiTypography-root': {
		...(highlight === 'quantity-increase' && {
			'&.quantity': {
				color: theme.palette.success.main,
			},
			'&.quantitychange': {
				color: theme.palette.success.main,
			},
			'&.price': {
				color: theme.palette.success.main,
			},
		}),
		...(highlight === 'quantity-decrease' && {
			'&.quantity': {
				color: theme.palette.error.main,
			},
			'&.quantitychange': {
				color: theme.palette.error.main,
			},
			'&.price': {
				color: theme.palette.error.main,
			},
		}),
		...(highlight === 'canceled' && {
			'&.name': canceledStyleUtils(true),
			'&.quantity': canceledStyleUtils(true),
			'&.price': canceledStyleUtils(true),
		}),
	},
}));
