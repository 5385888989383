import {
	DayCalendarFilterOptionType,
	MonthCalendarFilterOptionType,
} from '@kinderlabs-pos/shared-data-type';
import {
	AdminDatePicker,
	AdminMonthPicker,
	WithDialog,
	XlDialogProps,
} from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

const today = dayjs();
const rangeMonth = 12;
const rangeDayErrorMessage = `날짜 선택 범위는 ${rangeMonth}일 이내로 설정해주세요`;
const rangeMonthErrorMessage = `월별 날짜 선택 범위는 ${rangeMonth} 개월 이내로 설정해주세요`;
const fromIsLaterThanToErrorMessage = `날짜 선택이 잘못되었습니다.`;
export interface ICalendarSelectDialogProps extends XlDialogProps {
	option: MonthCalendarFilterOptionType;
	setOption: (option: MonthCalendarFilterOptionType) => void;
}

export const MonthSelectDialog: React.FC<ICalendarSelectDialogProps> = ({
	open,
	closeDialog,
	option,
	setOption,
}) => {
	const [tempFrom, setTempFrom] = useState(() => {
		if (option.fromMonth) {
			try {
				return dayjs(option.fromMonth, 'YYYY-MM');
			} catch {
				return today.startOf('month');
			}
		} else return today.startOf('month');
	});
	const [tempTo, setTempTo] = useState(() => {
		if (option.toMonth) {
			try {
				return dayjs(option.toMonth, 'YYYY-MM');
			} catch {
				return today.endOf('month');
			}
		} else return today.endOf('month');
	});
	const [error, setError] = useState<string | null>(null);

	const setSearchTimeFormFilter = () => {
		setOption({
			fromMonth: tempFrom.format('YYYY-MM'),
			toMonth: tempTo.format('YYYY-MM'),
		});
	};

	const handleTempFromChange = (val: Dayjs | null) => {
		if (val) {
			if (val.isAfter(tempTo)) {
				setError(fromIsLaterThanToErrorMessage);
			} else if (tempTo.diff(val, 'month') > rangeMonth) {
				setError(rangeMonthErrorMessage);
			} else {
				setError(null);
			}
			setTempFrom(val);
		}
	};

	const handleTempToChange = (val: Dayjs | null) => {
		if (val) {
			if (tempFrom.isAfter(val)) {
				setError(fromIsLaterThanToErrorMessage);
			} else if (val.diff(tempFrom, 'month') > rangeMonth) {
				setError(rangeMonthErrorMessage);
			} else {
				setError(null);
			}

			setTempTo(val);
		}
	};

	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>날짜 설정</DialogTitle>

			<DialogContent>
				<Stack
					spacing={2}
					py={1}>
					<Stack
						direction={'row'}
						alignItems={'center'}
						spacing={1}
						mb={1}>
						<AdminMonthPicker
							sx={{ flex: 1 }}
							value={tempFrom}
							onChange={handleTempFromChange}
							isMonth
						/>
						<Typography variant={'h5'}>~</Typography>
						<AdminMonthPicker
							sx={{ flex: 1 }}
							value={tempTo}
							onChange={handleTempToChange}
							isMonth
						/>
					</Stack>
					{error && <Typography color='error'>{error}</Typography>}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					variant={'contained'}
					disabled={!!error}
					onClick={() => {
						setSearchTimeFormFilter();
						closeDialog && closeDialog();
					}}>
					확인
				</Button>
				<Button
					onClick={closeDialog}
					variant={'outlined'}>
					취소
				</Button>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
