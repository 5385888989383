import { DeleteFilled, MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { CartLineInfoType } from '@kinderlabs-pos/shared-data-type';
import {
	getI18nString,
	numberWithCommasAnd원,
	numberWithCommasAnd원PlusMinus,
} from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Card, CardContent, IconButton, Stack, Typography } from '@mui/material';
import { useKioskTranslation } from '../../../../const/useKioskTranslation';
import { ProductImageInKioskBoard } from '../ProductImageInKioskBoard';

export const KioskCartItem = ({
	cartLine,
	optionsCartLines,
}: {
	cartLine: CartLineInfoType;
	optionsCartLines: CartLineInfoType[];
}) => {
	const { t, kioskI18N } = useKioskTranslation();
	const cartDispatcher = OrderState.cart.useDispatcher();
	const customConfirm = useConfirm();

	const handleClickMinusQuantity = () => {
		if (cartLine.quantity === 1) {
			customConfirm(
				`${t('cart.reallyDelete', {
					cartLineName: getI18nString({
						ko: cartLine.name,
						i18nString: cartLine.i18n,
						i18n: kioskI18N,
					}),
				})}`,
				() => {
					cartDispatcher({
						type: 'BOARD',
						subAction: { type: 'MINUS', cartLineType: cartLine.type, cartLineId: cartLine.id },
					});
				},
				undefined
			);
		} else {
			cartDispatcher({
				type: 'BOARD',
				subAction: { type: 'MINUS', cartLineType: cartLine.type, cartLineId: cartLine.id },
			});
		}
	};

	const handleClickPlusQuantity = () => {
		cartDispatcher({
			type: 'BOARD',
			subAction: { type: 'ADD', cartLineType: cartLine.type, cartLineId: cartLine.id },
		});
	};

	const totalPrice = optionsCartLines.reduce(
		(arr, optionCl) => arr + optionCl.price,
		cartLine.price
	);

	const cartLineName =
		getI18nString({
			ko: cartLine.name,
			i18nString: cartLine.i18n,
			i18n: kioskI18N,
		}) ?? '';

	return (
		<Card sx={{ width: '100', position: 'relative' }}>
			<CardContent>
				<Stack
					height={'100%'}
					spacing={1}>
					<Stack
						direction={'row'}
						alignItems={'center'}
						justifyContent={'space-between'}
						spacing={3}>
						<ProductImageInKioskBoard
							backgroundImage={cartLine.imageUrl}
							size={80}
						/>
						<Stack
							flex={1}
							direction={'row'}
							alignItems={'center'}
							justifyContent={'space-between'}>
							<IconButton
								color={'primary'}
								size={'large'}
								onClick={handleClickMinusQuantity}>
								{cartLine.quantity > 1 ? (
									<MinusCircleFilled style={{ color: 'inherit' }} />
								) : (
									<DeleteFilled style={{ color: 'inherit' }} />
								)}
							</IconButton>
							<Typography
								variant='h5'
								style={{ textAlign: 'right' }}>
								{` ${cartLine.quantity} `}
							</Typography>
							<IconButton
								color={'primary'}
								size={'large'}
								onClick={handleClickPlusQuantity}>
								<PlusCircleFilled style={{ color: 'inherit' }} />
							</IconButton>
						</Stack>
					</Stack>
					<Stack
						width={'100%'}
						direction={cartLineName.length > 10 ? 'column' : 'row'}
						justifyContent={'space-between'}>
						<Typography
							alignSelf={'flex-start'}
							variant='body2'>
							{cartLineName}
						</Typography>
						<Typography
							alignSelf={'flex-end'}
							variant='body2'
							color={'text.secondary'}>
							{`${numberWithCommasAnd원(totalPrice, kioskI18N)}`}
						</Typography>
					</Stack>
					{optionsCartLines.length > 0 && (
						<Stack>
							<Stack
								direction={'row'}
								width={'100%'}
								justifyContent={'space-between'}>
								<Typography
									variant='caption'
									color={'text.secondary'}>
									{t('cart.price')}
								</Typography>
								<Typography
									variant='caption'
									color={'text.secondary'}>
									{`${numberWithCommasAnd원(cartLine.price, kioskI18N)}`}
								</Typography>
							</Stack>
							{optionsCartLines.map((optionCartLine, idx) => (
								<Stack
									key={idx}
									direction={'row'}
									width={'100%'}
									justifyContent={'space-between'}>
									<Typography
										variant='caption'
										color={'text.secondary'}>
										{`└ㅤ${getI18nString({
											ko: optionCartLine.name,
											i18nString: optionCartLine.i18n,
											i18n: kioskI18N,
										})}`}
									</Typography>
									<Typography
										variant='caption'
										color={'text.secondary'}>
										{`${numberWithCommasAnd원PlusMinus(optionCartLine.price, kioskI18N)}`}
									</Typography>
								</Stack>
							))}
						</Stack>
					)}
					{/* <Stack
						direction={'row'}
						alignItems={'center'}
						justifyContent={'space-between'}>
						<IconButton
							color={'primary'}
							onClick={handleClickPlusQuantity}>
							<PlusCircleFilled style={{ color: 'inherit' }} />
						</IconButton>
						<IconButton
							color={'primary'}
							onClick={handleClickMinusQuantity}>
							<MinusCircleFilled style={{ color: 'inherit' }} />
						</IconButton>
					</Stack> */}
				</Stack>
			</CardContent>
		</Card>
	);
};
