import {
	styled,
	Table,
	TableBody,
	TableCell,
	TableCellProps,
	TableContainer,
	TableRow,
} from '@mui/material';
import { ReactNode } from 'react';

export interface INoHeaderTableProps {
	labelCellProps?: TableCellProps;
	valueCellProps?: TableCellProps;
	data: { label: ReactNode; value: ReactNode }[];
}
export const NoHeaderTable: React.FC<INoHeaderTableProps> = ({
	data,
	labelCellProps,
	valueCellProps,
}) => {
	return (
		<TableContainer>
			<Table
				size={'small'}
				sx={{ border: '1px solid lightgray' }}>
				<TableBody>
					{data.map(({ label, value }, idx) => (
						<StyledTableRow key={idx}>
							<TableCell {...labelCellProps}>{label}</TableCell>
							<TableCell {...valueCellProps}>{value}</TableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'& .MuiTableCell-root': {
		padding: theme.spacing(0.5),
		// fontSize: theme.typography.body2.fontSize,
		'&:nth-of-type(1)': {
			borderRight: '1px solid lightgray',
		},
	},
}));
