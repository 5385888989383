import { guestMemberApis } from '@kinderlabs-pos/apis/waivers';
import { TelephoneForm } from '@kinderlabs-pos/frameworks/forms';
import { GuestMemberInfoType } from '@kinderlabs-pos/shared-data-type';
import { GuestMemberInfoState } from '@kinderlabs-pos/state';
import { Box, CardMedia, CircularProgress, Stack, styled } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import codeReading from 'assets/barcode-scan.gif';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { KioksUserInfoPage } from '.';
import { BarcodeReadHandler } from '../../components/BarcodeReadHandler';
import { KioskNumberBoard } from '../../components/KioskNumberBoard';
import { PrevNextButton } from '../../components/UserInfoPageComponents/PrevNextButton';
import { TitleWithDesc } from '../../components/UserInfoPageComponents/TitleWithDesc';
import { useKioskRouter } from '../../utils/useKioskRouter';
import { UserInfoPageTemplate } from './UserInfoPageTemplate';

type TelephoneCheckProps = {
	telephone?: string;
	goNextStep: () => void;
	goPrevStep: () => void;
	setAlreadyMember: (guestMember: GuestMemberInfoType) => void;
	setTelephone: (telephone: string) => void;
};

export const UserTelephoneCheckPage = ({
	isFnBKiosk,
	signupImageUrl,
	telephoneProps,
}: {
	isFnBKiosk: boolean;
	signupImageUrl?: string;
	telephoneProps: TelephoneCheckProps;
}) => {
	return (
		<>
			{isFnBKiosk && <FnBKioskMappingPage />}
			{!isFnBKiosk && (
				<TicketMappingPage
					props={telephoneProps}
					signupImageUrl={signupImageUrl}
				/>
			)}
		</>
	);
};

const TicketMappingPage = ({
	props,
	signupImageUrl,
}: {
	props: TelephoneCheckProps;
	signupImageUrl?: string;
}) => {
	const { telephone, setAlreadyMember, setTelephone, goNextStep, goPrevStep } = props;
	const { values, handleChange, handleBlur, handleSubmit, touched, errors, isValid, isSubmitting } =
		useFormik({
			initialValues: { telephone: telephone ?? '010' },
			validationSchema: Yup.object({
				telephone: Yup.string()
					.length(11, '전화번호는 11자리 이어야 합니다.')
					.matches(/^[0][1][0][0-9]+$/, '앞자리 휴대폰 번호는 010 입니다.')
					.required('전화번호를 입력해주세요.'),
			}),
			onSubmit: async ({ telephone }) => {
				try {
					const result = await guestMemberApis.getGuestMemberInfo({
						type: 'telephone',
						value: values.telephone,
					});
					setAlreadyMember(result);
				} catch (error) {
					const errorCode = (error as { code: string; message: string }).code;
					const 신규가입자인경우 = errorCode === 'MEMBER-01';
					if (신규가입자인경우) {
						setTelephone(telephone);
						goNextStep();
					}
				}
			},
			validateOnMount: true,
			enableReinitialize: true,
		});

	return (
		<UserInfoPageTemplate>
			<Stack
				sx={{ width: '100%', height: '100%' }}
				spacing={3}>
				{signupImageUrl && (
					<Stack
						flex={1}
						alignItems={'center'}>
						{/* <CardMedia
							image={signupImageUrl}
							sx={{ height: '100%', border: '1px solid gray' }}
						/> */}
						<img
							src={signupImageUrl}
							style={{ height: '100%', objectFit: 'contain' }}
						/>
					</Stack>
				)}
				<TitleWithDesc title={'휴대폰 번호 입력'} />
				<TelephoneForm
					type={'tel'}
					name={'telephone'}
					value={values.telephone}
					onChange={handleChange}
					onBlur={handleBlur}
					autoComplete='off'
					InputProps={{ autoComplete: 'off' }}
					error={!!(touched.telephone && errors.telephone)}
					helperText={(touched.telephone && errors.telephone) || 'ㅤ'}
					// autoFocus
				/>
				<PrevNextButton
					nextDisabled={!isValid || isSubmitting}
					endIcon={
						isSubmitting && (
							<CircularProgress
								sx={{ color: 'white' }}
								size={25}
							/>
						)
					}
					prevFn={goPrevStep}
					nextFn={handleSubmit}
				/>
			</Stack>
		</UserInfoPageTemplate>
	);
};

const BarcodeReaderSection = () => {
	const setFinalUser = useSetAtom(KioksUserInfoPage.finalUserInfo);
	const { navigate키오스크보드 } = useKioskRouter();
	return (
		<Stack sx={{ width: '100%', alignItems: 'center' }}>
			<BarcodeReadHandler
				onEnter={async (value) => {
					try {
						const { data } = useQuery({
							...GuestMemberInfoState.keys.search({ params: { search: value } }),
							keepPreviousData: false,
							refetchOnWindowFocus: true,
						});
						const infoList = data?.content ?? [];
						const userInfo = getUserInfo(infoList);
						if (userInfo) {
							setFinalUser({
								memberId: userInfo.id,
								name: userInfo.name,
								telephone: userInfo.telephone,
							});
						}
					} catch (error) {
						return;
					} finally {
						// mapping 되든 안되는 일단 보드 페이지로 넘어간다.
						navigate키오스크보드();
					}
				}}
			/>
			<BarcodeReading />
		</Stack>
	);
};

const getUserInfo = (list: GuestMemberInfoType[]): GuestMemberInfoType | undefined => {
	if (list.length === 1) return list[0];
	else return undefined;
};

const FnBKioskMappingPage = () => {
	const { values, handleChange, handleBlur, setValues, handleSubmit, touched, errors, isValid } =
		useFormik({
			initialValues: { telephone: '010' },
			onSubmit: async () => {
				try {
					const result = await guestMemberApis.getGuestMemberInfo({
						type: 'telephone',
						value: values.telephone,
					});
					setFinalUserInfo({ memberId: result.id, name: result.name, telephone: result.telephone });
				} catch (error) {
					console.log(error);
				} finally {
					navigate키오스크보드();
				}
			},
		});
	const setFinalUserInfo = useSetAtom(KioksUserInfoPage.finalUserInfo);
	const { navigate키오스크보드 } = useKioskRouter();
	const [pinValues, setPinValues] = useState<string[]>(['0', '1', '0']);

	const handleClickPinValue = (value: number) => {
		if (pinValues.length < 11) setPinValues([...pinValues, `${value}`]);
	};

	const clearPinValues = () => {
		setPinValues([]);
	};

	useEffect(() => {
		setValues((prev) => ({ ...prev, telephone: pinValues.join('') }));
	}, [pinValues, setValues]);

	return (
		<UserInfoPageTemplate>
			<Stack
				sx={{ width: '100%' }}
				spacing={5}>
				<TitleWithDesc title={'주문을 위해 바코드 리더기에 \n입장권을 태깅해주세요.'} />
				<BarcodeReaderSection />
				<TitleWithDesc title={'입장권이 없는 경우\n 발권 시 사용한 핸드폰 번호를 입력해주세요'} />
				<TelephoneForm
					name={'telephone'}
					value={values.telephone}
					onChange={handleChange}
					onBlur={handleBlur}
					error={!!(touched.telephone && errors.telephone)}
					helperText={(touched.telephone && errors.telephone) || 'ㅤ'}
					placeholder={'예)010-1234-5678'}
				/>
				<KioskNumberBoard
					onClickNumber={(value) => {
						if (value === 'clear') {
							clearPinValues();
						} else if (value === 'delete') {
							setPinValues(pinValues.slice(0, -1));
						} else {
							handleClickPinValue(value);
						}
					}}
					customsize='small'
				/>
				<PrevNextButton
					nextDisabled={!isValid}
					nextFn={handleSubmit}
					notUsingPrevBtn
				/>
			</Stack>
		</UserInfoPageTemplate>
	);
};

const BarcodeReading = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${codeReading})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '250px',
	height: '250px',
}));
