import { CheckSquareFilled, MinusSquareFilled } from '@ant-design/icons';
import { alpha, Box, Components, Theme } from '@mui/material';

export const adminComponentOverrides: Components<Theme> = {
	MuiOutlinedInput: {
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				'&:hover > .MuiOutlinedInput-notchedOutline': {
					borderColor: `${theme.palette.primary.light}`,
				},
				'&.MuiInputBase-readOnly:hover > .MuiOutlinedInput-notchedOutline': {
					borderColor: `${theme.palette.text.secondary}`,
				},
				'&.MuiInputBase-readOnly.Mui-focused > .MuiOutlinedInput-notchedOutline': {
					borderColor: `${theme.palette.text.secondary}`,
				},
				'&.Mui-disabled: hover > .MuiOutlinedInput-notchedOutline': {
					borderColor: `inherit`,
				},
				'&.Mui-disabled': {
					backgroundColor: `${alpha(theme.palette.text.secondary, 0.05)}`,
				},
			}),
		},
	},
	MuiCheckbox: {
		defaultProps: {
			icon: (
				<Box
					className='icon'
					sx={{
						width: 16,
						height: 16,
						border: '1px solid',
						borderColor: 'inherit',
						borderRadius: 0.25,
					}}
				/>
			),
			checkedIcon: (
				<Box
					className='icon'
					sx={{
						width: 16,
						height: 16,
						border: '1px solid',
						borderColor: 'inherit',
						borderRadius: 0.25,
						position: 'relative',
					}}>
					<CheckSquareFilled
						className='filled'
						style={{ position: 'absolute' }}
					/>
				</Box>
			),
			indeterminateIcon: (
				<Box
					className='icon'
					sx={{
						width: 16,
						height: 16,
						border: '1px solid',
						borderColor: 'inherit',
						borderRadius: 0.25,
						position: 'relative',
					}}>
					<MinusSquareFilled
						className='filled'
						style={{ position: 'absolute' }}
					/>
				</Box>
			),
		},
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				borderRadius: 0,
				color: theme.palette.secondary.light,
				'& .icon': {
					width: 16,
					height: 16,
					'& .filled': {
						fontSize: `2rem`,
						top: -2,
						left: -2,
					},
				},
			}),
		},
	},
	MuiPaginationItem: {
		variants: [
			{
				props: { variant: 'squared' },
				style: ({ theme }) => ({
					textTransform: 'none',
					border: '1px solid',
					borderRadius: theme.spacing(1),
					borderColor: theme.palette.divider,
					'&.MuiPaginationItem-ellipsis': {
						border: 'none',
					},
					'&.Mui-selected': {
						backgroundColor: theme.palette.primary.light,
						color: 'white',
					},
				}),
			},
		],
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				color: theme.palette.error.main,
			}),
		},
	},
	MuiTableCell: {
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				// padding: '8px 12px',
				// [theme.breakpoints.down('md')]: {
				// 	padding: '6px 8px',
				// },
				padding: '4px 4px',
				[theme.breakpoints.down('md')]: {
					padding: '4px 4px',
				},
			}),
		},
	},
	MuiIconButton: {
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				[theme.breakpoints.down('md')]: {
					fontSize: '2rem',
				},
			}),
		},
	},
	MuiCard: {
		styleOverrides: {
			root: ({ ownerState, theme }) => ({
				// boxShadow: theme.shadows[1],
				boxShadow: 'none',
			}),
		},
	},
};

declare module '@mui/material/Pagination' {
	interface PaginationPropsVariantOverrides {
		squared: true;
	}
}

declare module '@mui/material/PaginationItem' {
	interface PaginationItemPropsVariantOverrides {
		squared: true;
	}
}
