import { BaseInfoType, PosInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const getPosInfoAsync =
	(rootUrl: string) =>
	async ({ storeId, posId }: { storeId?: number; posId: number }) =>
		(
			await posAxiosClient.get<PosInfoType & BaseInfoType>(`${rootUrl}/store/pos`, {
				params: { storeId, id: posId },
			})
		).data;
