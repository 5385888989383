import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	BasicUnitType,
	ProductInfoType,
	ProductUnitType,
	StockInfoType,
	StockUnitType,
} from '@kinderlabs-pos/shared-data-type';

export const canDeleteWarehouseInfoAsync = (rootUrl: string) => async (warehouseId: number) =>
	(
		await posAxiosClient.get<CanDeleteWarehouseInfoResponseType[]>(
			`${rootUrl}/warehouse/can-delete`,
			{
				params: {
					warehouseId,
				},
			}
		)
	).data;

type CanDeleteWarehouseInfoResponseType = Omit<
	StockInfoType,
	'defaultStockValue' | 'minimumStockValue'
> &
	Pick<ProductInfoType, 'name' | 'barcode' | 'price'> & {
		productUnit: ProductUnitType;
	};
