import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CursorPageType, DdiziType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getDdiziListInfiniteAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posId,
		cursorId,
		from,
		to,
		pageable,
		status,
		search,
	}: GetDdiziListRequestType) =>
		(
			await posAxiosClient.get<CursorPageType<DdiziType>>(`${rootUrl}/ddizi/search/cursor`, {
				params: {
					...pageable.getPageableQueryParam(),
					storeIdList,
					posId,
					from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
					to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
					cursorId,
					status,
					search,
				},
			})
		).data;

export type GetDdiziListRequestType = {
	storeIdList?: number[];
	posId?: number;
	cursorId?: string | number;
	from: Dayjs;
	to: Dayjs;
	status?: DdiziType['status'];
	search?: string;
	pageable: Pageable;
};
