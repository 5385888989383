import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { AuthorityType, ManagerUserType } from '@kinderlabs-pos/shared-data-type';

export const updateAuthorityAsync =
	(rootUrl: string) =>
	async ({ data, myAuthority }: UpdateAuthorityType) =>
		(
			await posAxiosClient.put<ManagerUserType>(
				`${rootUrl}/auth-service/manager/member/${myAuthority}/authority/modify`,
				{ ...data }
			)
		).data;

export type UpdateAuthorityType = {
	data: Pick<ManagerUserType, 'email' | 'authority' | 'storeIdList'>;
	myAuthority: AuthorityType;
};
