import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	BaseInfoType,
	GuestVisitType,
	PosInfoType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../const';

type MobileCheckerGuestVisitInfoType = {
	guestVisit: GuestVisitType;
	recentCalledGuestVisit?: GuestVisitType;
	countBeforeMe: number;
	storeInfo: StoreInfoType;
	posInfoList: (PosInfoType & BaseInfoType)[];
};

const getMobileCheckeGuestVisitInfo =
	(rootUrl: string) =>
	async ({ guestVisitToken, enterpriseId }: { guestVisitToken: string; enterpriseId: string }) =>
		(
			await posAxiosClient.get<MobileCheckerGuestVisitInfoType>(
				`${rootUrl}/mobile-checker/guest-visit`,
				{
					headers: {
						'Enterprise-Id': enterpriseId,
					},
					params: {
						token: guestVisitToken,
					},
				}
			)
		).data;

export const MobileCheckerApis = {
	// 조회
	getGuestVisitinfo: getMobileCheckeGuestVisitInfo(rootUrl),
};
