import { Stack } from '@mui/material';
import { grey } from '@mui/material/colors';

import { AdminKioskInfoState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { SimpleScrollbar } from '@kinderlabs-pos/ui-atoms';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect } from 'react';
import { KioskCartOrderButton } from './cart/KioskCartOrderButton';
import { KioskCartSection } from './cart/KioskCartSection';
import { KioskBoardItemBoard } from './KioskBoardItemBoard';
import { KioskBoardMenu } from './KioskBoardMenu';

export const KioskBoardPage = () => {
	return (
		<Suspense>
			<KioskBoardPageInternal />
		</Suspense>
	);
};

const KioskBoardPageInternal = () => {
	const orderReducer = useSetAtom(OrderState.value);
	useEffect(() => {
		return () => {
			orderReducer({ type: 'CLEAR' });
		};
	}, []);

	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId: storeInfo.id, kioskId: savedKioskInfo.id }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (!savedKioskInfo || !kioskInfo) throw new Error();

	return (
		<>
			{kioskInfo.kioskBoardId && (
				<>
					<Suspense>
						<KioskBoardMenu kioskBoardInfoId={kioskInfo.kioskBoardId} />
					</Suspense>
					<Stack
						flex={1}
						overflow={'auto'}
						flexDirection={'row'}>
						<Stack
							// 스크롤바 분리를 위하여
							flex={1}
							overflow={'auto'}>
							<KioskBoardItemBoard
								kioskInfo={kioskInfo}
								kioskBoardInfoId={kioskInfo.kioskBoardId}
							/>
						</Stack>
						<Stack
							width={360}
							bgcolor={grey[300]}
							alignItems={'center'}
							justifyContent={'center'}
							overflow={'auto'}>
							<Stack
								flex={1}
								overflow={'auto'}
								width={'100%'}>
								<KioskCartSection />
							</Stack>
							<Stack
								width={'100%'}
								alignItems={'center'}
								justifyContent={'center'}
								height={240}>
								<KioskCartOrderButton kioskInfo={kioskInfo} />
							</Stack>
						</Stack>
					</Stack>
				</>
			)}
		</>
	);
};
