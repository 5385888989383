import {
	KioskTypeLabel,
	ManagerUserType,
	ManagerUserTypeRule,
} from '@kinderlabs-pos/shared-data-type';
import {
	AdminKioskBoardInfoState,
	AdminKioskInfoState,
	AdminStoreInfoState,
	authState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { Button, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useMemo } from 'react';
import { KioskLocalAdminPinNumberDialog } from '../../../dialog/KioskLocalAdminPinNumberDialog';
import { KioskSelectionState } from '../../../utils/KioskSelectionState';
import * as Sentry from '@sentry/react';

export const KioskStoreSelectSection = ({ user }: { user: ManagerUserType }) => {
	const { handleSubmit, values, setValues, isValid } =
		KioskStoreSelectSection.useKioskSelectFormik();

	const { data } = useQuery({
		...AdminStoreInfoState.keys.all(),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const storeInfoList = data ?? [];

	const selectableStoreInfoList = useMemo(
		() => ManagerUserTypeRule.getStoreList({ allStoreInfoList: storeInfoList, user: user }),
		[user, storeInfoList]
	);

	return (
		<Stack
			width={'100%'}
			spacing={5}>
			<Stack>
				<Typography variant={'subtitle1'}>매장선택</Typography>
				<Select
					value={values.storeInfoId}
					onChange={(e) => {
						setValues((prev) => ({ ...prev, storeInfoId: e.target.value as number }));
					}}>
					{selectableStoreInfoList.map((storeInfo, idx) => (
						<MenuItem
							key={idx}
							value={storeInfo.id}>
							{storeInfo.name}
						</MenuItem>
					))}
				</Select>
			</Stack>
			{values.storeInfoId && (
				<Suspense>
					<KioskSection
						storeId={values.storeInfoId}
						tempKioskInfoId={values.kioskInfoId}
						setTempKioskInfoId={(kioskInfoId) => setValues((prev) => ({ ...prev, kioskInfoId }))}
					/>
				</Suspense>
			)}
			<WithDialog
				dialog={(open, closeDialog) =>
					values.storeInfoId &&
					values.kioskInfoId && (
						<KioskLocalAdminPinNumberDialog
							open={open}
							closeDialog={closeDialog}
							storeId={values.storeInfoId}
							kioskId={values.kioskInfoId}
							onSuccess={() => {
								handleSubmit();
							}}
						/>
					)
				}>
				{(openDialog) => (
					<Button
						variant='contained'
						disabled={!isValid}
						onClick={openDialog}>
						키오스크 변경
					</Button>
				)}
			</WithDialog>
		</Stack>
	);
};

const KioskSection = ({
	storeId,
	tempKioskInfoId,
	setTempKioskInfoId,
}: {
	storeId: number;
	tempKioskInfoId?: number;
	setTempKioskInfoId: (id?: number) => void;
}) => {
	const { data: kioskInfoListData } = useQuery({
		...AdminKioskInfoState.keys.list(storeId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const kioskInfoList = kioskInfoListData ?? [];

	const { data: kioskBoardListData } = useQuery(AdminKioskBoardInfoState.keys.list(storeId));
	const kioskInfoBoardList = kioskBoardListData ?? [];

	useEffect(() => {
		if (!kioskInfoList.some((kioskInfo) => kioskInfo.id === tempKioskInfoId))
			setTempKioskInfoId(undefined);
	}, [tempKioskInfoId, kioskInfoList]);

	return (
		<Stack>
			<Typography variant={'subtitle1'}>키오스크 선택</Typography>
			<Select
				value={tempKioskInfoId}
				onChange={(e) => {
					setTempKioskInfoId(e.target.value as number);
				}}>
				{kioskInfoList.map((kioskInfo, idx) => {
					const kioskBoard = kioskInfoBoardList.find(
						(board) => board.id === kioskInfo.kioskBoardId
					);

					const FANDBKiosk는반드시보드가가있어야만선택가능합니다 =
						kioskInfo.type === 'FANDB' && !kioskBoard;

					return (
						<MenuItem
							disabled={FANDBKiosk는반드시보드가가있어야만선택가능합니다}
							key={idx}
							value={kioskInfo.id}>
							{`[${KioskTypeLabel[kioskInfo.type]}] ${kioskInfo.name} ${
								kioskBoard ? `(${kioskBoard.name})` : '(보드 없음)'
							}`}
						</MenuItem>
					);
				})}
			</Select>
		</Stack>
	);
};

KioskStoreSelectSection.useKioskSelectFormik = () => {
	const savedKioskInfo = StoreInfoState.useKioskLoginInfo();

	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);

	const deviceLogin = useSetAtom(StoreInfoState.deviceLoginInfo);
	const selectKiosk = KioskSelectionState.useSelectKiosk();
	const customAlert = useAlert();

	const result = useFormik<{ storeInfoId?: number; kioskInfoId?: number }>({
		initialValues: savedKioskInfo
			? { storeInfoId: savedKioskInfo.storeId, kioskInfoId: savedKioskInfo.deviceId }
			: { storeInfoId: undefined, kioskInfoId: undefined },
		onSubmit: (values) => {
			if (values.kioskInfoId && values.storeInfoId && enterpriseInfo) {
				selectKiosk({
					brandId: 0,
					storeId: values.storeInfoId,
					kioskId: values.kioskInfoId,
				});

				Sentry.setContext('kioskInfo', {
					kioskId: savedKioskInfo?.deviceId,
					kioskType: savedKioskInfo?.deviceType,
					storeId: savedKioskInfo?.storeId,
					enterpriseId: enterpriseInfo.id,
				});

				deviceLogin({
					deviceType: 'KIOSK',
					storeId: values.storeInfoId,
					deviceId: values.kioskInfoId,
					enterpriseId: enterpriseInfo.id,
				});
				customAlert('설정이 저장 완료되었습니다.');
			}
		},
	});

	return result;
};
