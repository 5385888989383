import {
	BaseInfoType,
	KioskBoardItemInfoWithDetailType,
	KioskBoardMenuInfoForStoreType,
	KioskInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import {
	AdminKioskBoardInfoState,
	AdminKioskInfoState,
	KioskElectronStoreState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import {
	CardMedia,
	FormControlLabel,
	List,
	ListItemButton,
	ListSubheader,
	Stack,
	Switch,
	Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useQuery } from '@tanstack/react-query';
import defaultKioskItemJpg from 'assets/defaultkioskitem.jpg';
import { useAtomValue } from 'jotai';
import { Fragment } from 'react';

export const KioskSoldoutPanel = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId: storeInfo.id, kioskId: savedKioskInfo.id }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (!kioskInfo || !kioskInfo.kioskBoardId)
		return (
			<Stack>
				<Typography variant='h6'>키오스크 보드가 없습니다.</Typography>
			</Stack>
		);
	return <KioskSoldoutPanelInternal kioskInfo={kioskInfo} />;
};

const KioskSoldoutPanelInternal = ({ kioskInfo }: { kioskInfo: KioskInfoType & BaseInfoType }) => {
	const { data: kioskBoard } = useQuery(
		AdminKioskBoardInfoState.keys.detail(kioskInfo.kioskBoardId)
	);

	const { data } = useQuery({
		...AdminKioskBoardInfoState.keys.menuList(kioskInfo.kioskBoardId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const kioskBoardMenuList = data ?? [];

	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();
	const hideSoldoutItems = localSettings?.hideSoldOutItems ?? false;
	const sethideSoldoutItems = (newHideSoldOutItems: boolean) => {
		if (localSettings)
			saveLocalSettingsMutation.mutate({ ...localSettings, hideSoldOutItems: newHideSoldOutItems });
	};

	const soldoutSingleItem = AdminKioskBoardInfoState.useSetKioskBoardItemSoldOut();

	const customConfirm = useConfirm();

	const handleClickSoldOutKioskBoardMenu =
		({
			item,
			itemIndex,
			boardMenu,
		}: {
			item: KioskBoardItemInfoWithDetailType;
			itemIndex: number;
			boardMenu: KioskBoardMenuInfoForStoreType;
		}) =>
		() => {
			if (!kioskBoard) return;
			customConfirm(
				`${item.productInfo.name} 상품을 ${item.isSoldOut ? '품절 해제' : '품절 처리'} 하겠습니까?`,
				() => {
					soldoutSingleItem.mutateAsync(
						{
							kioskBoardInfoId: kioskBoard.id,
							kioskBoardMenuId: boardMenu.id,
							index: itemIndex,
							soldOut: !item.isSoldOut,
						},
						{
							onSuccess: () => {
								AdminKioskBoardInfoState.invalidateQueries();
							},
						}
					);
				}
			);
		};

	return (
		<Stack spacing={1}>
			<Stack
				width='100%'
				flexDirection={'row'}
				justifyContent={'flex-end'}
				alignItems={'center'}>
				<FormControlLabel
					control={
						<Switch
							checked={hideSoldoutItems}
							onChange={(e) => sethideSoldoutItems(e.target.checked)}
						/>
					}
					label={'품절 상품 숨기기'}></FormControlLabel>
			</Stack>
			<Stack
				flex={1}
				overflow={'auto'}>
				<List>
					{kioskBoardMenuList.map((boardMenu, boardMenuIndex) =>
						!boardMenu.isBestBoard && !boardMenu.isNewBoard ? (
							<Fragment key={boardMenuIndex}>
								<ListSubheader>
									<Typography variant='h6'>{boardMenu.name}</Typography>
								</ListSubheader>
								{boardMenu.itemWithDetailList.flatMap((item, itemIndex) => (
									<KioskItemListItem
										item={item}
										onClickItem={handleClickSoldOutKioskBoardMenu({
											item,
											boardMenu,
											itemIndex,
										})}
										key={itemIndex}
									/>
								))}
								<ListSubheader sx={{ height: 80 }} />
							</Fragment>
						) : null
					)}
				</List>
			</Stack>
		</Stack>
	);
};

export const KioskItemListItem = ({
	item,
	onClickItem,
}: {
	item: KioskBoardItemInfoWithDetailType;
	onClickItem: () => void;
}) => {
	return (
		<ListItemButton onClick={onClickItem}>
			<Stack
				width={'100%'}
				spacing={2}
				height={140}
				direction={'row'}>
				<Stack sx={{ aspectRatio: '1/1', overflow: 'hidden', position: 'relative' }}>
					<CardMedia
						component={'img'}
						sx={{ width: '100%', aspectRatio: 1 }}
						image={item.imageUrl || defaultKioskItemJpg}
						alt={'상품 사진'}
					/>
					{item.isSoldOut && (
						<Stack
							position={'absolute'}
							sx={{ width: '100%', aspectRatio: 1, backgroundColor: 'rgba(128, 128, 128, 0.6)' }}
							top={0}
							left={0}
							justifyContent={'center'}
							alignItems={'center'}>
							<Typography
								variant='h3'
								color={'white'}>
								품절
							</Typography>
						</Stack>
					)}
				</Stack>
				<Stack flex={1}>
					<Typography color={'text.primary'}>{item.productInfo.name}</Typography>
					<Typography color={grey[500]}>
						{numberWithCommasAndWon(item.productInfo.price)}
					</Typography>
				</Stack>
			</Stack>
		</ListItemButton>
	);
};
