import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteVendorInfoAsync = (rootUrl: string) => async (deleteId: number) =>
	(
		await posAxiosClient.delete(`${rootUrl}/vendor`, {
			params: {
				id: deleteId,
			},
		})
	).data;
