import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const KioskSettingsItem = ({
	label,
	innerElement,
}: {
	label: string;
	innerElement: ReactNode;
}) => {
	return (
		<Stack spacing={1}>
			<Typography
				variant='subtitle1'
				fontWeight={600}>
				{label}
			</Typography>
			{innerElement}
		</Stack>
	);
};
