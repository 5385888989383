import {
	Box,
	Button,
	CircularProgress,
	CircularProgressProps,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Unstable_Grid2 as Grid,
	Stack,
	Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useKioskRouter } from '../../utils/useKioskRouter';
import { StepType } from './steps';
import { KioskElectronStoreState } from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { ArrowRightOutlined } from '@ant-design/icons';
import { KioskType } from '@kinderlabs-pos/shared-data-type';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';

export const UserInfoPageHeader = ({
	curStep,
	kioskType,
}: {
	curStep: StepType;
	kioskType: KioskType | 'ONLINE-TICKETS';
}) => {
	const { navigate홈으로 } = useKioskRouter();
	const { data: KioskElectronStore } = KioskElectronStoreState.useKioskMainPageLocalSettings();
	const customConfirm = useConfirm();
	const { navigate온라인권발권, navigate키오스크보드, navigate퇴장페이지 } = useKioskRouter();

	return (
		<Grid
			container
			sx={{ alignItems: 'center', mb: 2 }}>
			<Grid xs={4}>
				<Button
					onClick={navigate홈으로}
					variant='outlined'
					endIcon={
						<CircularProgressWithlabel
							curStep={curStep}
							timeout={30}
							onTimeoutCallback={navigate홈으로}
						/>
					}>
					HOME
				</Button>
			</Grid>
			<Grid xs={4}>
				<Typography
					variant='h5'
					textAlign={'center'}>
					{curStep === '이용약관' ? '이용 약관' : 'ㅤ'}
				</Typography>
			</Grid>
			<Grid xs={4}>
				{curStep === '전화번호' && (
					<Stack alignItems={'flex-end'}>
						{KioskElectronStore?.skipMembership && (
							<WithDialog
								dialog={(open, closeDialog) => (
									<확인Dialog
										open={open}
										closeDialog={closeDialog}
										onSuccess={async () => {
											switch (kioskType) {
												case 'ONLINE-TICKETS':
													navigate온라인권발권();
													break;
												case 'TICKETS':
												case 'FANDB':
													navigate키오스크보드();
													break;
												case 'JUNGSAN':
													navigate퇴장페이지();
													break;
												case 'CUSTOMER_MAPPING':
													break;
											}
										}}
									/>
								)}>
								{(openDialog) => (
									<Button
										variant='text'
										sx={{ color: 'text.secondary' }}
										onClick={openDialog}
										endIcon={<ArrowRightOutlined />}>
										{kioskType === 'TICKETS' && (
											<Typography variant='body2'>비회원 티켓구매</Typography>
										)}
										{kioskType === 'ONLINE-TICKETS' && (
											<Typography variant='body2'>비회원 티켓출력</Typography>
										)}
									</Button>
								)}
							</WithDialog>
						)}
					</Stack>
				)}
			</Grid>
		</Grid>
	);
};

const CircularProgressWithlabel = ({
	onTimeoutCallback,
	timeout,
	onClick,
	curStep,
	...others
}: CircularProgressProps & {
	timeout: number;
	onTimeoutCallback: () => void;
	curStep: StepType;
}) => {
	const [seconds, setSeconds] = useState(timeout);

	useEffect(() => {
		const mouseEvent = (event: MouseEvent) => {
			setSeconds(timeout);
		};

		const keyDownEvent = (event: KeyboardEvent) => {
			setSeconds(timeout);
		};

		window.addEventListener('mousedown', mouseEvent);
		window.addEventListener('keydown', keyDownEvent);

		return () => {
			window.removeEventListener('mousedown', mouseEvent);
			window.removeEventListener('keydown', keyDownEvent);
		};
	}, []);

	useEffect(() => setSeconds(timeout), [curStep]);

	useEffect(() => {
		const interval = setInterval(() => setSeconds((prev) => prev - 1), 1000 * 1);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (seconds === 0) onTimeoutCallback();
	}, [seconds]);

	return (
		<Box
			sx={{ position: 'relative', display: 'inline-flex' }}
			onClick={onClick}>
			<CircularProgress
				{...others}
				variant='determinate'
				value={(100 * seconds) / timeout}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Typography
					variant='body2'
					component='div'
					color='primary'>
					{seconds}
				</Typography>
			</Box>
		</Box>
	);
};

const 확인Dialog = ({
	open,
	closeDialog,
	onSuccess,
}: {
	open: boolean;
	closeDialog: () => void;
	onSuccess: () => void;
}) => {
	return (
		<Dialog
			open={open}
			maxWidth='lg'>
			<DialogContent>
				<DialogContentText>
					<Typography variant='body2'>{`비회원 티켓구매 혹은 비회원 티켓출력 선택 시 주렁주렁 멤버십 혜택을 받지 못 하게 됩니다.`}</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Stack
					width='100%'
					direction={'row'}
					gap={2}>
					<Button
						sx={{ flex: 1 }}
						variant={'contained'}
						onClick={closeDialog}>
						<Typography variant='body2'>멤버십 가입하기</Typography>
					</Button>
					<Button
						sx={{ flex: 1 }}
						variant={'outlined'}
						onClick={onSuccess}>
						<Typography variant='body2'>비회원 티켓 구매/출력 계속</Typography>
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
