import { WarningOutlined } from '@ant-design/icons';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { Divider, Stack, Typography, alpha } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useKioskTranslation } from '../../../../../const/useKioskTranslation';

export const KioskBoardItemOptionAggregateAndAlert = ({
	productPrice,
	optionTotalSum,
	alertMessage,
}: {
	productPrice: number;
	optionTotalSum: number;
	alertMessage?: string;
}) => {
	const totalSum = productPrice + optionTotalSum;
	const { kioskI18N } = useKioskTranslation();

	return (
		<Stack spacing={2}>
			{alertMessage ? (
				<Stack
					direction={'row'}
					py={3}
					px={5}
					sx={(theme) => ({
						backgroundColor: alpha(theme.palette.warning.light, 0.1),
						color: theme.palette.warning.main,
					})}
					spacing={1}>
					<Typography
						sx={{ color: 'inherit' }}
						variant='subtitle1'>
						<WarningOutlined />
					</Typography>
					<Typography
						sx={{ color: 'inherit', whiteSpace: 'pre-wrap' }}
						variant='subtitle1'>
						{alertMessage}
					</Typography>
				</Stack>
			) : (
				<Divider flexItem />
			)}
			<Stack
				direction={'row'}
				width={'100%'}
				alignItems={'center'}
				spacing={4}
				justifyContent={'flex-end'}>
				<Typography
					variant='subtitle2'
					color={'text.secondary'}>
					{`${numberWithCommasAnd원(productPrice, kioskI18N)} + ${numberWithCommasAnd원(
						optionTotalSum,
						kioskI18N
					)} =`}
				</Typography>
				<Typography variant='h4'>{numberWithCommasAnd원(totalSum, kioskI18N)}</Typography>
			</Stack>
		</Stack>
	);
};
