import { PosLoginForm } from '@kinderlabs-pos/feature/pos/pos-login';
import { KioskElectronStoreState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogContent, Stack } from '@mui/material';

export const KioskLoginDialog = ({
	open,
	closeDialog,
	onLoginSuccess,
	...others
}: XlDialogProps & {
	onLoginSuccess: () => void;
}) => {
	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();
	return (
		<WithDialog.XlDialog
			maxWidth='xl'
			open={open}
			dialogTitle={'로그인'}
			closeDialog={closeDialog}>
			<DialogContent>
				<Stack
					width={650}
					height={450}>
					<PosLoginForm
						onLogin={(args) => {
							if (args) {
								const { email, password } = args;

								if (localSettings)
									saveLocalSettingsMutation.mutate({
										...localSettings,
										recentLoginInfo: { userId: email, saltedPassword: password },
									});
							}
							onLoginSuccess();
						}}
						onClose={() => closeDialog && closeDialog()}
						exitButtonLabel={'닫기'}
						ContainerProps={{ sx: { width: '100%' } }}
						recentId={localSettings?.recentLoginInfo?.userId}
					/>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
