import { CartLineInfoType, CartLineTypeRules } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { OutlinedInput, Stack, Typography } from '@mui/material';

export const ProductQuantityAdjustItemStack = ({
	cartLine,
	changeQuantity,
	inputDisabled,
}: {
	cartLine: CartLineInfoType;
	changeQuantity: (quantity: number) => void;
	inputDisabled?: boolean;
}) => {
	const { productInfo, productDiscountInfo, quantity } = cartLine;
	const { getDiscountLabel } = OrderState.cart.lines.boardUtils;

	return (
		<Stack
			flex={1}
			direction={'row'}
			alignItems={'center'}
			width={'100%'}
			spacing={1}>
			<Stack flex={1}>
				<Typography variant={'h5'}>
					{CartLineTypeRules.renderCartLineNameInTable(cartLine)}
				</Typography>
				<Typography
					variant={'body2'}
					color={'primary'}>
					{productDiscountInfo && getDiscountLabel(productDiscountInfo)}
				</Typography>
			</Stack>
			<Typography
				variant={'subtitle1'}
				textAlign={'left'}>
				{`${numberWithCommasAndWon(cartLine.price)}`}
			</Typography>
			<OutlinedInput
				disabled={inputDisabled}
				sx={{ width: 70 }}
				inputProps={{
					sx: { textAlign: 'center', padding: 0.5 },
				}}
				value={quantity}
				onClick={(e) => e.stopPropagation()}
				onChange={(e) => {
					changeQuantity(+e.target.value);
				}}
			/>
		</Stack>
	);
};
