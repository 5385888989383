import { PaymentRequestInfoType } from '@kinderlabs-pos/shared-data-type';
import {
	HandlePaymentArgsType,
	OrderState,
	PaymentFormikType,
	PaymentState,
} from '@kinderlabs-pos/state';
import { debounce } from '@mui/material';
import { FormikConfig, FormikErrors, FormikValues, useFormik } from 'formik';
import { useCallback, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useAtomValue } from 'jotai';

export const usePaymentFormik = <T extends FormikValues>({
	initialValues,
	storeId,
	posId,
	// orderId,
	getHandlePaymentArgs,
	handleSubmitPayment,
	validationSchema,
	onClose,
}: {
	initialValues: T;
	storeId: number;
	posId: number;
	// orderId?: string;
	validationSchema: FormikConfig<T>['validationSchema'];
	getHandlePaymentArgs: (values: T) => HandlePaymentArgsType;
	handleSubmitPayment: (paymentRequestInfo: PaymentRequestInfoType) => Promise<void>;
	onClose?: () => void;
}) => {
	const orderState = useAtomValue(OrderState.value);
	const { id: orderId } = orderState;

	const handleSubmitPaymentPM = PaymentState.actions.submit.useHandlePaymentPM({
		storeId,
		posId,
		orderId,
	});
	const [errorValue, setErrorValue] = useState<FormikErrors<T & PaymentFormikType>>({});

	const formikResult = useFormik<T & PaymentFormikType>({
		initialValues: {
			...initialValues,
			submit: null,
			status: 'NOT_SUBMITTING',
			failedPaymentRequestData: undefined,
		},

		validateOnMount: true,
		validationSchema,
		onSubmit: async (values, { setErrors, setFieldValue, resetForm, setValues }) => {
			setErrorValue({ submit: undefined });

			try {
				if (!navigator.onLine) throw new Error('기기의 네트워크 연결이 없는 상태입니다.');
				// 결제모듈 요청
				setValues({ ...values, status: 'SUBMITTING_PM' });

				const pmRequestData = getHandlePaymentArgs(values);
				const serverRequestData = await handleSubmitPaymentPM(pmRequestData, orderState);

				// 서버 요청
				setValues({ ...values, status: 'SUBMITTING_SERVER' });
				try {
					await handleSubmitPayment(serverRequestData);
				} catch (e) {
					if (PaymentState.actions.submit.isOfficialPaymentPayload[pmRequestData.method]) {
						setValues({ ...values, failedPaymentRequestData: serverRequestData });
					}

					throw e;
				}

				// 종료
				onClose && onClose();

				resetForm();
			} catch (e) {
				//@ts-ignore
				setErrorValue({ submit: e?.message });

				try {
					Sentry.withScope((scope) => {
						scope.setTags({
							Type: '결제',
							Where: 'POS',
						});
						Sentry.captureMessage(
							'storeId : ' + storeId + ' / posId : ' + posId + ' / ' + JSON.stringify(e)
						);
						Sentry.captureException(e);
					});
				} catch (e) {
					// ignored;
				}

				// status 만 변경할 것
				setFieldValue('status', 'NOT_SUBMITTING');
			}
		},
	});

	const handleSubmitDebounce = useCallback(debounce(formikResult.handleSubmit, 500), [
		formikResult.handleSubmit,
	]);

	return {
		...formikResult,
		errors: { ...formikResult.errors, ...errorValue },
		handleSubmit: handleSubmitDebounce,
	};
};
