import axios from 'axios';
import { setAxiosRequestTokenInterceptor } from '../common/setAxiosRequestTokenInterceptor';

// Post일 경우엔 형식이 다름
export const axiosAppPostClient = axios.create();

setAxiosRequestTokenInterceptor(axiosAppPostClient);

axiosAppPostClient.interceptors.response.use(
	(response) => {
		// if ((response.data as AppPostResponseEntityType<any>).header.code === 200) {
		// 	response.data = response.data.body;
		// }
		return response;
	},
	(error) => {
		console.log(error, error.response.data);
		return Promise.reject(error.response.data);
	}
);
