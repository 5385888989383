export const WaitingTypeArray = ['TICKET', 'PHONE', 'APP', 'PHONE_AND_APP'] as const;
export type WaitingType = (typeof WaitingTypeArray)[number];
export const WaitingTypeLabel: Record<WaitingType, string> = {
	TICKET: '핸드폰 번호 미입력, 지류 번호표인쇄',
	PHONE: '핸드폰 번호 입력, 알림톡 발송',
	PHONE_AND_APP: '앱 간편 입장 겸용 사용',
	APP: '앱 간편 입장 전용',
};
export type SignUpInfoType = {
	isPhoneEnabled: boolean;
	isBirthdayEnabled: boolean;
	isAddressEnabled: boolean;
	isNameEnabled: boolean;
	isGenderEnabled: boolean;
};

export type WaiverInfoType = {
	id: number;
	// ipAddress: string;
	name: string;
	storeInfoId: number;
	pinNumber: string;

	waitingType: WaitingType;
	signupInfo?: SignUpInfoType;
	isVisitCountEnabled: boolean;
	waitingInfo?: {
		isNameEnabled: boolean;
		isBirthdayEnabled: boolean;
		isGenderEnabled: boolean;
		isRelationEnabled: boolean;
	};

	agreementImageUrl?: string;
	agreementImageEngUrl?: string;
	backgroundImageUrl?: string;
	brandLogoImageUrl?: string;
};

export const isRequired유저상세정보 = (waitingInfo: WaiverInfoType['waitingInfo']) => {
	return (
		waitingInfo?.isBirthdayEnabled ||
		waitingInfo?.isGenderEnabled ||
		waitingInfo?.isNameEnabled ||
		waitingInfo?.isRelationEnabled ||
		false
	);
};
