import { AdminKioskBoardInfoState } from '@kinderlabs-pos/state';
import { Stack, Tab, Tabs } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { useEffect, useMemo } from 'react';
import { useKioskTranslation } from '../../../const/useKioskTranslation';
import { getI18nString } from '@kinderlabs-pos/shared-util';

export const KioskBoardMenu = ({ kioskBoardInfoId }: { kioskBoardInfoId: number }) => {
	const { kioskI18N } = useKioskTranslation();
	const { data } = useQuery({
		...AdminKioskBoardInfoState.keys.menuList(kioskBoardInfoId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const [selectedMenuIndex, selectMenu] = useAtom(KioskBoardMenu.selectedMenuIndex);
	const unFilteredkioskBoardMenuList = data ?? [];

	const kioskBoardMenuList = useMemo(
		() => unFilteredkioskBoardMenuList.filter((menu) => !menu.isDisabled),
		[unFilteredkioskBoardMenuList]
	);

	useEffect(() => {
		selectMenu(kioskBoardMenuList.length > 0 ? 0 : undefined);

		return () => {
			selectMenu(kioskBoardMenuList.length > 0 ? 0 : undefined);
		};
	}, [kioskBoardMenuList]);

	const hasMenu = selectedMenuIndex || selectedMenuIndex === 0;

	return (
		<Stack
			sx={(theme) => ({ bgcolor: theme.palette.primary.main })}
			minHeight={70}
			alignItems={'center'}
			justifyContent={'center'}>
			<Tabs
				sx={{ width: '100%', height: '100%', color: 'white', mt: 3 }}
				value={selectedMenuIndex}
				onChange={(e, selectedIdx: number) => {
					selectMenu(selectedIdx);
				}}
				TabIndicatorProps={{
					sx: { height: 8, color: 'white', bgcolor: 'white' },
				}}
				TabScrollButtonProps={{
					className: 'aa',
					sx: { mx: 3, '&:not(.Mui-disabled) > svg': { fontSize: 70 } },
				}}
				variant={'scrollable'}
				scrollButtons>
				{(hasMenu ? kioskBoardMenuList : []).map((boardMenu, idx) => (
					<Tab
						disableFocusRipple
						disableRipple
						disableTouchRipple
						sx={(theme) => ({
							color: 'white',
							minWidth: '20%',
							fontSize: theme.typography.h6.fontSize,
							'&.Mui-selected': {
								color: 'white',
							},
						})}
						key={idx}
						label={
							getI18nString({
								ko: boardMenu.name,
								i18nString: boardMenu.i18nName,
								i18n: kioskI18N,
							}) ?? ''
						}
					/>
				))}
			</Tabs>
		</Stack>
	);
};

KioskBoardMenu.selectedMenuIndex = atomWithReset<number | undefined>(undefined);
