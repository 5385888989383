import { CashUnitType, DdiziType } from '@kinderlabs-pos/shared-data-type';
import {
	CustomerReceiptRequestType,
	ExchangeCouponRequestType,
	FrontReceiptRequestType,
	KitchenReceiptRequestType,
	VaultcashReceiptRequestType,
} from './ReceiptPrinterType';

export const ExchangeCouponRequestDummy: ExchangeCouponRequestType = {
	couponId: '15213',
	couponName: '아메리카노',
	couponState: '발행',
	orderId: 'O241201-001-000001',
	publishedDate: new Date(),
	others: {
		greetings: [
			'',
			'차액 결제 시 다른 음료로 교환가능합니다',
			'',
			'※ 발행 당일 1회만 사용가능',
			'※ 분실시 재발행 불가',
			'※ 1장당 1잔씩 제공',
			'※ 제조음료 교환은 19시까지 가능합니다.',
		],
	},
	barcodeType: 'QRCODE',
};

export const CustomerReceiptRequestDummy: CustomerReceiptRequestType = {
	deviceName: '샘플 포스',
	receipt: {
		paymentId: 'O221207-001-000001',
		receiptType: 'ORDER',
		orderDate: new Date(),
		// cancelDate: undefined,
	},
	company: {
		name: '(주)킨더랩스',
		number: '276-81-01890',
		representative: '김요나',
		address: '서울시 성동구 아차산로 49, 805호',
		contact: '02-6410-0116',
	},
	order: [
		{
			type: 'PRODUCT',
			name: '[이용권] 일반 2시간',
			quantity: 1,
			unitPrice: 25500,
			totalPrice: 25500,
			isForKitchen: false,
		},
		{
			type: 'PRODUCT',
			name: '[양말] NEW양말 SM',
			quantity: 1,
			unitPrice: 3000,
			totalPrice: 3000,
			isForKitchen: false,
		},
		{
			type: 'PRODUCT',
			name: '[이용권] 보호자 입장권',
			quantity: 1,
			unitPrice: 6000,
			totalPrice: 6000,
			isForKitchen: false,
		},
	],
	aggregate: {
		total: 34500,
		discount: 0,
		actual: 34500,
		totalTax: 345,
	},
	payments: [
		{
			type: 'BUY',
			amount: 34500,
			approvalCompany: 'IBK비씨카드',
			bin: '512345**********',
			installment: 0,
			approvalId: '73341534',
		},
	],
	others: {
		greetings: ['정성을 다하겠습니다.', '결제 취소시 영수증을 지참해 주시기 바랍니다'],
	},
	orderId: '221211-1-1',
	orderDailySeq: 1,
	barcodeType: 'QRCODE',
};

const sampleCashUnits: CashUnitType = {
	cash50000: 100,
	cash10000: 100,
	cash5000: 100,
	cash1000: 100,
	cash500: 100,
	cash100: 100,
	cash50: 100,
	cash10: 100,
};

export const VaultcashReceiptRequestDummy: VaultcashReceiptRequestType = {
	id: 0,
	createdDate: new Date(),
	type: 'CHECK',
	overAndShort: 0,
	countedAmount: 6666000,
	expectedAmount: 6666000,
	cashUnits: sampleCashUnits,
	storeId: 1,
	posId: 1,
	memo: '테스트 시재점검표',
	memoId: '0',
	cashSales: 1000000,
	withdraws: 100000,
	deposits: 100000,
	openCashAmount: 100000,
};

export const FoodCustomerReceiptRequestDummy: CustomerReceiptRequestType = {
	deviceName: '샘플 포스',
	receipt: {
		paymentId: 'O221207-001-000001',
		receiptType: 'ORDER',
		orderDate: new Date(),
		// cancelDate: undefined,
	},
	company: {
		name: '(주)킨더랩스',
		number: '276-81-01890',
		representative: '김요나',
		address: '서울시 성동구 아차산로 49, 805호',
		contact: '02-6410-0116',
	},
	order: [
		{
			type: 'PRODUCT',
			name: '[임의분류] 진동벨17',
			quantity: 1,
			unitPrice: 0,
			totalPrice: 0,
			isForKitchen: false,
		},
		{
			type: 'PRODUCT',
			name: '[푸드] 순살후라이드 치킨',
			quantity: 2,
			unitPrice: 17000,
			totalPrice: 34000,
			isForKitchen: true,
		},
		{
			type: 'PRODUCT',
			name: '[푸드] 콜라',
			quantity: 4,
			unitPrice: 1500,
			totalPrice: 6000,
			isForKitchen: false,
		},
	],
	aggregate: {
		total: 40000,
		discount: 0,
		actual: 40000,
		totalTax: 400,
	},
	payments: [
		{
			amount: 40000,
			approvalCompany: 'IBK비씨카드',
			bin: '512345**********',
			installment: 0,
			approvalId: '73341534',
			type: 'BUY',
		},
	],
	others: {
		greetings: ['정성을 다하겠습니다.', '결제 취소시 영수증을 지참해 주시기 바랍니다'],
	},
	orderId: 'O220101-001-000001',
	orderDailySeq: 1,
	barcodeType: 'QRCODE',
};

export const FrontReceiptRequestDummy: FrontReceiptRequestType = {
	foodReceiptId: 41,
	order: FoodCustomerReceiptRequestDummy.order,
	orderDeviceName: '',
	orderDate: new Date(),
	largeFont: false,
	isCancel: false,
};

export const KitchenReceiptRequestDummy: KitchenReceiptRequestType = {
	foodReceiptId: 41,
	order: FoodCustomerReceiptRequestDummy.order,
	orderDeviceName: '',
	orderDate: new Date(),
	largeFont: false,
	isCancel: false,
};

export const getDdiziDummy = ({
	storeId,
	deviceId,
}: {
	storeId: number;
	deviceId: number;
}): DdiziType => ({
	id: '0625010002',
	status: 'USING',
	name: '더미 티켓',
	multiple: 1,
	available: 'ALL',
	// hoursType: DdiziInfoType['hours'];
	type: 'NORMAL',
	minutes: 60,
	hasNoAdditionalCharge: false,
	publishInfo: {
		published: new Date(),
		storeId,
		posId: deviceId,
		orderId: 'DUMMY_ORDER',
		sourceType: 'POS',
		sourceId: 'DUMMY',
		productInfoId: 'DUMMY',
	},
});
