import { axiosAppClient, axiosAppPostClient } from '@kinderlabs-pos/apis/axios';
import {
	InfoType,
	MyInfoType,
	MyUsageType,
	PasswordType,
	PostImageType,
} from '@kinderlabs-pos/shared-data-type';

export const getMyInfoAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<MyInfoType>(`${rootUrl}/users/myInfo`)
	).data;

export const getInfoAsync = (rootUrl: string) => async () =>
	await (
		await axiosAppClient.get<InfoType>(`${rootUrl}/users/info`)
	).data;

export const postAddressAsync = (rootUrl: string) => async (addressFormData: FormData) =>
	await (
		await axiosAppClient.post<InfoType>(`${rootUrl}/users/address`, addressFormData)
	).data;

export const updateInfoPasswordAsync = (rootUrl: string) => async (passwordFormData: FormData) =>
	await (
		await axiosAppClient.post<PasswordType>(`${rootUrl}/users/password`, passwordFormData)
	).data;

export const postInfoImageAsync = (rootUrl: string) => async (imageFormData: FormData) =>
	await (
		await axiosAppClient.post<PostImageType>(`${rootUrl}/users/images`, imageFormData)
	).data;

export const getUsageInfoAsync = (rootUrl: string) => async () =>
	(await axiosAppClient.get<MyUsageType>(`${rootUrl}/users/usage`)).data;

export const getSignIn = (domain: string) => async () =>
	await (
		await axiosAppPostClient.get<any>(`${domain}/identity/ready`)
	).data;
