import { AdminKioskInfoState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogProps,
	Stack,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { PinNumberBoard } from '../components/PinNumberBoard';

const WRONG_PIN_NUMBER_MESSAGE = '키오스크의 핀번호가 틀렸습니다.';
export const KioskLocalAdminPinNumberDialog = ({
	storeId,
	kioskId,
	onSuccess,
	closeDialog,
	...others
}: DialogProps & {
	storeId: number;
	kioskId: number;
	onSuccess: () => void;
	closeDialog: () => void;
}) => {
	const [tempPinValues, setTempPinValues] = useState<string[]>([]);
	const [pinErrorMessage, setPinErrorMessage] = useState<string>('');
	useEffect(() => {
		if (tempPinValues.length === 1) {
			setPinErrorMessage('');
		}
	}, [tempPinValues.length]);

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId, kioskId }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const handleClick확인 = () => {
		if (kioskInfo && tempPinValues.join('') === kioskInfo.pinNumber) {
			onSuccess();
			closeDialog();
		} else {
			setPinErrorMessage(WRONG_PIN_NUMBER_MESSAGE);
			setTempPinValues([]);
		}
	};

	return (
		<WithDialog.XlDialog
			maxWidth={'md'}
			dialogTitle={'PIN 넘버 입력'}
			closeDialog={closeDialog}
			{...others}>
			<DialogContent>
				<Stack spacing={2}>
					<PinNumberBoard
						pinValues={tempPinValues}
						setPinValues={setTempPinValues}
					/>
				</Stack>
				<Stack px={2}>
					<Typography
						variant={'subtitle1'}
						color={'error'}>
						{pinErrorMessage || 'ㅤ'}
					</Typography>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					width={'100%'}
					spacing={3}
					px={2}
					direction={'row'}>
					<Button
						fullWidth
						variant='contained'
						onClick={handleClick확인}>
						확인
					</Button>
					<Button
						fullWidth
						variant='outlined'
						onClick={closeDialog}>
						닫기
					</Button>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
