import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ProductDiscountMgInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateProductDiscountInfoAsync =
	(rootUrl: string) =>
	async ({ id, updateData }: UpdateProductDiscountInfoRequestType) =>
		(
			await posAxiosClient.put<ProductDiscountMgInfoType>(
				`${rootUrl}/product-discount`,
				{ ...updateData, id: id },
				{
					params: {
						id,
					},
				}
			)
		).data;

type UpdateProductDiscountInfoRequestType = {
	id: number;
	updateData: Omit<ProductDiscountMgInfoType, 'id'>;
};
