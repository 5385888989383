import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, DeviceType, KitchenSystemInfoType } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../../const';

const getKitchenSystemInfoListAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		kitchenSystemIdList,
	}: {
		storeIdList?: number[];
		kitchenSystemIdList?: number[];
	}) =>
		(
			await posAxiosClient.get<(KitchenSystemInfoType & BaseInfoType)[]>(
				`${rootUrl}/kitchen-system/list`,
				{
					params: {
						storeIdList,
						idList: kitchenSystemIdList,
					},
				}
			)
		).data;

const getKitchenSystemInfoAsync =
	(rootUrl: string) =>
	async ({ systemId }: { systemId: number }) =>
		(
			await posAxiosClient.get<KitchenSystemInfoType & BaseInfoType>(`${rootUrl}/kitchen-system`, {
				params: {
					id: systemId,
				},
			})
		).data;

const createKitchenSystemInfoAsync =
	(rootUrl: string) =>
	async ({ data }: { data: Omit<KitchenSystemInfoType, 'id'> }) =>
		(
			await posAxiosClient.post<KitchenSystemInfoType & BaseInfoType>(`${rootUrl}/kitchen-system`, {
				...data,
			})
		).data;

const updateKitchenSystemInfoAsync =
	(rootUrl: string) =>
	async ({ data }: { data: KitchenSystemInfoType }) =>
		(
			await posAxiosClient.put(
				`${rootUrl}/kitchen-system`,
				{ ...data },
				{
					params: {
						id: data.id,
					},
				}
			)
		).data;

const deleteKitchenSystemInfoAsync = (rootUrl: string) => async (idList: number[]) =>
	(
		await posAxiosClient.delete(`${rootUrl}/kitchen-system`, {
			params: {
				idList,
			},
		})
	).data;

export type CanDeleteDeviceInfoType = {
	storeId: number;
	deviceId: number;
	deviceName: string;
	deviceType: DeviceType;
	kitchenSystemId: number;
	modifiedBy: string;
	modifiedDate: Date;
};
const canDeleteKitchenSystemAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<CanDeleteDeviceInfoType[]>(`${rootUrl}/kitchen-system/can-delete`, {
			params: {
				id,
			},
		})
	).data;

export const AdminKitchenSystemInfoApis = {
	getKitchenSystemInfoList: getKitchenSystemInfoListAsync(rootUrl),
	getKitchenSystemInfo: getKitchenSystemInfoAsync(rootUrl),
	createKitchenSystem: createKitchenSystemInfoAsync(rootUrl),
	updateKitchenSystem: updateKitchenSystemInfoAsync(rootUrl),
	deleteKitchenSystem: deleteKitchenSystemInfoAsync(rootUrl),
	canDeleteKitchenSystem: canDeleteKitchenSystemAsync(rootUrl),
};
