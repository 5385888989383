export const boardColorArray = [
	'blue',
	'purple',
	'gold',
	'pink',
	'magenta',
	'green',
	'yellow',
	'red',
	'black',
] as const;
export type BoardColorType = typeof boardColorArray[number];

export const boardColorSelectionsMap: Record<BoardColorType, string> = {
	blue: '#568DE5',
	purple: '#A32CC4',
	gold: '#E69B00',
	pink: '#F27F9D',
	magenta: '#A1045A',
	green: '#00703c',
	yellow: '#CCCC00',
	red: '#D0312D',
	black: '#000000',
};

const excludeChartColor: Partial<BoardColorType>[] = ['black'];

export const chartColorArray = boardColorArray.filter(
	(color) => !excludeChartColor.includes(color)
);
