import { AuthApis } from '@kinderlabs-pos/apis/storeinfo';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { AuthGuard } from './AuthGuard';
import { enterpriseInfoAtom, storeIdListForManagerAtom, userAtom } from './userAtom';
import { GiftCardProviderType } from '@kinderlabs-pos/shared-data-type';
import base64 from 'base-64';

const loginAtom = atom(
	null,
	async (
		get,
		set,
		payload: {
			email: string;
			password: string;
		}
	) => {
		const result = await AuthApis.login(payload);

		set(userAtom, result.userInfo);
		set(enterpriseInfoAtom, result.enterpriseInfo);
	}
);

const isLoggedInAtom = atom((get) => !!get(userAtom));

// 이후 useMutation 등으로 afterVerify 를 없애보자.
const verifyAtom = atom(
	null,
	async (get, set, callback: { onSuccess: () => void; onFailure: () => void }) => {
		const data = await AuthApis.verify();

		if (data?.userInfo) {
			set(userAtom, data.userInfo);
			set(enterpriseInfoAtom, data.enterpriseInfo);
			callback.onSuccess();
		} else {
			callback.onFailure();
		}
	}
);

const logoutAtom = atom(null, async (get, set) => {
	const logoutResult = await AuthApis.logout();

	set(userAtom, null);
});

const reissueToken = atom(null, async (get, set, update) => {
	await AuthApis.reissueTokenAsync();
});

const curAuthority = atom((get) => {
	return get(userAtom)?.authority;
});

let interval: NodeJS.Timer | null = null;

/**
 * 25 분에 한번씩 Token을 Refresh 하는 Request를 보내는 녀석
 */
const InfiniteAuthRefresh = () => {
	const user = useAtomValue(authState.user);
	const reissueToken = useSetAtom(authState.reissueToken);

	useEffect(() => {
		if (!user) return;

		console.log('Auto Refresh Token Interval Start');
		interval = setInterval(() => {
			reissueToken();
		}, 30 * 1000);

		return () => {
			console.log('clear Reissue Interval', new Date());
			interval && clearInterval(interval);
		};
	}, [user]);

	return null;
};
const useIsOverAdmin = () => {
	const authority = useAtomValue(authState.curAuthority);

	return authority === 'ADMIN' || authority === 'SUPERUSER';
};

const use상품권ProviderList = () => {
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);

	return useMemo<GiftCardProviderType[]>(() => {
		if (!enterpriseInfo) return [];
		else if (enterpriseInfo.id === 'vaunce') return [];
		else if (enterpriseInfo.id === 'zoolung') return ['ZLGOON'];
		else return [];
	}, [enterpriseInfo]);
};

export const authState = {
	login: loginAtom,
	logout: logoutAtom,
	isLoggedIn: isLoggedInAtom,
	verify: verifyAtom,
	user: userAtom,
	enterpriseInfo: enterpriseInfoAtom,
	storeIdListForManager: storeIdListForManagerAtom,
	curAuthority: curAuthority,
	useIsOverAdmin,
	use상품권ProviderList,
	reissueToken: reissueToken,
	AuthGuard: AuthGuard,
	InfiniteAuthRefresh,
	isTokenExpiringSoon,
};

function decodeToken(token: string) {
	const payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
	const decodingInfo = base64.decode(payload);
	return JSON.parse(decodingInfo);
}

function isTokenExpiringSoon(token: string) {
	const decodedToken = decodeToken(token);
	const currentTime = Math.floor(Date.now() / 1000); // 현재 시간 (초 단위)
	const oneMinuteLater = currentTime + 60; // 현재 시간에 60초 더함

	return decodedToken.exp < oneMinuteLater; // 만료 시간이 1분 이내인지 확인
}
