import { ProductItemPropsType } from '.';

export const ProductDefaultItemProps: ProductItemPropsType = {
	CartListItemProp: {
		size: undefined,
		color: undefined,
	},
	nameTypographyProps: {
		variant: 'h5',
	},
	subNameTypographyProps: {
		variant: 'body2',
		color: 'primary',
	},
	quantityTypographyProps: {
		variant: 'h5',
	},
	quantityChangeTypographyProps: {
		variant: 'body2',
	},
	priceTypographyProps: {
		variant: 'h5',
	},
	unitPriceTypographyProps: {
		variant: 'body2',
		color: 'primary',
	},
};
