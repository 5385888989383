import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminCategoryInfoListFilterType,
	BaseInfoType,
	CategoryInfoType,
	Pageable,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';

const productApiName = 'product';
const categoryApiName = 'category';
export const canDeleteCategoryInfoAsync =
	(rootUrl: string) =>
	async ({ categoryList }: { categoryList: number[] }) =>
		(
			await posAxiosClient.get<{
				productInfos: Record<number, (ProductInfoType & BaseInfoType)[]>;
			}>(`${rootUrl}/${productApiName}/${categoryApiName}/can-delete`, {
				params: {
					idList: categoryList,
				},
			})
		).data;

export const createCategoryInfo =
	(rootUrl: string) =>
	async (categoryName: string, level: CategoryInfoType['level'], parentCategoryId?: number) =>
		await (
			await posAxiosClient.post<CategoryInfoType>(
				`${rootUrl}/${productApiName}/${categoryApiName}/`,
				null,
				{
					params: {
						categoryName,
						level,
						parentCategoryId,
					},
				}
			)
		).data;

export const deleteCategoryInfo =
	(rootUrl: string) =>
	async ({ deleteList }: { deleteList: number[] }) =>
		await posAxiosClient.delete<number[]>(`${rootUrl}/${productApiName}/${categoryApiName}/list`, {
			params: {
				idList: deleteList,
			},
		});

export const getCategoryAllListAsync = (rootUrl: string) => async () =>
	(
		await posAxiosClient.get<(Omit<CategoryInfoType, 'usageCount'> & BaseInfoType)[]>(
			`${rootUrl}/${productApiName}/${categoryApiName}/all`
		)
	).data;

export const getCategoryTopListAsync = (rootUrl: string) => async () =>
	(
		await posAxiosClient.get<(CategoryInfoType & BaseInfoType)[]>(
			`${rootUrl}/${productApiName}/${categoryApiName}/top`
		)
	).data;

export const getCategoryInfoDetailAsync = (rootUrl: string) => async (categoryId: number) =>
	(
		await posAxiosClient.get<{
			category: CategoryInfoType & BaseInfoType;
			productInfoList: (ProductInfoType & BaseInfoType)[];
		}>(`${rootUrl}/${productApiName}/${categoryApiName}/detail`, {
			params: {
				id: categoryId,
			},
		})
	).data;

export const getCategoryInfoListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: AdminCategoryInfoListFilterType) =>
		(
			await posAxiosClient.get<(CategoryInfoType & BaseInfoType)[]>(
				`${rootUrl}/${productApiName}/${categoryApiName}/list`,
				{
					params: {
						...options,
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;

export const getCategoryInfoOneAsync = (rootUrl: string) => async (categoryId: number) =>
	(
		await posAxiosClient.get<CategoryInfoType & BaseInfoType>(
			`${rootUrl}/${productApiName}/${categoryApiName}`,
			{
				params: {
					id: categoryId,
				},
			}
		)
	).data;

export const updateCategoryInfo =
	(rootUrl: string) =>
	async ({ id, name }: { id: number; name: string }) =>
		await posAxiosClient.put<CategoryInfoType>(`${rootUrl}/${productApiName}/${categoryApiName}`, {
			id,
			name,
		});

export const changeParentCategoryInfo =
	(rootUrl: string) =>
	async ({
		categoryId,
		toCategoryId,
	}: {
		// categoryId: 옮길 카테고리
		categoryId: number;
		// toCategoryId: 어느 부모 밑으로 옮길지
		toCategoryId: number;
	}) =>
		await posAxiosClient.post<CategoryInfoType>(
			`${rootUrl}/${productApiName}/${categoryApiName}/change/${categoryId}/to/${toCategoryId}`
		);

export const updateProductCategoryInfo =
	(rootUrl: string) =>
	async ({ productId, categoryId }: { productId: number; categoryId: number }) =>
		(
			await posAxiosClient.put<ProductInfoType>(`${rootUrl}/${productApiName}/product-category`, {
				productId,
				categoryId,
			})
		).data;

export const updateMultiProductsCategory =
	(rootUrl: string) =>
	async ({ productIdList, categoryId }: { productIdList: number[]; categoryId: number }) =>
		(
			await posAxiosClient.put<number[]>(`${rootUrl}/${productApiName}/edit/category`, {
				productIdList,
				categoryId,
			})
		).data;
