import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import { Button, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ReactNode } from 'react';
import { useKioskTranslation } from '../const/useKioskTranslation';

export const KioskNumberBoard = ({
	onClickNumber,
	customsize = 'medium',
}: {
	onClickNumber: (value: number | 'clear' | 'delete') => void;
	customsize?: 'small' | 'medium';
}) => {
	const { t, kioskI18N } = useKioskTranslation();

	return (
		<Grid2
			container
			spacing={3}>
			{cards.map((card, idx) => (
				<Grid2
					key={idx}
					xs={4}>
					<StyledButton
						customsize={customsize}
						variant='outlined'
						fullWidth
						onClick={() => onClickNumber(card.key)}>
						<Typography variant={'h4'}>{card.label}</Typography>
					</StyledButton>
				</Grid2>
			))}
			<Grid2 xs={4}>
				<StyledButton
					customsize={customsize}
					variant='outlined'
					fullWidth
					onClick={() => onClickNumber('clear')}>
					<Typography variant={'body1'}>
						{t('common.all')}
						<br /> {t('common.delete')}
					</Typography>
				</StyledButton>
			</Grid2>
			<Grid2 xs={4}>
				<StyledButton
					customsize={customsize}
					variant='outlined'
					fullWidth
					onClick={() => onClickNumber(0)}>
					<Typography variant={'h4'}>{'0'}</Typography>
				</StyledButton>
			</Grid2>
			<Grid2 xs={4}>
				<StyledButton
					customsize={customsize}
					variant='outlined'
					fullWidth
					onClick={() => onClickNumber('delete')}>
					<Typography
						variant={'h4'}
						sx={{ lineHeight: 1 }}>
						<BackspaceOutlinedIcon fontSize='inherit' />
					</Typography>
				</StyledButton>
			</Grid2>
		</Grid2>
	);
};

const cards: { key: number; label: ReactNode }[] = [
	{
		key: 1,
		label: '1',
	},
	{
		key: 2,
		label: '2',
	},
	{
		key: 3,
		label: '3',
	},
	{
		key: 4,
		label: '4',
	},
	{
		key: 5,
		label: '5',
	},
	{
		key: 6,
		label: '6',
	},
	{
		key: 7,
		label: '7',
	},
	{
		key: 8,
		label: '8',
	},
	{
		key: 9,
		label: '9',
	},
];

const StyledButton = styled(Button)<{
	customsize?: 'small' | 'medium';
}>(({ theme, customsize }) => ({
	aspectRatio: customsize === 'medium' ? '1/1' : '2/1',
	borderRadius: 4,
	border: `2px solid ${theme.palette.primary.main}`,
}));
