import { PaymentAPI } from '@kinderlabs-pos/apis/pos';
import {
	GiftCardProviderType,
	Pageable,
	PaymentMethodType,
} from '@kinderlabs-pos/shared-data-type';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { QueryClient } from '../QueryClient';
import { StoreInfoState } from '../StoreInfoState';
import { authState } from '../AuthState';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useEffect } from 'react';

type PaymentListParamsType = {
	page: number;
	size?: number;
	storeIdList?: number[];
	posIdList?: number[];
	search?: string;
	from: Dayjs;
	to: Dayjs;
	method?: PaymentMethodType;
	status?: 'REFUND' | 'COMPLETE';
};

type PaymentListInfiniteParamsType = {
	params: Omit<PaymentListParamsType, 'page' | 'size' | 'status'> & { isForRefundSearch: boolean };
	pageable?: Pageable;
};

const paymentKeys = createQueryKeys('payments', {
	list: ({ params }: { params: PaymentListParamsType }) => {
		const storeIdList = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				const res = await PaymentAPI.getList({
					...params,
					storeIdList: params.storeIdList ?? storeIdList,
					posIdList: params.posIdList || undefined,
					pageable: Pageable.getPageable(params),
					from: params.from.startOf('d'),
					to: params.to.endOf('d'),
					method: params.method,
					status: params.status,
					keyword: params.search,
				});
				return res;
			},
		};
	},
	infinite: ({
		params,
		pageable = Pageable.getPageable({ page: 0, size: 20 }),
	}: PaymentListInfiniteParamsType) => ({
		queryKey: ['list', params],
		queryFn: async ({ pageParam }: { pageParam?: { startCursorId: string | number } }) => {
			const res = await PaymentAPI.getInfiniteList({
				...params,
				cursorId: pageParam?.startCursorId,
				pageable,
			});

			return {
				content: res.content,
				isLast: !res.hasMore,
				startCursorId: res.startCursorId,
			};
		},
	}),
	order: ({ storeId, posId, orderId }: { storeId: number; posId: number; orderId?: string }) => ({
		queryKey: [storeId, posId, orderId],
		queryFn: async () => {
			if (!orderId) return null;
			const res = await PaymentAPI.getPaymentsInOrder({
				storeId,
				posId,
				orderId: orderId,
			});
			return res;
		},
	}),
	giftCard: ({ keyword, provider }: { keyword: string; provider?: GiftCardProviderType }) => {
		const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
		return {
			queryKey: [provider, keyword],
			queryFn: async () => {
				if (!provider || keyword.length < 3) return null;

				try {
					return await PaymentAPI.searchGiftCard({
						storeId,
						posId: deviceId,
						keyword,
						provider,
					});
				} catch {
					return null;
				}
			},
		};
	},
});

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: paymentKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: paymentKeys._def });
};

export const PaymentQueryState = {
	keys: paymentKeys,
	invalidateQueries,
};
