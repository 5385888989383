import { KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { useMemo, useState } from 'react';

const StepTypeArray = ['이용약관', '전화번호', '생년월일', '이름', '주소', '성별'] as const;
export type StepType = (typeof StepTypeArray)[number];

type FunnelStepType = {
	curStep: StepType;
	enabled: boolean;
};

export const useFunnelStepsForKioskSignup = ({
	kioskSignupInfo,
	isTicketKiosk,
}: {
	kioskSignupInfo: KioskInfoType['signupInfo'];
	isTicketKiosk: boolean;
}) => {
	return useMemo<FunnelStepType[]>(() => {
		if (!kioskSignupInfo) return [];
		const result: FunnelStepType[] = [];

		result.push({ curStep: '전화번호', enabled: kioskSignupInfo && isTicketKiosk });
		result.push({ curStep: '이용약관', enabled: kioskSignupInfo && isTicketKiosk });
		result.push({ curStep: '생년월일', enabled: kioskSignupInfo.isBirthdayEnabled ?? false });
		result.push({ curStep: '이름', enabled: kioskSignupInfo.isNameEnabled ?? false });
		result.push({ curStep: '주소', enabled: kioskSignupInfo.isAddressEnabled ?? false });
		result.push({ curStep: '성별', enabled: kioskSignupInfo.isGenderEnabled ?? false });

		return result;
	}, [kioskSignupInfo, isTicketKiosk]);
};

export const useFunnelStepHandler = ({
	funnelSteps,
	beforeStart,
	onFinish,
}: {
	funnelSteps: FunnelStepType[];
	beforeStart: () => void;
	onFinish: () => void;
}) => {
	const [curStep, setCurStep] = useState<StepType>('전화번호');

	const goNextStep = () => {
		const index = funnelSteps.findIndex((f) => f.curStep === curStep);
		let indexNext = index + 1;

		while (indexNext < funnelSteps.length) {
			if (funnelSteps[indexNext].enabled) {
				setCurStep(funnelSteps[indexNext].curStep);
				return;
			} else {
				indexNext = indexNext + 1;
			}
		}

		setTimeout(() => onFinish(), 0);
	};

	const goPrevStep = () => {
		const index = funnelSteps.findIndex((f) => f.curStep === curStep);
		let indexNext = index - 1;

		while (indexNext >= 0) {
			if (funnelSteps[indexNext].enabled) {
				setCurStep(funnelSteps[indexNext].curStep);
				return;
			} else {
				indexNext = indexNext - 1;
			}
		}

		beforeStart();
	};

	return {
		curStep,
		goNextStep,
		goPrevStep,
	};
};
