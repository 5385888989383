import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ServerInfoType } from '@kinderlabs-pos/shared-data-type';

const serverInfoRootUrl = process.env['NX_SERVERINFO_API_URL'];
if (!serverInfoRootUrl) {
	throw Error('[DEV] .env 에 NX_SERVERINFO_API_URL 를 설정해주세요.');
}

const getServerInfoAsync = (rootUrl: string) => async () =>
	(await posAxiosClient.get<ServerInfoType>(`${rootUrl}/actuator/info`)).data;

export const ServerInfoApis = {
	getServerInfo: getServerInfoAsync(serverInfoRootUrl || ''),
};
