import { deviceAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ConnectInfoType, DeviceType } from '@kinderlabs-pos/shared-data-type';

export const getSessionName = (deviceType: DeviceType) =>
	`${deviceType.toLowerCase()}-connect-session`;

export const healthCheckDeviceAsync =
	(rootUrl: string) =>
	async ({ storeId, deviceId, deviceType }: RequestType) => {
		const SESSION_NAME = getSessionName(deviceType);
		const sessionId = localStorage.getItem(SESSION_NAME);

		const result = await deviceAxiosClient.post<ConnectInfoType>(
			`${rootUrl}/connect/store/${storeId}/type/${deviceType}/health-check`,
			{},
			{
				params: {
					deviceId,
				},
				...(sessionId && {
					headers: {
						[SESSION_NAME]: sessionId,
					},
				}),
			}
		);

		const resultSessionId = result.headers[SESSION_NAME];
		if (resultSessionId) localStorage.setItem(SESSION_NAME, resultSessionId);

		return result.data;
	};
type RequestType = {
	storeId: number;
	deviceId: number;
	deviceType: DeviceType;
};
