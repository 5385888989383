import { AdminKioskInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import banner from 'assets/banner.jpg';
import { useAtomValue } from 'jotai';
import { CircularTimeoutButton } from '../../../components/CircularTimeoutButton';
import { useKioskRouter } from '../../../utils/useKioskRouter';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';

export const KioskBoardPageHeader = () => {
	const { navigate홈으로 } = useKioskRouter();

	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const { t, kioskI18N } = useKioskTranslation();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId: storeInfo.id, kioskId: savedKioskInfo.id }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (!savedKioskInfo || !kioskInfo) throw new Error();

	const bannerImageUrl = (() => {
		switch (kioskI18N) {
			case 'eng':
				return kioskInfo?.bannerImageUrlEng ?? kioskInfo?.bannerImageUrl;
			case 'jpn':
				return kioskInfo?.bannerImageUrlJpn ?? kioskInfo?.bannerImageUrl;
			case 'chn':
				return kioskInfo?.bannerImageUrlChn ?? kioskInfo?.bannerImageUrl;
		}

		return kioskInfo?.bannerImageUrl;
	})();

	return (
		<Stack
			position={'relative'}
			height={360}>
			<상단Image backgroundImage={bannerImageUrl} />
			<Button
				sx={{
					position: 'absolute',
					top: 20,
					left: 40,
					backgroundColor: 'rgba(255, 255, 255, 0.7)',
				}}
				variant='outlined'
				onClick={navigate홈으로}>
				<Stack
					alignItems={'center'}
					direction={'row'}
					spacing={3}>
					<Typography variant={'h6'}>HOME</Typography>
					<CircularTimeoutButton
						timeout={60}
						onTimeoutCallback={navigate홈으로}
					/>
				</Stack>
			</Button>
			{/* </Stack> */}
		</Stack>
	);
};

const 상단Image = styled(Box)<{ backgroundImage?: string }>(({ backgroundImage }) => ({
	backgroundImage: `url(${backgroundImage || banner})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '100%',
	height: '100%',
}));
