import {
	CartLineExcessChargeInfoType,
	CartLineInfoType,
	CartLineInputType,
	DdiziTypeToJungsan,
	JungsanInfoType,
	ProductInfoType,
	defaultProductAdditionalInfo,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { DdiziState } from '../../../DdiziState';

export type ExcessChargeAction =
	| { type: 'INITIAL_SELECT'; ddizi?: DdiziTypeToJungsan; jungsanInfo: JungsanInfoType }
	| { type: 'TOGGLE'; ddizi: DdiziTypeToJungsan; jungsanInfo: JungsanInfoType }
	| { type: 'SELECT'; ddizi: DdiziTypeToJungsan; jungsanInfo: JungsanInfoType }
	| { type: 'UNSELECT'; ddiziId: string }
	| {
			type: 'TOGGLE_ALL';
			ddizis: DdiziTypeToJungsan[];
			jungsanInfo: JungsanInfoType;
	  }
	| {
			type: 'TOGGLE_FOR_ORDER';
			ddizis: DdiziTypeToJungsan[];
			jungsanInfo: JungsanInfoType;
	  }
	| { type: 'REFRESH'; ddizis: DdiziTypeToJungsan[]; jungsanInfo: JungsanInfoType }
	| { type: 'CLEAR' };

const findIndexFromCartLines = (cartLines: CartLineInputType[], ddiziId: string) => {
	return cartLines.findIndex(
		(cartLine) => (cartLine as CartLineExcessChargeInfoType).ddiziUsageId === ddiziId
	);
};

const cartLineExcessChargeReducer = (
	prev: CartLineInfoType[],
	action: ExcessChargeAction
): CartLineInfoType[] => {
	switch (action.type) {
		// 최초 SELECT 한번
		case 'INITIAL_SELECT':
			// new Date 체크할 것
			return action.ddizi && DdiziState.utils.isDdiziSelectableForJungsan(action.ddizi)
				? [createCartLineInput({ ddizi: action.ddizi, jungsanInfo: action.jungsanInfo })]
				: [];
		// TOGGLE 과 다르게 무조건 SELECT. 이미 SELECT 되어있다면 TOGGLE 하지 않는다.
		// KIOSK 때문에생긴 기능
		case 'SELECT':
			const isAlreadySelected = findIndexFromCartLines(prev, action.ddizi.id) >= 0;
			return isAlreadySelected
				? prev
				: //
				  [...prev, createCartLineInput({ ddizi: action.ddizi, jungsanInfo: action.jungsanInfo })];
		case 'TOGGLE':
			const hasAlready = findIndexFromCartLines(prev, action.ddizi.id) >= 0;
			return hasAlready
				? prev.filter(
						(cartLine) =>
							(cartLine as CartLineExcessChargeInfoType).ddiziUsageId !== action.ddizi.id
				  )
				: // new Date 체크할 것
				  [...prev, createCartLineInput({ ddizi: action.ddizi, jungsanInfo: action.jungsanInfo })];
		case 'UNSELECT':
			return prev.filter(
				(cartLine) => (cartLine as CartLineExcessChargeInfoType).ddiziUsageId !== action.ddiziId
			);
		case 'TOGGLE_ALL':
			if (prev.length > 0) return [];

			return action.ddizis
				.filter((ddizi) => DdiziState.utils.isDdiziSelectableForJungsan(ddizi))
				.map((ddizi) =>
					createCartLineInput({
						ddizi,
						jungsanInfo: action.jungsanInfo,
					})
				);

		case 'TOGGLE_FOR_ORDER':
			const untouchableDdiziList = prev.filter(
				(selectedDdizi) =>
					!action.ddizis
						.map((ddizi) => ddizi.id)
						.includes((selectedDdizi as CartLineExcessChargeInfoType).ddiziUsageId)
			);

			const includedInActionDdiziList = prev.filter(
				(selectedDdizi) => !untouchableDdiziList.includes(selectedDdizi)
			);

			return includedInActionDdiziList.length > 0
				? [...untouchableDdiziList]
				: [
						...untouchableDdiziList,
						...action.ddizis
							.filter((ddizi) => DdiziState.utils.isDdiziSelectableForJungsan(ddizi))
							.map((ddizi) => createCartLineInput({ ddizi, jungsanInfo: action.jungsanInfo })),
				  ];

		case 'REFRESH':
			return prev.map((cl) => {
				const targetDdizi = action.ddizis.find(
					(ddizi) => ddizi.id === (cl as CartLineExcessChargeInfoType).ddiziUsageId
				);

				return targetDdizi
					? {
							...createCartLineInput({ ddizi: targetDdizi, jungsanInfo: action.jungsanInfo }),
							quantity: 1,
					  }
					: cl;
			});
		case 'CLEAR':
			return [];
	}
};

const createCartLineInput = ({
	ddizi,
	jungsanInfo,
}: {
	ddizi: DdiziTypeToJungsan;
	jungsanInfo: JungsanInfoType;
}): CartLineExcessChargeInfoType => {
	if (jungsanInfo.type === 'PRODUCT')
		return {
			id: getUuidV4(),
			type: 'EXCESS_CHARGE',
			name: `${jungsanInfo.productInfo.name}`,
			price: ddizi.overTimeFeeMultiplier * jungsanInfo.productInfo.price,
			productInfoId: jungsanInfo.productInfo.id,
			productInfo: {
				category: '초과요금',
				isForKitchen: false,
			},
			productAdditionalInfo: {
				taxFree: jungsanInfo.productInfo.taxFree,
				supplyPrice: 0,
				rawPrice: 0,
			},
			ddiziUsageId: ddizi.id,
			quantity: ddizi.multiple,
		};
	else
		return {
			id: getUuidV4(),
			type: 'EXCESS_CHARGE',
			name: `초과 요금 (${ddizi.overTimeFeeMultiplier * jungsanInfo.overtimeUnit} 분)`,
			price: ddizi.overTimeFeeMultiplier * jungsanInfo.overtimeFeePolicy,
			productInfoId: undefined,
			productInfo: {
				category: '초과요금',
				isForKitchen: false,
			},
			productAdditionalInfo: defaultProductAdditionalInfo,
			ddiziUsageId: ddizi.id,
			quantity: ddizi.multiple,
		};
};

export const CartLineExcessChargeState = {
	reducer: cartLineExcessChargeReducer,
};
