import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ManagerUserType } from '@kinderlabs-pos/shared-data-type';

export const logoutAsync = (rootUrl: string) => async () => {
	// axios.defaults.headers.common['Authorization'] = '';
	// axios.defaults.headers.common['refreshToken'] = '';

	const result = (await posAxiosClient.post<ManagerUserType>(`${rootUrl}/auth-service/auth/logout`))
		.data;

	localStorage.removeItem('accessToken');
	localStorage.removeItem('refreshToken');
	localStorage.removeItem('enterpriseHash');

	return result;
};
