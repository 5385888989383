import { alpha, ListItemButton, styled } from '@mui/material';

export const SelectableListItem = styled(ListItemButton)<{ outlined?: boolean }>(
	({ theme, outlined = false }) => ({
		borderRadius: 4,
		border: outlined ? `1px solid ${alpha(theme.palette.primary.main, 0.3)}` : undefined,
		'&.Mui-disabled': {
			opacity: 1,
			backgroundColor: alpha(theme.palette.error.main, 0.2),
			border: 'none',
			// '& #buttons': {
			//   pointerEvents: 'all',
			// },
		},
		'&.Mui-selected': {
			opacity: 1,
			backgroundColor: alpha(theme.palette.primary.main, 0.2),
			border: outlined ? `1px solid ${alpha(theme.palette.primary.main, 0.5)}` : undefined,
		},
	})
);
