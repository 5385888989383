import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import { CustomerReceiptRequestType, DeviceType } from '@kinderlabs-pos/shared-data-type';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { StoreInfoState } from '../StoreInfoState';
import { PosState } from '../PosState';
import { sleep } from '@kinderlabs-pos/shared-util';

export const 직전영수증Atom = atom<CustomerReceiptRequestType | undefined>(undefined);
export const useCustomerReceiptPrinter = ({
	deviceType,
	autoOrManual,
}: {
	deviceType: DeviceType;
	autoOrManual: '자동' | '수동';
}) => {
	const { printMode, receiptPrinterList: RPList } = StoreInfoState.useCurDeviceInfoForOrder();
	const set직전영수증 = useSetAtom(직전영수증Atom);
	const { autoReceiptPrintOn } = useAtomValue(PosState.settings);
	return {
		printReceiptAsync: async ({
			receiptRequestData,
		}: {
			receiptRequestData: CustomerReceiptRequestType;
		}) => {
			if (autoOrManual === '자동') {
				set직전영수증(receiptRequestData);
			}

			if (autoOrManual === '자동' && !autoReceiptPrintOn) {
				return;
			}

			const CandidatePrinterInfos = RPList.filter(
				(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
			);
			for (const printerInfo of CandidatePrinterInfos) {
				for (let i = 0; i < (autoOrManual === '자동' ? printerInfo.quantity : 1); i++) {
					printMode === 'BIXOLONSDK' && (await sleep(500));
					await ReactRPIPC.invoke고객영수증출력({
						...receiptRequestData,
						hardwareInfo: {
							deviceType: deviceType,
							printerInfo: printerInfo,
							printMode,
						},
					});
				}
			}
		},
	};
};

// JM : BXLN 레거시 코드 백업
// if (RPList.length === 0) {
//   await ReactPosIPC.hardware.invoke고객영수증출력({
//     ...receiptRequestData,
//     deviceType: deviceType,
//     autoOrManual: 'BIXOLONSDK',
//     deviceInfo: undefined,
//   });
// }
