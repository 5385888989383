import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ProductInfoType } from '@kinderlabs-pos/shared-data-type';

export const createProductInfoAsync =
	(rootUrl: string) => async (createProductInfoRequestInfo: CreateProductInfoRequestType) =>
		(
			await posAxiosClient.post<ProductInfoType>(`${rootUrl}/product/`, {
				...createProductInfoRequestInfo,
			})
		).data;

export type CreateProductInfoRequestType = Omit<ProductInfoType, 'id'>;
