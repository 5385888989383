import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminProductDiscountHistroyInfoType,
	BaseInfoType,
	PageType,
	RevisionType,
} from '@kinderlabs-pos/shared-data-type';

export const getProductDiscountHistoryAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<
			PageType<RevisionType<AdminProductDiscountHistroyInfoType & BaseInfoType>>
		>(`${rootUrl}/product-discount/history`, {
			params: { id, revisionType: 'ALL' },
		})
	).data;
