import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { b2CMainThemeOption } from './b2cMainThemeOption';
import { waiverComponentsOverride } from './waiverComponentOverrides';

const fontFamily = 'SpoqaHanSansNeo-Regular';
export const waiverDefaultTheme = createTheme(
	deepmerge(b2CMainThemeOption, {
		components: waiverComponentsOverride,
	})
);

waiverDefaultTheme.typography = deepmerge(waiverDefaultTheme.typography, {
	h1: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '2rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '1.5rem',
		},
	},
	h2: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.7rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '1.4rem',
		},
	},
	h3: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.6rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '1.3rem',
		},
	},
	h4: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.5rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '1.2rem',
		},
	},
	h5: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.4rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '1.1rem',
		},
	},
	h6: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.4rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '1rem',
		},
	},
	subtitle1: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.3rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '0.9rem',
		},
	},
	subtitle2: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.2rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '0.8rem',
		},
	},
	body1: {
		[waiverDefaultTheme.breakpoints.down('lg')]: {
			fontSize: '1.1rem',
		},
		[waiverDefaultTheme.breakpoints.down('sm')]: {
			fontSize: '0.7rem',
		},
	},
});
