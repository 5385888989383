import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { StockHistoryLineType, stockQuantityType } from '@kinderlabs-pos/shared-data-type';

export const countingStockQtyAsync =
	(rootUrl: string) =>
	async ({ warehouseId, data }: { warehouseId: number; data: CoutingStockQtyType[] }) =>
		(
			await posAxiosClient.put(`${rootUrl}/stock/quantity/counting`, data, {
				params: {
					warehouseId,
				},
			})
		).data;

type CoutingStockQtyType = Omit<StockHistoryLineType, 'id' | 'createdTime' | 'stockQuantity'> & {
	stockQuantity: stockQuantityType & { totalQuantity: number };
};
