import { KioskBoardItemInfoWithDetailType } from '@kinderlabs-pos/shared-data-type';
import { kioskTheme } from '@kinderlabs-pos/ui/themes';
import { Button, CardMedia, Stack, Typography } from '@mui/material';
import defaultKioskItemJpg from 'assets/defaultkioskitem.jpg';
import { ReactNode, RefObject } from 'react';
import { KioskBoardDesignBase } from './KioskBoardDesignBase';
export interface IKioskBoardBaseItemProps {
	boardItemInfo: KioskBoardItemInfoWithDetailType;
	borderColor?: string;
	onClick?: () => void;
	soldOutIconButton?: ReactNode;
	boardItemRef?: RefObject<HTMLDivElement>;
	i18N?: 'ko' | 'eng' | 'jpn' | 'chn';
}
export const KioskBoardBaseItem: React.FC<IKioskBoardBaseItemProps> = ({
	soldOutIconButton,
	boardItemInfo,
	borderColor,
	boardItemRef,
	onClick,
	i18N = 'ko',
}) => {
	return (
		<KioskBoardDesignBase
			borderColor={borderColor}
			opacity={boardItemInfo.isSoldOut ? 0.5 : 1}
			onClick={onClick}>
			<Stack
				ref={boardItemRef}
				width={'100%'}
				height={'100%'}
				spacing={0.5}
				alignItems={'center'}>
				<Stack
					position={'relative'}
					sx={{ borderRadius: 4, overflow: 'hidden' }}>
					<Stack
						position={'absolute'}
						top={10}
						left={10}
						spacing={1}
						direction={'row'}>
						{boardItemInfo.isNew && <NewTextComponent />}
						{boardItemInfo.isBest && <BestTextComponent />}
					</Stack>
					<CardMedia
						component={'img'}
						sx={{ width: '100%', aspectRatio: 1 }}
						image={boardItemInfo.imageUrl || defaultKioskItemJpg}
						alt={'상품 사진'}
					/>
					{boardItemInfo.isSoldOut && (
						<Stack
							position={'absolute'}
							sx={{ width: '100%', aspectRatio: 1, backgroundColor: 'rgba(128, 128, 128, 0.6)' }}
							top={0}
							left={0}
							justifyContent={'center'}
							alignItems={'center'}>
							<Typography
								variant='h3'
								color={'white'}>
								{i18N === 'ko' && '품절'}
								{i18N === 'eng' && 'SOLD OUT'}
							</Typography>
						</Stack>
					)}
				</Stack>
				{soldOutIconButton}
				<Stack
					flex={1}
					justifyContent={'center'}>
					<Typography
						textAlign={'center'}
						color={'primary'}
						fontSize={'130%'}
						variant='h6'>
						{i18N === 'ko'
							? boardItemInfo.productInfo?.name
							: boardItemInfo.productInfo?.i18nName
							? boardItemInfo.productInfo.i18nName[i18N]
							: ''}
					</Typography>
					<Typography
						textAlign={'center'}
						color={'text.secondary'}
						fontSize={'120%'}
						variant='body2'>
						{i18N === 'ko' && `${boardItemInfo.productInfo?.price.toLocaleString()} 원`}
						{i18N !== 'ko' && `₩ ${boardItemInfo.productInfo?.price.toLocaleString()}`}
					</Typography>
				</Stack>
			</Stack>
		</KioskBoardDesignBase>
	);
};

const TextComponentSx = {
	py: '2px',
	px: '20px',
	width: 50,
	minWidth: 0,
	fontSize: 15,
	fontWeight: 600,
	boxShadow: 'none',
	backgroundColor: 'pink',
};

// const youtubeRed = '#fb0006';
const redTagColor = kioskTheme.palette.primary.main;

const NewTextComponent = () => {
	return (
		<Button
			disableElevation
			disableRipple
			variant='outlined'
			color={'inherit'}
			sx={{
				...TextComponentSx,
				color: redTagColor,
				borderWidth: 2,
				borderColor: redTagColor,
				backgroundColor: 'white',
				'&:hover': {
					backgroundColor: 'white',
				},
			}}>
			NEW
		</Button>
	);
};

const BestTextComponent = () => {
	return (
		<Button
			disableElevation
			disableRipple
			variant='contained'
			color={'inherit'}
			sx={{
				...TextComponentSx,
				color: 'white',
				backgroundColor: redTagColor,
				'&:hover': {
					backgroundColor: redTagColor,
				},
			}}>
			BEST
		</Button>
	);
};
