import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { StockInfoListByWarehouse } from '@kinderlabs-pos/shared-data-type';

export const getStockInfoForWarehouseAsync =
	(rootUrl: string) =>
	async ({ warehouseId, productIdList }: GetStockInfoForWarehouseRequestType) =>
		(
			await posAxiosClient.get<StockInfoListByWarehouse[]>(`${rootUrl}/stock/list/warehouse`, {
				params: {
					warehouseId,
					productIdList,
				},
			})
		).data;

type GetStockInfoForWarehouseRequestType = {
	warehouseId: number;
	productIdList?: number[];
};
