import { Box, BoxProps, IconButton, IconButtonProps, styled } from '@mui/material';
import { grey } from '@mui/material/colors';

type IIconSquareButtonProps = BoxProps & {
	color?: string;
	IconButtonProps?: IconButtonProps;
};
export const IconSquareButton = ({
	children,
	sx,
	color = grey[400],
	IconButtonProps,
	...otherProps
}: IIconSquareButtonProps) => {
	return (
		<Box
			sx={{
				...sx,
				color: color,
				border: `1px solid ${color}`,
				borderRadius: 1,
			}}
			{...otherProps}>
			<StyledIconButon
				color={'inherit'}
				{...IconButtonProps}>
				{children}
			</StyledIconButon>
		</Box>
	);
};

const StyledIconButon = styled(IconButton)(({ theme }) => ({
	borderRadius: 0,
	'& .MuiTouchRipple-child': {
		borderRadius: 0,
	},
}));
