import { ArrowLeftOutlined } from '@ant-design/icons';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKioskRouter } from '../../utils/useKioskRouter';

export const KioskPageHeader = ({ title }: { title: ReactNode }) => {
	const { navigate홈으로 } = useKioskRouter();
	const navigate = useNavigate();
	const deviceLoginInfo = useAtomValue(StoreInfoState.deviceLoginInfo);

	return (
		<Stack
			p={5}
			sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}
			direction={'row'}
			position={'relative'}>
			<Stack
				position={'absolute'}
				direction={'row'}
				alignItems={'center'}
				sx={{
					top: 0,
					bottom: 0,
					marginTop: 'auto',
					marginBottom: 'auto',
				}}>
				<Button
					onClick={() => (deviceLoginInfo ? navigate(-1) : navigate홈으로())}
					variant={'outlined'}
					sx={{ color: 'white' }}
					color='inherit'
					startIcon={<ArrowLeftOutlined style={{ fontSize: '120%' }} />}>
					뒤로가기
				</Button>
			</Stack>
			<Stack
				flex={1}
				alignItems={'center'}>
				<Typography
					variant={'h4'}
					color={'white'}>
					{title}
				</Typography>
			</Stack>
		</Stack>
	);
};
