import { atom, useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { authState } from './index';
import { useSearchParams } from 'react-router-dom';
import { StoreInfoType } from '@kinderlabs-pos/shared-data-type';
import { storeIdListForManagerAtom } from './userAtom';

export interface IAuthGuardProps {
	redirectToWhenLoggedIn: () => void;
	escapeWhenNoLogin: () => void;
	children: ReactNode;
}
export const AuthGuard = ({
	redirectToWhenLoggedIn,
	escapeWhenNoLogin,
	children,
}: IAuthGuardProps) => {
	const [initialized, setInitialized] = useState(false);
	const verify = useSetAtom(authState.verify);
	const isLoggedIn = useAtomValue(authState.isLoggedIn);

	useEffect(() => {
		verify({
			onSuccess: () => {
				setInitialized(true);
			},
			onFailure: () => {
				setInitialized(true);
				escapeWhenNoLogin();
			},
		});
	}, []);

	useEffect(() => {
		if (!initialized) return;

		if (!isLoggedIn) {
			escapeWhenNoLogin();
		} else {
			redirectToWhenLoggedIn();
		}
	}, [initialized, isLoggedIn]);

	if (!initialized) return null;
	return <>{children}</>;
};

AuthGuard.useAuthGuardForStoreIdSearchParams = ({
	candidateStoreList,
}: {
	candidateStoreList: StoreInfoType[];
}) => {
	const hasAuthorityForStore = useHasAuthorityForStore();
	const { storeId, changeStoreId } = useStoreIdInSearchParams();
	const prevStoreId = useAtomValue(prevStoreIdAtom);

	const [permissionAllowed, setPermissionAllowed] = useState(false);

	useEffect(() => {
		if (!!storeId) {
			if (hasAuthorityForStore(storeId)) {
				changeStoreId(storeId);
				setPermissionAllowed(true);
			}
		} else {
			if (prevStoreId && candidateStoreList.some((store) => `${store.id}` === `${prevStoreId}`)) {
				changeStoreId(prevStoreId);
			} else {
				const newStoreId = candidateStoreList[0].id;
				changeStoreId(newStoreId);
			}
		}
	}, [candidateStoreList, storeId]);

	return permissionAllowed && storeId
		? ({ permissionAllowed: true, changeStoreId, storeId } as const)
		: ({ permissionAllowed: false, changeStoreId } as const);
};

const prevStoreIdAtom = atom<number | undefined>(undefined);
const useStoreIdInSearchParams = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const setPrevStoreId = useSetAtom(prevStoreIdAtom);

	const storeIdInSearchparams = searchParams.get('storeId');
	const numberedStoreId = storeIdInSearchparams && parseInt(storeIdInSearchparams);
	const storeId =
		!!numberedStoreId && !isNaN(numberedStoreId) ? Number(numberedStoreId) : undefined;

	const changeStoreId = (storeId: number) => {
		setPrevStoreId(storeId);
		setSearchParams({ storeId: `${storeId}` });
	};

	return {
		storeId,
		changeStoreId,
	};
};

const useHasAuthorityForStore = () => {
	const storeIdListForManager = useAtomValue(storeIdListForManagerAtom);

	return (storeId: number) => {
		if (!storeIdListForManager) return true;
		else return storeIdListForManager.includes(storeId);
	};
};
