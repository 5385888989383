import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { kioskMainThemeOption } from './kioskMainThemeOption';
import { stagingColor } from '../stagingColor';

const isStagingMode = process.env['NX_STAGING_MODE'] ? true : false;

export const kioskTheme = createTheme(
	deepmerge(kioskMainThemeOption, {
		palette: {
			...(isStagingMode && {
				primary: {
					main: stagingColor,
				},
			}),
		},
	})
);

// displayer Theme 에서는 Typography 를 사용하지 않았음
