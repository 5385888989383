import { styled, TextField } from '@mui/material';
import { MobileDatePicker, MobileDatePickerProps } from '@mui/x-date-pickers/MobileDatePicker';
import { Dayjs } from 'dayjs';

type DatePickerUsageType = 'POS' | 'ADMIN';
export interface IDatePickerProps<TInputDate extends Dayjs, TDate extends Dayjs>
	extends Omit<MobileDatePickerProps<TInputDate, TDate>, 'renderInput'> {
	usageType?: DatePickerUsageType;
	isMonth?: boolean;
}
export const DatePicker = <TInputDate extends Dayjs, TDate extends Dayjs>({
	label,
	value,
	minDate,
	maxDate,
	onChange,
	disabled,
	usageType = 'ADMIN',
	shouldDisableDate,
	isMonth = false,
}: IDatePickerProps<TInputDate, TDate>) => {
	return (
		<MobileDatePicker
			disableFuture
			disabled={disabled}
			closeOnSelect
			inputFormat={isMonth ? 'YYYY.MM.' : 'YYYY.MM.DD.'}
			label={label}
			value={value}
			views={isMonth ? ['year', 'month'] : undefined}
			minDate={minDate}
			maxDate={maxDate}
			onChange={onChange}
			shouldDisableDate={shouldDisableDate}
			renderInput={({ sx, ...params }) => (
				<StyledTextField
					placeholder={'날짜를 설정해주세요'}
					usageType={usageType}
					sx={{ ...sx, width: '100%' }}
					{...params}
				/>
			)}
			DialogProps={{
				sx: {
					'& .MuiPickersDay-root': {
						fontSize: 18,
					},
					'& .MuiDayPicker-weekDayLabel': {
						fontSize: 18,
					},
				},
			}}
		/>
	);
};

const StyledTextField = styled(TextField)<{ usageType: DatePickerUsageType }>(
	({ theme, usageType }) => ({
		color: 'inherit',
		flexGrow: 1,
		'& .MuiInputBase-root': {
			height: datePickerHeightByUsageType[usageType],
		},
		'& .MuiInputBase-input': {
			padding: theme.spacing(1, 1, 1, 1),
			transition: theme.transitions.create('width'),
			width: '100%',
		},
	})
);

const datePickerHeightByUsageType: Record<DatePickerUsageType, number> = {
	POS: 37,
	ADMIN: 34,
};
