import { DdiziType, PrintingStatusType } from '@kinderlabs-pos/shared-data-type';
import { atom, useAtomValue } from 'jotai';

type DdiziStatus = Record<DdiziType['id'], PrintingStatusType>;

const ddizisAtom = atom<DdiziType[], DdiziType[] | undefined>([], (get, set, payload) => {
	const defaultPayload = payload || [];
	set(ddizisAtom, defaultPayload);

	const statusMap: DdiziStatus = {};
	defaultPayload.forEach((ddizi) => {
		statusMap[ddizi.id] = 'READY';
	});

	set(ddizisStatusAtom, statusMap);
});
const ddizisStatusAtom = atom<DdiziStatus>({});

const printingDdiziAtom = atom((get) => {
	const ddiziStatusMap = get(ddizisStatusAtom);
	return get(ddizisAtom).map((ddizi) => ({
		...ddizi,
		printStatus: ddiziStatusMap[ddizi.id],
	}));
});
const setDdizisStatus = atom(
	null,
	(
		get,
		set,
		payload: {
			id: DdiziType['id'];
			printingStatus: PrintingStatusType;
		}
	) => {
		set(ddizisStatusAtom, (state) => {
			state[payload.id] = payload.printingStatus;
			return { ...state };
		});
	}
);

const useIsPrintingComplete = () => {
	const printingDdizi = useAtomValue(printingDdiziAtom);

	if (printingDdizi.length === 0) return true;
	return Object.values(printingDdizi).findIndex((ddizi) => ddizi.printStatus !== 'COMPLETE') === -1;
};

const clearDdizi = atom(null, (get, set, payload) => {
	set(ddizisAtom, []);
});

export const DdiziPrintingState = {
	value: ddizisAtom,
	changeStatus: setDdizisStatus,
	printing: printingDdiziAtom,
	useIsPrintingComplete,
	clear: clearDdizi,
};
