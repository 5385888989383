import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CursorPageType, GuestVisitType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const searchGuestVisitsInfiniteAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		cursorId,
		from,
		to,
		statusList,
		search,
		pageable,
	}: SearchGuestVisitsType) =>
		(
			await posAxiosClient.get<CursorPageType<GuestVisitType>>(
				`${rootUrl}/guest-visit/search/cursor`,
				{
					params: {
						storeIdList,
						...pageable.getPageableQueryParam(),
						cursorId,
						from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
						to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
						statusList,
						search,
					},
				}
			)
		).data;

interface SearchGuestVisitsType {
	storeIdList?: number[];
	cursorId?: string | number;
	from: Dayjs;
	to: Dayjs;
	statusList?: GuestVisitType['status'][];
	search?: string;
	pageable: Pageable;
}
