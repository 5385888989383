import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminVendorInfoListFilterType,
	VendorInfoListType,
} from '@kinderlabs-pos/shared-data-type';

export const getVendorInfoListAsync =
	(rootUrl: string) =>
	async ({ search }: AdminVendorInfoListFilterType) =>
		(
			await posAxiosClient.get<VendorInfoListType[]>(`${rootUrl}/vendor/search`, {
				params: {
					search,
				},
			})
		).data;
