import { AdminProductDiscountInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	AdminProductDiscountInfoListFilterType,
	ProductDiscountMgInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../QueryClient';

const productDiscountInfoKeys = createQueryKeys('productDiscountInfo', {
	list: ({ param }: { param: AdminProductDiscountInfoListFilterType }) => ({
		queryKey: [param],
		queryFn: async () => {
			const res = await AdminProductDiscountInfoApis.getProductDiscountInfoList(param);
			return res;
		},
	}),
	detail: (id?: number) => ({
		queryKey: [id],
		queryFn: async () => {
			if (!id) return undefined;
			const res = await AdminProductDiscountInfoApis.getProductDiscountInfo({ id: id });
			return res;
		},
	}),
	all: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			const res = await AdminProductDiscountInfoApis.getAllProductDiscountInfoList({ storeId });
			return res;
		},
	}),
	history: (id?: number) => ({
		queryKey: [id],
		queryFn: async () => {
			if (!id) return undefined;
			const res = await AdminProductDiscountInfoApis.getProductDiscountHistoryInfo(id);
			return res;
		},
	}),
});

const useCreateProductDiscountInfo = () => {
	return QueryClient.useMutation(
		async (param: Omit<ProductDiscountMgInfoType, 'id'>) => {
			await AdminProductDiscountInfoApis.createProductDiscountInfo(param);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

type UpdateInfoType = {
	id: number;
	updateData: Omit<ProductDiscountMgInfoType, 'id'>;
};
const useUpdateProductDiscountInfo = () => {
	return QueryClient.useMutation(
		async ({ id, updateData }: UpdateInfoType) => {
			await AdminProductDiscountInfoApis.updateProductDiscountInfo({ id, updateData });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteProductDiscountInfo = () => {
	return QueryClient.useMutation(
		async (idList: number[]) => {
			await AdminProductDiscountInfoApis.deleteProductDiscountInfo({ idList });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDisableProductDiscountInfo = () => {
	return QueryClient.useMutation(
		async ({ discountId, isDisabled }: { discountId: number; isDisabled: boolean }) => {
			await AdminProductDiscountInfoApis.disableProductDiscountInfo({ discountId, isDisabled });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: productDiscountInfoKeys._def });
};

export const AdminProductDiscountInfoState = {
	keys: productDiscountInfoKeys,

	useCreate: useCreateProductDiscountInfo,
	useDelete: useDeleteProductDiscountInfo,
	useUpdate: useUpdateProductDiscountInfo,
	useDisable: useDisableProductDiscountInfo,
};
