import {
	CartLineInfoType,
	CartLineKlPosDiscountInfoType,
	CartLineKlPosProductInfoType,
	DdiziInfoType,
	ProductDiscountInfoType,
	defaultProductAdditionalInfo,
} from '@kinderlabs-pos/shared-data-type';
import { CartLineState } from './CartLineBoardState';
import { findRealTargetCartLineForDiscount, getRealTargetName } from './cartLineDiscountCommon';
import { adjustTargetCartLineForOneQuantity } from './cartLineOptionCommon';

export type CartLinePosCouponActionType =
	| {
			type: 'REMOVE';
			couponId: string;
	  }
	| {
			type: 'ADD_PRODUCT';
			cartLineId: string;
			couponId: string;
			couponInfoId: number;
			couponName: string;
			ddiziInfo: DdiziInfoType;
	  }
	| {
			type: 'ADD_DISCOUNT';
			cartLineId: string;
			couponName: string;
			couponId: string;
			couponInfoId: number;
			productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
			targetCartLineId: string;
			taxFreeTarget: boolean;
	  };

const cartLinePosCouponReducer = (
	prev: CartLineInfoType[],
	action: CartLinePosCouponActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'REMOVE':
			return prev.filter((cl) => cl?.couponId !== action.couponId);
		case 'ADD_PRODUCT':
			return [
				...prev,
				createKlPosProductCartLine({
					name: action.couponName,
					couponId: action.couponId,
					couponInfoId: action.couponInfoId,
					cartLineId: action.cartLineId,
					ddiziInfo: action.ddiziInfo,
				}),
			];
		case 'ADD_DISCOUNT':
			const {
				타겟이옵션이냐,
				targetCartLine: originalTargetCartLine,
				targetOfTargetCartLine: realTargetCartLine,
			} = findRealTargetCartLineForDiscount({
				cartLines: prev,
				targetCartLineId: action.targetCartLineId,
			});

			const {
				cartLines: newCartLines,
				targetCartLine: newTargetCartLine,
				targetCartLineIndex: newTargetCartLineIndex,
			} = adjustTargetCartLineForOneQuantity(
				prev,
				타겟이옵션이냐 ? realTargetCartLine.id : originalTargetCartLine.id
			);

			if (타겟이옵션이냐) {
				return [
					...newCartLines,
					createKlPosDiscountCartLine({
						name: action.couponName,
						targetCartLineId: newTargetCartLine.id,
						targetName: originalTargetCartLine.name,
						targetPrice: originalTargetCartLine.price,
						couponId: action.couponId,
						couponInfoId: action.couponInfoId,
						cartLineId: action.cartLineId,
						productDiscountInfo: action.productDiscountInfo,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			} else {
				return [
					...newCartLines,
					createKlPosDiscountCartLine({
						name: action.couponName,
						targetCartLineId: newTargetCartLine.id,
						targetPrice: originalTargetCartLine.price,
						couponId: action.couponId,
						couponInfoId: action.couponInfoId,
						cartLineId: action.cartLineId,
						productDiscountInfo: action.productDiscountInfo,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			}
	}
};

export const CartLinePosCouponState = {
	reducer: cartLinePosCouponReducer,
};

const createKlPosDiscountCartLine = ({
	name,
	targetName,
	targetPrice,
	targetCartLineId,
	couponId,
	couponInfoId,
	cartLineId,
	productDiscountInfo,
	taxFreeTarget,
}: {
	name: string;
	targetName?: string;
	couponId: string;
	cartLineId: string;
	targetPrice: number;
	targetCartLineId: string;
	couponInfoId: number;
	productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
	taxFreeTarget: boolean;
}): CartLineKlPosDiscountInfoType => {
	return {
		id: cartLineId,
		type: 'KL_COUPON_DISCOUNT',
		productInfoId: undefined,
		price: -CartLineState.utils.getUnitDiscount({
			price: targetPrice,
			productDiscountInfo,
		}),
		productAdditionalInfo: {
			rawPrice: 0,
			supplyPrice: 0,
			taxFree: taxFreeTarget,
		},
		name: getRealTargetName({ discountName: name, targetName, productDiscountInfo }),
		couponId,
		productInfo: {
			category: 'POS쿠폰',
			isForKitchen: false,
		},
		productDiscountInfo,
		targetCartLineId: targetCartLineId,
		quantity: 1,
	};
};

const createKlPosProductCartLine = ({
	name,
	couponId,
	couponInfoId,
	cartLineId,
	ddiziInfo,
}: {
	name: string;
	couponId: string;
	couponInfoId: number;
	cartLineId: string;
	ddiziInfo: DdiziInfoType;
}): CartLineKlPosProductInfoType => {
	return {
		id: cartLineId,
		type: 'KL_COUPON_PRODUCT',
		price: 0,
		name,
		couponId,
		targetCartLineId: undefined,
		productInfoId: undefined,
		productInfo: {
			category: 'POS쿠폰',
			isForKitchen: false,
			ddiziInfo,
		},
		productAdditionalInfo: defaultProductAdditionalInfo,
		quantity: 1,
	};
};
