import { AdminProductInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	AdminProductInfoListFilterType,
	BaseInfoType,
	CategoryDataForTable,
	Pageable,
	ProductInfoType,
	ProductInfoWithCategory,
	getCategoryDataForTable,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { QueryClient } from '../QueryClient';
import { AdminCategoryInfoState } from './AdminCategoryState';

const productInfoKeys = createQueryKeys('productInfo', {
	list: ({ param }: { param: AdminProductInfoListFilterType }) => ({
		queryKey: [param],
		queryFn: async () => {
			const res = await AdminProductInfoApis.getProductInfoList(param);
			return res;
		},
	}),
	infinite: ({
		params,
	}: {
		params: Pick<AdminProductInfoListFilterType, 'storeId' | 'type' | 'search'> & {
			categoryId?: number;
		};
	}) => ({
		queryKey: [params],
		queryFn: async ({ pageParam }: { pageParam?: { startCursorId: string | number } }) => {
			const res = await AdminProductInfoApis.getListInfinite({
				...params,
				pageable: Pageable.getPageable({ page: 0, size: 20 }),
				cursorPkId: pageParam?.startCursorId,
			});

			return {
				content: res.content,
				isLast: !res.hasMore,
				startCursorId: res.startCursorId,
			};
		},
	}),
	/**
	 * StoreId 가 있다면 가격을 Override
	 * @param storeId
	 * @returns
	 */
	inStore: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			return await AdminProductInfoApis.getListInStore({ storeId });
		},
	}),
	stock: () => ({
		queryKey: ['managed'],
		queryFn: async () => {
			const res = await AdminProductInfoApis.getStockManagedProductList();
			return res;
		},
	}),
	detail: (productId?: number) => ({
		queryKey: [productId],
		queryFn: async () => {
			if (!productId) return null;
			const res = await AdminProductInfoApis.getProductInfoDetail({
				productId: productId,
			});
			return res;
		},
	}),
	history: (id?: number) => ({
		queryKey: [id],
		queryFn: async () => {
			if (!id) return undefined;
			const res = await AdminProductInfoApis.getProductInfoHistory(id);
			return res;
		},
	}),
	variants: ({ targetStoreId, posBoardId }: { targetStoreId?: number; posBoardId?: number }) => ({
		queryKey: [targetStoreId, posBoardId],
		queryFn: async () => {
			if (!targetStoreId || !posBoardId) return null;
			const res = await AdminProductInfoApis.getCompareVariantsList({ targetStoreId, posBoardId });
			return res;
		},
	}),
	candelete: (input: number[]) => ({
		queryKey: [input],
		queryFn: async () => {
			return await AdminProductInfoApis.canDelete({ productList: input });
		},
	}),
});

const useProductInfoMapInStoreQuery = ({ storeId }: { storeId?: number }) => {
	const { data } = useQuery({
		...productInfoKeys.inStore(storeId),
		keepPreviousData: true,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
	});
	const result = data ?? [];

	return useMemo(
		() =>
			result.reduce((result, row) => {
				result[`${row.id}`] = row;
				return result;
			}, {} as Record<string, ProductInfoType & BaseInfoType>),
		[result]
	);
};

/**
 * 상품별 매출통계는 특수 카테고리가 있어서 이 규칙을 적용하면 안 됨
 */

const useProductInfoMapWithCategoryQuery = ({ storeId }: { storeId?: number }) => {
	const productMap = useProductInfoMapInStoreQuery({ storeId });
	const categoryTree = AdminCategoryInfoState.useCategoryTreeForSearch();

	return useMemo(() => {
		const result = {} as Record<string, ProductInfoWithCategory>;
		[...Object.keys(productMap)].forEach((productId) => {
			result[productId] = {
				...productMap[productId],
				categoryData: getCategoryDataForTable({
					baseCategoryId: productMap[productId].categoryId,
					categoryTree,
				}),
			};
		});
		return result;
	}, [productMap, categoryTree]);
};

const useCreateProductInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<ProductInfoType, 'id'>) => {
			await AdminProductInfoApis.createProductInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateProductInfo = () => {
	return QueryClient.useMutation(
		async (input: ProductInfoType) => {
			await AdminProductInfoApis.updateProductInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateProductInfoBulk = () => {
	return QueryClient.useMutation(
		async ({ data }: { data: ProductInfoType[] }) => {
			await AdminProductInfoApis.updateProductInfoBulk(data);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteProductInfoList = () => {
	return QueryClient.useMutation(
		async (input: number[]) => {
			await AdminProductInfoApis.deleteProductInfoList({ productList: input });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteUnusedProductVariants = () => {
	return QueryClient.useMutation(
		async ({ storeId, productIdList }: { storeId: number; productIdList: number[] }) => {
			await AdminProductInfoApis.deleteUnusedProductVariants({ storeId, productIdList });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: productInfoKeys._def });
};

export const AdminProductInfoState = {
	keys: productInfoKeys,
	useMapInStoreQuery: useProductInfoMapInStoreQuery,
	useMapWithCategoryQuery: useProductInfoMapWithCategoryQuery,
	useCreate: useCreateProductInfo,
	useUpdate: useUpdateProductInfo,
	useUpdateBulk: useUpdateProductInfoBulk,
	useDeleteList: useDeleteProductInfoList,
	useDeleteUnusedProducts: useDeleteUnusedProductVariants,
	utils: {
		getProductInfoIdFromMap: ({
			productId,
			productMap,
		}: {
			productId?: string;
			productMap: Record<number, ProductInfoType>;
		}) => {
			if (!productId) return undefined;

			try {
				const productIdNumber = Number(productId);
				return productMap[productIdNumber];
			} catch {
				return undefined;
			}
		},
	},
};
