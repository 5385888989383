import { DdiziInfoType } from './admin/DdiziInfoType';

export const MembershipTicketTypeArray = [
	'MULTIUSE',
	'COMMUTATION',
	'ONETIME',
	'DISCOUNT',
	'FREECOUPON',
] as const;
export type MembershipTicketType = (typeof MembershipTicketTypeArray)[number];
export const MembershipTicketTypeLabel: Record<MembershipTicketType, string> = {
	MULTIUSE: '다회권',
	COMMUTATION: '정기권',
	ONETIME: '일회권',
	DISCOUNT: '할인권', // 앱쿠폰 (할인, 증정) 모두 할인권으로 취급함
	FREECOUPON: '증정권',
};

export type CartTicketUsageType = {
	type: MembershipTicketType;
	id?: number;
	name: string;
	useId: string;
	ticketId: number;
	ticketInfoId: number;
	memberId: number;
	userName: string;
	userTelephone: string;
	ddiziInfo?: DdiziInfoType;
};
