import { PosBoardInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const getPosBoardInfoDetailAsync =
	(rootUrl: string) =>
	async ({ id }: { id: number }) =>
		(
			await posAxiosClient.get<PosBoardInfoType>(`${rootUrl}/pos-board`, {
				params: {
					id,
				},
			})
		).data;
