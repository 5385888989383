import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import warning from 'assets/warning.png';

export interface IEmptyDdiziListPageProps {}
export const EmptyDdiziListPage: React.FC<IEmptyDdiziListPageProps> = ({}) => {
	return (
		<Stack
			display={'flex'}
			alignItems={'center'}
			justifyContent={'center'}
			width={'100%'}
			height={'100%'}
			spacing={10}>
			<Stack>
				<Typography
					variant='h3'
					color={'text.secondary'}
					textAlign={'center'}>
					{'QR 코드를 읽혀주세요.'}
				</Typography>
			</Stack>
		</Stack>
	);
};
