import { AddressType } from '@kinderlabs-pos/shared-data-type';
import { AdminStoreInfoState } from '@kinderlabs-pos/state';
import { MenuItem, Select, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PrevNextButton } from '../../components/UserInfoPageComponents/PrevNextButton';
import { TitleWithDesc } from '../../components/UserInfoPageComponents/TitleWithDesc';
import { UserInfoPageTemplate } from './UserInfoPageTemplate';

type AddressCheckProps = {
	address?: {
		region?: string;
		district?: string;
	};
	setAddress: (address: AddressType) => Promise<void>;
	goNextStep: () => void;
	goPrevStep: () => void;
};

export const UserAddressPage = ({ props }: { props: AddressCheckProps }) => {
	const addressInfoMap =
		useQuery({ ...AdminStoreInfoState.keys.addressInfo() }).data?.sigunguMap ?? {};
	const { address, setAddress, goNextStep, goPrevStep } = props;
	const { values, setValues, handleChange, isValid, handleSubmit } = useFormik({
		initialValues: { address: address ?? { region: undefined, district: undefined } },
		onSubmit: async ({ address }) => {
			if (address.region && address.district)
				await setAddress({
					region: address.region,
					district: address.district,
				});
			goNextStep();
		},
		validationSchema: Yup.object({
			address: Yup.object({
				region: Yup.string().required(),
				district: Yup.string().required(),
			}),
		}),
		enableReinitialize: true,
		validateOnMount: true,
	});

	return (
		<UserInfoPageTemplate>
			<Stack
				sx={{ width: '100%' }}
				spacing={5}>
				<TitleWithDesc
					title={'거주지 입력'}
					desc={'거주지를 선택해 주세요.'}
				/>
				<Stack
					direction={'row'}
					spacing={2}>
					<Select
						sx={{ flex: 1 }}
						name='address.region'
						value={values.address?.region ?? ''}
						onChange={(e) => {
							setValues({ address: { region: e.target.value, district: undefined } });
						}}>
						{[...Object.keys(addressInfoMap)]
							.sort((a, b) => (a === '경상북도' ? -1 : 1)) // 경상북도가 위에서 3번째
							.sort((a, b) => (a === '경기도' ? -1 : 1)) // 경기도가 위에서 2번째
							.sort((a, b) => (a === '서울특별시' ? -1 : 1)) // 서울특별시가 맨 위
							.map((시와도) => (
								<MenuItem
									value={시와도}
									key={시와도}>
									{/* {true
									? GeometryInfoByProvince[시와도 as ProvinceKONmaeType]?.eng ?? 시와도
									: 시와도} */}
									{시와도}
								</MenuItem>
							))}
					</Select>
					<Select
						sx={{ flex: 1 }}
						name='address.district'
						value={values.address?.district ?? ''}
						onChange={handleChange}>
						{(values.address?.region ? addressInfoMap[values.address?.region] : [])
							.sort((a, b) => a.localeCompare(b, 'ko'))
							.map((시군구) => (
								<MenuItem
									value={시군구}
									key={시군구}>
									{시군구}
								</MenuItem>
							))}
					</Select>
				</Stack>
				<PrevNextButton
					nextDisabled={!isValid}
					prevFn={goPrevStep}
					nextFn={handleSubmit}
				/>
			</Stack>
		</UserInfoPageTemplate>
	);
};
