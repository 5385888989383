import { AdminCmtInfoApis, AdminMutInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { CmtInfoType, GlobalBrandInfoType, MutInfoType } from '@kinderlabs-pos/shared-data-type';
import { Atom, atom } from 'jotai';
import { atomFamily } from 'jotai/utils';

const globalBrandInfo = atom<Promise<GlobalBrandInfoType>>(async () => {
	return {
		storeIds: 0,
		name: 'vaunce',
		multiUseTicketInfoList: [],
		commutationTicketInfoList: [],
	};
});

const multiUseTicketInfoAtom = atom(async (get) => await AdminMutInfoApis.getListInStore());
const commutationTicketInfoAtom = atom(async (get) => await AdminCmtInfoApis.getListInStore());

const multiuseTicketInfo = atomFamily<number, Atom<MutInfoType | undefined>>(
	(multiuseTicketInfoId: number) =>
		atom((get) => {
			return get(multiUseTicketInfoAtom).find((info) => info.id === multiuseTicketInfoId);
		})
);

const commutationTicketInfo = atomFamily<number, Atom<CmtInfoType | undefined>>(
	(commutationTicketInfoId: number) =>
		atom((get) => {
			return get(commutationTicketInfoAtom).find((info) => info.id === commutationTicketInfoId);
		})
);

export const BrandInfoState = {
	value: globalBrandInfo,
	multiUseTicketInfo: multiuseTicketInfo,
	commutationTicketInfo: commutationTicketInfo,
};
