import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { numberWithCommasAnd원PlusMinus } from '@kinderlabs-pos/shared-util';
import {
	Card,
	CardActionArea,
	CardContent,
	IconButton,
	Stack,
	Typography,
	alpha,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';

export const KioskBoardItemOptionSelectButton = ({
	selected,
	onClick,
	label,
	price,
}: {
	selected: boolean;
	onClick: () => void;
	label: string;
	price: number;
}) => {
	const { kioskI18N } = useKioskTranslation();
	return (
		<Card
			sx={(theme) => ({
				width: '100%',
				border: `2px solid grey`,
				boxShadow: 'none',
				overflow: 'hidden',
				borderColor: selected ? theme.palette.primary.main : grey[400],
				backgroundColor: selected ? alpha(theme.palette.primary.light, 0.1) : undefined,
			})}>
			<CardActionArea onClick={onClick}>
				<CardContent>
					<Stack
						minWidth={180}
						direction={'row'}
						alignItems={'center'}
						spacing={3}>
						<IconButton
							color={'inherit'}
							sx={{ color: selected ? 'primary' : grey[400] }}>
							{selected ? <CheckOutlined /> : <PlusOutlined />}
						</IconButton>
						<Stack
							flex={1}
							alignItems={'flex-end'}
							spacing={1}>
							<Typography
								variant='h6'
								fontSize={'1.5rem'}
								color={selected ? 'primary' : grey[400]}>
								{label}
							</Typography>
							<Typography
								variant='body2'
								fontSize={'1.3rem'}
								textAlign={'right'}
								color={'inherit'}
								sx={{ color: selected ? 'primary' : grey[400] }}>
								{`${numberWithCommasAnd원PlusMinus(price, kioskI18N)}`}
							</Typography>
						</Stack>
					</Stack>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};
