export * from './lib/ReactCommonCommandIPC';
export * from './lib/ReactLPIPC';
export * from './lib/ReactRPIPC';
export * from './lib/ReactPMIPC';
export * from './lib/ReactPosMainPageIPC';
export * from './lib/ReactKioskMainPageIPC';
export * from './lib/ReactPosSubPageIPC';

import { isOnElectron as _isOnElectron } from './lib/const';
export const isOnElectron = _isOnElectron;
