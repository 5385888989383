import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VaultCashCheckType, VaultCashStatusType } from '@kinderlabs-pos/shared-data-type';

export const postChangePosStatusAsync =
	(rootUrl: string) =>
	async ({ payload, checkType }: { payload: VaultCashStatusType; checkType: VaultCashCheckType }) =>
		(
			await posAxiosClient.post<VaultCashStatusType>(
				`${rootUrl}/vault-cash/${checkType.toLowerCase()}`,
				payload
			)
		).data;
