import { AdminDisplayerInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { DisplayerInfoType } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { DeviceTokenQueryState } from '../DeviceTokenQueryState';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const displayerKeys = createQueryKeys('displayerInfo', {
	list: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			if (!storeId) throw Error;
			const res = await AdminDisplayerInfoApis.getDisplayerInfoList({ storeId: storeId });
			return res;
		},
	}),
	getOne: (storeId: number, deviceId: number) => ({
		queryKey: [storeId, deviceId],
		queryFn: async () => {
			const data = await AdminDisplayerInfoApis.getDisplayerInfoList({ storeId });

			return data.find((displayer) => displayer.id === deviceId) ?? null;
		},
	}),
});

const useCreateDisplayerInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<DisplayerInfoType, 'id'> & { ipAddress: string }) => {
			await AdminDisplayerInfoApis.createDisplayerInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
				DeviceTokenQueryState.invalidateQueries();
			},
		}
	);
};

const useUpdateDisplayerInfo = () => {
	return QueryClient.useMutation(
		async (input: DisplayerInfoType & { ipAddress: string }) => {
			return await AdminDisplayerInfoApis.updateDisplayerInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
				DeviceTokenQueryState.invalidateQueries();
			},
		}
	);
};

const useDeleteDisplayerInfo = () => {
	return QueryClient.useMutation(
		async (displayerId: number) => {
			await AdminDisplayerInfoApis.deleteDisplayerInfoList({ idList: [displayerId] });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteDisplayerListInfo = () => {
	return QueryClient.useMutation(
		async (idList: number[]) => {
			await AdminDisplayerInfoApis.deleteDisplayerInfoList({ idList });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: displayerKeys._def });
};

export const AdminDisplayerInfoState = {
	keys: displayerKeys,
	useCreateDisplayerInfo: useCreateDisplayerInfo,
	useUpdateDisplayerInfo: useUpdateDisplayerInfo,
	useDeleteDisplayerInfo: useDeleteDisplayerInfo,
	useDeleteDisplayerListInfo: useDeleteDisplayerListInfo,
};
