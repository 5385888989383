import { PosBoardInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const updatePosBoardInfoAsync =
	(rootUrl: string) => async (updatePosBoardInfoRequestInfo: UpdatePosBoardInfoRequestType) =>
		(
			await posAxiosClient.put<PosBoardInfoType>(
				`${rootUrl}/pos-board/`,
				{
					...updatePosBoardInfoRequestInfo,
				},
				{
					params: {
						id: updatePosBoardInfoRequestInfo.id,
					},
				}
			)
		).data;

export type UpdatePosBoardInfoRequestType = Omit<PosBoardInfoType, 'items'>;
