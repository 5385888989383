import {
	CartLineTypeRules,
	CartLineInfoType,
	getDiscountLabel,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { List, SquareTextField, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { NumberBoard } from '@kinderlabs-pos/ui-components';
import { Button, DialogActions, DialogContent, Divider, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactNode, useEffect, useState } from 'react';

export interface IWithChangeNumberDialogProps {
	selectedCartLine: CartLineInfoType | undefined;
	children: (onClick: () => void) => ReactNode;
}
export const WithChangeNumberDialog: React.FC<IWithChangeNumberDialogProps> = ({
	selectedCartLine,
	children,
}) => {
	// 코드가 매우 더러운데 리팩토링 난이도가 꽤 높다. 시간이 없으니 어쩔수 없이 미뤄두었음. 꼭 리팩토링하자.

	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<WithDialog.XlDialog
					maxWidth={'lg'}
					dialogTitle={'수량 변경'}
					open={open}
					closeDialog={closeDialog}>
					{selectedCartLine && (
						<ChangeNumberDialogContent
							selectedCartLine={selectedCartLine}
							closeDialog={closeDialog}
						/>
					)}
				</WithDialog.XlDialog>
			)}>
			{children}
		</WithDialog>
	);
};

const ChangeNumberDialogContent: React.FC<{
	selectedCartLine: CartLineInfoType;
	closeDialog: () => void;
}> = ({ closeDialog, selectedCartLine }) => {
	const [value, setValue] = useState(0);

	const carDispatcher = OrderState.cart.useDispatcher();

	useEffect(() => {
		setValue(selectedCartLine.quantity);
	}, [selectedCartLine]);

	const cartLines = useAtomValue(OrderState.cart.selector).lines;
	const cannotChangeQuantity =
		!selectedCartLine ||
		!CartLineTypeRules.canChangeQuantity({
			cartLine: selectedCartLine,
			cartLines: cartLines,
		});

	return (
		<>
			<DialogContent>
				<Typography variant='body1'>{'변경할 수량을 입력하세요.'}</Typography>
				<Stack
					direction={'row'}
					spacing={5}>
					<List>
						<List.ItemButton
							selected
							sx={{
								display: 'flex',
								justifyContent: 'flex-end',
								gap: 1,
							}}>
							<Stack alignItems={'flex-end'}>
								<Typography variant='h1'>{`${selectedCartLine.name}`}</Typography>
								<Typography variant='subtitle1'>
									{numberWithCommasAndWon(selectedCartLine.price)}
								</Typography>
							</Stack>
							{/* 맨 처음의 quantity 는 변경하기 위한 value 이므로 삭제가 되게 해주어야 한다. 그래서 value, defaultValue 구분이 있다. */}
							<SquareTextField
								focused
								sx={{ width: 60 }}
								value={value}
								onChange={(e) => {
									setValue(+e.target.value);
								}}
							/>
						</List.ItemButton>
						{/* {discountInfo && (
							<List.Item sx={{ gap: 1, justifyContent: 'flex-end' }}>
								<Stack
									direction={'row'}
									divider={
										<Divider
											orientation='vertical'
											flexItem
										/>
									}
									spacing={2}>
									<Typography variant='subtitle1'>{discountInfo.name}</Typography>
									<Typography variant='subtitle1'>{`${getDiscountLabel(discountInfo)}`}</Typography>
									<Typography variant='subtitle1'>
										{`할인 수량 : ${selectedCartLine.quantity}`}
									</Typography>
								</Stack>
							</List.Item>
						)} */}
					</List>
					<NumberBoard
						value={value}
						setValue={setValue}
					/>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={cannotChangeQuantity}
					onClick={() => {
						carDispatcher({
							type: 'BOARD',
							subAction: {
								type: 'CHANGE_QUANTITIY',
								cartLineType: selectedCartLine.type,
								cartLineId: selectedCartLine.id,
								quantity: value,
							},
						});
						closeDialog();
					}}>
					{'확인'}
				</Button>
				<Button
					onClick={() => {
						setValue(0);
						closeDialog();
					}}>
					{'취소'}
				</Button>
			</DialogActions>
		</>
	);
};
