import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	VendorOrderRequestInfoType,
	VendorOrderReturnInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const createVendorOrderAsync =
	(rootUrl: string) => async (requestData: VendorOrderRequestInfoType) =>
		(await posAxiosClient.post<VendorOrderReturnInfoType>(`${rootUrl}/vendor-order`, requestData))
			.data;
