import { VaunceAppTicketOnPosType } from '@kinderlabs-pos/shared-data-type';
import { canceledStyleUtils, getDateFormat } from '@kinderlabs-pos/shared-util';
import { Accordion, AccordionSummary, Divider, Stack, Typography, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import { ReactNode } from 'react';

export const VaunceTicketItemAccordion = ({
	ticket,
	rightPanel,
	children,
	canUseTicket,
}: {
	ticket: VaunceAppTicketOnPosType;
	/**
	 * 오른쪽에 들어갈 패널
	 */
	rightPanel: ReactNode;
	children: ReactNode;
	canUseTicket: boolean;
}) => {
	const 사용등록필요 = !ticket.ticketOrderNum;
	const 기간텍스트 = 사용등록필요
		? '-'
		: `${getDateFormat(ticket.ticketStartDate)} ~ ${getDateFormat(ticket.ticketEndDate)}`;

	return (
		<StyledAccordion
			disableGutters
			disabled={!canUseTicket}>
			<AccordionSummary>
				<Stack
					width={'100%'}
					direction={'row'}
					spacing={1}
					alignItems={'center'}>
					<Stack flex={1}>
						<Typography variant={'h5'}>{ticket.ticketTitle}</Typography>
						<Typography
							variant={
								'body2'
							}>{`${ticket.ticketUserName} / ${ticket.storeName} / ${기간텍스트}`}</Typography>
						<Typography variant={'body1'}>{`# ${ticket.ticketId}`}</Typography>
					</Stack>
					{rightPanel}
				</Stack>
			</AccordionSummary>
			{children}
		</StyledAccordion>
	);
};

const StyledAccordion = styled(Accordion)(({ theme }) => ({
	border: `1px solid ${grey[400]}`,
	boxShadow: 'unset',
}));
