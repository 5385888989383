import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminVendorOrderHistoryInfoListFileterType,
	VendorOrderHistoryType,
	VendorOrderType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getVendorOrderHistoryAsync =
	(rootUrl: string) =>
	async ({ vendorId, from, to, search, type }: GetVendorOrderHistoryReqType) =>
		(
			await posAxiosClient.get<{ lineList: VendorOrderHistoryType[] }>(
				`${rootUrl}/vendor-order/statistics`,
				{
					params: {
						vendorId,
						from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
						to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
						search,
						type,
					},
				}
			)
		).data;

type GetVendorOrderHistoryReqType = {
	vendorId?: number;
	from: Dayjs;
	to: Dayjs;
	search?: string;
	type?: VendorOrderType;
};
