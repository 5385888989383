import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { rootUrl } from '../../const';
import { BaseInfoType, SignupDeviceInfo } from '@kinderlabs-pos/shared-data-type';

const getSignupDeviceInfoAsync =
	(rootUrl: string) =>
	async ({ storeId, deviceId }: { storeId: number; deviceId: number }) =>
		(
			await posAxiosClient.get<SignupDeviceInfo & BaseInfoType>(`${rootUrl}/store/signup-device`, {
				params: { storeId, id: deviceId },
			})
		).data;

export const createSignupDeviceInfoAsync =
	(rootUrl: string) => async (createSignupDeviceInfoRequestInfo: Omit<SignupDeviceInfo, 'id'>) =>
		(
			await posAxiosClient.post<SignupDeviceInfo & BaseInfoType>(`${rootUrl}/store/signup-device`, {
				...createSignupDeviceInfoRequestInfo,
			})
		).data;

export const getSignupDeviceInfoListAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<(SignupDeviceInfo & BaseInfoType & { isConnected: boolean })[]>(
				`${rootUrl}/store/signup-device/list`,
				{
					params: { storeId },
				}
			)
		).data;
export const deleteSignupDeviceInfoListAsync =
	(rootUrl: string) =>
	async ({ idList }: { idList: number[] }) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/store/signup-device/list`, {
				params: {
					idList,
				},
			})
		).data;

export const updateSignupDeviceInfoAsync =
	(rootUrl: string) => async (updateSignupDeviceInfoRequestInfo: SignupDeviceInfo) =>
		(
			await posAxiosClient.put<SignupDeviceInfo & BaseInfoType>(
				`${rootUrl}/store/signup-device`,
				{
					...updateSignupDeviceInfoRequestInfo,
				},
				{
					params: {
						id: updateSignupDeviceInfoRequestInfo.id,
					},
				}
			)
		).data;

export const AdminSignupDeviceInfoApis = {
	getSignupDeviceInfo: getSignupDeviceInfoAsync(rootUrl),
	getSignupDeviceInfoList: getSignupDeviceInfoListAsync(rootUrl),
	createSignupDeviceInfo: createSignupDeviceInfoAsync(rootUrl),
	deleteSignupDeviceInfoList: deleteSignupDeviceInfoListAsync(rootUrl),
	updateSignupDeviceInfo: updateSignupDeviceInfoAsync(rootUrl),
};
