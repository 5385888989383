import { Button, DialogActions, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
	createContext,
	PropsWithChildren,
	ReactNode,
	useCallback,
	useContext,
	useState,
} from 'react';

const DUMMY_FUNCTION = () => Promise.resolve();
interface StateType {
	mode: 'alert' | 'confirm';
	open: boolean;
	title?: string;
	content: ReactNode;
	callback: () => void | Promise<void>;
	onReject?: () => void | Promise<void>;
}
type ConfirmType = (
	content: ReactNode,
	callback: StateType['callback'],
	onReject?: StateType['onReject'],
	i18n?: 'ko' | 'eng' | 'jpn' | 'chn'
) => Promise<unknown>;
type AlertType = (content: ReactNode, callback?: StateType['callback']) => Promise<unknown>;
type ContextType = {
	confirm: ConfirmType;
	alert: AlertType;
};

// dummy
const confirmThemeContext = createContext<ContextType>({
	confirm: DUMMY_FUNCTION,
	alert: DUMMY_FUNCTION,
});

const initialState: StateType = {
	mode: 'confirm',
	open: false,
	title: undefined,
	content: '',
	callback: DUMMY_FUNCTION,
};

interface IConfirmProviderProps {
	variant?: 'default' | 'large' | 'fullScreen';
	i18n?: 'ko' | 'eng' | 'jpn' | 'chn';
}
export const ConfirmProvider: React.FC<PropsWithChildren<IConfirmProviderProps>> = ({
	variant = 'default',
	i18n = 'ko',
	children,
}) => {
	const [resolve, setResolve] = useState<(value?: unknown) => void>();
	const [{ mode, open, title, content, callback, onReject }, setState] =
		useState<StateType>(initialState);

	const confirm = useCallback<ConfirmType>((content, callback, onReject) => {
		setState({ mode: 'confirm', content, callback, onReject, open: true });
		return new Promise((resolve) => setResolve(resolve));
	}, []);
	const alert = useCallback<AlertType>((content, callback) => {
		setState({ mode: 'alert', content, open: true, callback: callback || DUMMY_FUNCTION });
		return new Promise((resolve) => setResolve(resolve));
	}, []);

	const handleClose = () => setState(initialState);
	return (
		<confirmThemeContext.Provider
			value={{
				confirm: confirm,
				alert: alert,
			}}>
			<Dialog
				open={open}
				fullScreen={variant === 'fullScreen'}
				maxWidth={variant === 'large' ? 'lg' : undefined}>
				{title && <DialogTitle>{title}</DialogTitle>}
				<DialogContent
					sx={
						variant === 'fullScreen'
							? {
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
							  }
							: undefined
					}>
					<DialogContentText>{content}</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ gap: variant === 'large' ? 2 : undefined }}>
					<Button
						variant={'contained'}
						onClick={async () => {
							await callback();
							resolve && resolve();
							handleClose();
						}}>
						{i18n === 'ko' && '확인'}
						{i18n !== 'ko' && 'OK'}
					</Button>
					{mode === 'confirm' && (
						<Button
							variant={'outlined'}
							onClick={() => {
								onReject && onReject();
								resolve && resolve();
								handleClose();
							}}>
							{i18n === 'ko' && '취소'}
							{i18n !== 'ko' && 'CANCEL'}
						</Button>
					)}
				</DialogActions>
			</Dialog>
			{children}
		</confirmThemeContext.Provider>
	);
};

export const useConfirm = () => useContext(confirmThemeContext).confirm;
export const useAlert = () => useContext(confirmThemeContext).alert;
