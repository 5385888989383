import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CursorPageType,
	OrderType,
	OrderTypeWithPayments,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getListInfiniteAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		from,
		cursorId,
		to,
		pageable,
		status,
		type,
		keyword,
	}: GetOrderListRequestType) =>
		(
			await posAxiosClient.get<CursorPageType<OrderTypeWithPayments>>(
				`${rootUrl}/order/search/cursor`,
				{
					params: {
						...pageable.getPageableQueryParam(),
						storeIdList,
						posIdList,
						cursorId,
						from: from.format('YYYY-MM-DD HH:mm:ss'),
						to: to.format('YYYY-MM-DD HH:mm:ss'),
						status,
						type,
						search: keyword,
					},
				}
			)
		).data;

export type GetOrderListRequestType = {
	storeIdList?: number[];
	posIdList?: number[];
	cursorId?: string | number;
	pageable: Pageable;
	from: Dayjs;
	to: Dayjs;
	status?: OrderType['status'];
	type?: OrderType['type'];
	keyword?: string;
};
