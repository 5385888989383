import * as Sentry from '@sentry/react';
import {
	QueryClient as TanstackQueryClient,
	QueryClientProvider as TanstackQueryClientProvider,
	useMutation as useTanstackMutation,
	useQueryClient as useTanstackQueryClient,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode } from 'react';

const queryClient = new TanstackQueryClient({
	defaultOptions: {
		queries: {
			// staleTime: Infinity,
			suspense: true,
			// retry: 3,
			// retryDelay: (attemptIndex) => {
			// 	// console.log(`${attemptIndex} 시도 : ${dayjs().format('yyyy-MM-DD HHH:mm:ss')}`);

			// 	return Math.min(1000 * 1 * attemptIndex, 10000);
			// },
			useErrorBoundary: true,
			onError: (error) => {
				try {
					Sentry.withScope((scope) => {
						scope.setTags({
							Type: '리액트쿼리',
						});
						Sentry.captureException(error);
					});
				} finally {
					// ignored
				}
			},
		},
	},
});
export const QueryClientProvider = ({ children }: { children: ReactNode }) => {
	return (
		<TanstackQueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools />
		</TanstackQueryClientProvider>
	);
};
const useQueryClient = useTanstackQueryClient;
const useMutation = useTanstackMutation;

export const QueryClient = {
	origin: queryClient,
	Provider: QueryClientProvider,
	useMutation: useMutation,
	useQueryClient: useQueryClient,
};

// export const QueryClientProvider = ({ children }: { children: ReactNode }) => {
//   const queryClient = useAtomValue(queryClientAtom)
//   // return (
//   //   <>
//   //     <TanstackQueryClientProvider client={queryClient}>
//   //       {children}
//   //       {/* <ReactQueryDevtools initialIsOpen={false} /> */}
//   //     </TanstackQueryClientProvider>
//   //   </>)

//   return (
//     <>
//       <TanstackQueryClientProvider client={queryClient}>
//         <ReactQueryDevtools />
//         {/* <Provider initialValues={[[queryClientAtom, queryClient]]}> */}
//         {children}
//         {/* </Provider> */}
//       </TanstackQueryClientProvider>
//     </>
//   )
//   // return (
//   //   <Provider initialValues={[[queryClientAtom, queryClient]]}>
//   //     {children}
//   //     {/* <ReactQueryDevtools /> */}
//   //   </Provider>
//   // )
// }
