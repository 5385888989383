import { DeviceStatusLabel } from '@kinderlabs-pos/shared-data-type';
import { AdminKioskInfoState, authState, StoreInfoState } from '@kinderlabs-pos/state';
import { Button, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const KioskSalesTabPanel = () => {
	const user = useAtomValue(authState.user);
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId, kioskId: deviceId }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	const status = kioskInfo?.status || 'BEFORE_OPEN';
	const changeKioskInfoStatus = AdminKioskInfoState.useKioskInfoChangeStatus();

	const handle영업시작 = () => {
		changeKioskInfoStatus.mutateAsync({
			kioskId: deviceId,
			status: 'OPENED',
		});
	};

	const handle영업중지 = () => {
		changeKioskInfoStatus.mutateAsync({
			kioskId: deviceId,
			status: 'PAUSED',
		});
	};

	const handle영업마감 = () => {
		changeKioskInfoStatus.mutateAsync({
			kioskId: deviceId,
			status: 'CLOSED',
		});
	};

	return (
		<Stack
			width={'100%'}
			flex={1}
			spacing={5}>
			<Stack
				flex={1}
				spacing={1}>
				<Typography
					variant='h4'
					color={status === 'BEFORE_OPEN' || status === 'CLOSED' ? 'error' : 'primary'}>
					{DeviceStatusLabel[status]}
				</Typography>
			</Stack>
			<Stack
				width={'100%'}
				spacing={3}
				direction={'row'}>
				{status === 'BEFORE_OPEN' && (
					<Button
						fullWidth
						onClick={handle영업시작}
						variant='outlined'>
						영업 시작
					</Button>
				)}
				{status === 'PAUSED' && (
					<Button
						fullWidth
						onClick={handle영업시작}
						variant='outlined'>
						영업 재개
					</Button>
				)}
				{status === 'OPENED' && (
					<>
						<Button
							fullWidth
							onClick={handle영업중지}
							variant='outlined'>
							영업 중지
						</Button>
						<Button
							fullWidth
							onClick={handle영업마감}
							variant='outlined'>
							영업 마감
						</Button>
					</>
				)}
				{status === 'CLOSED' && (
					<>
						<Button
							fullWidth
							onClick={handle영업시작}
							variant='outlined'>
							영업 재개
						</Button>
					</>
				)}
			</Stack>
		</Stack>
	);
};
