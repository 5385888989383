import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteImageAsync =
	(rootUrl: string) =>
	async ({ fileId }: DeleteImageRequestType) =>
		(
			await posAxiosClient.delete<number>(`${rootUrl}/store/file`, {
				params: {
					fileId,
				},
			})
		).data;

export type DeleteImageRequestType = { fileId: number };
