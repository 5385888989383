import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, CmtInfoType } from '@kinderlabs-pos/shared-data-type';

export const createCmtInfoAsync =
	(rootUrl: string) => async (createCmtInfoRequest: CreateCmtInfoRequestType) =>
		(
			await posAxiosClient.post<CmtInfoType & BaseInfoType>(`${rootUrl}/cmt`, {
				...createCmtInfoRequest,
			})
		).data;

export type CreateCmtInfoRequestType = Omit<CmtInfoType, 'id' | 'ddiziInfo'>;
