import { ShoppingCartOutlined } from '@ant-design/icons';
import { useSortedCartLines } from '@kinderlabs-pos/feature/pos/cart-table';
import { OrderState } from '@kinderlabs-pos/state';
import { SimpleScrollbar } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, Stack, Typography } from '@mui/material';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';
import { useAtomValue } from 'jotai';
import { KioskCartItem } from './KioskCartItem';

export const KioskCartSection = () => {
	const customConfirm = useConfirm();
	const { t } = useKioskTranslation();

	const cartLines = useAtomValue(OrderState.cart.selector).lines;
	const cartDispatcher = OrderState.cart.useDispatcher();
	const sortCartLines = useSortedCartLines();
	const sortedCartLines = sortCartLines(cartLines);

	const sortedCartLinesForTarget = sortedCartLines
		.filter((cl) => cl.type !== 'BELL')
		.filter((cl) => !cl.targetCartLineId);

	const handleClearCart = () =>
		customConfirm(t('payment.resetWarning'), () => {
			cartDispatcher({ type: 'CLEAR' });
		});

	return (
		<Stack
			height={'100%'}
			width={'100%'}>
			<Stack
				p={3}
				alignItems={'center'}
				direction={'row'}
				justifyContent={'space-between'}>
				<Typography variant='h5'>{t('cart.title')}</Typography>
				<Button
					variant='outlined'
					disabled={cartLines.length === 0}
					onClick={handleClearCart}>
					<Typography variant='body2'>{t('cart.deleteAll')}</Typography>
				</Button>
			</Stack>
			<Stack
				flex={1}
				overflow={'auto'}>
				{sortedCartLines.length === 0 && (
					<Stack
						flex={1}
						justifyContent={'center'}
						alignItems={'center'}>
						<Typography
							textAlign={'center'}
							variant={'subtitle1'}
							color={'text.secondary'}>
							<ShoppingCartOutlined style={{ fontSize: '200%' }} />
							<br />
							{t('cart.emptyLabel1')}
							<br />
							{t('cart.emptyLabel2')}
						</Typography>
					</Stack>
				)}
				{sortedCartLines.length > 0 && (
					<SimpleScrollbar>
						<Stack
							spacing={2}
							pb={3}
							px={3} // 스크롤 여부보면서 값 조절할 것
							flex={1}
							overflow={'auto'}>
							{sortedCartLinesForTarget.map((cartLine, idx) => (
								<KioskCartItem
									cartLine={cartLine}
									optionsCartLines={sortedCartLines.filter(
										(cl) => cl.targetCartLineId === cartLine.id
									)}
									key={idx}
								/>
							))}
						</Stack>
					</SimpleScrollbar>
				)}
			</Stack>
		</Stack>
	);
};
