import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	매장별CategoryData,
	상품별CategoryData,
	일자별CategoryData,
	전년도대비매출증감Type,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
import { rootUrl } from '../../const';
import { StatisticsApis } from '.';

const get상품일자별매출통계 =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		search,
		category,
		isWithTargetCartLine,
		posIdList,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
		category: string[];
		search?: string;
		isWithTargetCartLine: boolean;
	}) =>
		(
			await posAxiosClient.get<상품별CategoryData>(
				`${rootUrl}/statistics/product/product/revenue/lambda/date`,
				{
					params: {
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						storeIdList,
						posIdList,
						categoryList: category,
						search,
						isWithTargetCartLine,
					},
				}
			)
		).data;

const get상품시간대별매출통계 =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		search,
		category,
		isWithTargetCartLine,
		posIdList,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
		category: string[];
		search?: string;
		isWithTargetCartLine: boolean;
	}) =>
		(
			await posAxiosClient.get<상품별CategoryData>(
				`${rootUrl}/statistics/product/product/revenue/lambda/time`,
				{
					params: {
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						fromTime: StatisticsApis.salesStatisticsStartTime,
						toTime: StatisticsApis.salesStatisticsEndTime,
						storeIdList,
						posIdList,
						categoryList: category,
						search,
						isWithTargetCartLine,
					},
				}
			)
		).data;

const get상품월별매출통계 =
	(rootUrl: string) =>
	async ({
		fromMonth,
		toMonth,
		storeIdList,
		search,
		category,
		isWithTargetCartLine,
		posIdList,
	}: {
		fromMonth: string;
		toMonth: string;
		storeIdList?: number[];
		posIdList?: number[];
		category: string[];
		search?: string;
		isWithTargetCartLine: boolean;
	}) =>
		(
			await posAxiosClient.get<상품별CategoryData>(
				`${rootUrl}/statistics/product/product/revenue/lambda/month`,
				{
					params: {
						fromMonth,
						toMonth,
						storeIdList,
						posIdList,
						categoryList: category,
						search,
						isWithTargetCartLine,
					},
				}
			)
		).data;

const get일자별카테고리별 =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		posIdList,
		search,
		isWithTargetCartLine,
		category,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
		category: string[];
		search?: string;
		isWithTargetCartLine: boolean;
	}) =>
		(
			await posAxiosClient.get<일자별CategoryData>(
				`${rootUrl}/statistics/product/category/revenue/lambda/date`,
				{
					params: {
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						storeIdList,
						posIdList: posIdList,
						categoryList: category,
						search: search,
						isWithTargetCartLine: isWithTargetCartLine,
					},
				}
			)
		).data;

const get월별카테고리별 =
	(rootUrl: string) =>
	async ({
		fromMonth,
		toMonth,
		storeIdList,
		posIdList,
		search,
		isWithTargetCartLine,
		category,
	}: {
		fromMonth: string;
		toMonth: string;
		storeIdList?: number[];
		posIdList?: number[];
		category: string[];
		search?: string;
		isWithTargetCartLine: boolean;
	}) =>
		(
			await posAxiosClient.get<일자별CategoryData>(
				`${rootUrl}/statistics/product/category/revenue/lambda/month`,
				{
					params: {
						fromMonth,
						toMonth,
						storeIdList,
						posIdList: posIdList,
						categoryList: category,
						search: search,
						isWithTargetCartLine: isWithTargetCartLine,
					},
				}
			)
		).data;

const get매장별카테고리별 =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		search,
		isWithTargetCartLine,
		category,
		posIdList,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
		category: string[];
		search?: string;
		isWithTargetCartLine: boolean;
	}) =>
		(
			await posAxiosClient.get<매장별CategoryData>(
				`${rootUrl}/statistics/product/category/revenue/lambda/store`,
				{
					params: {
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						storeIdList,
						isWithTargetCartLine: isWithTargetCartLine,
						categoryList: category,
						search: search,
						posIdList: posIdList,
					},
				}
			)
		).data;

const get전년도대비매출증감통계 =
	(rootUrl: string) =>
	async ({
		fromMonth,
		toMonth,
		storeIdList,
		category,
		posIdList,
	}: {
		fromMonth: string;
		toMonth: string;
		storeIdList?: number[];
		posIdList?: number[];
		category: string[];
	}) =>
		(
			await posAxiosClient.get<전년도대비매출증감Type[]>(
				`${rootUrl}/statistics/product/category/revenue/lambda/month/sales-growth`,
				{
					params: {
						fromMonth,
						toMonth,
						storeIdList,
						categoryList: category,
						posIdList: posIdList,
						isWithTargetCartLine: true,
					},
				}
			)
		).data;

export const ProductStatisticsApis = {
	get상품일자별매출통계: get상품일자별매출통계(rootUrl),
	get상품시간대별매출통계: get상품시간대별매출통계(rootUrl),
	get상품월별매출통계: get상품월별매출통계(rootUrl),
	get카테고리일자별매출통계: get일자별카테고리별(rootUrl),
	get카테고리월별매출통계: get월별카테고리별(rootUrl),
	get카테고리별매장별매출통계: get매장별카테고리별(rootUrl),

	get전년도대비매출증감통계: get전년도대비매출증감통계(rootUrl),
};
