import { Button, Stack, Typography } from '@mui/material';
import { KioskSettingsItem } from './commonKioskSettingsItem';
import packageJson from 'package.json';
import GitInfo from 'react-git-info/macro';
import { useMemo } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

export const KioskSoftwareInfoPanel = () => {
	const gitInfo = useMemo(() => GitInfo(), []);

	return (
		<Stack
			width={'100%'}
			flex={1}
			spacing={3}>
			<KioskSettingsItem
				label={'제작사'}
				innerElement={
					<Typography
						variant={'body2'}
						color={'text.secondary'}>
						{`${packageJson.author}`}
					</Typography>
				}
			/>
			<KioskSettingsItem
				label={'License'}
				innerElement={
					<Typography
						variant={'body2'}
						color={'text.secondary'}>
						{`${packageJson.license}`}
					</Typography>
				}
			/>
			<KioskSettingsItem
				label={'KIOSK 버전'}
				innerElement={
					<Typography
						variant={'body2'}
						color={'text.secondary'}>
						{`${packageJson.version} (${gitInfo.commit.shortHash})`}
					</Typography>
				}
			/>
			<KioskSettingsItem
				label={'이용 문의'}
				innerElement={
					<Typography
						variant={'body2'}
						color={'text.secondary'}>
						{`help@kinderlabs.co.kr`}
					</Typography>
				}
			/>
			<KioskSettingsItem
				label={'홈페이지'}
				innerElement={
					<Typography
						variant={'body2'}
						color={'text.secondary'}>
						{packageJson.homepage}
					</Typography>
				}
			/>
			<KioskSettingsItem
				label={'이용약관'}
				innerElement={
					<QrCode qrData={'https://kinderlabs.notion.site/13b1162159164f4ea0e9a25a8c5c8295'} />
				}
			/>
			<KioskSettingsItem
				label={'개인정보 처리방침'}
				innerElement={
					<QrCode qrData={'https://kinderlabs.notion.site/709e32195d53439a9ad490470c1ec8bb'} />
				}
			/>
			<KioskSettingsItem
				label={'KIOSK 매뉴얼'}
				innerElement={<QrCode qrData={'https://kinderlabs-ko-manual.oopy.io/'} />}
			/>
			<KioskSettingsItem
				label={'업데이트 노트'}
				innerElement={
					<QrCode
						qrData={'https://kinderlabs.notion.site/KL-POS-7f194292bd884233ad9f774aeb825359'}
					/>
				}
			/>
		</Stack>
	);
};

interface IQrCodeProps {
	qrData: string;
}
const QrCode: React.FC<IQrCodeProps> = ({ qrData }) => {
	return (
		<QRCodeCanvas
			value={qrData}
			size={80}
			bgColor={'#ffffff'}
			fgColor={'#000000'}
			level={'M'}
			includeMargin={false}
		/>
	);
};
