import { KioskBoardInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	KioskBoardInfoType,
	KioskBoardItemInfoType,
	KioskBoardMenuInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../../QueryClient';

const kioskBoardKeys = createQueryKeys('kioskBoard', {
	list: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			if (!storeId) throw Error;
			const res = await KioskBoardInfoApis.getKioskBoardInfoList({ storeId });
			return res;
		},
	}),
	detail: (kioskBoardInfoId?: number) => ({
		queryKey: [kioskBoardInfoId],
		queryFn: async () => {
			if (!kioskBoardInfoId) throw Error;
			const res = await KioskBoardInfoApis.getKioskBoardInfo({ id: kioskBoardInfoId });
			return res;
		},
	}),
	menuList: (kioskBoardInfoId?: number) => ({
		queryKey: [kioskBoardInfoId],
		queryFn: async () => {
			if (!kioskBoardInfoId) throw Error;
			const res = await KioskBoardInfoApis.getKioskBoardMenuListInBoard({ kioskBoardInfoId });
			return res;
		},
	}),
});

type CreateKioskBoardInfo = Omit<KioskBoardInfoType, 'id' | 'usingKioskInfoList'>;

const useKioskBoardInfoCreate = () => {
	return QueryClient.useMutation(
		async (kioskBoardInfo: CreateKioskBoardInfo) => {
			await KioskBoardInfoApis.createKioskBoardInfo({ kioskBoardInfo });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardInfoUpdate = () => {
	return QueryClient.useMutation(
		async ({ kioskBoardInfo }: { kioskBoardInfo: KioskBoardInfoType }) => {
			await KioskBoardInfoApis.updateKioskBoardInfo({ id: kioskBoardInfo.id, kioskBoardInfo });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardInfoDelete = () => {
	return QueryClient.useMutation(
		async (kioskBoardInfoId: number) => {
			await KioskBoardInfoApis.deleteKioskBoardInfo({ id: kioskBoardInfoId });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

type CreateKioskBoardType = Omit<KioskBoardMenuInfoType, 'id' | 'itemList'>;
const useKioskBoardMenuCreate = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenu,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenu: CreateKioskBoardType;
		}) => {
			await KioskBoardInfoApis.createKioskBoardMenu({
				kioskBoardInfoId,
				kioskBoardMenu,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardMenuUpdate = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenu,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenu: KioskBoardMenuInfoType;
		}) => {
			await KioskBoardInfoApis.updateKioskBoardMenu({
				kioskBoardInfoId,
				kioskBoardMenu,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardMenuI18NUpdate = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenuI18NMap,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenuI18NMap: Record<number, { eng?: string; jpn?: string; chn?: string }>;
		}) => {
			await KioskBoardInfoApis.updateKioskBoardMenuI18N({
				kioskBoardInfoId,
				kioskBoardMenuI18NMap,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardMenuDelete = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenuId,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenuId: number;
		}) => {
			await KioskBoardInfoApis.deleteKioskBoardMenu({
				kioskBoardInfoId,
				kioskBoardMenuId,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardMenuDisable = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardId,
			kioskBoardMenuId,
			isDisabled,
		}: {
			kioskBoardId: number;
			kioskBoardMenuId: number;
			isDisabled: boolean;
		}) => {
			await KioskBoardInfoApis.updateKioskBoardMenuVisibility({
				kioskBoardId,
				kioskBoardMenuId,
				isDisabled,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useAdjustKioskMenuOrder = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			boardIdList,
		}: {
			kioskBoardInfoId: number;
			boardIdList: number[];
		}) => {
			await KioskBoardInfoApis.adjustKioskMenuOrder({
				kioskBoardInfoId,
				boardIdList,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardItemCreate = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenuId,
			kioskBoardItem,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenuId: number;
			kioskBoardItem: KioskBoardItemInfoType;
		}) => {
			await KioskBoardInfoApis.createKioskBoardItem({
				kioskBoardInfoId,
				kioskBoardMenuId,
				kioskBoardItem,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardItemUpdate = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenuId,
			index,
			kioskBoardItem,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenuId: number;
			index: number;
			kioskBoardItem: KioskBoardItemInfoType;
		}) => {
			await KioskBoardInfoApis.updateKioskBoardItem({
				kioskBoardInfoId,
				kioskBoardMenuId,
				index,
				kioskBoardItem,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskBoardItemDelete = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenuId,
			index,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenuId: number;
			index: number;
		}) => {
			await KioskBoardInfoApis.deleteKioskBoardItem({
				kioskBoardInfoId,
				kioskBoardMenuId,
				index,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useSetKioskBoardItemSoldOut = () => {
	return QueryClient.useMutation(
		async ({
			kioskBoardInfoId,
			kioskBoardMenuId,
			index,
			soldOut,
		}: {
			kioskBoardInfoId: number;
			kioskBoardMenuId: number;
			index: number;
			soldOut: boolean;
		}) => {
			await KioskBoardInfoApis.setSoldOutKioskBoardItem({
				kioskBoardInfoId,
				kioskBoardMenuId,
				index,
				soldOut,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: kioskBoardKeys._def });
};

export const AdminKioskBoardInfoState = {
	keys: kioskBoardKeys,

	useKioskBoardInfoCreate,
	useKioskBoardInfoUpdate,
	useKioskBoardInfoDelete,

	useKioskBoardMenuCreate,
	useKioskBoardMenuUpdate,
	useKioskBoardMenuDelete,
	useKioskBoardMenuDisable,
	useKioskBoardMenuI18NUpdate,

	useAdjustKioskMenuOrder,
	useKioskBoardItemCreate,
	useKioskBoardItemUpdate,
	useKioskBoardItemDelete,
	useSetKioskBoardItemSoldOut,

	invalidateQueries,
};
