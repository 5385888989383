import { KioskInfoType, PaymentRequestInfoType } from '@kinderlabs-pos/shared-data-type';
import { KioskElectronStoreState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { DialogContent, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { KioskBellSection } from './KioskBellSection';
import { KioskCardPaymentSection } from './KioskCardPaymentSection';
import { KioskPaymentResultSection } from './KioskPaymentResultSection';
import { KioskPaymentSelectSection } from './KioskPaymentSelectSection';
import { KioskTelephoneSection } from './KioskTelephoneSection';
import { KioskNoPaymentSection } from './KioskNoPaymentSection';
import { KioksUserInfoPage } from '../../../KioskUserInfoMappingPage';

type StepsType = 'telephoneOrBell' | 'paymentSelect' | 'payment' | 'paymentResult';

export const KioskFNBCartOrderDialog = ({
	open,
	closeDialog,
	kioskInfo,
}: {
	kioskInfo: KioskInfoType;
} & XlDialogProps) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const guestMemberInfo = useAtomValue(KioksUserInfoPage.finalUserInfo);
	const hasTelephoneOption = kioskInfo.telephoneOption !== 'NONE';
	const hasBellOption = !hasTelephoneOption && kioskInfo.bellOption !== 'NONE';

	const [orderStep, setOrderStep] = useState<StepsType>(
		hasTelephoneOption || hasBellOption ? 'telephoneOrBell' : 'paymentSelect'
	);

	const [paymentMethod, setPaymentMethod] = useState<'CARD' | 'VIRTUAL_CARD' | 'NO_PAYMENT'>(
		'CARD'
	);

	const handleGoNextStep = () => {
		if (orderStep === 'telephoneOrBell') setOrderStep('paymentSelect');
	};

	const handleGoBackStep = () => {
		if (orderStep === 'payment') setOrderStep('paymentSelect');
	};

	const selectPayment = (method: 'CARD' | 'VIRTUAL_CARD' | 'NO_PAYMENT') => {
		setOrderStep('payment');
		setPaymentMethod(method);
	};

	const createKioskOrderPayment = OrderState.actions.useCreateKioskOrderPayment({
		storeInfo,
		deviceId: kioskInfo.id,
		guestMemberInfo,
	});

	const [orderId, setOrderId] = useState<string | undefined>(undefined);
	const handleSubmitPayment = async (paymentRequestData?: PaymentRequestInfoType) => {
		const result = await createKioskOrderPayment.mutateAsync({ paymentRequestData });
		setOrderId(result.order.id);

		setOrderStep('paymentResult');
	};

	const cartReducer = OrderState.cart.useDispatcher();

	useEffect(() => {
		return () => {
			cartReducer({ type: 'BELL', subAction: { type: 'REMOVE_BELL' } });
		};
	}, []);

	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const 영수증자동인쇄옵션켜졌는지 = localSettings?.receiptAutoPrint ?? false;

	return (
		<WithDialog.XlDialog
			open={open}
			maxWidth={'xl'}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 768, aspectRatio: '1/1.8' }}>
				<Stack
					height={'100%'}
					width={'100%'}
					flex={1}
					spacing={3}>
					<Suspense fallback={<FullSizeCircularProgress />}>
						{orderStep === 'telephoneOrBell' &&
							(hasTelephoneOption ? (
								<KioskTelephoneSection
									open={open}
									closeDialog={closeDialog}
									kioskInfo={kioskInfo}
									handleGoNextStep={handleGoNextStep}
								/>
							) : (
								<KioskBellSection
									open={open}
									closeDialog={closeDialog}
									kioskInfo={kioskInfo}
									handleGoNextStep={handleGoNextStep}
								/>
							))}
						{orderStep === 'paymentSelect' && (
							<KioskPaymentSelectSection
								open={open}
								closeDialog={closeDialog}
								kioskInfo={kioskInfo}
								selectPayment={selectPayment}
							/>
						)}
						{orderStep === 'payment' && paymentMethod !== 'NO_PAYMENT' && (
							<KioskCardPaymentSection
								kioskInfo={kioskInfo}
								storeInfo={storeInfo}
								goBack={handleGoBackStep}
								paymentMethod={paymentMethod}
								handleSubmitPayment={handleSubmitPayment}
							/>
						)}
						{orderStep === 'payment' && paymentMethod === 'NO_PAYMENT' && (
							<KioskNoPaymentSection
								kioskInfo={kioskInfo}
								storeInfo={storeInfo}
								goBack={handleGoBackStep}
								handleSubmitPayment={handleSubmitPayment}
							/>
						)}
						{orderStep === 'paymentResult' && orderId && (
							<KioskPaymentResultSection
								kioskInfo={kioskInfo}
								orderId={orderId}
								영수증자동인쇄옵션켜졌는지={영수증자동인쇄옵션켜졌는지}
							/>
						)}
					</Suspense>
				</Stack>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
