import {
	CartLineInfoType,
	KioskBoardItemInfoWithDetailType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { SimpleScrollbar, WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogContent, Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { KioskBoardItemProductInfoSection } from './components/KioskBoardItemProductInfoSection';
import { KioskBoardItemSelectDialogAction } from './components/KioskBoardItemSelectDialogAction';
import { KioskBoardItemSelectDialogTitle } from './components/KioskBoardItemSelectDialogTitle';
import { KioskBoardItemOptionSection } from './KioskBoardItemOptionSection';
import { KioskBoardItemOptionSelectButton } from './components/KioskBoardItemOptionSelectButton';
import { KioskBoardItemOptionAggregateAndAlert } from './components/KioskBoardItemOptionAggregate';
import { OrderState } from '@kinderlabs-pos/state';
import { getI18nString, getUuidV4 } from '@kinderlabs-pos/shared-util';
import { useAtomValue } from 'jotai';
import { useKioskTranslation } from '../../../../const/useKioskTranslation';

export const KioskBoardNormalItemOptionDialog = ({
	open,
	closeDialog,
	itemInfo,
	alertMessage,
}: XlDialogProps & {
	itemInfo: KioskBoardItemInfoWithDetailType;
	alertMessage?: string;
}) => {
	const { kioskI18N } = useKioskTranslation();
	const [quantity, setQuantity] = useState(1);
	const [selectedOptionIdList, setSelectedOptionIdList] = useState<number[]>([]);

	const handleToggleSelection = (optionProductInfoId: number) => {
		if (selectedOptionIdList.some((selectedOptionId) => selectedOptionId === optionProductInfoId)) {
			setSelectedOptionIdList(
				selectedOptionIdList.filter((selectedOptionId) => selectedOptionId !== optionProductInfoId)
			);
		} else {
			setSelectedOptionIdList([...selectedOptionIdList, optionProductInfoId]);
		}
	};

	const optionTotalSum = itemInfo.productOptionInfoList
		.filter((productInfo) => selectedOptionIdList.includes(productInfo.id))
		.reduce((arr, productInfo) => arr + productInfo.price, 0);

	const 상품과옵션장바구니담기 = use상품과옵션장바구니담기({
		productInfo: itemInfo.productInfo,
		imageUrl: itemInfo.imageUrl,
		selectedOptionProducts: itemInfo.productOptionInfoList.filter((productOptionInfo) =>
			selectedOptionIdList.includes(productOptionInfo.id)
		),
		onComplete: closeDialog,
	});

	return (
		<WithDialog.XlDialog
			open={open}
			dialogTitle={
				<KioskBoardItemSelectDialogTitle
					title={'상품 선택'}
					closeDialog={closeDialog}
				/>
			}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 824, aspectRatio: '1/1.6', overflow: 'auto' }}>
				<Stack
					height={'100%'}
					overflow={'auto'}>
					<Stack
						overflow={'auto'}
						flex={1}
						spacing={5}>
						<KioskBoardItemProductInfoSection
							imageUrl={itemInfo.imageUrl}
							productInfo={itemInfo.productInfo}
						/>
						<Stack
							overflow={'auto'}
							spacing={5}
							flex={1}>
							<SimpleScrollbar>
								<Stack
									direction={'row'}
									flexWrap={'wrap'}
									width={'100%'}
									rowGap={3}
									columnGap={3}>
									{itemInfo.productOptionInfoList.map((productOption, idx) => {
										return (
											<KioskBoardItemOptionSelectButton
												key={idx}
												selected={selectedOptionIdList.some(
													(selectedId) => productOption.id === selectedId
												)}
												label={
													getI18nString({
														ko: productOption.name,
														i18nString: productOption.i18nName,
														i18n: kioskI18N,
													}) ?? ''
												}
												price={productOption.price}
												onClick={() => handleToggleSelection(productOption.id)}
											/>
										);
									})}
								</Stack>
							</SimpleScrollbar>
						</Stack>
					</Stack>
					<KioskBoardItemOptionAggregateAndAlert
						alertMessage={alertMessage}
						productPrice={itemInfo.productInfo.price}
						optionTotalSum={optionTotalSum}
					/>
				</Stack>
			</DialogContent>
			<KioskBoardItemSelectDialogAction
				onClose={closeDialog}
				isValid={true}
				onApply={상품과옵션장바구니담기}
			/>
		</WithDialog.XlDialog>
	);
};

const use상품과옵션장바구니담기 = ({
	productInfo,
	imageUrl,
	selectedOptionProducts,
	onComplete,
}: {
	productInfo: ProductInfoType;
	imageUrl?: string;
	selectedOptionProducts: ProductInfoType[];
	onComplete?: (cartLine?: CartLineInfoType) => void;
}) => {
	const cartReducer = OrderState.cart.useDispatcher();

	return () =>
		cartReducer({
			type: 'PRODUCTS',
			subAction: {
				type: 'ADD',
				imageUrl,
				cartLineId: getUuidV4(),
				productInfo: productInfo,
				options: selectedOptionProducts,
				onComplete: (cartLineType) => {
					const targetCartLineId = cartLineType?.id;

					if (!targetCartLineId) return;

					setTimeout(() => {
						onComplete && onComplete();
					}, 0);
				},
			},
		});
};
