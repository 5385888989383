import { PaymentAPI } from '@kinderlabs-pos/apis/pos';
import {
	OrderDetailType,
	PaymentInfoType,
	ReceiptInfoType,
	현금영수증OptionType,
} from '@kinderlabs-pos/shared-data-type';
import {
	OrderQueryState,
	OrderState,
	PaymentQueryState,
	ReceiptQueryState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { useAtomValue, useSetAtom } from 'jotai';
import {
	isOfficialPaymentPayload,
	useHandlePaymentPM,
	useHandlePaymentPM강제취소,
	useHandle카드삽입여부확인하기,
	useHandle카드정보가져오기,
} from './handlePayment';
import { useHandle결제모듈 } from './handlePaymentByPM';
import { usePaymentFormik } from './usePaymentFormik';

const use사후Publish현금영수증 = () => {
	const setOrder = useSetAtom(OrderState.value);
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { vanCompany } = StoreInfoState.useCurDeviceInfoForOrder();

	const handle결제모듈 = useHandle결제모듈(vanCompany);

	return async ({
		payment,
		options,
	}: {
		payment: Pick<PaymentInfoType, 'id' | 'amount' | 'tax' | 'created' | 'receipt'> & {
			method: 'CASH' | 'TRANSFER' | 'GIFTCARD';
		};
		options: 현금영수증OptionType;
	}) => {
		const submitReceipt: ReceiptInfoType = {
			...(await handle결제모듈({
				method: 'CASH_현금영수증_발급',
				amount: payment.amount,
				// JIN : 여기서 payment 별 tax 계산 필요
				tax: payment.tax,
				customerId: options.customerId,
				isEnterprise: options.type === '지출증빙',
				isSignPadUsed: options.isSignPadUsed,
			})),
			receiptType: 'BUY',
		};

		const result = await PaymentAPI.publishCashReceipt({
			storeId: storeId,
			posId: deviceId,
			paymentId: payment.id,
			receipt: submitReceipt,
		});

		setOrder({
			type: 'PAYMENTS',
			subAction: {
				type: 'ADD_CASH_RECEIPT',
				paymentId: payment.id,
				receipt: submitReceipt,
			},
		});

		OrderQueryState.invalidateQueries();
		PaymentQueryState.invalidateQueries();
		ReceiptQueryState.invalidateQueries();
	};
};

export const PaymentSubmitActions = {
	usePaymentFormik,
	use사후Publish현금영수증,
	useHandlePaymentPM,
	isOfficialPaymentPayload,
	useHandlePaymentPM강제취소,
	useHandle카드정보가져오기,
	useHandle카드삽입여부확인하기,
};
