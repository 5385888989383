import { CloseCircleFilled } from '@ant-design/icons';
import { IconButton, Stack, Typography } from '@mui/material';

export const KioskBoardItemSelectDialogTitle = ({
	closeDialog,
	title,
}: {
	title: string;
	closeDialog?: () => void;
}) => {
	return (
		<Stack
			direction={'row'}
			alignItems={'center'}>
			<Stack flex={1}>
				<Typography variant={'h5'}>{title}</Typography>
			</Stack>
			<IconButton
				size='large'
				onClick={closeDialog}
				color={'primary'}>
				<CloseCircleFilled style={{ fontSize: '200%' }} />
			</IconButton>
		</Stack>
	);
};
