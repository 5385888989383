import {
	DdiziState,
	ExitQueryState,
	OrderState,
	QueryClient,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { CardMedia, CircularProgress, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import scanner from 'assets/wristband-scan.gif';
import { useSetAtom, useAtomValue, useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { KioskExitPage } from '.';
import { OrderAPI } from '@kinderlabs-pos/apis/pos';
import { KioskExitCartOrderDialog } from './KioskExitCartOrderDialog';
import { BaseInfoType, ProductInfoType } from '@kinderlabs-pos/shared-data-type';

export interface IQRNotificationSectionProps {
	fixedTime: Date;
}
export const QRNotificationSection: React.FC<IQRNotificationSectionProps> = ({ fixedTime }) => {
	const handleSearchDdiziMutate = useDdiziOrderMutate({ fixedTime });
	useQRCodeReadAndSearchDdizi({ handleSearchDdizi: handleSearchDdiziMutate.mutateAsync });

	return (
		<Stack
			sx={{ p: 5 }}
			direction={'row'}
			display={'flex'}
			alignItems={'center'}
			justifyContent={'center'}
			spacing={5}>
			{handleSearchDdiziMutate.isLoading ? (
				<Stack sx={{ width: 180, aspectRatio: 1, justifyContent: 'center', alignItems: 'center' }}>
					<CircularProgress style={{ width: 120, height: 120 }} />
				</Stack>
			) : (
				<CardMedia
					image={scanner}
					sx={{ width: 180, aspectRatio: 1 }}
				/>
			)}
			<Stack spacing={2}>
				<Typography variant='h3'>{'QR 코드를 읽혀주세요.'}</Typography>
				<Typography
					variant='subtitle1'
					sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>
					{'티켓 퇴장/정산 완료 후 최대 10분 안에 퇴장 부탁드립니다.'}
				</Typography>
			</Stack>
		</Stack>
	);
};

// 02. 띠지 검색 로직
const useDdiziOrderMutate = ({ fixedTime }: { fixedTime: Date }) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const ticketSharedStoreIdList = storeInfo.policy.ticketShareStoreIdList || [];
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const { overtime } = useAtomValue(StoreInfoState.curStoreInfo);
	const jungsanInfo = StoreInfoState.useCurStoreJungsanInfo();

	const setSelectedOrderList = useSetAtom(KioskExitPage.selectedOrderListAtom);
	const dispatchExcessCharge = OrderState.cart.useDispatcher();

	return QueryClient.useMutation(
		async (keywordToSearch: string) => {
			return await OrderAPI.searchForDdizi({
				storeId,
				posId: deviceId,
				search: keywordToSearch,
				storeIdList: [...ticketSharedStoreIdList, storeInfo.id],
			});
		},
		{
			onSuccess: (resultOrderList, keywordToSearch) => {
				setSelectedOrderList((prev) => {
					const newState = prev;
					resultOrderList.forEach((order) => {
						if (prev.every((prevOrder) => prevOrder.id !== order.id)) newState.push(order);
					});

					return [...newState];
				});

				const 다이렉트로검색한띠지 = resultOrderList
					.flatMap((order) => order.ddizis)
					.find((ddizi) => ddizi.id === keywordToSearch);

				if (다이렉트로검색한띠지) {
					const 다이렉트로검색한띠지ToJungsain = DdiziState.utils.getDdiziToJungsan({
						ddizi: 다이렉트로검색한띠지,
						timeStandard: fixedTime,
						overtimePolicy: overtime,
					});

					if (DdiziState.utils.isDdiziSelectableForJungsan(다이렉트로검색한띠지ToJungsain))
						dispatchExcessCharge({
							type: 'EXCESS_CHARGE',
							subAction: {
								type: 'SELECT',
								ddizi: 다이렉트로검색한띠지ToJungsain,
								jungsanInfo,
							},
						});
				}
			},
		}
	);
};

// 03. QR 코드인식기능 활용
const useQRCodeReadAndSearchDdizi = ({
	handleSearchDdizi,
}: {
	handleSearchDdizi: (keyword: string) => void;
}) => {
	let inputStrings: string[] = [];

	const paymentDialogOpened = useAtomValue(KioskExitCartOrderDialog.opened);
	useEffect(() => {
		const keyboardEvent = (event: KeyboardEvent) => {
			// 지우지말고 각자 테스트할때 사용합시다!
			// if (event && event.key === 'a') handleSearchDdizi('O240523-001-000003');
			// if (event && event.key === 'b') handleSearchDdizi('0523010003');

			if (!event || event.key === 'Escape' || event.key === 'Shift' || event.key === 'CapsLock')
				return;
			else if (event.key === 'Enter') {
				const joinnedText = inputStrings.join('');
				inputStrings = [];

				handleSearchDdizi(joinnedText);
			} else {
				inputStrings.push(event.key as string);
			}
		};

		if (!paymentDialogOpened) window.addEventListener('keydown', keyboardEvent);
		return () => window.removeEventListener('keydown', keyboardEvent);
	}, [paymentDialogOpened]);
};
