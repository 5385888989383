import { FilledInput, Input, InputProps, OutlinedInput, TextFieldProps } from '@mui/material';
import { MaskElement } from 'imask';
import React, { useCallback, useMemo, useState } from 'react';
import { IMaskInput } from 'react-imask';

type PhoneNumberInputProps = Omit<InputProps, 'inputComponent'> & {
	variant?: TextFieldProps['variant'];
	getUnmasked: boolean;
};
interface CustomProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
	const { variant, getUnmasked, ...InputProps } = props;
	const Component = useMemo(
		() =>
			variant === 'outlined'
				? PhoneNumberOutlinedInputComponent
				: variant === 'filled'
				? PhoneNumberFilledInputComponent
				: PhoneNumberDefaultInputComponent,
		[variant]
	);
	const inputComponent = useMemo(() => PhoneNumberInputMask(getUnmasked) as any, [getUnmasked]);

	return (
		<Component
			{...InputProps}
			inputComponent={inputComponent}
		/>
	);
};

const PhoneNumberDefaultInputComponent = (props: InputProps) => {
	return <Input {...props} />;
};

const PhoneNumberOutlinedInputComponent = (props: InputProps) => {
	return <OutlinedInput {...props} />;
};

const PhoneNumberFilledInputComponent = (props: InputProps) => {
	return <FilledInput {...props} />;
};

const 전화번호10자리 = '999 - 999 - 9999#';
const 전화번호11자리 = '999 - 9999 - 9999';
export const PhoneNumberInputMask = (getUnmasked: boolean) =>
	React.forwardRef<MaskElement, CustomProps>(function TextMaskCustom({ onChange, ...props }, ref) {
		const [mask, setMask] = useState<typeof 전화번호10자리 | typeof 전화번호11자리>(전화번호10자리);
		const changeMask = useCallback(
			(value: string) => setMask(value.length > 16 ? 전화번호11자리 : 전화번호10자리),
			[]
		);

		return (
			<IMaskInput
				{...props}
				mask={mask}
				definitions={{
					'9': /[0-9]/,
					'#': /[0-9]/,
				}}
				inputRef={ref}
				onAccept={(value: any, ref: any) => {
					changeMask(value);
					onChange({
						target: {
							name: props.name,
							// 아래 작업을 통해서 옵션에 따라 마스크 되지 않은 Value 를 Return 해준다.
							//@ts-ignore
							value: getUnmasked ? ref._unmaskedValue : value,
						},
					});
				}}
				validate={(value: string) => {
					changeMask(value);
					return true;
				}}
				overwrite
			/>
		);
	});
