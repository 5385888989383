import { DownCircleFilled, UpCircleFilled } from '@ant-design/icons';
import { KioskBoard } from '@kinderlabs-pos/feature/kiosk-board';
import { KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { useScrollControl } from '@kinderlabs-pos/shared-react-util';
import { AdminKioskBoardInfoState, KioskElectronStoreState } from '@kinderlabs-pos/state';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { KioskBoardMenu } from './KioskBoardMenu';
import { KioskBoardProductionItem } from './KioskBoardProductionItem';
import { useKioskTranslation } from '../../../const/useKioskTranslation';
import { getI18nString } from '@kinderlabs-pos/shared-util';

export const KioskBoardItemBoard = ({
	kioskInfo,
	kioskBoardInfoId,
}: {
	kioskInfo: KioskInfoType;
	kioskBoardInfoId: number;
}) => {
	const theme = useTheme();
	const { kioskI18N } = useKioskTranslation();

	const { data: kioskBoard } = useQuery({
		...AdminKioskBoardInfoState.keys.detail(kioskBoardInfoId),
	});
	const { data } = useQuery({
		...AdminKioskBoardInfoState.keys.menuList(kioskBoardInfoId),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});
	const unFilteredkioskBoardMenuList = data ?? [];

	const kioskBoardMenuList = useMemo(
		() => unFilteredkioskBoardMenuList.filter((menu) => !menu.isDisabled),
		[unFilteredkioskBoardMenuList]
	);

	const selectedMenuIndex = useAtomValue(KioskBoardMenu.selectedMenuIndex);

	const hideSoldoutItems =
		KioskElectronStoreState.useKioskMainPageLocalSettings().data?.hideSoldOutItems ?? false;
	const showScrollButtons =
		KioskElectronStoreState.useKioskMainPageLocalSettings().data?.showScrollButtons;

	const selectedKioskBoard =
		selectedMenuIndex || selectedMenuIndex === 0
			? kioskBoardMenuList[selectedMenuIndex]
			: undefined;

	// 품절상품 숨김처리시 한번 전처리를 해줍니다.
	const kioskBoardItemList = useMemo(() => {
		const originItems = selectedKioskBoard?.itemWithDetailList || [];
		return hideSoldoutItems
			? originItems.filter((item) => hideSoldoutItems && !item.isSoldOut)
			: originItems;
	}, [hideSoldoutItems, selectedKioskBoard?.itemWithDetailList]);

	const { isScrollButtonRequired, isTop, isBottom, scrollUp, scrollDown, setScrollRef, onScroll } =
		useScrollControl({ scrollSize: 650 });

	if (!selectedKioskBoard) return null;

	return (
		<Stack
			flex={1}
			overflow={'auto'}
			spacing={2}>
			<Stack p={2}>
				<Typography
					variant='h5'
					color={'primary'}>
					{getI18nString({
						ko: selectedKioskBoard.name,
						i18nString: selectedKioskBoard.i18nName,
						i18n: kioskI18N,
					}) ?? ''}
				</Typography>
			</Stack>
			{kioskBoardItemList.length === 0 ? (
				<Stack
					width='100%'
					minHeight={820}
					alignItems={'center'}
					justifyContent={'center'}>
					<Typography>판매중인 상품이 없습니다.</Typography>
				</Stack>
			) : (
				<Stack
					position={'relative'}
					overflow={'hidden'}>
					<Stack
						direction={'row'}
						sx={{ height: '100%', overflowY: 'auto', p: 2 }}
						onScroll={onScroll}
						ref={setScrollRef}
						key={selectedMenuIndex}>
						<KioskBoard width={'100%'}>
							{kioskBoardItemList.map((item, idx) => (
								<KioskBoardProductionItem
									key={idx}
									itemInfo={item}
									kioskBoardalertMessage={kioskBoard?.alertMessage ?? ''}
									kioskInfo={kioskInfo}
								/>
							))}
						</KioskBoard>
						{showScrollButtons && <Stack width={80} />}
						{showScrollButtons && isScrollButtonRequired && (
							<Stack
								position={'absolute'}
								spacing={6}
								sx={{ right: 8, bottom: 75, width: 54 }}>
								<IconButton
									sx={{
										transform: 'scale(1.8)',
										transformOrigin: 'center',
									}}
									disabled={isTop}
									onClick={scrollUp}>
									<UpCircleFilled
										style={{
											color: !isTop ? theme.palette.primary.main : undefined,
										}}
									/>
								</IconButton>
								<IconButton
									sx={{
										transform: 'scale(1.8)',
										transformOrigin: 'center',
									}}
									disabled={isBottom}
									onClick={scrollDown}>
									<DownCircleFilled
										style={{
											color: !isBottom ? theme.palette.primary.main : undefined,
										}}
									/>
								</IconButton>
							</Stack>
						)}
					</Stack>
				</Stack>
			)}
		</Stack>
	);
};
