import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CardPaymentType,
	Pageable,
	PageType,
	PaymentInfoType,
	PaymentMethodType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getListAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		method,
		status,
		from,
		to,
		pageable,
		cardPaymentType,
		keyword,
	}: GetPaymentListRequestType) =>
		(
			await posAxiosClient.get<PageType<PaymentInfoType>>(`${rootUrl}/payment/search`, {
				params: {
					posIdList,
					storeIdList,
					...pageable.getPageableQueryParam(),
					from: from.format('YYYY-MM-DD HH:mm:ss'),
					to: to.format('YYYY-MM-DD HH:mm:ss'),
					method: method,
					status: status,
					cardPaymentType: cardPaymentType,
					search: keyword,
				},
			})
		).data;

export type GetPaymentListRequestType = {
	posIdList?: number[];
	storeIdList?: number[];
	// targetPosId?: number;
	method?: PaymentMethodType;
	status?: 'REFUND' | 'COMPLETE';
	cardPaymentType?: CardPaymentType;
	pageable: Pageable;
	from: Dayjs;
	to: Dayjs;
	keyword?: string;
};
