import { rootUrl } from '../const';
import { changeMemoStatusAsync } from './changeMemoStatusAsync';
import { createMemoAsync } from './createMemoAsync';
import { deleteMemoAsync } from './deleteMemoAsync';
import { getMemoDetailAsync } from './getMemoDetailAsync';
import { getMemoListAsync, getMemoListForMonitoringAsync } from './getMemoListAsync';
import { modifyMemoAsync } from './modifyMemoAsync';

export const MemoAPI = {
	getMemoDetail: getMemoDetailAsync(rootUrl),
	getMemoList: getMemoListAsync(rootUrl),
	getMemoListForMonitoring: getMemoListForMonitoringAsync(rootUrl),
	changeMemoStatus: changeMemoStatusAsync(rootUrl),
	modifyMemo: modifyMemoAsync(rootUrl),
	deleteMemo: deleteMemoAsync(rootUrl),
	createMemo: createMemoAsync(rootUrl),
};
