import { KioskElectronStoreState, StoreInfoState, authState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import DomainIcon from '@mui/icons-material/Domain';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { KioskLoginDialog } from '../../../dialog/KioskLoginDialog';
import { KioskStoreSelectSection } from './KioskStoreSelectSection';
import { KioskSettingsItem } from './commonKioskSettingsItem';

export const KioskSettingTabPanel = () => {
	const user = useAtomValue(authState.user);
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
	const logout = useSetAtom(authState.logout);
	const deviceLogin = useSetAtom(StoreInfoState.deviceLoginInfo);

	const customConfirm = useConfirm();

	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();

	const handleClickLogout = () => {
		customConfirm('로그아웃 하시겠습니까?', async () => {
			if (localSettings)
				saveLocalSettingsMutation.mutate({
					...localSettings,
					recentLoginInfo: undefined,
					kioskInfo: undefined,
				});
			deviceLogin(undefined);

			await logout();
		});
	};

	return (
		<Stack
			flex={1}
			alignItems={'center'}>
			{user ? (
				<Stack
					flex={1}
					width={'100%'}
					spacing={3}>
					{enterpriseInfo && (
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={2}>
							<DomainIcon sx={{ fontSize: '3rem' }} />
							<Typography variant={'h6'}>{enterpriseInfo.name || ''}</Typography>
						</Stack>
					)}
					<Divider />
					<Stack
						width={'100%'}
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}>
						<KioskSettingsItem
							label={'로그인된 유저'}
							innerElement={<Typography variant='subtitle1'>{user.email}</Typography>}
						/>
						<Button
							variant='outlined'
							onClick={handleClickLogout}>
							로그아웃
						</Button>
					</Stack>
					<Suspense>
						<KioskStoreSelectSection user={user} />
					</Suspense>
				</Stack>
			) : (
				<WithDialog
					dialog={(open, closeDialog) => (
						<KioskLoginDialog
							open={open}
							closeDialog={closeDialog}
							onLoginSuccess={() => {}}
						/>
					)}>
					{(openDialog) => (
						<Button
							variant='outlined'
							onClick={openDialog}>
							<Typography variant={'h4'}>로그인 필요</Typography>
						</Button>
					)}
				</WithDialog>
			)}
		</Stack>
	);
};
