import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	OrderDetailType,
	PaymentInfoType,
	ReceiptInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../const';

type PayloadType = { paymentId: string; type: ReceiptInfoType['receiptType'] };
const getReceiptForOrder =
	(rootUrl: string) =>
	async ({ paymentId, type }: PayloadType) =>
		(
			await posAxiosClient.get<{
				order: OrderDetailType;
				payment: PaymentInfoType;
				receipt: ReceiptInfoType;
			}>(`${rootUrl}/receipt/order`, {
				params: {
					paymentId,
					type,
				},
			})
		).data;

const getReceiptForJungSan =
	(rootUrl: string) =>
	async ({ paymentId, type }: PayloadType) =>
		(
			await posAxiosClient.get<{
				jungSan: any;
				payment: PaymentInfoType;
				receipt: ReceiptInfoType;
			}>(`${rootUrl}/receipt/jungsan`, {
				params: {
					paymentId,
					type,
				},
			})
		).data;

export const ReceiptAPI = {
	getReceiptForOrder: getReceiptForOrder(rootUrl),
	getReceiptForJungSan: getReceiptForJungSan(rootUrl),
};
