import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { calculateVAT } from '../statistics/StatisticsType';
import { CardPaymentType, PaymentResponseType } from './PaymentModuleType';

export const getEmptyDummy: () => PaymentResponseType = () => ({
	amount: 0,
	tax: 0,
	supply: 0,
	approvalId: '',
	approvalDate: new Date(),
	installment: 0,
	bin: '',
	merchantNumber: '',
	cardPaymentType: 'ETC',
	transactionId: 'KL-' + getUuidV4(),
	isEnterprise: false,
	transactionClassification: '',
	transactionType: '',
	responseCode: '',
	tip: 0,
	issuerCode: '',
	issuer: '',
	acquirerCode: '',
	acquirer: '',
	catId: '',
	balance: 0,
	responseMessage: '',
	textManageId: '',
	vanCompany: 'NO_PAYMENT',
});

export const getBinDummy: () => PaymentResponseType = () => ({
	...getEmptyDummy(),
	bin: '123456**********',
});

export const getNormalDummy: (props: {
	amount: number;
	installment: number;
	customerId?: string;
	cardPaymentType: CardPaymentType;
	isEnterprise?: boolean;
}) => PaymentResponseType = (args) => ({
	...args,
	...getEmptyDummy(),
	tax: calculateVAT({ revenue: args.amount, taxFree: false }),
	supply: args.amount - calculateVAT({ revenue: args.amount, taxFree: false }),
	approvalId: '012341234',
	bin: '010****7252',
	issuer: '국세청',
	issuerCode: '03',
	merchantNumber: '킨더랩스점',
	method: 'CASH',
	isEnterprise: args.isEnterprise || false,
});

export const getRefundDummy: (props: {
	amount: number;
	approvalId: string;
	customerId?: string;
	cardPaymentType: CardPaymentType;
	isEnterprise?: boolean;
}) => PaymentResponseType = (args) => ({
	...args,
	...getEmptyDummy(),
	tax: calculateVAT({ revenue: args.amount, taxFree: false }),
	supply: args.amount - calculateVAT({ revenue: args.amount, taxFree: false }),
	approvalId: '098765432',
	issueCompany: '하나카드',
	issueCompanyCode: '03',
	bin: '010****7252',
	merchantNumber: '킨더랩스점',
	isEnterprise: args.isEnterprise || false,
});
