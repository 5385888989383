import { CreditCardOutlined } from '@ant-design/icons';
import { isOnElectron } from '@kinderlabs-pos/ipc/react';
import { KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';
import { useAtomValue } from 'jotai';

export const KioskPaymentSelectSection = ({
	open,
	closeDialog,
	kioskInfo,
	selectPayment,
}: {
	kioskInfo: KioskInfoType;
	selectPayment: (method: 'CARD' | 'VIRTUAL_CARD' | 'NO_PAYMENT') => void;
} & XlDialogProps) => {
	const totalToPay = useAtomValue(OrderState.aggregate.cart).totalToPay;
	const { t, kioskI18N } = useKioskTranslation();

	return (
		<Stack height={'100%'}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>{t('payment.select')}</Typography>
			</Stack>
			<Stack
				flex={1}
				height={'100%'}
				width={'100%'}
				alignItems={'center'}
				py={5}
				spacing={5}>
				<Stack
					width={'100%'}
					alignItems={'center'}>
					<Typography variant='h4'>
						<Typography
							component={'span'}
							color={'text.secondary'}>
							{t('payment.money')}
						</Typography>
						{`ㅤ${numberWithCommasAnd원(totalToPay, kioskI18N)}`}
					</Typography>
				</Stack>
				<Grid2
					width={'100%'}
					container
					spacing={2}>
					<Grid2 xs={6}>
						<Button
							disabled={totalToPay === 0}
							onClick={() => selectPayment('CARD')}
							variant='outlined'
							sx={{ height: 140 }}
							fullWidth>
							<Stack spacing={1}>
								<Stack
									direction={'row'}
									alignItems={'center'}
									spacing={1}>
									<CreditCardOutlined />
									<Typography variant='subtitle1'>{t('payment.cardTitle')}</Typography>
								</Stack>
								<Typography
									variant='body2'
									color={'text.secondary'}>
									{`${t('card.debit')} / ${t('card.check')}`}
									<br />
									{t('card.SamsungPay')}
								</Typography>
							</Stack>
						</Button>
					</Grid2>
					{!isOnElectron && (
						<Grid2 xs={6}>
							<Button
								disabled={totalToPay === 0}
								onClick={() => selectPayment('VIRTUAL_CARD')}
								variant='outlined'
								sx={{ height: 140 }}
								fullWidth>
								<Stack spacing={1}>
									<Stack
										direction={'row'}
										alignItems={'center'}
										spacing={1}>
										<Typography variant='subtitle1'>가상카드 결제</Typography>
									</Stack>
								</Stack>
							</Button>
						</Grid2>
					)}
					{totalToPay === 0 && (
						<Grid2 xs={6}>
							<Button
								onClick={() => selectPayment('NO_PAYMENT')}
								variant='outlined'
								sx={{ height: 140 }}
								fullWidth>
								<Stack spacing={1}>
									<Stack
										direction={'row'}
										alignItems={'center'}
										spacing={1}>
										<Typography variant='subtitle1'>결제금액 없음</Typography>
									</Stack>
								</Stack>
							</Button>
						</Grid2>
					)}
				</Grid2>
			</Stack>
			<Stack
				width={'100%'}
				direction={'row'}
				py={3}
				spacing={3}>
				<Button
					variant='outlined'
					fullWidth
					onClick={closeDialog}>
					{t('common.return')}
				</Button>
			</Stack>
		</Stack>
	);
};
