import { Button, ButtonProps, Stack } from '@mui/material';

export const PrevNextButton = ({
	prevFn,
	nextFn,
	isEven = false,
	notUsingPrevBtn = false,
	nextDisabled = false,
	endIcon,
}: {
	prevFn?: () => void;
	nextFn: () => void;
	isEven?: boolean;
	notUsingPrevBtn?: boolean;
	nextDisabled?: boolean;
} & ButtonProps) => {
	const buttonSize: { prev: number; next: number } = {
		prev: isEven ? 1 : 0.2,
		next: isEven || notUsingPrevBtn ? 1 : 0.8,
	};
	return (
		<Stack
			direction={'row'}
			spacing={2}>
			{!notUsingPrevBtn && (
				<Button
					sx={{ flex: buttonSize.prev }}
					variant='outlined'
					onClick={prevFn}>
					{'이전'}
				</Button>
			)}
			<Button
				type={'submit'}
				endIcon={endIcon}
				disabled={nextDisabled}
				sx={{ flex: buttonSize.next }}
				variant='contained'
				onClick={(e) => {
					e.preventDefault();
					nextFn();
				}}>
				{'다음'}
			</Button>
		</Stack>
	);
};
