import { ArlimTalkInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { authState } from '../AuthState';
import { QueryClient } from '../QueryClient';

type ArlimTalkParamsType = {
	storeIdList?: number[];
	templateCode?: string;
	from: Dayjs;
	to: Dayjs;
};

const arlimTalkKeys = createQueryKeys('arlimTalk', {
	usage: ({ params }: { params: ArlimTalkParamsType }) => {
		const enterpriseInfo = useAtomValue(authState.enterpriseInfo);
		return {
			queryKey: [params],
			queryFn: async () => {
				const res = await ArlimTalkInfoApis.getArlimTalkUsageInfo({
					from: params.from,
					to: params.to,
					storeIdList: params.storeIdList,
					enterpriseId: enterpriseInfo?.id,
					templateCode: params.templateCode,
				});

				return res;
			},
		};
	},
});

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries(arlimTalkKeys._def);
};

export const AdminArlimTalkInfoState = {
	keys: arlimTalkKeys,
	invalidateQueries,
};
