import { CheckOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { Button, DialogActions, Stack, Typography } from '@mui/material';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';

export const KioskBoardItemSelectDialogAction = ({
	onClose,
	isValid,
	onApply,
}: {
	onClose?: () => void;
	isValid: boolean;
	onApply: () => void;
}) => {
	const { t } = useKioskTranslation();
	return (
		<DialogActions>
			<Stack
				width={'100%'}
				spacing={3}
				direction={'row'}>
				<Stack flex={2}>
					<Button
						style={{ borderWidth: 2, color: 'gray' }}
						fullWidth
						onClick={onClose}
						color={'inherit'}
						variant='outlined'>
						<Typography variant={'h6'}>{t('common.close')}</Typography>
					</Button>
				</Stack>
				<Stack flex={3}>
					<Button
						style={{ borderWidth: 2 }}
						onClick={onApply}
						fullWidth
						disabled={!isValid}
						variant='contained'
						startIcon={
							isValid ? (
								<ShoppingCartOutlined style={{ fontSize: '120%' }} />
							) : (
								<CheckOutlined style={{ fontSize: '120%' }} />
							)
						}>
						<Typography variant={'h6'}>
							{isValid ? t('cart.cartIn') : t('cart.pleaseSelect')}
						</Typography>
					</Button>
				</Stack>
			</Stack>
		</DialogActions>
	);
};
