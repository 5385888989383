import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CartLineOnlineTicketOnoffmixInfoType,
	OrderTypeWithDdizisAndExchanges,
} from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../const';
import { cancelOrderAsync } from './cancelOrder';
import { changePointUserMemberIdAsync } from './changePointUserMemberIdAsync';
import { downloadOrderExcel } from './downloadOrderExcel';
import { editOrderAsync } from './editOrderAsync';
import { getDetailAsync } from './getDetailAsync';
import { getListAsync } from './getListAsync';
import { getListInfiniteAsync } from './getListInfiniteAsync';
import { kyulsanOrderAsync } from './kyulsanOrderAsync';
import { postOrderAsync } from './postOrderAsync';
import { postponeOrderAsync } from './postponeOrder';
import { searchForDdiziAsync } from './searchForDdiziAsync';
import { deleteOrderMemberAsync, updateOrderMemberAsync } from './updateOrderMemberAsync';

// JIN : 24.03.30 앞으로 파일분리 안하도록 변경할게요
const cancelOnlineTicket =
	(rootUrl: string) =>
	async ({
		// 주체
		storeId,
		posId,
		orderId,
		onlineTicketCartLine,
	}: // 조회 대상
	{
		storeId: number;
		posId: number;
		orderId: string;
		onlineTicketCartLine: CartLineOnlineTicketOnoffmixInfoType;
	}) =>
		(
			await posAxiosClient.post<OrderTypeWithDdizisAndExchanges[]>(
				`${rootUrl}/order/store/${storeId}/pos/${posId}/online-ticket/cancel`,
				onlineTicketCartLine,
				{
					params: {
						orderId,
					},
				}
			)
		).data;

export const OrderAPI = {
	postOrder: postOrderAsync(rootUrl),
	postponeOrder: postponeOrderAsync(rootUrl),
	cancelOrder: cancelOrderAsync(rootUrl),
	editOrder: editOrderAsync(rootUrl),
	kyulsanOrder: kyulsanOrderAsync(rootUrl),
	changePointUserMemberId: changePointUserMemberIdAsync(rootUrl),
	getList: getListAsync(rootUrl),
	getListInfinite: getListInfiniteAsync(rootUrl),
	getDetail: getDetailAsync(rootUrl),
	searchForDdizi: searchForDdiziAsync(rootUrl),
	downloadExcel: downloadOrderExcel(rootUrl),
	cancelOnlineTicket: cancelOnlineTicket(rootUrl),
	updateOrderMember: updateOrderMemberAsync(rootUrl),
	deleteOrderMember: deleteOrderMemberAsync(rootUrl),
};
