import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	PageType,
	Pageable,
	VendorOrderReturnInfoType,
	VendorOrderType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getVendorOrderInfoListAsync =
	(rootUrl: string) =>
	async ({ warehouseId, vendorId, type, from, to, search, pageable }: GetVendorOrderRequestType) =>
		(
			await posAxiosClient.get<PageType<VendorOrderReturnInfoType>>(
				`${rootUrl}/vendor-order/search`,
				{
					params: {
						...pageable.getPageableQueryParam(),
						warehouseId,
						vendorId,
						search,
						type,
						from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
						to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
					},
				}
			)
		).data;

type GetVendorOrderRequestType = {
	warehouseId?: number;
	vendorId?: number;
	type: VendorOrderType;
	from: Dayjs;
	to: Dayjs;
	search?: string;
	pageable: Pageable;
};
