import { useQuery } from '@tanstack/react-query';
import { DeviceType } from '@kinderlabs-pos/shared-data-type';
import { DeviceTokenApis } from '@kinderlabs-pos/apis/storeinfo';
import { DeviceTokenAction } from './actions';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const deviceTokenKeys = createQueryKeys('deviceTokens', {
	detail: ({
		storeId,
		deviceId,
		deviceType,
	}: {
		storeId: number;
		deviceId: number;
		deviceType: DeviceType;
	}) => ({
		queryKey: [storeId, deviceId, deviceType],
		queryFn: async () => {
			const res = await DeviceTokenApis.getToken({ storeId, deviceId, deviceType });
			return res;
		},
	}),
});

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: deviceTokenKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: deviceTokenKeys._def });
};

export const DeviceTokenQueryState = {
	keys: deviceTokenKeys,
	invalidateQueries,
	action: DeviceTokenAction,
};
