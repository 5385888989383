import { Divider, Stack } from '@mui/material';
import { Suspense, useState } from 'react';
import { KioskLocalAdminFooter } from './KioskLocalAdminFooter';
import { KioskLocalAdminTab } from './KioskLocalAdminTab';
import { KioskPageHeader } from './KioskPageHeader';
import { KioskLabelPrinterTabPanel } from './tabs/KioskLabelPrinterTabPanel';
import { KioskPaymentModuleTabPanel } from './tabs/KioskPaymentModuleTabPanel';
import { KioskReceiptPrinterTabPanel } from './tabs/KioskReceiptPrinterTabPanel';
import { KioskSalesTabPanel } from './tabs/KioskSalesTabPanel';
import { KioskSettingTabPanel } from './tabs/KioskSettingTabPanel';
import { KioskSoftwareInfoPanel } from './tabs/KioskSoftwareInfoPanel';
import { KioskSoldoutPanel } from './tabs/KioskSoldoutPanel';
import { KioskAutomationPanel } from './tabs/KioskAutomationPanel';
import { KioskI18NPanel } from './tabs/KioskI18NPanel';

export const KioskLocalAdminPage = () => {
	const [tabValue, setTabValue] = useState(0);

	return (
		<Stack height={'100%'}>
			<KioskPageHeader title={'관리자 페이지'} />
			<Stack
				overflow={'auto'}
				flex={1}
				direction={'row'}>
				<Stack height={'100%'}>
					<KioskLocalAdminTab
						tabValue={tabValue}
						setTabValue={setTabValue}
					/>
				</Stack>
				<Stack
					component={'main'}
					overflow='auto'
					position={'relative'}
					flex={1}
					p={5}
					px={5}>
					<Suspense>
						{KioskLocalAdminTab.TypeIndex['SETTINGS'] === tabValue && <KioskSettingTabPanel />}
						{KioskLocalAdminTab.TypeIndex['SALES'] === tabValue && <KioskSalesTabPanel />}
						{KioskLocalAdminTab.TypeIndex['PAYMENT_MODULES'] === tabValue && (
							<KioskPaymentModuleTabPanel />
						)}
						{KioskLocalAdminTab.TypeIndex['LABEL_PRINTER'] === tabValue && (
							<KioskLabelPrinterTabPanel />
						)}
						{KioskLocalAdminTab.TypeIndex['RECEIPT_PRINTER'] === tabValue && (
							<KioskReceiptPrinterTabPanel />
						)}
						{KioskLocalAdminTab.TypeIndex['INFORMATION'] === tabValue && <KioskSoftwareInfoPanel />}
						{KioskLocalAdminTab.TypeIndex['AUTOMATION'] === tabValue && <KioskAutomationPanel />}
						{KioskLocalAdminTab.TypeIndex['SOLD_OUT'] === tabValue && <KioskSoldoutPanel />}
						{KioskLocalAdminTab.TypeIndex['I18N'] === tabValue && <KioskI18NPanel />}
					</Suspense>
				</Stack>
			</Stack>
			<Divider />
			<KioskLocalAdminFooter />
		</Stack>
	);
};
