import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VendorOrderReturnInfoType } from '@kinderlabs-pos/shared-data-type';

export const getVendorOrderDetailInfoAsync = (rootUrl: string) => async (orderId: number) =>
	(
		await posAxiosClient.get<VendorOrderReturnInfoType>(`${rootUrl}/vendor-order`, {
			params: {
				id: orderId,
			},
		})
	).data;
