import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';
import isToday from 'dayjs/plugin/isToday';
import dayjs from 'dayjs';
dayjs.extend(isToday);

const getRecentCall = (guestVisit: GuestVisitType) => {
	if (!guestVisit.calls || guestVisit.calls.length === 0) return undefined;
	else return guestVisit.calls[guestVisit.calls.length - 1];
};

const sortOrderByCallTimeDesc = (guestVisitList: GuestVisitType[]) => {
	return guestVisitList.sort((before, after) => {
		const beforeLastcall = getRecentCall(before);
		const afterLastcall = getRecentCall(after);

		// after 에는 호출이 있고 before 에는 호출이 없으면 sort
		if (!afterLastcall) return -1;
		else if (!beforeLastcall) return 1;

		// 내림차순
		return beforeLastcall.calledDateTime < afterLastcall.calledDateTime ? 1 : -1;
	});
};

const sortOrderBySunbunDesc = (guestVisitList: GuestVisitType[]) => {
	return guestVisitList.sort((before, after) => {
		return before.sunbun > after.sunbun ? 1 : -1;
	});
};

export const GuestVisitUtils = {
	getMainUser: (guestVisit?: GuestVisitType) => {
		return guestVisit ? guestVisit.users[0] : undefined;
	},
	canReturnToWaiting: (guestVisit: GuestVisitType) =>
		['COMPLETED', 'CANCELED', 'POSTPONED'].includes(guestVisit.status) &&
		dayjs(guestVisit.status).isToday(),
	canRerun: (guestVisit: GuestVisitType) =>
		['WAITING', 'CANCELED', 'POSTPONED', 'COMPLETED'].includes(guestVisit.status) &&
		dayjs(guestVisit.status).isToday(),
	canCancel: (guestVisit: GuestVisitType) =>
		['WAITING', 'PROCESSING', 'POSTPONED'].includes(guestVisit.status) &&
		dayjs(guestVisit.status).isToday(),
	getRecentCall: getRecentCall,
	sortOrderByCallTimeDesc,
	sortOrderBySunbunDesc,
};
