import { Theme, ThemeProvider } from '@mui/material';
import React, { ReactNode } from 'react';

const ColorModeContext = React.createContext<{ changeColorMode: (mode: 'light' | 'dark') => void }>(
	{
		changeColorMode: (mode: 'light' | 'dark') => {
			//empty
		},
	}
);

export const ThemeProviderWithColorMode = ({
	theme,
	children,
}: {
	theme: (mode: 'light' | 'dark') => Theme;
	children: ReactNode;
}) => {
	const [mode, setMode] = React.useState<'light' | 'dark'>('light');
	const colorMode = React.useMemo(
		() => ({
			changeColorMode: (mode: 'light' | 'dark') => {
				setMode(mode);
			},
		}),
		[]
	);

	const themeToRender = React.useMemo(() => theme(mode), [theme, mode]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={themeToRender}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	);
};

ThemeProviderWithColorMode.useChangeColor = () => {
	const context = React.useContext(ColorModeContext);

	return context.changeColorMode;
};

// 임시? 혹은 추후 바꾸기
ThemeProviderWithColorMode.getColorFromEnterpriseId = (enterpriseId?: string): 'light' | 'dark' => {
	if (enterpriseId === 'vaunce') return 'dark';
	else if (enterpriseId === 'zoolung') return 'light';

	return 'light';
};
