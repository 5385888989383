import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { b2CMainThemeOption } from './b2cMainThemeOption';
import { b2cDarkPaletteOptions, b2cPaletteOptions } from './b2cPaletteOptions';
import { waiverComponentsOverride } from './waiverComponentOverrides';

export const mobileCheckerTheme = createTheme(
	deepmerge(b2CMainThemeOption, {
		components: waiverComponentsOverride,
	})
);

export const getMobileCheckerTheme = (mode: 'light' | 'dark') =>
	createTheme(
		deepmerge(b2CMainThemeOption, {
			palette:
				mode === 'dark'
					? { ...b2cDarkPaletteOptions, mode: 'dark' }
					: { ...b2cPaletteOptions, mode: 'light' },
			components: waiverComponentsOverride,
		})
	);
