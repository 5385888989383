import { AdminKioskInfoApis, KioskBoardInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { KioskLocalSettingsType } from '@kinderlabs-pos/ipc/common';
import { KioskInfoTypeRule } from '@kinderlabs-pos/shared-data-type';
import { KioskElectronStoreState, StoreInfoState, authState } from '@kinderlabs-pos/state';
import { useAtomValue, useSetAtom } from 'jotai';

type LocalKioskInfo = {
	brandId: number;
	storeId: number;
	kioskId: number;
};
const useLoadKioskInfoFromAuthStore = () => {
	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const kioskInfo = localSettings?.kioskInfo;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();
	const saveKioskInfo = (kioskInfo?: LocalKioskInfo) => {
		if (localSettings) saveLocalSettingsMutation.mutate({ ...localSettings, kioskInfo });
	};

	return () =>
		_loadKioskInfoWithVerify({
			kioskInfo,
			saveKioskInfo,
		});
};

const _loadKioskInfoWithVerify = async ({
	kioskInfo,
	saveKioskInfo,
}: {
	kioskInfo?: LocalKioskInfo;
	saveKioskInfo: (args?: LocalKioskInfo) => void;
}) => {
	try {
		console.log('kioskInfo 는 로드되었나?', kioskInfo);

		if (kioskInfo) {
			const kioskListToVerify = await AdminKioskInfoApis.getKioskInfoList({
				storeId: kioskInfo.storeId,
			});

			const kioskBoardListToVerify = await KioskBoardInfoApis.getKioskBoardInfoList({
				storeId: kioskInfo.storeId,
			});

			const kioskVerified = kioskListToVerify.find(
				(kioskToVerify) => kioskToVerify.id === kioskInfo.kioskId
			);

			const kioskBoardVerified =
				kioskVerified?.type && !KioskInfoTypeRule.hasBoard[kioskVerified.type]
					? true
					: kioskBoardListToVerify.some(
							(kioskBoardToVerify) => kioskBoardToVerify.id === kioskVerified?.kioskBoardId
					  );

			console.log('kioskInfo 는 verify 되었나?', kioskVerified, kioskBoardVerified);

			if (!kioskVerified || !kioskBoardVerified) {
				throw new Error('[DEV] KIOSK INFO 초기화가 필요하다.');
			}
		}

		return kioskInfo;
	} catch {
		console.log('kioskInfo 는 verify 되지 않았다.');

		saveKioskInfo(undefined);
		return undefined;
	}
};

const useSelectKiosk = () => {
	const deviceLogin = useSetAtom(StoreInfoState.deviceLoginInfo);
	const enterpriseInfo = useAtomValue(authState.enterpriseInfo);

	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();

	return (kioskSelectInfo: KioskLocalSettingsType['kioskInfo']) => {
		if (!kioskSelectInfo || !enterpriseInfo) return;

		if (localSettings)
			saveLocalSettingsMutation.mutate({ ...localSettings, kioskInfo: kioskSelectInfo });

		deviceLogin({
			deviceType: 'KIOSK',
			storeId: kioskSelectInfo?.storeId,
			deviceId: kioskSelectInfo?.kioskId,
			enterpriseId: enterpriseInfo.id,
		});
	};
};

/**
 * StoreInfoState 와 엮어서 사용하는 state 이므로 StoreInfoState 를 반드시 참고하자.
 * 반드시 KIOSK 에서만 사용하자.
 */
export const KioskSelectionState = {
	useLoadKioskInfoFromAuthStore,
	useSelectKiosk,
};
