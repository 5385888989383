import { ReceiptInfoType, VanCompanyType } from '@kinderlabs-pos/shared-data-type';
import { useHandle환불_결제모듈 } from './handleRefundByPM';

export type HandleRefundArgsType =
	| { method: 'CASH_IC' | 'CARD' | 'CARD_MS'; receipt: ReceiptInfoType }
	| {
			method: 'CASH_현금영수증_발급' | 'TRANSFER_현금영수증_발급';
			receipt: ReceiptInfoType;
			customerId: string;
			isSignPadUsed: boolean;
	  }
	| {
			method:
				| 'VIRTUAL_CARD'
				| 'CASH_현금영수증_미발급'
				| 'TRANSFER_현금영수증_미발급'
				| 'INADVANCE'
				| 'POINT';
	  }
	| {
			method: 'ZEROPAY' | 'SEOULPAY' | 'KAKAOPAY' | 'SSGPAY' | 'APPCARD';
			customerId: string;
			receipt: ReceiptInfoType;
	  };

export const useHandleRefund = (vanCompany: VanCompanyType) => {
	const handle환불_결제모듈 = useHandle환불_결제모듈();
	return async (payload: HandleRefundArgsType): Promise<ReceiptInfoType | undefined> => {
		switch (payload.method) {
			case 'CARD':
			case 'CARD_MS':
			case 'CASH_IC':
				const 카드결제환불모듈처리Data = await handle환불_결제모듈(payload, vanCompany);

				return {
					...카드결제환불모듈처리Data,
					receiptType: 'REFUND',
				};

			case 'CASH_현금영수증_발급':
			case 'TRANSFER_현금영수증_발급':
				const 현금영수증_취소Data = await handle환불_결제모듈(payload, vanCompany);

				return {
					...현금영수증_취소Data,
					receiptType: 'REFUND',
				};
			case 'KAKAOPAY':
			case 'SSGPAY':
			case 'APPCARD':
			case 'ZEROPAY':
			case 'SEOULPAY':
				const 환편_결제모듈처리Data = await handle환불_결제모듈(payload, vanCompany);

				return {
					...환편_결제모듈처리Data,
					receiptType: 'REFUND',
				};
			default:
				return undefined;
		}
	};
};
