export const getI18nString = ({
	ko,
	i18nString,
	i18n,
}: {
	ko?: string;
	i18nString?: { eng?: string; jpn?: string; chn?: string };
	i18n: 'ko' | 'eng' | 'jpn' | 'chn';
}) => {
	if (i18n === 'ko') return ko;
	else {
		return i18nString ? i18nString[i18n] : undefined;
	}
};
