import { rootUrl } from '../../const';
import { createProductDiscountInfoAsync } from './createProductDiscountInfoAsync';
import { deleteProductDiscountInfoAsync } from './deleteProductDiscountInfoAsync';
import { disableProductDiscountInfoAsync } from './disableProductDiscountInfoAsync';
import { getAllProductDiscountInfoListAsync } from './getAllProductDiscountInfoListAsync';
import { getProductDiscountDetailInfoAsync } from './getProductDIscountDetailInfoAsync';
import { getProductDiscountHistoryAsync } from './getProductDiscountHistoryAsync';
import { getProductDiscountInfoListAsync } from './getProductDiscountInfoListAsync';
import { updateProductDiscountInfoAsync } from './updateProductDiscountInfoAsync';

export const AdminProductDiscountInfoApis = {
	createProductDiscountInfo: createProductDiscountInfoAsync(rootUrl),
	getProductDiscountInfo: getProductDiscountDetailInfoAsync(rootUrl),
	updateProductDiscountInfo: updateProductDiscountInfoAsync(rootUrl),
	disableProductDiscountInfo: disableProductDiscountInfoAsync(rootUrl),
	deleteProductDiscountInfo: deleteProductDiscountInfoAsync(rootUrl),
	getProductDiscountInfoList: getProductDiscountInfoListAsync(rootUrl),
	getProductDiscountHistoryInfo: getProductDiscountHistoryAsync(rootUrl),
	getAllProductDiscountInfoList: getAllProductDiscountInfoListAsync(rootUrl),
};
