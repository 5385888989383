import { CartLineInfoType } from '@kinderlabs-pos/shared-data-type';
import { getI18nString, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { Divider, ListItem, Stack, Typography } from '@mui/material';
import { Fragment } from 'react';
import { useKioskTranslation } from '../../../../const/useKioskTranslation';

export const KioskCartSimpleItem = ({
	cartLine,
	optionsCartLines,
}: {
	cartLine: CartLineInfoType;
	optionsCartLines: CartLineInfoType[];
}) => {
	const { kioskI18N } = useKioskTranslation();

	return (
		<>
			<ListItem>
				<Stack
					width={'100%'}
					direction={'row'}
					alignItems={'center'}
					spacing={1}>
					<Stack
						flex={1}
						alignItems={'flex-start'}>
						<Typography variant='h6'>{` ${getI18nString({
							ko: cartLine.name,
							i18nString: cartLine.i18n,
							i18n: kioskI18N,
						})} `}</Typography>
						<Typography
							variant='subtitle1'
							color={'text.secondary'}>{`${numberWithCommasAnd원(
							cartLine.price,
							kioskI18N
						)} `}</Typography>
					</Stack>
					<Typography
						variant='subtitle1'
						color={'text.secondary'}
						sx={{ pr: 2 }}>{`x ${cartLine.quantity} `}</Typography>
					<Typography
						variant='h5'
						sx={{ textAlign: 'right', minWidth: 180 }}>
						{`${numberWithCommasAnd원(cartLine.quantity * cartLine.price, kioskI18N)} `}
					</Typography>
				</Stack>
			</ListItem>
			<Divider />
			{optionsCartLines.map((optionCartline, idx) => (
				<Fragment key={idx}>
					<ListItem>
						<Typography variant='h5'>{`└ㅤ`}</Typography>
						<Stack
							flex={1}
							alignItems={'flex-start'}>
							<Typography variant='subtitle1'>{`${getI18nString({
								ko: optionCartline.name,
								i18nString: optionCartline.i18n,
								i18n: kioskI18N,
							})} `}</Typography>
							<Typography
								variant='body1'
								color={'text.secondary'}>{`${numberWithCommasAnd원(
								optionCartline.price,
								kioskI18N
							)} `}</Typography>
						</Stack>
						<Typography
							variant='body1'
							color={'text.secondary'}
							sx={{ pr: 2 }}>{`x ${optionCartline.quantity} `}</Typography>
						<Typography
							variant='subtitle1'
							sx={{ textAlign: 'right', minWidth: 180 }}>
							{`${numberWithCommasAnd원(
								optionCartline.quantity * optionCartline.price,
								kioskI18N
							)} `}
						</Typography>
					</ListItem>
					<Divider />
				</Fragment>
			))}
		</>
	);
};
