import {
	AvailableBarcodeModelType,
	CashPaymentInfoType,
	CustomerReceiptRequestType,
	DeviceType,
	hardwareInfoType,
	has공식현금영수증,
	has비공식영수증,
	PaymentInfoType,
	PaymentMethodTypeLabel,
	ReceiptInfoType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { OrderState, OrderStateType } from '../PosOrder/OrderState';
import { KioskElectronStoreState, PosElectronStoreState } from '@kinderlabs-pos/state';

const getCustomerReceiptData = ({
	storeInfo,
	order,
	payments,
	receiptType,
	is간이영수증 = false,
	deviceName,
	barcodeModel,
}: {
	storeInfo: StoreInfoType;
	order: OrderStateType;
	payments: PaymentInfoType[];
	receiptType: 'ORDER' | 'CANCEL';
	is간이영수증?: boolean;
	deviceName: string;
	barcodeModel: AvailableBarcodeModelType;
}) => {
	if (!order.id || !order.dailySeq) throw Error('[DEV] 주문번호가 필요합니다.');

	const aggregate = OrderState.cart.utils.getAggregate(order.cart);

	return {
		receipt: {
			paymentId: `${order.id}`,
			receiptType,
			orderDate: order.completed || new Date(),
			cancelDate: order.canceled,
		},
		company: {
			name: storeInfo.officialName,
			number: storeInfo.representative.number, // 사업자등록번호
			representative: storeInfo.representative.name,
			address: storeInfo.address.detail,
			contact: storeInfo.telephone,
		},
		order: !is간이영수증
			? OrderState.cart.utils.getOrderListFromCart(order.cart.lines, 'default')
			: [],
		aggregate: {
			total: aggregate.totalSum,
			discount: aggregate.totalDiscount,
			actual: aggregate.totalToPay,
			totalTax: aggregate.totalTax,
		},
		payments: payments.map((paymentInfo) => getReceiptOption({ paymentInfo: paymentInfo })),
		others: {
			greetings: storeInfo.greetings,
		},
		orderId: order.id,
		orderDailySeq: order.dailySeq,
		barcodeType: barcodeModel,
		deviceName,
	};
};

const getOfficialPaymentReceiptData = ({
	payment,
	receipt,
	paymentType,
}: {
	payment: PaymentInfoType;
	receipt: ReceiptInfoType;
	paymentType: 'BUY' | 'REFUND';
}): CustomerReceiptRequestType['payments'][number] => {
	return {
		amount: receipt.supply + receipt.tax,
		method: PaymentMethodTypeLabel[payment.method],
		approvalCompany: receipt.issuer,
		bin: receipt.bin,
		installment: receipt.installment,
		approvalId: receipt.approvalId,
		type: paymentType,
		receivedMoney:
			payment.method === 'CASH' ? (payment as CashPaymentInfoType).receivedMoney : undefined,
	};
};

const getUnofficialPaymentReceiptData = ({
	payment,
	paymentType,
}: {
	payment: PaymentInfoType;
	paymentType: 'BUY' | 'REFUND';
}): CustomerReceiptRequestType['payments'][number] => {
	return {
		amount: payment.amount,
		method: PaymentMethodTypeLabel[payment.method],
		approvalCompany: PaymentMethodTypeLabel[payment.method],
		bin: '',
		installment: 0,
		approvalId: '',
		type: paymentType,
		receivedMoney:
			payment.method === 'CASH' ? (payment as CashPaymentInfoType).receivedMoney : undefined,
	};
};

const getReceiptOption = ({
	paymentInfo,
}: {
	paymentInfo: PaymentInfoType;
}): CustomerReceiptRequestType['payments'][number] => {
	// 현금 영수증이 있는 경우
	if (has공식현금영수증[paymentInfo.method] && paymentInfo.receipt) {
		return getOfficialPaymentReceiptData({
			payment: paymentInfo,
			receipt: paymentInfo.receipt,
			paymentType: !paymentInfo.refunded ? 'BUY' : 'REFUND',
		});
	} else if (has비공식영수증[paymentInfo.method]) {
		return getUnofficialPaymentReceiptData({
			payment: paymentInfo,
			paymentType: !paymentInfo.refunded ? 'BUY' : 'REFUND',
		});
	} else {
		if (!paymentInfo.receipt) throw Error('[DEV] 비공식 영수증 아님');
		return getOfficialPaymentReceiptData({
			payment: paymentInfo,
			receipt: paymentInfo.receipt,
			paymentType: !paymentInfo.refunded ? 'BUY' : 'REFUND',
		});
	}
};

export const PrintReceiptUtils = {
	getCustomerReceiptData,
};
