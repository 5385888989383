import { DdiziInfoType, DdiziType, DdiziTypeToJungsan } from '@kinderlabs-pos/shared-data-type';
import dayjs from 'dayjs';

const _getLeftMinute = (timeStandard: Date, ddizi: DdiziType) =>
	ddizi.expiration ? -dayjs(timeStandard).diff(ddizi.expiration, 'minutes') : null;
const _getOverMinute = (leftMinute: number, overTimeUnit: number) =>
	leftMinute > 0 ? 0 : Math.floor(-leftMinute / overTimeUnit) * overTimeUnit;

const _getAfterExitMinute = (timeStandard: Date, ddizi: DdiziType) =>
	ddizi.exited ? dayjs(timeStandard).diff(ddizi.exited, 'minutes') : null;
const _getAfterExitCountMinute = (overMinute: number, overTimeUnit: number) =>
	Math.floor(overMinute / overTimeUnit) * overTimeUnit;

/**
 * Ddizi To Jungsan 정보를 계산해내는 로직
 */
const getDdiziToJungsan = ({
	ddizi,
	timeStandard,
	overtimePolicy,
}: {
	ddizi: DdiziType;
	timeStandard: Date;
	overtimePolicy: number;
}): DdiziTypeToJungsan => {
	const leftMinute = _getLeftMinute(timeStandard, ddizi);
	const overMinute = leftMinute ? _getOverMinute(leftMinute, overtimePolicy) : 0;

	const afterExitMinute = _getAfterExitMinute(timeStandard, ddizi);
	const afterExitCountMinute = afterExitMinute
		? _getAfterExitCountMinute(afterExitMinute, overtimePolicy)
		: 0;

	const overTimeFeeMultiplier =
		ddizi.status !== 'EXITED'
			? !ddizi.hasNoAdditionalCharge && overMinute
				? Math.floor(overMinute / overtimePolicy)
				: 0
			: !ddizi.hasNoAdditionalCharge && afterExitCountMinute
			? Math.floor(afterExitCountMinute / overtimePolicy)
			: 0;

	// const amount =
	// 	ddizi.status !== 'EXITED'
	// 		? !ddizi.hasNoAdditionalCharge && overMinute
	// 			? _getAdditionalPay(overMinute, overtimePolicy, overtimeFeePolicy) * ddizi.multiple
	// 			: 0
	// 		: !ddizi.hasNoAdditionalCharge && afterExitCountMinute
	// 		? (_getAdditionalPay(afterExitCountMinute, overtimePolicy, overtimeFeePolicy) -
	// 				overtimeFeePolicy) *
	// 		  ddizi.multiple
	// 		: 0;

	return {
		...ddizi,
		leftMinute,
		overMinute,
		overMinuteAfterExit: ddizi.status === 'EXITED' ? afterExitCountMinute : undefined,
		overTimeFeeMultiplier,
		// amount,
	};
};

const isDdiziSelectableForJungsan = (ddizi: DdiziTypeToJungsan): boolean => {
	if (ddizi.status === 'CANCELED' || ddizi.status === 'NOTJUNGSAN') return false;
	else if (ddizi.status === 'EXITED' && ddizi.overTimeFeeMultiplier === 0) return false;
	else if (!dayjs(ddizi.publishInfo.published).isToday()) return false;
	return true;
};

export const DdiziUtils = {
	getDdiziToJungsan,
	isDdiziSelectableForJungsan,
	getDdizi인쇄Data: (
		ddizi: DdiziType
	): {
		message: {
			labelType: DdiziInfoType['ddiziType'];
			labelId: string;
			labelName: string;
			timeEnter: Date;
			timeExit: Date;
			timeUse: string;
		};
		deviceNum: 'LabelPrinter1' | 'LabelPrinter2';
	} => ({
		message: {
			labelType: ddizi.type,
			labelId: ddizi.id,
			labelName:
				ddizi.type === 'GROUP_TICKET' ? `${ddizi.name} (${ddizi.multiple} 명)` : ddizi.name,
			timeEnter: new Date(ddizi.started || ddizi.publishInfo.published),
			timeExit: ddizi.expiration
				? dayjs(ddizi.expiration).toDate()
				: dayjs(new Date(ddizi.publishInfo.published)).add(ddizi.minutes, 'minutes').toDate(),
			timeUse: `${ddizi.minutes / 60} 시간`,
		},
		deviceNum: ddizi.type === 'NORMAL' ? 'LabelPrinter1' : 'LabelPrinter2',
	}),
};
