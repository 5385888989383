import { StatisticsApis } from '@kinderlabs-pos/apis/storeinfo';
import {
	CategorySearchSpec,
	DayCalendarFilterOptionType,
	DdiziInfoType,
	GuestMemberStatisticsFilterType,
	GuestVisitDateType,
	MonthCalendarFilterOptionType,
	Pageable,
	getBaseCategoryListForAPI,
} from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Dayjs } from 'dayjs';
import { AdminCategoryInfoState } from '../Admin/AdminCategoryState';
import { StatisticsUtils } from './StatisticsUtils';

const statisticsKeys = createQueryKeys('statistics', {
	gv: ({ params }: { params: GuestVisitInfoParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.GuestVisit.get이용객입퇴장(params);
		},
	}),
	gv일별체류시간: ({ params }: { params: GuestVisit체류시간ParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.GuestVisit.get일별체류시간(params);
		},
	}),
	gvDetail: ({ params }: { params: GuestVisitDetailInfoParam }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.getDetailGuestVisit(params);
		},
	}),
	salesByPaymentMethodForStore: ({ params }: { params: SalesByPaymentMethodParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.Payment.get매장별PaymentMethod(params);
		},
	}),
	salesByPeriodForDate: ({ params }: { params: SalesByPeriodParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const commonParams = {
				storeIdList: params.storeIdList,
				posIdList: params.posIdList,
			};

			switch (params.dateType) {
				case 'MONTH':
					return await StatisticsApis.Payment.get월별PaymentMethod({
						...commonParams,
						fromMonth: params.fromMonth,
						toMonth: params.toMonth,
					});
				case 'DAY':
				default:
					return await StatisticsApis.Payment.get일자별PaymentMethod({
						...commonParams,
						from: params.from,
						to: params.to,
					});
			}
		},
	}),
	salesByTime: ({ params }: { params: SalesByTimeParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const commonParams = {
				storeIdList: params.storeIdList,
				posIdList: params.posIdList,
			};

			return await StatisticsApis.Payment.get일자별시간대별({
				...commonParams,
				from: params.from,
				to: params.to,
			});
		},
	}),
	salesByCardCompany: ({ params }: { params: SalesByCardComParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			// return await StatisticsApis.CardCompany.get카드사별일자별(params);
			const commonParams = {
				storeIdList: params.storeIdList,
				posIdList: params.posIdList,
			};
			switch (params.dateType) {
				case 'MONTH':
					return await StatisticsApis.CardCompany.get카드사별월별({
						...commonParams,
						fromMonth: params.fromMonth,
						toMonth: params.toMonth,
					});
				case 'DAY':
				default:
					return await StatisticsApis.CardCompany.get카드사별일자별({
						...commonParams,
						from: params.from,
						to: params.to,
					});
			}
		},
	}),
	salesByProduct: ({
		params,
	}: {
		params: 매출통계시간대별RequestParam & {
			storeIdList?: number[];
			posIdList?: number[];
			isWithTargetCartLine: boolean;
			categorySpec: CategorySearchSpec;
			search?: string;
		};
	}) => {
		const categoryTree = AdminCategoryInfoState.useCategoryTreeForSearch();
		const category = getBaseCategoryListForAPI({
			spec: params.categorySpec,
			categoryTree,
		}).map((c) => `${c.id}`);

		return {
			queryKey: [params],
			queryFn: async () => {
				const commonParams = {
					storeIdList: params.storeIdList,
					posIdList: params.posIdList,
					isWithTargetCartLine: params.isWithTargetCartLine,
					search: params.search,
				};
				switch (params.dateType) {
					case 'MONTH':
						return await StatisticsApis.Product.get상품월별매출통계({
							...commonParams,
							category,
							fromMonth: params.fromMonth,
							toMonth: params.toMonth,
						});
					case 'TIME':
						return await StatisticsApis.Product.get상품시간대별매출통계({
							...commonParams,
							category,
							from: params.from,
							to: params.to,
						});
					case 'DAY':
					default:
						return await StatisticsApis.Product.get상품일자별매출통계({
							...commonParams,
							category,
							from: params.from,
							to: params.to,
						});
				}
			},
		};
	},
	salesByCategoryForDate: ({ params }: { params: SalesByCategoryForDateParamType }) => {
		const categoryTree = AdminCategoryInfoState.useCategoryTreeForSearch();
		const category = getBaseCategoryListForAPI({
			spec: params.categorySpec,
			categoryTree,
		}).map((c) => `${c.id}`);

		return {
			queryKey: [params],
			queryFn: async () => {
				if (params.dateType === 'MONTH')
					return await StatisticsApis.Product.get카테고리월별매출통계({ ...params, category });
				else return await StatisticsApis.Product.get카테고리일자별매출통계({ ...params, category });
			},
		};
	},
	salesCalendarForDate: ({ params }: { params: SalesCalendarForDateParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.Payment.get일자별캘린더환불(params);
		},
	}),
	salesTotalSumByGVForStore: ({ params }: { params: SalesSumByGVForStoreParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.GuestVisit.get매장별고객방문매출요약(params);
		},
	}),
	salesByCategoryForStore: ({ params }: { params: SalesByCategoryForStoreParamType }) => {
		const categoryTree = AdminCategoryInfoState.useCategoryTreeForSearch();
		const category = getBaseCategoryListForAPI({
			spec: params.categorySpec,
			categoryTree,
		}).map((c) => `${c.id}`);

		return {
			queryKey: [params],
			queryFn: async () => {
				return await StatisticsApis.Product.get카테고리별매장별매출통계({ ...params, category });
			},
		};
	},
	전년도대비매출증감: (params: 전년도대비매출증감Params) => {
		const categoryTree = AdminCategoryInfoState.useCategoryTreeForSearch();
		const category = getBaseCategoryListForAPI({
			spec: params.categorySpec,
			categoryTree,
		}).map((c) => `${c.id}`);
		return {
			queryKey: [params],
			queryFn: async () => {
				return await StatisticsApis.Product.get전년도대비매출증감통계({ ...params, category });
			},
		};
	},

	salesByExternalForStore: ({ params }: { params: SalesFromExternalForStoreParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.get매장별외부매출(params);
		},
	}),
	paymentDetailInfoForStore: ({ params }: { params: PaymentDetailInfoForStoreParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.get매장별결제내용참고(params);
		},
	}),
	salesByOLTForStore: ({ params }: { params: SalesByOTForStoreParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.get매장별OnlineTicketStatistics(params);
		},
	}),
	salesByOLTChannel: ({ params }: { params: SalesByOLTChannelParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.get체널별OnlineTicketStatistics(params);
		},
	}),
	salesByT2TByGVForStore: ({ params }: { params: SalesByT2TByGVForStoreParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.get매장별이용객입퇴장매출Statistics(params);
		},
	}),
	curStatusGVForStore: ({ params }: { params: CurStatusGVForStoreParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.GuestVisit.get매장고객대기현황(params);
		},
	}),
	vendorOrder: ({ params }: { params: VendorOrderParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.get거래처별통계현황(params);
		},
	}),
	stockSubul: ({ params }: { params: StockSubulParamType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.get재고수불현황(params);
		},
	}),
	guestMember: ({ params }: { params: GuestMemberStatisticsFilterType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await StatisticsApis.GuestVisit.get고객통계({
				storeIdList: params.storeIdList,
				ageGroupList: params.ageGroupList,
				gender: params.gender,
				tierList: params.tierList,
				fromMonth: params.calendarOption.fromMonth,
				toMonth: params.calendarOption.toMonth,
				indicator: params.indicator,
			});
		},
	}),
});

export const StatisticsState = {
	keys: statisticsKeys,
	utils: StatisticsUtils,
};

export type ProductDateType = 'TIME' | 'DAY' | 'MONTH';

type GuestVisitInfoParamType = {
	storeIdList?: number[];
	ddiziType?: DdiziInfoType['ddiziType'];
	from: Dayjs;
	to: Dayjs;
	fromTime: number;
	toTime: number;
	dateType: GuestVisitDateType;
};

type GuestVisit체류시간ParamType = {
	storeIdList?: number[];
	ddiziType?: DdiziInfoType['ddiziType'];
	from: Dayjs;
	to: Dayjs;
	dateType: GuestVisitDateType;
};

type GuestVisitDetailInfoParam = Pick<GuestVisitInfoParamType, 'storeIdList' | 'from' | 'to'> & {
	sort?: Pageable['sort'];
};

type SalesByStoreParamType = {
	storeId?: number;
	from: Dayjs;
	to: Dayjs;
	sort?: Pageable['sort'];
};

type 매출통계시간대별RequestParam =
	| ({ dateType: 'DAY' | 'TIME' } & DayCalendarFilterOptionType)
	| ({ dateType: 'MONTH' } & MonthCalendarFilterOptionType);

type SalesByPaymentMethodParamType = {
	from: Dayjs;
	to: Dayjs;
	storeIdList?: number[];
	posIdList?: number[];
	search?: string;
};

type SalesByPeriodParamType = {
	storeIdList?: number[];
	posIdList?: number[];
} & (
	| ({ dateType: 'DAY' } & DayCalendarFilterOptionType)
	| ({ dateType: 'MONTH' } & MonthCalendarFilterOptionType)
);

type SalesByTimeParamType = {
	storeIdList?: number[];
	posIdList?: number[];
} & ({ dateType: 'TIME' } & DayCalendarFilterOptionType);

type SalesByCardComParamType = {
	storeIdList?: number[];
	posIdList?: number[];
} & (
	| ({ dateType: 'DAY' } & DayCalendarFilterOptionType)
	| ({ dateType: 'MONTH' } & MonthCalendarFilterOptionType)
);

type SalesByCategoryForDateParamType = {
	storeIdList?: number[];
	posIdList?: number[];
	isWithTargetCartLine: boolean;
	categorySpec: CategorySearchSpec;
	search?: string;
} & (
	| ({
			dateType: 'DAY';
	  } & DayCalendarFilterOptionType)
	| ({ dateType: 'MONTH' } & MonthCalendarFilterOptionType)
);

type 전년도대비매출증감Params = {
	storeIdList?: number[];
	posIdList?: number[];
	categorySpec: CategorySearchSpec;
} & MonthCalendarFilterOptionType;

type SalesCalendarForDateParamType = {
	from: Dayjs;
	to: Dayjs;
	storeIdList?: number[];
	posIdList?: number[];
};

type SalesSumByGVForStoreParamType = {
	from: Dayjs;
	to: Dayjs;
	storeIdList?: number[];
};

type SalesByCategoryForStoreParamType = {
	from: Dayjs;
	to: Dayjs;
	storeIdList?: number[];
	dateType: ProductDateType;
	isWithTargetCartLine: boolean;
	categorySpec: CategorySearchSpec;
	search?: string;
	posIdList?: number[];
};

type SalesFromExternalForStoreParamType = {
	date: Dayjs;
	storeIdList?: number[];
};

type PaymentDetailInfoForStoreParamType = {
	date: Dayjs;
	fromTime: number;
	toTime: number;
	storeIdList?: number[];
};

type SalesByOTForStoreParamType = {
	storeId?: number;
	storeIdList?: number[];
	posIdList?: number[];
	from: Dayjs;
	to: Dayjs;
};

type SalesByOLTChannelParamType = {
	storeId?: number;
	storeIdList?: number[];
	posIdList?: number[];
	channelList?: string[];
	from: Dayjs;
	to: Dayjs;
};

type SalesByT2TByGVForStoreParamType = {
	storeIdList?: number[];
	date: Dayjs;
	fromTime: number;
	toTime: number;
};

type CurStatusGVForStoreParamType = {
	storeIdList?: number[];
	date: Dayjs;
};

type VendorOrderParamType = {
	from: Dayjs;
	to: Dayjs;
	vendorId?: number;
	warehouseId?: number;
};

type StockSubulParamType = {
	from: Dayjs;
	to: Dayjs;
	warehouseId?: number;
};
