import { TermsConditionsType } from '.';

export const 시설이용전안내사항List: TermsConditionsType[] = [
	{
		title: '시설 이용 전 안내사항',
		infos: [
			{
				title:
					'바운스 내 모든 활동은 부상의 위험이 있으므로 직원의 안내와 지시 사항을 준수해 주시기 바랍니다. 이용·안전수칙에 관련된 직원의 지시에 불응할 시 퇴장 조치될 수 있습니다.',
			},
			{
				title: '입장 전 바운스 안전 수칙을 숙지해 주시기 바랍니다.',
			},
			{
				title: '입장 전 준비운동을 철저히 해주시기 바랍니다.',
			},
			{
				title:
					'놀이 시설물 안으로 음식물 및 음료 반입을 금지하고, 껌이나 음식물을 입에 넣은 채로 시설을 이용하지 않습니다.',
			},
			{
				title:
					'트램폴린 전용 미끄럼방지 양말을 착용하지 않은 고객님 또는 보호자는 놀이시설 밖에서 촬영을 진행해 주시기 바랍니다.(단 시설 이용을 원하는 고객님의 경우 이용권까지 구매 후 이용)',
			},
			{
				title:
					'개인물건은 락커에 보관해 주시기 바랍니다. 고객의 부주의로 인한 물건의 분실·도난의 책임은 고객 본인이 부담합니다.',
			},
			{
				title: '다음 사항에 해당되시는 고객께서는 시설 이용이 제한됩니다.',
				descs: [
					{ label: '임산부  및 만 60세 이상 어르신.(단, 유아보호자로서 입장 가능)' },
					{ label: '수술, 척추지환, 골절 등의 질병을 최근 6개월 이내 앓으신분.' },
					{ label: '미끄럼 방지 양말을 착용하지 않은자.' },
					{ label: '음주 및 금지된 약물 복용자.' },
					{ label: '타인에게 피해주는자.' },
					{ label: '시설 안전 이용 수칙동의서에 서명하지 않은자.' },
				],
			},
			{
				title: '본인의 부주의로 인한 사고에 대해서는 책임지지 않습니다.',
			},
			{
				title: '보호자는 본 내용에 관해 인지하신 후 고객(자녀)에게 고지해야 합니다.',
			},
		],
	},
	{
		title: '시설 이용 간 공통 안내사항',
		infos: [
			{
				title:
					'다이빙, 덤블링 등 무리한 동작을 하지않고 본인 역량에 맞는 동작을 시도합니다. 시설물을 이용함에 있어 안전사고로 인한 심각한 상해가 발생할 수 있습니다.',
			},
			{
				title:
					'바운스 내에서 절대 뛰거나 술래잡기 등 장난치지 않습니다. 본인은 물론 타인까지 안전사고로 인한 심각한 상해가 발생할 수 있습니다.',
			},
			{
				title: '각 시설별 안전수칙 및 이용방법을 시설 이용 전 숙지하고 안전하게 이용 바랍니다.',
			},
			{
				title: '바운스 내에서는 절대 미끄럼방지 양말을 벗지 않습니다.',
			},
			{
				title:
					'어드벤처 시설 이용  시 반드시 안전 장비를 착용하고  직원의 통제를 따라 주시기 바랍니다.',
			},
			{
				title:
					'시설 이용은 정해진 이용 시간에만 이용이 가능 하며, 직원이 없는 경우 출입이나 이용을 금지합니다.시설 이용을 원할 시 주변에 있는 직원에게 요청해주세요.',
			},
			{
				title: '고객의 고의·과실로 타인에게 피해를 입힌 경우 고객은 그 책임을 부담합니다.',
			},
		],
	},
];
