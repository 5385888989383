// TODO color 는 <Typography color="primary"> 형식으로 사용할것. 테마로 등록해서
export const VaunceTypography = {
	//HEADLINE 35
	h1: {
		fontWeight: 700,
		fontSize: '35px',
	},
	//HEADLINE 22
	h2: {
		fontWeight: 700,
		fontSize: '22px',
	},
	//title 18
	h3: {
		fontWeight: 500, // medium = 500
		fontSize: '18px',
	},

	//subheader / input/ button 16
	h4: {
		fontWeight: 500,
		fontSize: '16px',
		// opacity: 0.7
	},
	//body 1
	h5: {
		fontWeight: 400, // regular = 400
		fontSize: '14px',
	},

	//body2, icon menu
	h6: {
		fontWeight: 300,
		fontSize: '12px',
	},

	//caption 10
	body1: {
		fontWeight: 200,
		fontSize: '10px',

		// opacity: 0.6
	},

	title1: {},

	//이까지만 쓸 거임
	title2: {
		fontWeight: 500,
		fontSize: '16pt',
		lineHeight: 1.5,
	},
	title3: {
		fontWeight: 500,
		fontSize: '1.125em',
		color: '#FF5C89',
		lineHeight: 1.5,
	},
	subtitle1: {
		fontWeight: 400,
		fontSize: '0.75em',
		lineHeight: 1.5,
	},
	subtitle2: {
		fontWeight: 300,
		fontSize: '0.65em',
		color: '#FFF',
		lineHeight: 'inherit',
	},

	body2: {
		fontWeight: 400,
		fontSize: '0.75em',
		color: 'rgba(0, 0, 0, 0.60)',
		lineHeight: 1.5,
	},
};
