import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { Pageable, ProductType } from '@kinderlabs-pos/shared-data-type';

export const productExcelDownload =
	(rootUrl: string) =>
	({ dataOnPage, type, search, pageable, categoryId }: AdminProductExcelRequestType) => {
		const url = new URL(`${rootUrl}/excel/product/${dataOnPage ? 'page' : 'all'}`);

		if (type) url.searchParams.append('type', type);
		if (search) url.searchParams.append('search', search);
		if (categoryId) url.searchParams.append('categoryId', `${categoryId}`);
		if (pageable.page) url.searchParams.append('page', `${pageable.page}`);
		if (pageable.size) url.searchParams.append('size', `${pageable.size}`);
		if (pageable.sort)
			url.searchParams.append('sort', `${pageable.sort.name},${pageable.sort.direction}`);

		return `${url}`;
	};
export type AdminProductExcelRequestType = {
	dataOnPage: boolean;
	type?: ProductType;
	search?: string;
	categoryId?: number;
	pageable: Pageable;
};
