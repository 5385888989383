import {
	CartLineInfoType,
	DiscountTypeUnitLabel,
	ProductDiscountInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommas } from '@kinderlabs-pos/shared-util';

export const findRealTargetCartLineForDiscount = ({
	targetCartLineId,
	cartLines,
}: {
	targetCartLineId: string;
	cartLines: readonly CartLineInfoType[];
}) => {
	const targetCartLine = cartLines.find((cl) => cl.id === targetCartLineId);

	if (!targetCartLine) throw Error();

	const targetCartLine이옵션인가 = targetCartLine.type === 'OPTION';

	if (targetCartLine이옵션인가) {
		const targetOfTargetCartLine = cartLines.find(
			(cl) => cl.id === targetCartLine.targetCartLineId
		);
		if (!targetOfTargetCartLine) throw Error();

		return {
			타겟이옵션이냐: targetCartLine이옵션인가,
			targetCartLine,
			targetOfTargetCartLine,
		};
	} else {
		return {
			타겟이옵션이냐: targetCartLine이옵션인가,
			targetCartLine,
			targetOfTargetCartLine: undefined,
		};
	}
};

export const getRealTargetName = ({
	discountName,
	targetName,
	productDiscountInfo,
}: {
	discountName: string;
	targetName?: string;
	productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
}) => {
	return `${discountName} ${targetName ? ` (${targetName})` : ''} ${numberWithCommas(
		productDiscountInfo.value
	)} ${DiscountTypeUnitLabel[productDiscountInfo.type]}`;
};
