import { CmtAPI, MutAPI } from '@kinderlabs-pos/apis/pos';
import { useQuery } from '@tanstack/react-query';
import { QueryClient } from '../QueryClient';

const mutQueryKey = ['tickets', 'mut'];
const cmtQueryKey = ['tickets', 'cmt'];

const useMutList = ({ search }: { search: string }) => {
	const result = useQuery({
		queryKey: [...mutQueryKey, 'list', search],
		queryFn: async () => {
			if (search === '') return [];

			return await MutAPI.searchListAsync({ search });
		},
	});

	if (result.isSuccess) return result.data;
	else throw Error('[GET] Mut Ticket Search Mut API Error');
};

const useCmtList = ({ search }: { search: string }) => {
	const result = useQuery({
		queryKey: [...cmtQueryKey, 'list', search],
		queryFn: async () => {
			if (!search) return [];
			return await CmtAPI.searchListAsync({ search });
		},
	});

	if (result.isSuccess) return result.data;
	else throw Error('[GET] Cmt Ticket Search Cmt API Error');
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries(['tickets']);
};

export const TicketQueryState = {
	useMutList,
	useCmtList,
	invalidateQueries,
};
