import { ServerInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { atom } from 'jotai';

const infoAtom = atom(async (get) => {
	try {
		return await ServerInfoApis.getServerInfo();
	} catch {
		return undefined;
	}
});

export const ServerInfoState = {
	info: infoAtom,
};
