import {
	DayCalendarFilterOptionType,
	MonthCalendarFilterOptionType,
} from '../CalendarFilterOptionType';
import { BaseCalendarSearchOptinType } from '../admin/BaseInfoType';
import { CategorySearchSpec } from '../admin/CategorySearchSpec';

export type StatisticsPageType = 'day' | 'month';
export type VisitStatisticsWithStoreType = {
	storeId: number;
	storeName: string;
	nowWaiting: number;

	nowUsing: number;
	nowParentGuests: number;
	nowNormalGuests: number;

	todayUsing: number;
	todayParentGuests: number;
	todayNormalGuests: number;
};

export type VisitorsAndTotalRevenueType = {
	storeId: number;
	storeName: string;
	totalVisitors: number;
	normalUses: number;
	parentUsers: number;
	totalRevenue: number;
	totalRevenueExceptVAT: number;
};

export type VisitorsAndTotalRevenueSumType = {
	storeId: null;
	storeName: null;
	totalVisitors: number;
	normalUses: number;
	parentUsers: number;
	totalRevenue: number;
	totalRevenueExceptVAT: number;
};

export type SummaryFilterTypeWithSingleStore = {
	type: 'day' | 'month';
	brandOption?: string;
	storeIdList?: number[];
	calendarOption: DayCalendarFilterOptionType;
};

export type CalendarFilterType = {
	storeIdList?: number[];
	posIdList?: number[];
	month: number;
	year: number;
};

export type SalesPerProductFilterTypeWithSingleStore =
	| {
			type: 'day';
			brandOption?: string;
			storeIdList?: number[];
			calendarOption: DayCalendarFilterOptionType;
			category?: string;
			posIdList?: number[];
	  }
	| {
			type: 'month';
			brandOption?: string;
			storeIdList?: number[];

			calendarOption: DayCalendarFilterOptionType;
			category?: string;
			posIdList?: number[];
	  };

export const calculateVAT = ({ revenue, taxFree }: { revenue: number; taxFree: boolean }) =>
	taxFree ? 0 : revenue / 11;

export type SalesPerProductFilterType = 카테고리별매출FilterType & {
	isWithTargetCartLine?: boolean;
};

export type 카테고리별매출FilterType = {
	brandOption?: string;
	storeIdList?: number[];
	categorySpec: CategorySearchSpec;
	search?: string;
	isWithTargetCartLine?: boolean;
	posIdList?: number[];
} & (
	| {
			dateType: 'DAY' | 'TIME';
			calendarOption: DayCalendarFilterOptionType;
	  }
	| {
			dateType: 'MONTH';
			calendarOption: MonthCalendarFilterOptionType;
	  }
);

export type OnlineTicketStatisticsFilterType = {
	type: 'day';
	brandOption?: string;
	storeIdList?: number[];
	posId?: number;
	calendarOption: DayCalendarFilterOptionType;
	posIdList?: number[];
};

export type SalesPerCardFilterType =
	| {
			type: 'day';
			brandOption?: string;
			storeId?: number;

			calendarOption: DayCalendarFilterOptionType;
	  }
	| {
			type: 'month';
			brandOption?: string;
			storeId?: number;

			calendarOption: DayCalendarFilterOptionType;
	  };

export type SalesPerCardSearchParamType =
	| (BaseCalendarSearchOptinType & {
			type?: 'day';
			brandOption?: string;
			storeId?: string;
			posIdList?: number[];
	  })
	| (BaseCalendarSearchOptinType & {
			type?: 'month';
			brandOption?: string;
			storeId?: string;
			posIdList?: number[];
	  });

export type MainStatisticsType = {
	date: Date;
	visitorsAndTotalRevenue: VisitorsAndTotalRevenueType[];
	totalSum: VisitorsAndTotalRevenueSumType;
};
