import {
	CartLineDiscountInfoType,
	CartLineInfoType,
	ProductDiscountInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { CartLineState } from './CartLineBoardState';
import { findRealTargetCartLineForDiscount, getRealTargetName } from './cartLineDiscountCommon';

export type CartLineDiscountActionType =
	| {
			type: 'ADD';
			cartLineId: string;
			discountName: string;
			productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
			discountInfoId: number;
			quantity: number;
			targetPrice: number;
			targetCartLineId: string;
			taxFreeTarget: boolean;
	  }
	| {
			type: 'DELETE';
			cartLineId: string;
	  };

const cartLineDiscountReducer = (
	prev: CartLineInfoType[],
	action: CartLineDiscountActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'ADD':
			const {
				타겟이옵션이냐,
				targetCartLine,
				targetOfTargetCartLine: realTargetCartLine,
			} = findRealTargetCartLineForDiscount({
				cartLines: prev,
				targetCartLineId: action.targetCartLineId,
			});

			if (타겟이옵션이냐) {
				return [
					...prev,
					createDiscountCartLineInput({
						productDiscountInfo: action.productDiscountInfo,
						quantity: action.quantity,
						targetName: targetCartLine.name,
						discountName: action.discountName,
						cartLineId: action.cartLineId,
						discountInfoId: action.discountInfoId,
						targetPrice: action.targetPrice,
						targetCartLineId: realTargetCartLine.id,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			} else {
				return [
					...prev,
					createDiscountCartLineInput({
						productDiscountInfo: action.productDiscountInfo,
						quantity: action.quantity,
						cartLineId: action.cartLineId,
						discountName: action.discountName,
						discountInfoId: action.discountInfoId,
						targetPrice: action.targetPrice,
						targetCartLineId: action.targetCartLineId,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			}
		case 'DELETE':
			return prev.filter((cl) => cl.id !== action.cartLineId);
	}
};

export const CartLineDiscountState = {
	reducer: cartLineDiscountReducer,
};

const createDiscountCartLineInput = ({
	cartLineId,
	productDiscountInfo,
	quantity,
	discountName,
	targetName,
	discountInfoId,
	targetPrice,
	targetCartLineId,
	taxFreeTarget,
}: {
	productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
	quantity: number;
	cartLineId: string;
	discountInfoId: number;
	discountName: string;
	targetName?: string;
	targetPrice: number;
	targetCartLineId: string;
	taxFreeTarget: boolean;
}): CartLineDiscountInfoType => {
	return {
		id: cartLineId,
		type: 'DISCOUNT',
		name: getRealTargetName({ discountName, targetName, productDiscountInfo }),
		productInfoId: undefined,
		price: -CartLineState.utils.getUnitDiscount({
			price: targetPrice,
			productDiscountInfo,
		}),
		productInfo: {
			category: '상품 할인',
			isForKitchen: false,
		},
		productAdditionalInfo: {
			taxFree: taxFreeTarget,
			rawPrice: 0,
			supplyPrice: 0,
		},
		productDiscountInfo: productDiscountInfo,
		targetCartLineId: targetCartLineId,
		quantity: quantity,
	};
};
