import { ReactNode } from "react";
// 사이즈가 좀 크긴 하네
import SimpleBarReact from "simplebar-react";
import 'simplebar-react/dist/simplebar.min.css';

export interface ISimpleScrollbarProps {
  direction?: "column" | "row"
  children: ReactNode
}
export const SimpleScrollbar: React.FC<ISimpleScrollbarProps> = ({
  direction = "column",
  children
}) => {
  return (
    <SimpleBarReact style={{
      ...(direction === "column"
        ? { maxHeight: "100%", height: "100%" }
        : { maxWidth: "100%", width: "100%" })
    }}>
      {children}
    </SimpleBarReact >
  )
};