import {
	CartLineInfoType,
	KioskBoardItemInfoWithDetailType,
	OptionType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { SimpleScrollbar, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { DialogContent, DialogProps, Stack } from '@mui/material';
import { KioskBoardItemOptionSection } from './KioskBoardItemOptionSection';
import { KioskBoardItemOptionAggregateAndAlert } from './components/KioskBoardItemOptionAggregate';
import { KioskBoardItemProductInfoSection } from './components/KioskBoardItemProductInfoSection';
import { KioskBoardItemSelectDialogAction } from './components/KioskBoardItemSelectDialogAction';
import { KioskBoardItemSelectDialogTitle } from './components/KioskBoardItemSelectDialogTitle';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';

export const KioskBoardSetItemSelectDialog = ({
	itemInfo,
	closeDialog,
	alertMessage,
	...others
}: DialogProps & {
	itemInfo: KioskBoardItemInfoWithDetailType;
	alertMessage?: string;
	closeDialog: () => void;
}) => {
	const { t, kioskI18N } = useKioskTranslation();
	const { selectedOptionMap, handleSelectedOptionIdList, isValid, validMap } =
		OrderState.cart.utils.useSetProductOptionSelectMap({
			productInfo: itemInfo.productInfo,
			initialSelect: false,
		});
	const optionGroupList = itemInfo.productInfo.optionGroupList || [];

	const optionTotalSum = optionGroupList
		.map((optionGroup) =>
			optionGroup.optionList.filter((option) =>
				selectedOptionMap[optionGroup.id].includes(option.id)
			)
		)
		.flatMap((selectedOptions) => selectedOptions)
		.reduce((arr, selectedOption) => arr + selectedOption.price, 0);

	const 세트상품장바구니담기 = use세트상품장바구니담기({
		onComplete: () => closeDialog && closeDialog(),
		productInfo: itemInfo.productInfo,
		imageUrl: itemInfo.imageUrl,
		selectedOptionMap,
	});

	const 옵션이너무많음 =
		optionGroupList.some((optionGroup) => optionGroup.optionList.length > 10) ||
		(optionGroupList.length > 1 &&
			optionGroupList.some((optionGroup) => optionGroup.optionList.length > 4));

	return (
		<WithDialog.XlDialog
			maxWidth={'xl'}
			dialogTitle={
				<KioskBoardItemSelectDialogTitle
					title={t('cart.optionsTitle')}
					closeDialog={closeDialog}
				/>
			}
			{...others}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 824, aspectRatio: '1/1.6', overflow: 'auto' }}>
				<Stack
					height={'100%'}
					overflow={'auto'}>
					<Stack
						overflow={'auto'}
						flex={1}
						spacing={5}>
						<KioskBoardItemProductInfoSection
							imageUrl={itemInfo.imageUrl}
							productInfo={itemInfo.productInfo}
						/>
						<Stack
							overflow={'auto'}
							spacing={5}
							flex={1}>
							<SimpleScrollbar>
								<Stack spacing={5}>
									{optionGroupList.map((optionGroup, idx) => {
										return (
											<KioskBoardItemOptionSection
												key={idx}
												optionGroup={optionGroup}
												selectedOptionIdList={selectedOptionMap[optionGroup.id]}
												setSelectedOptionIdList={handleSelectedOptionIdList(optionGroup.id)}
												isValid={validMap[optionGroup.id]}
												옵션이너무많음={옵션이너무많음}
											/>
										);
									})}
								</Stack>
							</SimpleScrollbar>
						</Stack>
					</Stack>
					<KioskBoardItemOptionAggregateAndAlert
						alertMessage={alertMessage}
						productPrice={itemInfo.productInfo.price}
						optionTotalSum={optionTotalSum}
					/>
				</Stack>
			</DialogContent>
			<KioskBoardItemSelectDialogAction
				onClose={closeDialog}
				isValid={isValid}
				onApply={세트상품장바구니담기}
			/>
		</WithDialog.XlDialog>
	);
};

const use세트상품장바구니담기 = ({
	productInfo,
	imageUrl,
	selectedOptionMap,
	onComplete,
}: {
	productInfo: ProductInfoType;
	imageUrl?: string;
	selectedOptionMap: { [optionGroupId: number]: OptionType['id'][] };
	onComplete?: (cartLine?: CartLineInfoType) => void;
}) => {
	const cartReducer = OrderState.cart.useDispatcher();

	const selectedOptions = (productInfo.optionGroupList || []).flatMap((optionGroup) => {
		return optionGroup.optionList
			.filter((option) =>
				// selected 된 것만 가져와서
				selectedOptionMap[optionGroup.id].includes(option.id)
			)
			.map((option) => ({
				// isForKitchen 을 맞춰넣은 후
				...option,
				isForKitchen: optionGroup.isForKitchen,
			}));
	});

	return () =>
		cartReducer({
			type: 'PRODUCTS',
			subAction: {
				type: 'ADD_SET',
				imageUrl,
				cartLineId: getUuidV4(),
				productInfo: productInfo,
				selectedOptions,
				onComplete,
			},
		});
};
