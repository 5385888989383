import { AuthApis } from '@kinderlabs-pos/apis/storeinfo';
import { authState, KioskElectronStoreState, StoreInfoState } from '@kinderlabs-pos/state';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { KioskSelectionState } from '../utils/KioskSelectionState';

export const KioskEasyLoginSetter = () => {
	const loadKioskInfo = KioskSelectionState.useLoadKioskInfoFromAuthStore();
	const deviceLogin = useSetAtom(StoreInfoState.deviceLoginInfo);

	const setUser = useSetAtom(authState.user);
	const setEnpterpriseInfo = useSetAtom(authState.enterpriseInfo);
	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();

	// Auto Login
	useEffect(() => {
		(async () => {
			try {
				if (localSettings?.recentLoginInfo) {
					const result = await AuthApis.login({
						email: localSettings.recentLoginInfo.userId,
						password: localSettings.recentLoginInfo.saltedPassword,
					});

					setUser(result.userInfo);
					setEnpterpriseInfo(result.enterpriseInfo);

					const kioskInfo = await loadKioskInfo();

					if (kioskInfo) {
						deviceLogin({
							storeId: kioskInfo.storeId,
							deviceId: kioskInfo.kioskId,
							enterpriseId: result.enterpriseInfo.id,
							deviceType: 'KIOSK',
						});
					}
				}
			} catch (e) {
				console.log('error');
				console.log(e);
				// 로그인 실패시

				if (localSettings)
					saveLocalSettingsMutation.mutate({ ...localSettings, recentLoginInfo: undefined });
			}
		})();
	}, []);

	return null;
};
