import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const getExcelDown =
	(rootUrl: string) =>
	async ({ posBoardInfoId }: ExcelRequestType) => {
		const blob = await getExcelDownloadBlob({ rootUrl, posBoardInfoId });

		const blobURL = window.URL.createObjectURL(blob);
		const tempLink = document.createElement('a');

		tempLink.style.display = 'none';
		tempLink.href = blobURL;
		tempLink.setAttribute('download', `Pos-Board_No.${posBoardInfoId}.xlsx`);

		document.body.appendChild(tempLink);

		tempLink.click();

		document.body.removeChild(tempLink);
		window.URL.revokeObjectURL(blobURL);
	};

/**
 * 빈엑셀 (엑셀 업로드 양식 다운로드F)
 */
export const getExcelEmptyFormDown = (rootUrl: string) => async () => {
	const blob = await getExcelDownloadBlob({ rootUrl });

	const blobURL = window.URL.createObjectURL(blob);
	const tempLink = document.createElement('a');

	tempLink.style.display = 'none';
	tempLink.href = blobURL;
	tempLink.setAttribute('download', `Pos-Board_No_Empty.xlsx`);

	document.body.appendChild(tempLink);

	tempLink.click();

	document.body.removeChild(tempLink);
	window.URL.revokeObjectURL(blobURL);
};

const getExcelDownloadBlob = async ({
	rootUrl,
	posBoardInfoId,
}: {
	rootUrl: string;
	posBoardInfoId?: number;
}) => {
	const response = await posAxiosClient.get(`${rootUrl}/excel/posboard`, {
		params: {
			posBoardInfoId,
		},
		responseType: 'blob',
	});

	const blob = new Blob([response.data], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});

	return blob;
};

type ExcelRequestType = {
	posBoardInfoId?: number;
};
