import {
	CartLineDiscountCouponUsageInfoType,
	CartLineInfoType,
	CartLineTicketUsageInfoType,
	CartLineTypeConverter,
	CartTicketUsageType,
	DdiziInfoType,
	MembershipTicketType,
	MembershipTicketTypeLabel,
	ProductDiscountInfoType,
	defaultProductAdditionalInfo,
} from '@kinderlabs-pos/shared-data-type';
import { OrderState } from '..';
import { findRealTargetCartLineForDiscount, getRealTargetName } from './cartLineDiscountCommon';
import { adjustTargetCartLineForOneQuantity } from './cartLineOptionCommon';

export type CartLineTicketUsageActionType =
	| {
			type: 'ADD_TICKET';
			cartLineId: string;
			ticketUsage: CartTicketUsageType;
	  }
	| {
			type: 'ADD_FREE_COUPON';
			cartLineId: string;
			ticketUsage: CartTicketUsageType & { type: 'FREECOUPON'; ddiziInfo: DdiziInfoType };
	  }
	| {
			type: 'ADD_DISCOUNT_TICKET';
			cartLineId: string;
			targetCartLineId: string;
			targetPrice: number;
			ticketUsage: CartTicketUsageType & { type: 'DISCOUNT' };
			productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
			taxFreeTarget: boolean;
	  }
	| {
			type: 'REMOVE_DISCOUNT_TICKET';
			ticketId: number;
	  }
	| {
			type: 'DELETE';
			useId: string;
			ticketType: MembershipTicketType;
	  }
	| { type: 'CLEAR' }
	| { type: 'CLEAR_DISCOUNTS' };

const cartLineTicketUsageReducer = (
	prev: CartLineInfoType[],
	action: CartLineTicketUsageActionType
): CartLineInfoType[] => {
	switch (action.type) {
		case 'ADD_TICKET':
			return [
				...prev,
				createTicketUsageCartLineInput({
					cartLineId: action.cartLineId,
					ticketUsage: action.ticketUsage,
				}),
			];
		case 'ADD_DISCOUNT_TICKET':
			const {
				타겟이옵션이냐,
				targetCartLine: originalTargetCartLine,
				targetOfTargetCartLine: realTargetCartLine,
			} = findRealTargetCartLineForDiscount({
				cartLines: prev,
				targetCartLineId: action.targetCartLineId,
			});

			const {
				cartLines: newCartLines,
				targetCartLine: newTargetCartLine,
				targetCartLineIndex: newTargetCartLineIndex,
			} = adjustTargetCartLineForOneQuantity(
				prev,
				타겟이옵션이냐 ? realTargetCartLine.id : originalTargetCartLine.id
			);

			if (타겟이옵션이냐) {
				return [
					...newCartLines,
					createDiscountCouponUsageCartLineInput({
						cartLineId: action.cartLineId,
						targetCartLineId: newTargetCartLine.id,
						targetPrice: originalTargetCartLine.price,
						targetName: originalTargetCartLine.name,
						ticketUsage: action.ticketUsage,
						productDiscountInfo: action.productDiscountInfo,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			} else {
				return [
					...newCartLines,
					createDiscountCouponUsageCartLineInput({
						cartLineId: action.cartLineId,
						targetCartLineId: newTargetCartLine.id,
						targetPrice: originalTargetCartLine.price,
						ticketUsage: action.ticketUsage,
						productDiscountInfo: action.productDiscountInfo,
						taxFreeTarget: action.taxFreeTarget,
					}),
				];
			}
		case 'ADD_FREE_COUPON':
			return [
				...prev,
				createAppFreeTicket({
					cartLineId: action.cartLineId,
					ticketUsage: action.ticketUsage,
				}),
			];
		case 'REMOVE_DISCOUNT_TICKET':
			return prev.filter((cl) => {
				const isCoupon = cl.type === 'VAUNCE_APP_DISCOUNT' || cl.type === 'VAUNCE_APP_FREE_COUPON';
				if (isCoupon) {
					const discountCouponId = (cl as CartLineDiscountCouponUsageInfoType).ticketUsageInfo
						.ticketUsageId;

					return discountCouponId !== `${action.ticketId}`;
				} else {
					return true;
				}
			});
		case 'DELETE':
			return prev.filter(
				(cl) => (cl as CartLineTicketUsageInfoType).ticketUsageInfo?.ticketUsageId !== action.useId
			);
		case 'CLEAR':
			return [];
		case 'CLEAR_DISCOUNTS':
			return prev.filter((cl) => cl.type !== 'VAUNCE_APP_DISCOUNT');
	}
};

export const CartLineTicketUsageState = {
	reducer: cartLineTicketUsageReducer,
};

const createTicketUsageCartLineInput = ({
	cartLineId,
	ticketUsage,
}: {
	cartLineId: string;
	ticketUsage: CartTicketUsageType;
}): CartLineTicketUsageInfoType => {
	return {
		id: cartLineId,
		type: CartLineTypeConverter[ticketUsage.type],
		price: 0,
		name: `${ticketUsage.name}`,
		productInfoId: undefined,
		productInfo: {
			category: MembershipTicketTypeLabel[ticketUsage.type],
			isForKitchen: false,
			ddiziInfo: ticketUsage.ddiziInfo,
		},
		productDiscountInfo: undefined,
		ticketUsageInfo: {
			name: ticketUsage.name,
			ticketId: ticketUsage.ticketId,
			ticketUsageId: `${ticketUsage.useId}`,
			ticketInfoId: `${ticketUsage.ticketInfoId}`,
			memberId: ticketUsage.memberId,
			userName: ticketUsage.userName,
			userTelephone: ticketUsage.userTelephone,
		},
		productAdditionalInfo: defaultProductAdditionalInfo,
		targetCartLineId: undefined,
		quantity: 1,
	};
};

const createDiscountCouponUsageCartLineInput = ({
	cartLineId,
	targetCartLineId,
	ticketUsage,
	targetPrice,
	targetName,
	productDiscountInfo,
	taxFreeTarget,
}: {
	cartLineId: string;
	targetCartLineId: string;
	ticketUsage: CartTicketUsageType & { type: 'DISCOUNT' };
	targetPrice: number;
	targetName?: string;
	productDiscountInfo: Pick<ProductDiscountInfoType, 'value' | 'type'>;
	taxFreeTarget: boolean;
}): CartLineTicketUsageInfoType => {
	return {
		id: cartLineId,
		type: 'VAUNCE_APP_DISCOUNT',
		price: -OrderState.cart.lines.boardUtils.getUnitDiscount({
			price: targetPrice,
			productDiscountInfo,
		}),
		name: getRealTargetName({ discountName: ticketUsage.name, targetName, productDiscountInfo }),
		productInfoId: undefined,
		productInfo: {
			category: MembershipTicketTypeLabel[ticketUsage.type],
			isForKitchen: false,
			ddiziInfo: ticketUsage.ddiziInfo,
		},
		productDiscountInfo: productDiscountInfo,
		ticketUsageInfo: {
			name: ticketUsage.name,
			ticketId: ticketUsage.ticketId,
			ticketUsageId: `${ticketUsage.useId}`,
			ticketInfoId: `${ticketUsage.ticketInfoId}`,
			memberId: ticketUsage.memberId,
			userName: ticketUsage.userName,
			userTelephone: ticketUsage.userTelephone,
		},
		productAdditionalInfo: {
			rawPrice: 0,
			supplyPrice: 0,
			taxFree: taxFreeTarget,
		},
		targetCartLineId,
		quantity: 1,
	};
};

const createAppFreeTicket = ({
	cartLineId,
	ticketUsage,
}: {
	cartLineId: string;
	ticketUsage: CartTicketUsageType & { type: 'FREECOUPON' };
}): CartLineTicketUsageInfoType => {
	return {
		id: cartLineId,
		type: 'VAUNCE_APP_FREE_COUPON',
		price: 0,
		name: `${ticketUsage.name}`,
		productInfoId: undefined,
		productInfo: {
			category: MembershipTicketTypeLabel[ticketUsage.type],
			isForKitchen: false,
			ddiziInfo: ticketUsage.ddiziInfo,
		},
		ticketUsageInfo: {
			name: ticketUsage.name,
			ticketId: ticketUsage.ticketId,
			ticketUsageId: `${ticketUsage.useId}`,
			ticketInfoId: `${ticketUsage.ticketInfoId}`,
			memberId: ticketUsage.memberId,
			userName: ticketUsage.userName,
			userTelephone: ticketUsage.userTelephone,
		},
		productAdditionalInfo: defaultProductAdditionalInfo,
		quantity: 1,
	};
};
