import { ImageInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { StoreInfoListFilterType } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../../QueryClient';

const fileUploadKeys = createQueryKeys('imageUpload', {
	list: (searchOption: StoreInfoListFilterType) => {
		return {
			queryKey: [searchOption],
			queryFn: async () => {
				return await ImageInfoApis.get(searchOption);
			},
		};
	},
});

const useCreateFileInfo = () => {
	return QueryClient.useMutation(
		async (input: FormData) => {
			return await ImageInfoApis.create(input);
		},
		{
			onSuccess: invalidateQuries,
		}
	);
};

const useDeleteFileInfo = () => {
	return QueryClient.useMutation(
		async (fileId: number) => {
			await ImageInfoApis.delete({ fileId: fileId });
		},
		{
			onSuccess: invalidateQuries,
		}
	);
};

const invalidateQuries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: fileUploadKeys._def });
};

export const AdminFileUploadState = {
	keys: fileUploadKeys,
	useCreate: useCreateFileInfo,
	useDelete: useDeleteFileInfo,
};
