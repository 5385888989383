import { ipcRPApiName } from '@kinderlabs-pos/ipc/common';
import {
	CustomReceiptRequestType,
	CustomerReceiptRequestType,
	ExchangeCouponRequestType,
	FrontReceiptRequestType,
	KioskReceiptRequestType,
	KitchenReceiptRequestType,
	PrintModeType,
	PrinterInfoType,
	ReceiptDdiziRequestType,
	ReceiptResponseType,
	VaultcashReceiptRequestType,
} from '@kinderlabs-pos/shared-data-type';
import { HAS_NO_ELECTRON, isOnElectron } from './const';

const invoke고객영수증출력 = async (
	args: CustomerReceiptRequestType
): Promise<ReceiptResponseType> => {
	if (!isOnElectron)
		return new Promise((resolve) => {
			resolve({ message: HAS_NO_ELECTRON });
		});
	return await window[ipcRPApiName].invoke영수증출력({
		key: 'customer-receipt-printer-request',
		args: args,
	});
};

const invoke교환권출력 = async (args: ExchangeCouponRequestType): Promise<ReceiptResponseType> => {
	if (!isOnElectron) {
		return new Promise((resolve, reject) => {
			console.log('교환권 출력', args);
			setTimeout(() => {
				const randomValue = Math.round(Math.random() * 10);
				const failed = randomValue % 4 === 0;

				if (failed) reject({ message: HAS_NO_ELECTRON });
				else resolve({ message: HAS_NO_ELECTRON });
			}, 1000);
		});
	}

	return await window[ipcRPApiName].invoke영수증출력({
		key: 'exchange-coupon-printer-request',
		args: args,
	});
};

const invoke영수증띠지출력 = async (
	args: ReceiptDdiziRequestType
): Promise<ReceiptResponseType> => {
	if (!isOnElectron) {
		return new Promise((resolve, reject) => {
			console.log('교환권 출력', args);
			setTimeout(() => {
				const randomValue = Math.round(Math.random() * 10);
				const failed = randomValue % 4 === 0;

				if (failed) reject({ message: HAS_NO_ELECTRON });
				else resolve({ message: HAS_NO_ELECTRON });
			}, 1000);
		});
	}

	return await window[ipcRPApiName].invoke영수증출력({
		key: 'receipt-ddizi-printer-request',
		args: args,
	});
};

const invoke시재점검표출력 = async (
	args: VaultcashReceiptRequestType
): Promise<ReceiptResponseType> => {
	if (!isOnElectron)
		return new Promise((resolve) => {
			resolve({ message: HAS_NO_ELECTRON });
		});
	return await window[ipcRPApiName].invoke영수증출력({
		key: 'vaultcash-receipt-printer-request',
		args: args,
	});
};

const invoke프론트영수증출력 = async (
	args: FrontReceiptRequestType
): Promise<ReceiptResponseType> => {
	if (!isOnElectron) {
		console.log('FRONT', args);
		return new Promise((resolve) => {
			resolve({ message: HAS_NO_ELECTRON });
		});
	}
	return await window[ipcRPApiName].invoke영수증출력({
		key: 'front-receipt-printer-request',
		args: args,
	});
};
const invoke주방영수증출력 = async (
	args: KitchenReceiptRequestType
): Promise<ReceiptResponseType> => {
	if (!isOnElectron) {
		console.log('KITCHEN', args);
		return new Promise((resolve) => {
			resolve({ message: HAS_NO_ELECTRON });
		});
	}
	return await window[ipcRPApiName].invoke영수증출력({
		key: 'kitchen-receipt-printer-request',
		args: args,
	});
};

const invoke커스텀영수증출력 = async (
	args: CustomReceiptRequestType
): Promise<ReceiptResponseType> => {
	if (!isOnElectron) {
		return new Promise((resolve) => {
			resolve({ message: HAS_NO_ELECTRON });
		});
	}
	return await window[ipcRPApiName].invoke영수증출력({
		key: 'custom-receipt-printer-request',
		args: args,
	});
};

const invoke키오스크주문증출력 = async (
	args: KioskReceiptRequestType
): Promise<ReceiptResponseType> => {
	if (!isOnElectron) {
		return new Promise((resolve) => {
			resolve({ message: HAS_NO_ELECTRON });
		});
	}
	return await window[ipcRPApiName].invoke영수증출력({
		key: 'kiosk-receipt-printer-request',
		args: args,
	});
};

const sendOpenCashBox = (args: { printMode: PrintModeType; printerInfo?: PrinterInfoType }) =>
	isOnElectron
		? window[ipcRPApiName].invoke영수증출력({
				key: 'cashbox',
				args,
		  })
		: console.log('cashbox open (dummy)');

export const ReactRPIPC = {
	invoke고객영수증출력,
	invoke교환권출력,
	invoke영수증띠지출력,
	invoke시재점검표출력,
	invoke프론트영수증출력,
	invoke주방영수증출력,
	invoke커스텀영수증출력,
	invoke키오스크주문증출력,
	sendOpenCashBox,
};

// 해당 코드는 의존성에 영향을 줌.
// 브라우저 프로세스가 접근하지 못하는 영역에 접근하게됨. 주의필요.
// 해당 기능이 꼭 필요하다면 nx webpack 설정을 건드려 쓰자.
// const isSample = process.env['NODE_ENV'] === 'sample';
// if (isSample) {
// 	console.log('NODE_ENV가 SAMPLE이 되었음');
// 	ReactIPC_Pos_Hardware.invoke띠지출력 = hwPrintLabel;
// 	ReactIPC_Pos_Hardware.sendOpenCashBox = hwOpenDrawer;
// 	ReactIPC_Pos_Hardware.invoke고객영수증출력 = hwPrintCustomerReceipt;
// 	ReactIPC_Pos_Hardware.invoke교환권출력 = hwPrintExchangeCoupon;
// 	ReactIPC_Pos_Hardware.invoke시재점검표출력 = hwPrintVaultcashReceipt;
// 	ReactIPC_Pos_Hardware.invoke프론트영수증출력 = hwPrintFoodFrontReceipt;
// 	ReactIPC_Pos_Hardware.invoke주방영수증출력 = hwPrintFoodKitchenReceipt;
// 	ReactIPC_Pos_Hardware.invoke커스텀영수증출력 = hwPrintCustomReceipt;
// }
