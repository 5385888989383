import {
	CheckCircleFilled,
	CheckCircleOutlined,
	CloseOutlined,
	ExportOutlined,
} from '@ant-design/icons';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { TCAgreementContents, TermsConditionsType } from '@kinderlabs-pos/ui-components';
import {
	Checkbox,
	CheckboxProps,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PrevNextButton } from '../../components/UserInfoPageComponents/PrevNextButton';
import { UserInfoPageTemplate } from './UserInfoPageTemplate';

type AgreementType = {
	kinderlabsAgreement: boolean;
	storeAgreement: boolean;
	marketingAgreement: boolean;
};

type TermsConditionsCheckProps = {
	agreement: AgreementType;
	setAgreement: (agreement: AgreementType) => void;
	goNextStep: () => void;
	goPrevStep: () => void;
};

export const UserTermsConditionsPage = ({ props }: { props: TermsConditionsCheckProps }) => {
	const formik = useFormik<AgreementType>({
		initialValues: { ...props.agreement },
		onSubmit: async (agreement) => {
			props.setAgreement(agreement);
			props.goNextStep();
		},
		validationSchema: Yup.object({
			kinderlabsAgreement: Yup.boolean().oneOf([true]),
			storeAgreement: Yup.boolean().oneOf([true]),
		}),
		validateOnMount: true,
		enableReinitialize: true,
	});

	return (
		<UserInfoPageTemplate>
			<Stack
				flex={1}
				sx={{ width: '100%', pt: 5 }}
				spacing={5}>
				<Typography
					textAlign={'center'}
					variant={'h6'}
					sx={{ whiteSpace: 'pre-line' }}>
					{
						'저희 매장은 안전 사고 예방과 미아 방지를 위해 \n 고객님의 개인정보를 활용하고 있습니다.'
					}
				</Typography>
				<Stack
					flex={1}
					sx={{ overflowY: 'auto' }}
					spacing={5}>
					<TermsCondtionsDetailContentsSection />
					<TermsConditionsConsentsSection formik={formik} />
				</Stack>
				<PrevNextButton
					nextDisabled={!formik.isValid}
					prevFn={props.goPrevStep}
					nextFn={formik.handleSubmit}
				/>
			</Stack>
		</UserInfoPageTemplate>
	);
};

const TermsConditionsConsentsSection = ({
	formik,
}: {
	formik: ReturnType<typeof useFormik<AgreementType>>;
}) => {
	const { values, setValues } = formik;

	const isAllChecked =
		values.kinderlabsAgreement && values.storeAgreement && values.marketingAgreement;

	const check = (targetField: 'kinderlabsAgreement' | 'storeAgreement' | 'marketingAgreement') => {
		setValues((prev) => ({
			...prev,
			[targetField]: !prev[targetField],
		}));
	};

	const checkAll = () => {
		setValues({
			kinderlabsAgreement: !isAllChecked,
			storeAgreement: !isAllChecked,
			marketingAgreement: !isAllChecked,
		});
	};

	return (
		<Stack
			flex={1}
			spacing={4}
			sx={{ width: '100%', height: '100%', justifyContent: 'center' }}>
			<CustomCheckBox
				type={'all'}
				autoFocus
				checked={isAllChecked}
				onChange={() => checkAll()}
			/>
			<Stack spacing={1}>
				<CustomCheckBox
					type={'store'}
					checked={values.storeAgreement}
					onChange={() => check('storeAgreement')}
				/>
				<CustomCheckBox
					type={'kinderlabs'}
					checked={values.kinderlabsAgreement}
					onChange={() => check('kinderlabsAgreement')}
				/>
				<CustomCheckBox
					type={'marketing'}
					checked={values.marketingAgreement}
					onChange={() => check('marketingAgreement')}
				/>
			</Stack>
		</Stack>
	);
};

const TermsCondtionsDetailContentsSection = () => {
	return (
		<Stack
			flex={1}
			spacing={4}
			sx={(theme) => ({
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				bgcolor: theme.palette.grey['300'],
				borderRadius: 4,
				p: 2,
			})}>
			{termsConditionsDetailList.map((detail) => (
				<Stack key={detail.id}>
					{detail.title && <Typography variant='body2'>{detail.title}</Typography>}
					{detail.desc.map((line) => (
						<Typography
							variant='body2'
							key={line}>
							{line}
						</Typography>
					))}
				</Stack>
			))}
		</Stack>
	);
};

type TermsConditionsDetailListType = {
	id: number;
	title?: string;
	desc: string[];
};

const termsConditionsDetailList: TermsConditionsDetailListType[] = [
	{
		id: 1,
		desc: [
			'대기 서비스 제공을 위해 주식회사 킨더랩스 및 대기를 신청한 매장에서 다음과 같이 개인정보를 수집, 이용하고 있습니다.',
		],
	},
	{
		id: 2,
		title: ' - (필수) 만 14세 이상임을 확인합니다.',
		desc: [
			'만14세 미만 아동의 개인정보 보호를 위하여 만 14세 미만 아동의 이용을 제한하고 있습니다. 만14세 미만 아동은 본 서비스를 이용하실 수 없습니다.',
		],
	},
	{
		id: 3,
		title: ' - (필수) 킨더랩스의 개인정보 수집 및 이용 동의',
		desc: [
			'수집, 이용 항목 : 성명 / 휴대전화번호 / 생년월일 / 거주지 / 성별',
			'이용 목적 : 매장이용 대기 접수, 이용기록 보관 및 안내, 서비스 개선',
			'보유기간 : 최종 이용 후 2년간 보관',
		],
	},
	{
		id: 4,
		desc: [
			'필요한 최소한의 개인정보 수집에 동의하셔야 서비스를 이용하실 수 있으며, 동의 거부시 서비스 이용이 제한됩니다.',
		],
	},
];

const CustomCheckBox = ({
	checked,
	onChange,
	autoFocus = false,
	type,
}: {
	checked: boolean;
	onChange: () => void;
	type: 'all' | 'kinderlabs' | 'store' | 'marketing';
} & CheckboxProps) => {
	return (
		<Stack
			direction={'row'}
			alignItems={'center'}
			spacing={4}>
			<Checkbox
				autoFocus={autoFocus}
				disableFocusRipple
				checked={checked}
				icon={
					<CheckCircleOutlined
						type='message'
						style={{ fontSize: 40 }}
					/>
				}
				checkedIcon={
					<CheckCircleFilled
						type='message'
						style={{ fontSize: 40 }}
					/>
				}
				onChange={onChange}
			/>
			<Typography variant='h6'>{`${
				type === 'all' ? '' : TCAgreementContents[type].isRequired ? '(필수)' : '(선택)'
			} ${TCAgreementContents[type].title}`}</Typography>
			{type !== 'all' && (
				<WithDialog
					dialog={(open, closeDialog) => (
						<TermsConditionDetailDialog
							open={open}
							closeDialog={closeDialog}
							dialogTitle={TCAgreementContents[type].title}
							detailList={TCAgreementContents[type].list}
						/>
					)}>
					{(openDialog) => (
						<IconButton
							onClick={openDialog}
							color='primary'>
							<ExportOutlined />
						</IconButton>
					)}
				</WithDialog>
			)}
		</Stack>
	);
};

const TermsConditionDetailDialog = ({
	open,
	closeDialog,
	dialogTitle,
	detailList,
}: {
	open: boolean;
	closeDialog: () => void;
	dialogTitle: string;
	detailList: TermsConditionsType[];
}) => {
	return (
		<WithDialog.XlDialog
			// maxWidth={'xl'}
			// fullWidth
			fullScreen
			open={open}
			closeDialog={closeDialog}>
			<DialogTitle>
				<Stack
					direction={'row'}
					sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
					{dialogTitle}
					<IconButton
						size={'large'}
						onClick={closeDialog}>
						<CloseOutlined style={{ color: 'black' }} />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<Stack
					flex={1}
					sx={{ overflow: 'auto' }}>
					{detailList.map((detail, idx) => (
						<Stack key={detail.title}>
							{detail.title && (
								<Stack spacing={1}>
									<Typography variant='subtitle1'>
										{idx + 1}. {detail.title}
									</Typography>
									<Divider sx={{ width: '100%' }} />
								</Stack>
							)}
							<ol>
								<Stack spacing={0.5}>
									{detail.infos.map((info, idx) => (
										<li key={idx}>
											<Stack key={info.title}>
												<Typography>{info.title}</Typography>
												{info.descs && (
													<ul>
														{info.descs.map((info, idx) => (
															<li>
																<Typography key={idx}>{info.label}</Typography>
															</li>
														))}
													</ul>
												)}
												{info.table &&
													getTCTable(info.table.tableHeadList, info.table.tableBodyList)}
											</Stack>
										</li>
									))}
								</Stack>
							</ol>
						</Stack>
					))}
				</Stack>
			</DialogContent>
			<DialogActions></DialogActions>
		</WithDialog.XlDialog>
	);
};

const getTCTable = (tableHeadList: string[], tableBodyList: string[][]) => {
	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						{tableHeadList.map((th, idx) => (
							<TableCell key={idx}>
								<Typography>{th}</Typography>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{tableBodyList.map((tb, idx) => (
						<TableRow key={idx}>
							{tb.map((tbdata, idx) => (
								<TableCell key={idx}>
									<Typography sx={{ whiteSpace: 'pre-line' }}>{tbdata}</Typography>
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
