import { rootUrl } from '../../const';
import { createPosBoardInfoAsync } from './createPosBoardInfoAsync';
import { deletePosBoardInfoAsync } from './deletePosBoardInfoListAsync';
import { getListCompareTwoStoreAsync } from './getListCompareTwoStore';
import { getPosBoardInfoDetailAsync } from './getPosBoardInfoDetailAsync';
import { getPosBoardInfoListAsync } from './getPosBoardInfoListAsync';
import { getPosBoardInfoListInStoreAsync } from './getPosBoardInfoListInStoreAsync';
import { updatePosBoardInfoAsync } from './updatePosBoardInfoAsync';
import { updatePosBoardItemInfoAsync } from './updatePosBoardItemInfoAsync';
import { updatePosBoardItemInfoListAsync } from './updatePosBoardItemInfoListAsync';
import { updatePosBoardOrderAsync } from './updatePosBoardOrderAsync';
import { updatePosBoardWithOtherStoreAsync } from './updatePosBoardWithOtherStore';

export const AdminPosBoardInfoApis = {
	getList: getPosBoardInfoListAsync(rootUrl),
	getListInStore: getPosBoardInfoListInStoreAsync(rootUrl),
	getListCompareTwoStore: getListCompareTwoStoreAsync(rootUrl),
	getDetail: getPosBoardInfoDetailAsync(rootUrl),
	create: createPosBoardInfoAsync(rootUrl),
	update: updatePosBoardInfoAsync(rootUrl),
	updateItem: updatePosBoardItemInfoAsync(rootUrl),
	updateItemBulk: updatePosBoardItemInfoListAsync(rootUrl),
	updateItemOrder: updatePosBoardOrderAsync(rootUrl),
	updatePosBoardWithOtherStore: updatePosBoardWithOtherStoreAsync(rootUrl),
	delete: deletePosBoardInfoAsync(rootUrl),
};
