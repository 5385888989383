import { CategoryInfoType } from './CategoryInfoType';

export type CategoryInfoTreeType = {
	ONLYBASE: {
		[id: number]: {
			// 소분류
			baseCategoryInfo: CategoryInfoType;
		};
	};
	BASE: {
		[id: number]: {
			// 소분류
			baseCategoryInfo: CategoryInfoType;
			// 중분류
			superCategoryInfo: CategoryInfoType;
			// 대분류
			topCategoryInfo: CategoryInfoType;
		};
	};
	SUPER: {
		[id: number]: {
			// 소분류
			baseCategoryInfo: undefined;
			// 중분류
			superCategoryInfo: CategoryInfoType;
			// 대분류
			topCategoryInfo: CategoryInfoType;
		};
	};
	TOP: {
		[id: number]: {
			// 소분류
			baseCategoryInfo: undefined;
			// 중분류
			superCategoryInfo: undefined;
			// 대분류
			topCategoryInfo: CategoryInfoType;
		};
	};
};

/**
 * level: categoryId
 */
export type CategorySearchSpec = {
	// categoryId 들 담고 있음
	BASE?: number;
	SUPER?: number;
	TOP?: number;
};

export const initialCategorySpec: CategorySearchSpec = {
	BASE: undefined,
	SUPER: undefined,
	TOP: undefined,
};

export const hasBaseCategoryId = (spec: CategorySearchSpec) => {
	return !!spec.BASE;
};

export const getCategorySpecFromBaseId = ({
	categoryTree,
	usingSuperCategory,
	baseCategoryId,
}: {
	categoryTree: CategoryInfoTreeType;
	usingSuperCategory: boolean;
	baseCategoryId?: number;
}): CategorySearchSpec => {
	if (!usingSuperCategory)
		return {
			BASE: baseCategoryId,
			SUPER: undefined,
			TOP: undefined,
		};
	else {
		if (baseCategoryId) {
			const superCategory = categoryTree.BASE[baseCategoryId].superCategoryInfo;
			const topCategory = categoryTree.BASE[baseCategoryId].topCategoryInfo;

			return {
				BASE: baseCategoryId,
				SUPER: !superCategory.isDummy ? superCategory.id : undefined,
				TOP: !topCategory.isDummy ? topCategory.id : undefined,
			};
		} else
			return {
				BASE: undefined,
				SUPER: undefined,
				TOP: undefined,
			};
	}
};

export const getCategoryIdToSearch = (spec: CategorySearchSpec) => {
	if (!!spec.BASE) return spec.BASE;
	else if (!!spec.SUPER) return spec.SUPER;
	else if (!!spec.TOP) return spec.TOP;
	else return undefined;
};

export const getBaseCategoryListForAPI = ({
	spec,
	categoryTree,
}: {
	spec: CategorySearchSpec;
	categoryTree: CategoryInfoTreeType;
}) => {
	if (spec.BASE) return [categoryTree.BASE[spec.BASE].baseCategoryInfo];
	else if (spec.SUPER) return categoryTree.SUPER[spec.SUPER].superCategoryInfo.children;
	else if (spec.TOP)
		return categoryTree.TOP[spec.TOP].topCategoryInfo.children.flatMap((sc) => sc.children);
	return [];
};
