import { Card, CardActionArea, CardActionAreaProps, styled } from '@mui/material';
import React from 'react';

export interface ICardButtonProps extends CardActionAreaProps {}
export const RegisterCardButton: React.FC<ICardButtonProps> = ({ children, ...others }) => {
	return <StyledRegisterCard {...others}>{children}</StyledRegisterCard>;
};

const StyledRegisterCard = styled(CardActionArea)(({ theme }) => ({
	background: 'linear-gradient(180deg, #92FAF8 0%, #5CBFBD 100%)',
	borderRadius: 20,
	'&.Mui-disabled': {
		background: theme.palette.grey[300],
		color: theme.palette.grey[200],
		cursor: '',
	},
}));

export const CardButton: React.FC<ICardButtonProps> = ({ disabled, children, sx, ...others }) => {
	return (
		<StyledCard
			sx={sx}
			disabled={disabled}>
			<CardActionArea
				disabled={disabled}
				sx={{ height: '100%' }}
				{...others}>
				{children}
			</CardActionArea>
		</StyledCard>
	);
};

const StyledCard = styled(Card)<{ disabled?: boolean }>(({ theme, disabled }) => ({
	borderRadius: 20,
	// border: '1px solid lightGray',
	// backgroundColor: disabled ? lightGray : pink,
	// color: 'white',
	// cursor: disabled ? 'not-allowed' : 'pointer',
}));

// export const RecentWhiteCardButton: React.FC<ICardButtonProps> = ({ children, sx, ...others }) => {
// 	return (
// 		<RecentWhiteStyledCard sx={sx}>
// 			<CardActionArea
// 				sx={{ height: '100%' }}
// 				{...others}>
// 				{children}
// 			</CardActionArea>
// 		</RecentWhiteStyledCard>
// 	);
// };

// const RecentWhiteStyledCard = styled(Card)(() => ({
// 	borderRadius: 20,
// 	borderTop: `20px solid ${mint}`,
// 	marginRight: 2,
// }));

export const WhiteBackgroundCardButton: React.FC<ICardButtonProps> = ({
	children,
	sx,
	...others
}) => {
	return (
		<KioskWhiteCardButton sx={sx}>
			<CardActionArea
				sx={{ height: '100%' }}
				{...others}>
				{children}
			</CardActionArea>
		</KioskWhiteCardButton>
	);
};

const KioskWhiteCardButton = styled(Card)(() => ({
	width: 460,
	height: 620,
	background: `linear-gradient(180deg, #FFFFFF 0%, #E8E9F4 100%)`,
	border: '4px solid #FFFFFF',
	boxShadow: '0px 20px 50px rgba(0, 0, 0, 0.3)',
	borderRadius: '40px',
}));
