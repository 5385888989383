import { useEffect, useState } from 'react';

let timeout: NodeJS.Timeout | null;
export const useCalculateTime = () => {
	const [value, setValue] = useState<Date>(new Date());

	useEffect(() => {
		timeout = setTimeout(() => {
			setValue(new Date());
		}, 60 * 1000);

		return () => {
			timeout && clearTimeout(timeout);
		};
	}, [value]);

	return { value };
};
