import { KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { KioskFNBCartOrderDialog } from './KioskFNBCartOrderDialog';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';

export const KioskCartOrderButton = ({ kioskInfo }: { kioskInfo: KioskInfoType }) => {
	const cart = useAtomValue(OrderState.cart.selector);
	const cartAggregate = OrderState.cart.utils.getAggregate(cart);
	const { t, kioskI18N } = useKioskTranslation();
	return (
		<WithDialog
			dialog={(open, closeDialog) => (
				<KioskFNBCartOrderDialog
					open={open}
					closeDialog={closeDialog}
					kioskInfo={kioskInfo}
				/>
			)}>
			{(openDialog) => (
				<Button
					onClick={openDialog}
					variant='contained'
					disabled={cart.lines.length === 0}
					disableTouchRipple
					sx={{ height: '100%', width: '100%' }}>
					<Stack>
						<Typography variant={'subtitle2'}>{`${t('common.total')} ${numberWithCommasAnd원(
							cartAggregate.totalToPay,
							kioskI18N
						)}`}</Typography>
						<Typography variant={'h4'}>{t('payment.action')}</Typography>
					</Stack>
				</Button>
			)}
		</WithDialog>
	);
};
