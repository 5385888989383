import { CircularProgress, CircularProgressProps, Stack } from '@mui/material';

export const FullSizeCircularProgress = (props: CircularProgressProps) => {
	return (
		<Stack
			width={'100%'}
			height={'100%'}
			minHeight={120}
			alignItems={'center'}
			justifyContent={'center'}>
			<CircularProgress {...props} />
		</Stack>
	);
};
