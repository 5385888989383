import {
	OrderDetailType,
	OrderStatusType,
	OrderType,
	OrderTypeWithPayments,
} from '@kinderlabs-pos/shared-data-type';
import { PaymentState } from '../../PaymentState';

export const OrderUtil = {
	// getOrderStatus: (order: OrderTypeWithPayments): OrderStatusType => {
	// 	if (!!order.canceled) return 'CANCELED';
	// 	else if (!!order.completed) {
	// 		const remaining = order.aggregate.totalToPay - PaymentState.utils.getTotal(order.payments);
	// 		return remaining === 0 ? 'COMPLETED' : 'PARTIALLY_CANCELED';
	// 	}
	// 	return 'NOT_COMPLETED';
	// },
};
