import { Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { KioskNumberBoard } from './KioskNumberBoard';

export const PinNumberBoard = ({
	pinValues,
	setPinValues,
}: {
	pinValues: string[];
	setPinValues: (pinValues: string[]) => void;
}) => {
	const handleClickPinValue = (value: number) => {
		if (pinValues.length < 6) setPinValues([...pinValues, `${value}`]);
	};

	const clearPinValues = () => {
		setPinValues([]);
	};

	const renderPinValues =
		pinValues.length <= 6
			? [...pinValues.map((v) => '●'), ...new Array(6 - pinValues.length).map((v) => '')]
			: pinValues;

	return (
		<Stack spacing={2}>
			<Stack p={2}>
				<Stack
					direction='row'
					width={'100%'}
					p={2}
					sx={(theme) => ({
						aspectRatio: '4/1',
						borderRadius: 4,
						border: `2px solid ${theme.palette.primary.main}`,
					})}>
					{renderPinValues.map((pv, idx) => {
						return (
							<Stack
								flex={1}
								justifyContent={'center'}
								alignItems={'center'}
								width={'100%'}
								height={'100%'}
								key={idx}>
								<Typography variant='body2'>{pv}</Typography>
							</Stack>
						);
					})}
				</Stack>
			</Stack>
			<KioskNumberBoard
				onClickNumber={(value) => {
					if (value === 'clear') {
						clearPinValues();
					} else if (value === 'delete') {
						setPinValues(pinValues.slice(0, -1));
					} else {
						handleClickPinValue(value);
					}
				}}
			/>
		</Stack>
	);
};
