import { Dayjs } from 'dayjs';

export const downloadExcels =
	(rootUrl: string) =>
	async ({ fileName, from, to }: { fileName: string; from: Dayjs; to: Dayjs }) => {
		window.open(
			`${rootUrl}/payment/excel/all?from=${from.format('YYYY-MM-DD HH:mm:ss')}&to=${to.format('YYYY-MM-DD HH:mm:ss')}`
		);

		// const response = await posAxiosClient.get(`${rootUrl}/payment/excel/all`, {
		// 	params: {
		// 		from: from.format('YYYY-MM-DD'),
		// 		to: to.format('YYYY-MM-DD'),
		// 	},
		// 	responseType: 'blob',
		// 	onDownloadProgress: (progressEvent) => {
		// 		console.log('PROGRESS', progressEvent);
		// 	},
		// });

		// const blob = new Blob([response.data], {
		// 	type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		// });
		// const blobURL = window.URL.createObjectURL(blob);
		// const tempLink = document.createElement('a');

		// tempLink.style.display = 'none';
		// tempLink.href = blobURL;
		// tempLink.setAttribute(
		// 	'download',
		// 	`${fileName} (${from.format('YYYY-MM-DD')} - ${to.format('YYYY-MM-DD')}).xlsx`
		// );

		// document.body.appendChild(tempLink);

		// tempLink.click();

		// document.body.removeChild(tempLink);
		// window.URL.revokeObjectURL(blobURL);
	};
