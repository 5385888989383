import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AdminProductInfoListFilterType,
	BaseInfoType,
	getCategoryIdToSearch,
	Pageable,
	PageType,
	ProductInfoType,
} from '@kinderlabs-pos/shared-data-type';

export const getProductInfoListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, categorySpec, ...options }: AdminProductInfoListFilterType) => {
		return (
			await posAxiosClient.get<PageType<ProductInfoType & BaseInfoType>>(
				`${rootUrl}/product/list/page`,
				{
					params: {
						...options,
						categoryId: getCategoryIdToSearch(categorySpec),
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;
	};
