import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GiftCardProviderType, GiftCardType } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../const';
import { cancelCashReceiptAsync } from './cancelCashReceiptAsync';
import { createPaymentAsync } from './createPaymentAsync';
import { downloadAdminExcel } from './downloadAdminExcel';
import { downloadCreditPointExcel } from './downloadCreditPointExcel';
import { downloadExcels } from './downloadExcels';
import { paymentExcelDownloadUrl } from './downloadPaymentExcel';
import { getInfiniteListAsync } from './getInfiniteListAsync';
import { getListAsync } from './getListAsync';
import { getPaymentsInOrderAsync } from './getPaymentsInOrderAsync';
import { publishCashReceiptAsync } from './publishCashReceiptAsync';
import { refundPaymentAsync } from './refundPaymentAsync';

const searchGiftCard =
	(rootUrl: string) =>
	async ({
		storeId,
		posId,
		keyword,
		provider,
	}: {
		storeId: number;
		posId: number;
		keyword: string;
		provider: GiftCardProviderType;
	}) =>
		(
			await posAxiosClient.get<GiftCardType>(
				`${rootUrl}/payment/store/${storeId}/pos/${posId}/gift-card`,
				{
					params: {
						storeId,
						posId,
						giftCardNumber: keyword,
						giftCardProvider: provider,
					},
				}
			)
		).data;

export const PaymentAPI = {
	publishCashReceipt: publishCashReceiptAsync(rootUrl),
	cancelCashReceipt: cancelCashReceiptAsync(rootUrl),
	getList: getListAsync(rootUrl),
	getInfiniteList: getInfiniteListAsync(rootUrl),
	createPayment: createPaymentAsync(rootUrl),
	refundPayment: refundPaymentAsync(rootUrl),
	getPaymentsInOrder: getPaymentsInOrderAsync(rootUrl),
	downloadExcels: downloadExcels(rootUrl),
	adminDownExcel: downloadAdminExcel(rootUrl),
	paymentExcelDownloadUrl: paymentExcelDownloadUrl(rootUrl),
	creditPointDownExcel: downloadCreditPointExcel(rootUrl),
	searchGiftCard: searchGiftCard(rootUrl),
};
