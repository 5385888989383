import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	AuthorityType,
	BaseInfoType,
	ManagerUserType,
	PageType,
	RevisionType,
} from '@kinderlabs-pos/shared-data-type';

export const getManagerHistoryListAsync =
	(rootUrl: string) =>
	async ({ myAuthority, email }: ManagerHistoryType) =>
		(
			await posAxiosClient.get<PageType<RevisionType<ManagerUserType & BaseInfoType>>>(
				`${rootUrl}/auth-service/manager/member/${myAuthority}/history`,
				{
					params: { id: email, revisionType: 'ALL' },
				}
			)
		).data;

type ManagerHistoryType = {
	myAuthority: AuthorityType;
	email?: string;
};
