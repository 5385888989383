import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VaultCashStatusDetailType } from '@kinderlabs-pos/shared-data-type';

export const getPosStatusAsync =
	(rootUrl: string) =>
	async ({ posId }: { posId: number }) =>
		(
			await posAxiosClient.get<VaultCashStatusDetailType | null>(`${rootUrl}/vault-cash`, {
				params: {
					posId,
				},
			})
		).data;
