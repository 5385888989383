import { Box, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const CircularTimeoutButton = ({
	onTimeoutCallback,
	timeout,
	onClick,
	...others
}: CircularProgressProps & { timeout: number; onTimeoutCallback: () => void }) => {
	const [seconds, setSeconds] = useState(timeout);

	useEffect(() => {
		const mouseEvent = (event: MouseEvent) => {
			setSeconds(timeout);
		};

		const keyDownEvent = (event: KeyboardEvent) => {
			setSeconds(timeout);
		};

		window.addEventListener('mousedown', mouseEvent);
		window.addEventListener('keydown', keyDownEvent);

		return () => {
			window.removeEventListener('mousedown', mouseEvent);
			window.removeEventListener('keydown', keyDownEvent);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => setSeconds((prev) => prev - 1), 1000 * 1);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		if (seconds === 0) onTimeoutCallback();
	}, [seconds]);

	return (
		<Box
			sx={{ position: 'relative', display: 'inline-flex' }}
			onClick={onClick}>
			<CircularProgress
				{...others}
				variant='determinate'
				value={(100 * seconds) / timeout}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<Typography
					variant='body2'
					component='div'
					color='primary'>
					{seconds}
				</Typography>
			</Box>
		</Box>
	);
};
