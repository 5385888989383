import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { StockInfoListType, 재고조정폐기FilterType } from '@kinderlabs-pos/shared-data-type';

export const getStockInfoListAsync =
	(rootUrl: string) =>
	async ({ warehouseId, search }: 재고조정폐기FilterType) =>
		(
			await posAxiosClient.get<StockInfoListType[]>(`${rootUrl}/stock/search`, {
				params: {
					warehouseId,
					search,
				},
			})
		).data;
