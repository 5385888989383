import { alpha, FormControl, FormControlProps, InputBase, InputLabel, styled } from "@mui/material";
import { ReactNode } from "react";



export interface ILabeledTextFieldProps extends FormControlProps {
  label: ReactNode
  value?: string
}
export const LabeledTextField: React.FC<ILabeledTextFieldProps> = ({
  label,
  defaultValue,
  value
}) => {
  return (
    <FormControl variant="standard">
      <InputLabel shrink htmlFor="labeled-input">
        {label}
      </InputLabel>
      <StyledTextField defaultValue={defaultValue} id="labeled-input" />
    </FormControl>
  )
};
const StyledTextField = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fcfcfb',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
