import { alpha, Stack, styled, Theme } from '@mui/material';
import { grey } from "@mui/material/colors";

export const CardStack = styled(Stack, { shouldForwardProp: (props) => props !== "variant" })
  <{
    variant?: "default" | "nocolors"
  }>(({ theme, variant = "default" }) => ({
    border: variant === "default" ? `1px solid ${grey[300]}` : "unset",
    backgroundColor: variant === "default" ? "white" : "unset",
    padding: variant === "default" ? theme.spacing(1) : 0
  }))