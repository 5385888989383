import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VaunceMemberInfoType } from '@kinderlabs-pos/shared-data-type';

export const getUserMemberAsync = (rootUrl: string) => {
	return async ({ userId }: { userId: string }) =>
		(
			await posAxiosClient.get<VaunceMemberInfoType | null>(`${rootUrl}/pos-bff/user`, {
				params: {
					userId,
				},
			})
		).data;
};
