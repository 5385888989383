import { PoweroffOutlined } from '@ant-design/icons';
import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import { VersionDisplay } from '@kinderlabs-pos/ui-atoms';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, Stack, Typography } from '@mui/material';

export const KioskLocalAdminFooter = () => {
	const customConfirm = useConfirm();

	const handleClickPowerOff = () => {
		customConfirm('정말 종료하시겠습니까?', () => {
			ReactCommonCommandIPC.closeApp();
		});
	};

	return (
		<Stack
			p={3}
			direction={'row'}
			color={'white'}
			position={'relative'}>
			<Stack alignItems={'center'}>
				<Button
					onClick={handleClickPowerOff}
					startIcon={<PoweroffOutlined />}>
					{/* <Stack
							spacing={2}
							direction={'row'}
							alignItems={'center'}>
							<Typography variant='h6'>
								<PoweroffOutlined />
							</Typography> */}
					<Typography variant='h6'>전원 종료</Typography>
					{/* </Stack> */}
				</Button>
			</Stack>
			<Stack
				flex={1}
				position={'relative'}>
				<VersionDisplay />
			</Stack>
		</Stack>
	);
};
