import { isOnElectron } from '@kinderlabs-pos/ipc/react';
import { authState } from '@kinderlabs-pos/state';
import { Box, Button, Stack, Typography, alpha, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import entry from 'assets/entry.jpg';
import { Suspense, useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useRoutes } from 'react-router-dom';
import { NavigateToHome, kioskRouteObject } from '../utils/useKioskRouter';
import { KioskEasyLoginSetter } from './KioskEasyLoginSetter';
import * as Sentry from '@sentry/react';
import { ConfirmProvider } from '@kinderlabs-pos/ui-components';
import { useKioskTranslation } from '../const/useKioskTranslation';

export function App() {
	const routes = useRoutes([kioskRouteObject, NavigateToHome]);
	const { kioskI18N } = useKioskTranslation();
	return (
		<ConfirmProvider
			variant='large'
			i18n={kioskI18N}>
			<Stack
				position={'relative'}
				sx={{
					...(isOnElectron
						? {
								width: ['100dvw', '100vw'],
								height: ['100dvh', '100vh'],
						  }
						: {
								width: [1080],
								aspectRatio: '1080/1920',
						  }),
				}}
				overflow={'auto'}>
				<ErrorBoundary
					FallbackComponent={전광판ImageErrorFallbackComponent}
					onReset={() => location.reload()}>
					<Suspense>
						<KioskEasyLoginSetter />
						<authState.InfiniteAuthRefresh />
					</Suspense>

					{routes}
				</ErrorBoundary>
			</Stack>
		</ConfirmProvider>
	);
}
export default App;

const 전광판ImageErrorFallbackComponent: React.FC<FallbackProps> = ({
	error,
	resetErrorBoundary,
}) => {
	useEffect(() => {
		Sentry.withScope((scope) => {
			scope.setTags({
				Type: '전광판',
				Where: 'KIOSK',
			});
			Sentry.captureException(error);
		});
	}, [error]);

	return (
		<전광판Image>
			<Stack
				width={'100%'}
				height={'100%'}
				alignItems={'center'}
				justifyContent={'center'}
				sx={{ backgroundColor: alpha(grey[800], 0.8) }}>
				<Stack spacing={2}>
					<Typography
						variant={'h5'}
						color={'white'}>
						서버와의 접속이 끊어졌습니다.
					</Typography>
					<Button
						variant='outlined'
						sx={{ backgroundColor: 'white' }}
						onClick={resetErrorBoundary}>
						<Typography variant={'h5'}>연결 재시도</Typography>
					</Button>
				</Stack>
			</Stack>
		</전광판Image>
	);
};

const 전광판Image = styled(Box)<{
	backgroundImage?: string;
	// salesStatus?: 'CLOSED' | 'BEFORE_OPEN';
}>(() => ({
	position: 'relative',
	backgroundImage: `url(${entry})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '100%',
	height: '100%',
}));
