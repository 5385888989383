import * as CONST from '@kinderlabs-pos/shared-const';
import {
	CustomerReceiptRequestType,
	LabelRequestType,
	PaymentResponseType,
} from '@kinderlabs-pos/shared-data-type';
import dayjs from 'dayjs';
import * as fs from 'fs';
import path from 'path';

export const makeLog = (
	logType: 'payment' | 'label' | 'receipt' | 'etc',
	infos: PaymentResponseType | LabelRequestType | CustomerReceiptRequestType | string,
	message?: string
) => {
	/** Check environment */
	const isNotWindowOS = process.env['NODE_ENV'] === 'sample' || process.platform !== 'win32';
	if (isNotWindowOS) {
		return;
	}

	/** Check dir & file existence */
	if (logType === 'payment' && !fs.existsSync(CONST.HW_PM_JSON_PATH))
		fs.mkdirSync(CONST.HW_PM_JSON_PATH, { recursive: true });
	else if (logType === 'label' && !fs.existsSync(CONST.HW_LP_JSON_PATH))
		fs.mkdirSync(CONST.HW_LP_JSON_PATH, { recursive: true });
	else if (logType === 'receipt' && !fs.existsSync(CONST.HW_RP_JSON_PATH))
		fs.mkdirSync(CONST.HW_RP_JSON_PATH, { recursive: true });
	else if (logType === 'etc' && !fs.existsSync(CONST.HW_ETC_JSON_PATH))
		fs.mkdirSync(CONST.HW_ETC_JSON_PATH, { recursive: true });

	/** Parse data to log */
	let textToWrite: string, fileName: string, targetPath: string;
	switch (logType) {
		case 'payment': {
			const info = infos as PaymentResponseType;
			const date = info.approvalDate;
			// prettier-ignore
			textToWrite = `[${dayjs(date).format('YYYY-MM-DD HH:mm:ss')}] 거래구분: ${info.transactionClassification} / 거래유형: ${info.transactionType} / 응답코드: ${info.responseCode} / 거래금액: ${info.amount} / 부가세: ${info.tax} / 봉사료: ${info.tip} / 할부개월: ${info.installment} / 승인번호: ${info.approvalId} / 승인일시: ${dayjs(info.approvalDate).format('YYYY-MM-DD HH:mm:ss')} / 발급사코드: ${info.issuerCode} / 발급사명: ${info.issuer} / 매입사코드: ${info.acquirerCode} / 매입사명: ${info.acquirer} / 가맹점번호: ${info.merchantNumber} 승인CATID: ${info.catId} / 잔액: ${info.balance} / 응답메시지: ${info.responseMessage} / BIN: ${info.bin} / 카드구분: ${info.cardPaymentType} / 전문관리번호: ${info.textManageId} / 거래일련번호: ${info.transactionId} / 공급가액: ${info.supply} / 소비사업자구분: ${info.isEnterprise}\r\n`;
			fileName = `${dayjs(date).format('YYYY-MM-DD')}.txt`;
			targetPath = path.join(CONST.HW_PM_JSON_PATH, fileName);
			break;
		}
		case 'label': {
			const info = infos as LabelRequestType;
			const date = info.message.timeEnter;
			// prettier-ignore
			textToWrite = `[${dayjs(date).format('YYYY-MM-DD HH:mm:ss')}] 장치번호: ${info.deviceNum} / 장치종류: ${info.deviceType} / 라벨타입: ${info.message.labelType} / 라벨번호: ${info.message.labelId} / 라벨이름: ${info.message.labelName} / 입장시각: ${info.message.timeEnter} / 퇴장시각: ${info.message.timeExit} / 사용시간: ${info.message.timeUse}\r\n`;
			fileName = `${dayjs(date).format('YYYY-MM-DD')}.txt`;
			targetPath = path.join(CONST.HW_LP_JSON_PATH, fileName);
			break;
		}
		case 'receipt': {
			const info = infos as CustomerReceiptRequestType;
			const date = info.receipt.cancelDate ? info.receipt.cancelDate : info.receipt.orderDate;
			textToWrite = `[${dayjs(date).format('YYYY-MM-DD HH:mm:ss')}] 주문번호 : ${
				info.orderId
			} / 장치종류: ${info.hardwareInfo?.deviceType} / 프린트방식: ${
				info.hardwareInfo?.printMode
			} / 포스번호 : ${info.hardwareInfo?.printerInfo.deviceId} / 로지컬네임 : ${
				info.hardwareInfo?.printerInfo.logicalName
			} / 모델명 : ${info.hardwareInfo?.printerInfo.modelName} / 영수증타입 : ${
				info.hardwareInfo?.printerInfo.hardwareType.usageType
			} / 연결방식: ${info.hardwareInfo?.printerInfo.connectionType.mode} / 메시지 : ${
				message ? message : '없음'
			}`;
			textToWrite += `\r\n`;
			fileName = `${dayjs(date).format('YYYY-MM-DD')}.txt`;
			targetPath = path.join(CONST.HW_RP_JSON_PATH, fileName);
			break;
		}
		case 'etc': {
			const info = infos as string;
			const date = new Date();
			// prettier-ignore
			textToWrite = `[${dayjs(date).format('YYYY-MM-DD HH:mm:ss')}] ${info}\r\n`;
			fileName = `${dayjs(date).format('YYYY-MM-DD')}.txt`;
			targetPath = path.join(CONST.HW_ETC_JSON_PATH, fileName);
			break;
		}
	}

	/** Generate file */
	fs.appendFileSync(targetPath, textToWrite, 'utf8');
};
