import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { MultiUseTicketType, Pageable, PageType } from '@kinderlabs-pos/shared-data-type';

export const getMutListAsync =
	(rootUrl: string) =>
	async ({ memberId, pageable }: GetMutListAsyncRequestType) =>
		(
			await posAxiosClient.get<PageType<MultiUseTicketType>>(`${rootUrl}/ticket/mut/list`, {
				params: {
					...pageable.getPageableQueryParam(),
					memberId,
				},
			})
		).data;

export type GetMutListAsyncRequestType = {
	memberId: number;
	pageable: Pageable;
};
