import {
	Button,
	Dialog,
	DialogActions,
	DialogProps,
	DialogTitle,
	styled,
	SxProps,
} from '@mui/material';
import { ReactNode } from 'react';

export interface IXlDialogProps extends Omit<DialogProps, 'onClose' | 'title'> {
	closeDialog?: () => void;
	dialogTitle?: ReactNode;
	children: ReactNode;
	closeDialogAction?: string;
	titleSxProps?: SxProps;
}
export const XlDialog: React.FC<IXlDialogProps> = ({
	open,
	maxWidth,
	closeDialog,
	dialogTitle,
	closeDialogAction,
	children,
	titleSxProps,
	...others
}) => {
	return (
		<StyledDialog
			open={open}
			maxWidth={maxWidth || 'xl'}
			scroll={'paper'}
			onClose={closeDialog}
			{...others}
			onClick={(e) => e.stopPropagation()}>
			{dialogTitle && (
				<DialogTitle sx={titleSxProps ? titleSxProps : undefined}>{dialogTitle}</DialogTitle>
			)}
			{children}
			{closeDialogAction && (
				<DialogActions>
					<Button onClick={closeDialog}>{closeDialogAction}</Button>
				</DialogActions>
			)}
		</StyledDialog>
	);
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		paddingBottom: 0,
		'& .MuiPaper-root': {
			maxWidth: 'unset',
		},
	},
}));
