import { KioskType } from '@kinderlabs-pos/shared-data-type';
import { IndexRouteObject, Navigate, NonIndexRouteObject, useNavigate } from 'react-router-dom';
import { HomeForEntery } from '../pages/HomeForEntry';
import { KioskLocalAdminPage } from '../pages/KioskLocalAdminPage';
import { KioskMainPage } from '../pages/KioskMainPage';
import { KioksUserInfoPage } from '../pages/KioskUserInfoMappingPage';
import { KioskAutoStoreTemplate } from './KioskAutoStoreTemplate';

type CustomRouteObject = Omit<NonIndexRouteObject, 'children' | 'path'> & {
	children?: CustomRouteObject[];
	path: string;
};

export const kioskRouteObject: CustomRouteObject = {
	path: 'main',
	element: <KioskAutoStoreTemplate />,
	children: [
		{
			path: 'kiosk-board',
			element: <KioskMainPage type={'FANDB'} />,
		},
		{
			path: 'online-tickets',
			element: <KioskMainPage type={'ONLINE_TICKET'} />,
		},
		{
			path: 'kiosk-exit',
			element: <KioskMainPage type={'JUNGSAN'} />,
		},
		{
			path: 'local-admin',
			element: <KioskLocalAdminPage />,
		},
		{
			path: 'userInfo/:type',
			element: <KioksUserInfoPage />,
		},
		{
			path: '',
			element: <HomeForEntery />,
		},
	],
};

export const NavigateToHome: IndexRouteObject = {
	path: '',
	index: true,
	element: (
		<Navigate
			to={`/main`}
			replace={true}
		/>
	),
};

export const useKioskRouter = () => {
	const navigate = useNavigate();

	return {
		navigate홈으로: () => {
			navigate('/main');
		},
		navigate키오스크보드: () => {
			navigate('/main/kiosk-board');
		},
		navigate온라인권발권: () => {
			navigate('/main/online-tickets');
		},
		navigate퇴장페이지: () => {
			navigate('/main/kiosk-exit');
		},
		navigate로컬어드민: () => {
			navigate('/main/local-admin');
		},
		navigate퇴장홈으로: () => {
			navigate('/main');
		},
		navigateUserInfoPage: (kioskType: KioskType | 'ONLINE-TICKETS') => {
			navigate(`/main/userInfo/${kioskType}`);
		},
		navigateKioskBoardPageByKioskType: (kioskType: KioskType | 'ONLINE-TICKETS') => {
			switch (kioskType) {
				case 'ONLINE-TICKETS':
					navigate('/main/online-tickets');
					break;
				case 'TICKETS':
				case 'FANDB':
					navigate('/main/kiosk-board');
					break;
				case 'JUNGSAN':
					navigate('/main/kiosk-exit');
					break;
				case 'CUSTOMER_MAPPING':
					break;
			}
		},
	};
};
