import { AdminVendorInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { AdminVendorInfoListFilterType, VendorCommandType } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { QueryClient } from '../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const vendorKeys = createQueryKeys('vendor', {
	list: ({ params }: { params: AdminVendorInfoListFilterType }) => ({
		queryKey: [params],
		queryFn: async () => {
			const res = await AdminVendorInfoApis.getVendorInfoList(params);
			return res;
		},
	}),
	detail: (vendorId?: number) => ({
		queryKey: [vendorId],
		queryFn: async () => {
			if (!vendorId) return null;
			const res = await AdminVendorInfoApis.getVendorDetailInfo(vendorId);
			return res;
		},
	}),
});

const useCreateVendorInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<VendorCommandType, 'id'>) => {
			await AdminVendorInfoApis.createVendorInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateVendorInfo = () => {
	return QueryClient.useMutation(
		async (input: VendorCommandType) => {
			await AdminVendorInfoApis.updateVendorInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteVendorInfo = () => {
	return QueryClient.useMutation(
		async (deleteId: number) => {
			await AdminVendorInfoApis.deleteVendorInfo(deleteId);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: vendorKeys._def });
};

export const AdminVendorInfoState = {
	keys: vendorKeys,

	createVendorInfo: useCreateVendorInfo,
	updateVendorInfo: useUpdateVendorInfo,
	deleteVendorInfo: useDeleteVendorInfo,
};
