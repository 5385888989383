import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	GuestUserType,
	GuestVisitType,
	VisitUserType,
	WaiverInfoType,
	isRequired유저상세정보,
} from '@kinderlabs-pos/shared-data-type';

/**
 * mainUserInfo 는 부모
 * users 는 자식으로 생각해주세요
 */
interface RegisterGuestVisitProps {
	storeId: number;
	waiverId: number;
	mainUserInfo: GuestUserType;
	users: (Omit<GuestUserType, 'relationship'> & {
		relationship: Omit<GuestUserType['relationship'], 'SELF'>;
	})[];
}
export const registerGuestVisitAsync =
	(rootUrl: string) =>
	async ({ waiverId, storeId, mainUserInfo, users }: RegisterGuestVisitProps) =>
		(
			await posAxiosClient.post<GuestVisitType>(
				`${rootUrl}/guest-visit/store/${storeId}/waiver/${waiverId}/publish`,
				{
					users: [mainUserInfo, ...users],
					mainUserPhone: mainUserInfo.telephone,
				}
			)
		).data;

type RegisterGuestVisitPropsV2 = {
	storeId: number;
	waiverId: number;
	memberId: number;
	visitInfo:
		| {
				구분: '입장인원입력없음';
				visitCount: undefined;
				users: undefined;
		  }
		| {
				구분: '입장인원수정보만';
				visitCount: GuestVisitType['visitCount'];
				users: undefined;
		  }
		| {
				구분: '입장인원상세정보';
				visitCount: GuestVisitType['visitCount'];
				users: Omit<GuestUserType, 'relationship'>[];
		  };
};

export const registerGuestVisitV2Async =
	(rootUrl: string) =>
	async ({
		waiverId,
		storeId,
		memberId,
		visitInfo: { users, visitCount },
	}: RegisterGuestVisitPropsV2) =>
		(
			await posAxiosClient.post<GuestVisitType>(
				`${rootUrl}/guest-visit/store/${storeId}/waiver/${waiverId}/publish`,
				{
					memberId,
					users,
					visitCount,
				}
			)
		).data;

export const registerGuestVisitV2지류티켓Async =
	(rootUrl: string) =>
	async ({ waiverId, storeId }: { storeId: number; waiverId: number }) =>
		(
			await posAxiosClient.post<GuestVisitType>(
				`${rootUrl}/guest-visit/store/${storeId}/waiver/${waiverId}/publish`,
				{}
			)
		).data;

export const getVisitInfoBy입장인원정보 = ({
	waiverInfo,
	users,
}: {
	waiverInfo: WaiverInfoType;
	users: Omit<VisitUserType, 'telephone'>[];
}): GuestVisitCountUserInfoTypeBy구분 => {
	if (!waiverInfo.isVisitCountEnabled) {
		return {
			구분: '입장인원입력없음',
			visitCount: undefined,
			users: undefined,
		};
	}

	const visitCount: GuestVisitType['visitCount'] = {
		totalCount: users.length,
		normalCount: users.filter((user) => user.guestType === 'NORMAL').length,
		childCount: users.filter((user) => user.guestType === 'CHILD').length,
	};
	if (isRequired유저상세정보(waiverInfo.waitingInfo)) {
		return {
			구분: '입장인원상세정보',
			visitCount: visitCount,
			users: users,
		};
	} else {
		return {
			구분: '입장인원수정보만',
			visitCount: visitCount,
			users: undefined,
		};
	}
};
export type GuestVisitCountUserInfoTypeBy구분 =
	| {
			구분: '입장인원입력없음';
			visitCount: undefined;
			users: undefined;
	  }
	| {
			구분: '입장인원수정보만';
			visitCount: GuestVisitType['visitCount'];
			users: undefined;
	  }
	| {
			구분: '입장인원상세정보';
			visitCount: GuestVisitType['visitCount'];
			users: Omit<GuestUserType, 'relationship'>[];
	  };
