import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { GuestVisitType } from '@kinderlabs-pos/shared-data-type';

interface CallGuestVisitProps {
	storeId: number;
	posId: number;
	id?: number;
}
export const callAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, id }: CallGuestVisitProps) => {
		// try {
		const { data, status } = await posAxiosClient.post<GuestVisitType | null>(
			`${rootUrl}/guest-visit/store/${storeId}/pos/${posId}/call${id ? `?id=${id}` : ''}`
		);

		if (status === 204) {
			alert('대기 목록이 없습니다.');
			return null;
		}
		return data;
		// } catch (err) {
		// 	alert(err);
		// 	return null;
		// }
	};
