import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	BaseInfoType,
	MutInfoListFilterType,
	MutInfoType,
	Pageable,
	PageType,
} from '@kinderlabs-pos/shared-data-type';

export const getMutInfoListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: MutInfoListFilterType) =>
		(
			await posAxiosClient.get<PageType<MutInfoType & BaseInfoType>>(`${rootUrl}/mut/list`, {
				params: {
					...options,
					...Pageable.getPageable({
						page,
						size,
						sort,
					}).getPageableQueryParam(),
				},
			})
		).data;
