import { FileAddOutlined } from '@ant-design/icons';
import { alpha, Stack, Typography } from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';

export const AdminFileUploader = ({
	handleBlur,
	handleChange,
	types,
	file,
	label,
}: {
	handleBlur: any;
	handleChange: any;
	types: string[];
	file?: File;
	label: string;
}) => {
	return (
		<FileUploader
			hoverTitle={false}
			handleBlur={handleBlur}
			handleChange={handleChange}
			types={types}>
			<Stack
				alignItems={'center'}
				justifyContent={'center'}
				spacing={1}
				p={1}
				height={100}
				sx={(theme) => ({
					border: `1px dashed`,
					backgroundColor: alpha(theme.palette.secondary.light, 0.1),
					cursor: 'Pointer',
				})}>
				{file ? (
					<Stack spacing={1}>
						<Typography variant='body1'>{file.name}</Typography>
					</Stack>
				) : (
					<Stack
						spacing={0.5}
						alignItems={'center'}>
						<Typography color={'text.secondary'}>
							<FileAddOutlined />
						</Typography>
						<Typography color={'text.secondary'}>{label}</Typography>
					</Stack>
				)}
			</Stack>
		</FileUploader>
	);
};
