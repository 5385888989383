import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, WaiverInfoType } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../../const';

const getWaiverInfoAsync =
	(rootUrl: string) =>
	async ({ storeId, deviceId }: { storeId: number; deviceId: number }) =>
		(
			await posAxiosClient.get<WaiverInfoType & BaseInfoType>(`${rootUrl}/store/waiver`, {
				params: { storeId, id: deviceId },
			})
		).data;

const getWaiverInfoListAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<(WaiverInfoType & BaseInfoType & { isConnected: boolean })[]>(
				`${rootUrl}/store/waiver/list`,
				{
					params: { storeId },
				}
			)
		).data;

const updateWaiverInfoAsync =
	(rootUrl: string) => async (updateWaiverInfoRequestInfo: WaiverInfoType) =>
		(
			await posAxiosClient.put<WaiverInfoType & BaseInfoType>(
				`${rootUrl}/store/waiver`,
				{
					...updateWaiverInfoRequestInfo,
				},
				{
					params: {
						id: updateWaiverInfoRequestInfo.id,
					},
				}
			)
		).data;
const deleteWaiverInfoListAsync =
	(rootUrl: string) =>
	async ({ idList }: { idList: number[] }) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/store/waiver/list`, {
				params: {
					idList,
				},
			})
		).data;

const createWaiverInfoAsync =
	(rootUrl: string) => async (createWaiverInfoRequestInfo: Omit<WaiverInfoType, 'id'>) =>
		(
			await posAxiosClient.post<WaiverInfoType & BaseInfoType>(`${rootUrl}/store/waiver`, {
				...createWaiverInfoRequestInfo,
			})
		).data;

export const AdminWaiverInfoApis = {
	getWaiverInfo: getWaiverInfoAsync(rootUrl),
	getWaiverInfoList: getWaiverInfoListAsync(rootUrl),
	createWaiverInfo: createWaiverInfoAsync(rootUrl),
	deleteWaiverInfoList: deleteWaiverInfoListAsync(rootUrl),
	updateWaiverInfo: updateWaiverInfoAsync(rootUrl),
};
