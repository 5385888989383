import { rootUrl } from '../../const';
import { createPosInfoAsync } from './createPosInfoAsync';
import { deleteLablePrinterAsync } from './deleteLabelPrinterAsync';
import { deletePosInfoAsync } from './deletePosInfoAsync';
import { deletePosInfoListAsync } from './deletePosInfoListAsync';
import { deleteReceiptPrinterAsync } from './deleteReceiptPrinterInfoAsync';
import { getPosInfoAsync } from './getPosInfoAsync';
import { getPosInfoListAsync } from './getPosInfoListAsync';
import { getPosPrinterModelListAsync } from './getPosPrinterModelListAsync';
import { updateAutomationOptionAsync } from './updateAutomationOptionAsync';
import { updateFileAsync } from './updateFileUrlAsync';
import { updateLabelPrinterInfoAsync } from './updateLablePrinterInfo';
import { updateParkingUrlAsync } from './updateParkingUrlAsync';
import { updatePosInfoAsync } from './updatePosInfoAsync';
import { updateReceiptPrinterInfoAsync } from './updateReceiptPrinterInfoAsync';

export const AdminPosInfoApis = {
	getPosInfoList: getPosInfoListAsync(rootUrl),
	getPosInfo: getPosInfoAsync(rootUrl),
	createPosInfo: createPosInfoAsync(rootUrl),
	deletePosInfo: deletePosInfoAsync(rootUrl),
	deletePosInfoList: deletePosInfoListAsync(rootUrl),
	updatePosInfo: updatePosInfoAsync(rootUrl),
	updateAutomationOption: updateAutomationOptionAsync(rootUrl),
	updateParkingUrl: updateParkingUrlAsync(rootUrl),
	// updateReceiptPrinterInfo: updateReceiptPrinterInfoAsync(rootUrl),
	// deleteReceiptPrinter: deleteReceiptPrinterAsync(rootUrl),
	// updateLabelPrinterInfo: updateLabelPrinterInfoAsync(rootUrl),
	// deleteLabelPrinter: deleteLablePrinterAsync(rootUrl),
	updateFile: updateFileAsync(rootUrl),
	getPosPrinterModelList: getPosPrinterModelListAsync(rootUrl),
};
