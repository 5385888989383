import {
	KioskInfoType,
	PaymentRequestInfoType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { sleep } from '@kinderlabs-pos/shared-util';
import { OrderState, PaymentState } from '@kinderlabs-pos/state';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import icCardImage from 'assets/ic-card.gif';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useFormik } from 'formik';
import { useKioskTranslation } from '../../../../const/useKioskTranslation';

const RETRY_LIMIT = 3;
export const KioskNoPaymentSection = ({
	goBack,
	storeInfo,
	kioskInfo,
	handleSubmitPayment,
}: {
	goBack: () => void;
	storeInfo: StoreInfoType;
	kioskInfo: KioskInfoType;
	handleSubmitPayment: () => Promise<void>;
}) => {
	const { handleSubmit, isSubmitting, isValid, values, handleChange, setValues, errors } =
		useFormik({
			initialValues: { failedPaymentRequestData: undefined },
			onSubmit: async () => {
				await handleSubmitPayment();
			},
		});

	useEffect(() => {
		(async () => {
			handleSubmitPayment();
		})();
	}, []);

	const { t } = useKioskTranslation();

	// const { isServerError, isRetrying } = useRetryOrder({
	// 	handleSubmitPayment,
	// });

	return (
		<Stack flex={1}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>{t('payment.cardTitle')}</Typography>
			</Stack>
			{/* {isServerError &&
				(isRetrying ? (
					<FullSizeCircularProgress />
				) : (
					<Stack
						flex={1}
						alignItems={'center'}
						justifyContent={'center'}
						width={'100%'}>
						<FullSizeCircularProgress />
						<Typography variant={'subtitle1'}>서버 에러입니다.</Typography>
						<Typography variant={'subtitle1'}>결제를 취소하고 돌아갑니다.</Typography>
					</Stack>
				))} */}
			{/* {이미꽂힌카드가있는지 && (
				<Stack
					flex={1}
					alignItems={'center'}
					justifyContent={'center'}
					width={'100%'}>
					<FullSizeCircularProgress />
					<Typography variant={'subtitle1'}>이미꽂힌카드</Typography>
				</Stack>
			)} */}
			{!values.failedPaymentRequestData && (
				<Stack
					flex={1}
					width={'100%'}
					alignItems={'center'}
					py={5}
					spacing={5}>
					<Typography
						variant='h6'
						color={'text.secondary'}>
						{t('payment.cardLabel1')}
						<br />
						{t('payment.cardLabel2')}
					</Typography>
					<Stack
						width={'100%'}
						height={'100%'}
						justifyContent={'center'}
						alignItems={'center'}>
						<Stack
							width={480}
							height={480}>
							<카드삽입Image />
						</Stack>
					</Stack>
				</Stack>
			)}
			<Stack alignItems={'center'}>
				{/* <Typography
					variant='subtitle1'
					color={'text.secondary'}>
					{!isServerError
						? errors.submit || 'ㅤ'
						: !isRetrying
						? errors.submit || 'ㅤ'
						: '결제정보 전송 중'}
				</Typography> */}
			</Stack>
			<Stack
				width={'100%'}
				direction={'row'}
				py={3}
				spacing={3}>
				<Button
					variant='outlined'
					fullWidth
					disabled={isSubmitting}
					onClick={goBack}>
					{t('common.return')}
				</Button>
			</Stack>
		</Stack>
	);
};

const 카드삽입Image = styled(Box)(() => ({
	backgroundImage: `url(${icCardImage})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '100%',
	height: '100%',
}));
