import { ActionBoard, IActionBoardProps } from '@kinderlabs-pos/ui-components';
import { adminTheme, kioskTheme } from '@kinderlabs-pos/ui/themes';
import { Stack, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';

export interface IKioskBoardProps extends Omit<IActionBoardProps, 'numOfColumns'> {
	inAdmin?: boolean;
}

export const KioskBoard: React.FC<IKioskBoardProps> = ({ children, inAdmin = false, ...props }) => {
	// admin 과 kiosk 에 나오는 화면을 같게 유지하기 위함
	const appliedTheme = inAdmin
		? deepmerge(kioskTheme, { palette: adminTheme.palette })
		: kioskTheme;

	return (
		<ThemeProvider theme={appliedTheme}>
			<ActionBoard
				numOfColumns={3}
				{...props}>
				{children}
			</ActionBoard>
		</ThemeProvider>
	);
};
