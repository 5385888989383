import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { StockInfoListByWarehouse } from '@kinderlabs-pos/shared-data-type';

export const getCommomnProductListAsync =
	(rootUrl: string) =>
	async ({ warehouseId, vendorId }: GetStockInfoForWarehouseRequestType) =>
		(
			await posAxiosClient.get<StockInfoListByWarehouse[]>(
				`${rootUrl}/stock/list/warehouse/${warehouseId}/vendor/${vendorId}`
			)
		).data;

type GetStockInfoForWarehouseRequestType = {
	warehouseId: number;
	vendorId: number;
};
