import {
	AdminKioskInfoState,
	KioskElectronStoreState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { useConfirm } from '@kinderlabs-pos/ui-components';
import { Button, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';

export const KioskAutomationPanel = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({
			storeId: storeInfo.id,
			kioskId: savedKioskInfo.id,
		}),
		refetchOnWindowFocus: true,
	});

	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();

	return (
		<Stack spacing={5}>
			<Stack
				width='100%'
				flexDirection={'row'}
				alignItems={'center'}>
				<FormControlLabel
					control={
						<Switch
							checked={localSettings?.receiptAutoPrint}
							onChange={(e) =>
								localSettings &&
								saveLocalSettingsMutation.mutate({
									...localSettings,
									receiptAutoPrint: e.target.checked,
								})
							}
						/>
					}
					label={<Typography variant={'subtitle1'}>영수증 자동 출력 설정</Typography>}
				/>
			</Stack>
			<Stack>
				<FormControlLabel
					control={
						<Switch
							checked={localSettings?.showScrollButtons}
							onChange={(e) =>
								localSettings &&
								saveLocalSettingsMutation.mutate({
									...localSettings,
									showScrollButtons: e.target.checked,
								})
							}
						/>
					}
					label={<Typography variant={'subtitle1'}>스크롤 버튼 사용</Typography>}
				/>
			</Stack>
			<Stack>
				<FormControlLabel
					control={
						<Switch
							checked={localSettings?.skipMembership}
							onChange={(e) =>
								localSettings &&
								saveLocalSettingsMutation.mutate({
									...localSettings,
									skipMembership: e.target.checked,
								})
							}
						/>
					}
					label={<Typography variant={'subtitle1'}>비회원 구매/출력 횔성화</Typography>}
				/>
			</Stack>
			{kioskInfo?.type === 'FANDB' && (
				<Stack>
					<FormControlLabel
						control={
							<Switch
								checked={localSettings?.isMemberInfoRequiredForFABKiosk}
								onChange={(e) =>
									localSettings &&
									saveLocalSettingsMutation.mutate({
										...localSettings,
										isMemberInfoRequiredForFABKiosk: e.target.checked,
									})
								}
							/>
						}
						label={
							<Typography variant={'subtitle1'}>
								{'메뉴화면 진입 전, 티켓/회원정보 수집'}
							</Typography>
						}
					/>
				</Stack>
			)}
			<온라인권안내메세지Formik />
			{/* <온라인권영수증메세지Formik /> */}
		</Stack>
	);
};

const 온라인권안내메세지Formik = () => {
	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();
	const formik = useFormik<{ kor?: string | undefined; eng?: string | undefined }>({
		initialValues: localSettings?.onlineTicketMessage ?? {
			kor: '온라인권을 사용하시겠습니까?',
			eng: undefined,
		},
		onSubmit: (value) => {
			localSettings &&
				saveLocalSettingsMutation.mutate({
					...localSettings,
					onlineTicketMessage: {
						kor: value.kor !== '' ? value.kor : undefined,
						eng: value.eng !== '' ? value.eng : undefined,
					},
				});
		},
	});
	const customConfirm = useConfirm();
	const handleClickSave = () =>
		customConfirm('변경사항을 저장하시겠습니까?', () => formik.handleSubmit());

	return (
		<Stack spacing={1}>
			<Stack
				spacing={2}
				direction={'row'}
				justifyContent={'space-between'}>
				<Stack>
					<Typography variant={'subtitle1'}>온라인권 안내 문구</Typography>
					<Typography
						variant={'body2'}
						color='text.secondary'>
						온라인권 사용시 고객에게 표시될 문구입니다.
					</Typography>
				</Stack>
				<Button
					variant='contained'
					onClick={handleClickSave}>
					저장
				</Button>
			</Stack>
			<Stack spacing={2}>
				<Stack
					direction={'row'}
					alignItems={'center'}
					spacing={2}>
					<Typography variant='subtitle2'>한글</Typography>
					<TextField
						variant='outlined'
						multiline
						minRows={2}
						sx={{ flex: 1 }}
						placeholder='온라인권을 사용하시겠습니까?'
						value={formik.values.kor}
						name='kor'
						onChange={formik.handleChange}
					/>
				</Stack>
				<Stack
					direction={'row'}
					alignItems={'center'}
					spacing={2}>
					<Typography variant='subtitle2'>영어</Typography>
					<TextField
						variant='outlined'
						multiline
						minRows={2}
						sx={{ flex: 1 }}
						placeholder='Would you like to use online tickets?'
						value={formik.values.eng}
						name='eng'
						onChange={formik.handleChange}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};

const 온라인권영수증메세지Formik = () => {
	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();
	const formik = useFormik<{ kor?: string | undefined; eng?: string | undefined }>({
		initialValues: localSettings?.onlineTicketReceiptMessage ?? {
			kor: undefined,
			eng: undefined,
		},
		onSubmit: (value) => {
			localSettings &&
				saveLocalSettingsMutation.mutate({
					...localSettings,
					onlineTicketReceiptMessage: {
						kor: value.kor !== '' ? value.kor : undefined,
						eng: value.eng !== '' ? value.eng : undefined,
					},
				});
		},
	});
	const customConfirm = useConfirm();
	const handleClickSave = () =>
		customConfirm('변경사항을 저장하시겠습니까?', () => formik.handleSubmit());

	return (
		<Stack spacing={1}>
			<Stack
				spacing={2}
				direction={'row'}
				justifyContent={'space-between'}>
				<Stack>
					<Typography variant={'subtitle1'}>온라인권 티켓 문구</Typography>
					<Typography
						variant={'body2'}
						color='text.secondary'>
						온라인권 티켓에 출력되어 표시될 문구입니다.
					</Typography>
				</Stack>
				<Button
					variant='contained'
					onClick={handleClickSave}>
					저장
				</Button>
			</Stack>
			<Stack spacing={2}>
				<Stack
					direction={'row'}
					alignItems={'center'}
					spacing={2}>
					<Typography variant='subtitle2'>한글</Typography>
					<TextField
						variant='outlined'
						multiline
						minRows={2}
						sx={{ flex: 1 }}
						placeholder=''
						value={formik.values.kor}
						name='kor'
						onChange={formik.handleChange}
					/>
				</Stack>
				<Stack
					direction={'row'}
					alignItems={'center'}
					spacing={2}>
					<Typography variant='subtitle2'>영어</Typography>
					<TextField
						variant='outlined'
						multiline
						minRows={2}
						sx={{ flex: 1 }}
						placeholder=''
						value={formik.values.eng}
						name='eng'
						onChange={formik.handleChange}
					/>
				</Stack>
			</Stack>
		</Stack>
	);
};
