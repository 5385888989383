import { RightOutlined } from '@ant-design/icons';
import { KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack, Typography, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';
import { KioskNumberBoard } from 'apps/kiosk-main/src/components/KioskNumberBoard';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useKioskTranslation } from '../../../../const/useKioskTranslation';

export const KioskTelephoneSection = ({
	open,
	closeDialog,
	kioskInfo,
	handleGoNextStep,
}: {
	kioskInfo: KioskInfoType;
	handleGoNextStep: () => void;
} & XlDialogProps) => {
	const { t, kioskI18N } = useKioskTranslation();

	const orderReducer = useSetAtom(OrderState.value);
	const handleAddTelephone = (telephone: string) => {
		orderReducer({
			type: 'ADD_TELEPHONE',
			telephone,
		});
	};

	const [tempTelephone, setTempTelephone] = useState('010');
	const clearTelephone = () => {
		setTempTelephone('010');
	};

	const addTelephone = (value: number) => {
		if (tempTelephone.length < 11) setTempTelephone(`${tempTelephone}${value}`);
	};

	const isValidTelephone = tempTelephone.length === 11;

	return (
		<Stack height={'100%'}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>{t('order.phoneTitle')}</Typography>
			</Stack>
			<Stack
				flex={1}
				spacing={3}
				alignItems={'center'}>
				<Stack flex={1} />
				<Stack alignItems={'center'}>
					<Typography
						variant='subtitle1'
						color={'text.secondary'}>
						{t('order.phoneDescription')}
					</Typography>
					<Typography
						variant='body1'
						color={'text.secondary'}>
						{t('order.phoneDescription2')}
					</Typography>
				</Stack>
				<Stack
					alignItems={'center'}
					py={5}>
					<Typography variant='h3'>{getTelephoneNumberWithDash(tempTelephone)}</Typography>
				</Stack>
				<Stack
					pb={10}
					width={428}
					alignItems={'center'}>
					<KioskNumberBoard
						onClickNumber={(value) => {
							if (value === 'clear') {
								clearTelephone();
							} else if (value === 'delete') {
								setTempTelephone(tempTelephone.slice(0, -1));
							} else {
								addTelephone(value);
							}
						}}
					/>
				</Stack>
			</Stack>
			<Stack width={'100%'}>
				{kioskInfo.telephoneOption === 'OPTIONAL' && (
					<Stack
						alignItems={'flex-end'}
						sx={{ color: grey[400] }}>
						<Button
							color={'inherit'}
							onClick={handleGoNextStep}
							endIcon={<RightOutlined />}>
							<Typography variant='body1'>{t('order.skipBell')}</Typography>
						</Button>
					</Stack>
				)}
				<Stack
					direction={'row'}
					py={3}
					spacing={3}>
					<Button
						variant='outlined'
						fullWidth
						onClick={closeDialog}>
						{t('common.cancel')}
					</Button>
					<Button
						variant='contained'
						fullWidth
						disabled={!isValidTelephone}
						onClick={() => {
							handleAddTelephone(tempTelephone);

							handleGoNextStep();
						}}>
						{t('payment.action')}
					</Button>
				</Stack>
			</Stack>
		</Stack>
	);
};
