import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, ProductInfoType } from '@kinderlabs-pos/shared-data-type';

export const getProductInfoListInStoreAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId?: number }) => {
		return (
			await posAxiosClient.get<(ProductInfoType & BaseInfoType)[]>(
				`${rootUrl}/product/list/in-store`,
				{
					params: {
						id: storeId,
					},
				}
			)
		).data;
	};
