import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { KioskInfoType, OrderTypeWithDdizisAndExchanges } from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAndWon, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { DdiziState, OrderState, StoreInfoState } from '@kinderlabs-pos/state';
import { SelectableListItem, WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, Divider, Grid, List, Stack, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useMemo } from 'react';
import { KioskExitPage } from '.';
import { EmptyDdiziListPage } from './EmptyDdiziListPage';
import { KioskExitDdiziDisplay } from './KioskDdiziDisplay';
import { KioskExitCartOrderDialog } from './KioskExitCartOrderDialog';

export interface IDdiziListSectionProps {
	kioskInfo: KioskInfoType;
	fixedTime: Date;
}
export const DdiziListSection: React.FC<IDdiziListSectionProps> = ({ kioskInfo, fixedTime }) => {
	const selectedOrderList = useAtomValue(KioskExitPage.selectedOrderListAtom);

	return (
		<Stack
			flex={1}
			overflow={'auto'}>
			<Stack
				flex={1}
				overflow={'auto'}>
				{selectedOrderList.length > 0 ? (
					<Suspense>
						<Stack
							flex={1}
							overflow={'auto'}>
							{selectedOrderList.map((orderInfo) => (
								<OrderAndDdiziSection
									key={orderInfo.id}
									orderInfo={orderInfo}
									fixedTime={fixedTime}
								/>
							))}
						</Stack>
						<KiosExitPagePaymentInfoBox kioskInfo={kioskInfo} />
					</Suspense>
				) : (
					<EmptyDdiziListPage />
				)}
			</Stack>
		</Stack>
	);
};

interface IInnerDdiziPageProps {
	orderInfo: OrderTypeWithDdizisAndExchanges;
	fixedTime: Date;
}
const OrderAndDdiziSection: React.FC<IInnerDdiziPageProps> = ({ orderInfo, fixedTime }) => {
	const { overtime } = useAtomValue(StoreInfoState.curStoreInfo);
	const jungsanInfo = StoreInfoState.useCurStoreJungsanInfo();

	// 퇴장 키오스크에서 초과시간을 고정하기 위한 수단
	const FixedDdizis = useMemo(
		() =>
			orderInfo.ddizis.map((ddizi) =>
				DdiziState.utils.getDdiziToJungsan({
					ddizi,
					timeStandard: fixedTime,
					overtimePolicy: overtime,
				})
			),
		[orderInfo.ddizis]
	);

	const availableDdizis = useMemo(
		() => FixedDdizis.filter((ddizi) => DdiziState.utils.isDdiziSelectableForJungsan(ddizi)),
		[FixedDdizis]
	);

	const selectedCartLines = useAtomValue(OrderState.cart.lines.excessChargeSelector);
	const cartDispatch = OrderState.cart.useDispatcher();
	const [selectedOrderList, setSelectedOrderList] = useAtom(KioskExitPage.selectedOrderListAtom);

	const handleAllToggle = () => {
		cartDispatch({
			type: 'EXCESS_CHARGE',
			subAction: {
				type: 'TOGGLE_FOR_ORDER',
				ddizis: availableDdizis.filter((ddizi) =>
					DdiziState.utils.isDdiziSelectableForJungsan(ddizi)
				),
				jungsanInfo,
			},
		});
	};

	const handleDeleteAllDdizisInOrder = () => {
		if (!orderInfo) return;
		const copied = [...selectedOrderList];
		const targetOrderIdx = copied.findIndex((order) => order === orderInfo);

		orderInfo.ddizis.forEach((ddizi) => {
			cartDispatch({
				type: 'EXCESS_CHARGE',
				subAction: {
					type: 'UNSELECT',
					ddiziId: ddizi.id,
				},
			});
		});

		delete copied[targetOrderIdx];
		setSelectedOrderList(copied.filter(Boolean));
	};

	return (
		<Stack sx={{ p: 5 }}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				justifyContent={'space-between'}>
				<Typography variant='h5'>주문 번호 : {orderInfo.dailySeq}</Typography>
				<Divider />
				<Stack
					direction={'row'}
					alignItems={'center'}
					spacing={1}>
					<Button onClick={handleAllToggle}>
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={2}>
							<Typography variant='button'>{'전체 선택'}</Typography>
							<CheckOutlined style={{ color: 'green' }} />
						</Stack>
					</Button>
					<Button onClick={handleDeleteAllDdizisInOrder}>
						<Stack
							direction={'row'}
							alignItems={'center'}
							spacing={2}>
							<Typography variant='button'>{'전체 삭제'}</Typography>
							<CloseOutlined style={{ color: 'red' }} />
						</Stack>
					</Button>
				</Stack>
			</Stack>
			<List>
				{FixedDdizis.map((ddizi, idx) => {
					const selected = selectedCartLines.map((cl) => cl.ddiziUsageId).includes(ddizi.id);
					return (
						<SelectableListItem
							selected={selected}
							disabled={!DdiziState.utils.isDdiziSelectableForJungsan(ddizi)}
							onClick={(e) => {
								cartDispatch({
									type: 'EXCESS_CHARGE',
									subAction: { type: 'TOGGLE', ddizi: ddizi, jungsanInfo },
								});
							}}
							key={idx}>
							<KioskExitDdiziDisplay
								sx={{ width: '100%' }}
								ddizi={ddizi}
								itemButton={
									<Stack sx={{ pr: 15 }}>
										{selected && <CheckOutlined style={{ fontSize: 70, color: 'green' }} />}
									</Stack>
								}
							/>
						</SelectableListItem>
					);
				})}
			</List>
		</Stack>
	);
};

interface IKiosExitPagePaymentInfoBoxProps {
	kioskInfo: KioskInfoType;
}
const KiosExitPagePaymentInfoBox: React.FC<IKiosExitPagePaymentInfoBoxProps> = ({ kioskInfo }) => {
	const selectedCartLines = useAtomValue(OrderState.cart.lines.excessChargeSelector);
	const selectedOrderList = useAtomValue(KioskExitPage.selectedOrderListAtom);
	const cart = useAtomValue(OrderState.cart.selector);
	const { totalToPay } = useAtomValue(OrderState.aggregate.cart);
	const { totalPaid, remainingPay } = useAtomValue(OrderState.aggregate.payments);
	const selectableDdiziList = selectedOrderList.map((order) => order.ddizis).flat();

	const get남은금액인지추가금액인지Label = (totalpaid: number) =>
		totalpaid > 0 ? '남은 금액' : '추가 금액';

	return (
		<Stack sx={{ width: '100%' }}>
			<Grid
				container
				sx={{ width: '100%', height: '100%' }}>
				<Grid
					item
					xs={8}
					sx={(theme) => ({
						bgcolor: theme.palette.grey[300],
						width: '100%',
						height: '100%',
						p: 6,
					})}>
					<Stack
						direction={'row'}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}>
						<Typography>총 추가 금액</Typography>
						<Typography>{numberWithCommasAndWon(totalToPay)}</Typography>
					</Stack>
					<Stack
						direction={'row'}
						display={'flex'}
						alignItems={'center'}
						justifyContent={'space-between'}>
						<Typography>선택된 티켓 개수</Typography>
						<Typography>
							{selectedCartLines.length} / {selectableDdiziList.length}
						</Typography>
					</Stack>
				</Grid>
				<Grid
					item
					xs={4}
					sx={(theme) => ({
						bgcolor: theme.palette.primary.main,
						width: '100%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					})}>
					<WithDialog
						dialog={(open, closeDialog) => (
							<KioskExitCartOrderDialog
								open={open}
								closeDialog={closeDialog}
								kioskInfo={kioskInfo}
							/>
						)}>
						{(openDialog) => (
							<Button
								onClick={openDialog}
								variant='contained'
								disabled={cart.lines.length === 0}
								disableTouchRipple
								sx={{ height: '100%', width: '100%' }}>
								<Stack alignItems={'center'}>
									{remainingPay > 0 ? (
										<>
											<Typography
												sx={(theme) => ({
													color: theme.palette.primary.contrastText,
												})}
												variant={'subtitle2'}>
												{get남은금액인지추가금액인지Label(totalPaid)}
											</Typography>
											<Typography
												sx={(theme) => ({
													color: theme.palette.primary.contrastText,
												})}
												variant={'subtitle2'}>
												{`${numberWithCommasAnd원(remainingPay)}`}
											</Typography>
											<Typography
												sx={(theme) => ({
													color: theme.palette.primary.contrastText,
												})}
												variant={'subtitle2'}
												mt={1}>
												결제
											</Typography>
										</>
									) : (
										<>
											{cart.lines.length === 0 ? (
												<Typography
													sx={(theme) => ({
														color: theme.palette.primary.contrastText,
													})}
													variant='subtitle2'>
													{'선택된 티켓 없음'}
												</Typography>
											) : (
												<>
													<Typography
														sx={(theme) => ({
															color: theme.palette.primary.contrastText,
														})}
														variant='subtitle2'>{`${get남은금액인지추가금액인지Label(
														totalPaid
													)} 없음`}</Typography>
													<Typography
														sx={(theme) => ({
															color: theme.palette.primary.contrastText,
														})}
														variant='subtitle2'>
														{'퇴장 완료'}
													</Typography>
												</>
											)}
										</>
									)}
								</Stack>
							</Button>
						)}
					</WithDialog>
				</Grid>
			</Grid>
		</Stack>
	);
};
