import { useFrameworkFormik } from '@kinderlabs-pos/frameworks/forms';
import {
	GuestMemberInfoType,
	KioskType,
	VisitUserGendersType,
} from '@kinderlabs-pos/shared-data-type';
import { GuestMemberInfoState } from '@kinderlabs-pos/state';
import { useFormik } from 'formik';
import { useSetAtom } from 'jotai';
import { KioksUserInfoPage, UserInfoStepType } from '.';
import { useKioskRouter } from '../../utils/useKioskRouter';

type KioskSignupUserInfoType = {
	storeId: number;
	name?: string;
	telephone?: string;
	birthday?: string;
	gender?: VisitUserGendersType;
	address?: {
		region: string;
		district: string;
	};
	kinderlabsAgreement: boolean;
	storeAgreement: boolean;
	marketingAgreement: boolean;
};

export const useKioskUserInfoSignupFormik = ({
	storeId,
	kioskType,
}: {
	storeId: number;
	kioskType: KioskType | 'ONLINE-TICKETS';
}) => {
	const setFinalUserInfo = useSetAtom(KioksUserInfoPage.finalUserInfo);
	const registerUser = GuestMemberInfoState.create();
	const { navigate온라인권발권, navigate키오스크보드, navigate퇴장페이지 } = useKioskRouter();

	const result = useFormik<KioskSignupUserInfoType>({
		initialValues: {
			storeId: storeId,
			kinderlabsAgreement: false,
			storeAgreement: false,
			marketingAgreement: false,
			telephone: '010',
		},
		onSubmit: async (values) => {
			const { name, telephone, gender, birthday, address, marketingAgreement } = values;
			const result = await registerUser.mutateAsync({
				name: name,
				telephone: telephone ?? '',
				gender: gender ?? 'UNKNOWN',
				birthday: birthday,
				address: address,
				storeId: storeId,
				isMarketingAgree: marketingAgreement,
			});

			setFinalUserInfo({ memberId: result.id, name: result.name, telephone: result.telephone });

			switch (kioskType) {
				case 'ONLINE-TICKETS':
					navigate온라인권발권();
					break;
				case 'TICKETS':
				case 'FANDB':
					navigate키오스크보드();
					break;
				case 'JUNGSAN':
					navigate퇴장페이지();
					break;
				case 'CUSTOMER_MAPPING':
					break;
			}
		},
	});

	return result;
};

export type KioskUserInfoType = Omit<GuestMemberInfoType, 'id' | 'address'> & {
	id?: number;
	isMarketingAgree: boolean;
	curStep: UserInfoStepType;
	address: {
		region?: string;
		district?: string;
	};
};

const USER_INFO_INTIALVALUE: KioskUserInfoType = {
	kinderlabsAgreement: {
		isAgree: true,
		agreeDateTime: null,
	},
	storeAgreement: {
		isAgree: true,
		agreeDateTime: null,
	},
	marketingAgreement: {
		isAgree: true,
		agreeDateTime: null,
	},
	address: {
		region: undefined,
		district: undefined,
	},
	telephone: '010',
	birthday: '',
	name: '',
	gender: 'MAN',
	isMarketingAgree: false,
	curStep: '휴대전화',
};

export const useUserInfoFormik = ({
	initialValues = USER_INFO_INTIALVALUE,
	onSubmit,
}: {
	initialValues?: KioskUserInfoType;
	onSubmit: (valeus: KioskUserInfoType) => void;
}) => {
	const result = useFrameworkFormik<KioskUserInfoType>({
		initialValues,
		onSubmit,
	});

	return result;
};

export type KioskUserInfoFormikType = ReturnType<typeof useFormik<KioskUserInfoType>>;

/*

const validationSchema = Yup.object().shape({
	kinderlabsAgreement: Yup.object().shape({
		isAgree: Yup.boolean().oneOf([true]),
		agreeDateTime: Yup.date().nullable(),
	}),
	storeAgreement: Yup.object().shape({
		isAgree: Yup.boolean().oneOf([true]),
		agreeDateTime: Yup.date().nullable(),
	}),
	telephone: Yup.string()
		.nullable()
		.when('curStep', {
			is: (curStep: UserInfoStepType) => curStep === '휴대전화',
			then: Yup.string()
				.length(11, '전화번호는 11자리 이어야 합니다.')
					.matches(/^[0][1][0][0-9]+$/, '앞자리 휴대폰 번호는 010 입니다.')
				.required('전화번호를 입력해주세요.'),
		}),
	birthday: Yup.string()
		.nullable()
		.when('curStep', {
			is: (curStep: UserInfoStepType) => curStep === '생년월일',
			then: Yup.string()
				.min(7, '생년월일은 7자리 이상이어야 합니다.')
				.max(8, '생년월일은 8자를 넘을 수 없습니다.')
				.required('생년월일을 입력해주세요')
				.matches(
					/^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
					'생년월일 형식에 맞지 않습니다.'
				),
		}),
	name: Yup.string()
		.nullable()
		.when('curStep', {
			is: (curStep: UserInfoStepType) => curStep === '이름',
			then: Yup.string()
				.max(255)
				.required('이름을 입력해주세요.')
				.matches(
					/^[가-힣|a-zA-Z\s]+$/,
					'이름 형식에 맞지 않습니다. (특수기호,숫자 사용 불가)'
				),
		}),
});

*/
