import { rootUrl } from '../../const';
import { createImageUploadAsync } from './createImageUploadAsync';
import { deleteImageAsync } from './deleteImageAsync';
import { getImageListAsync } from './getImageListAsync';

export const ImageInfoApis = {
	create: createImageUploadAsync(rootUrl),
	get: getImageListAsync(rootUrl),
	delete: deleteImageAsync(rootUrl),
};
