import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, ManagerUserType } from '@kinderlabs-pos/shared-data-type';

export const reissuePassword =
	(rootUrl: string) =>
	async ({ email, name, telephone }: ReissuePWDReuqestType) => {
		const result = await posAxiosClient.post<BaseInfoType & ManagerUserType>(
			`${rootUrl}/authenticate/auth/password/reissue`,
			{
				email,
				name,
				telephone,
			}
		);

		return result.data;
	};

type ReissuePWDReuqestType = {
	email: string;
	name: string;
	telephone: string;
};
