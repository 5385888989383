import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VendorDetailInfoType } from '@kinderlabs-pos/shared-data-type';

export const getVendorDetailInfoAsync = (rootUrl: string) => async (vendorId: number) =>
	(
		await posAxiosClient.get<VendorDetailInfoType>(`${rootUrl}/vendor`, {
			params: {
				id: vendorId,
			},
		})
	).data;
