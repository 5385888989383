import { PosBffApi } from '@kinderlabs-pos/apis/pos';
import { QueryClient } from '@kinderlabs-pos/state';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const searchMemberKeys = createQueryKeys('search-member', {
	search: (search: string) => ({
		queryKey: [search],
		queryFn: async () => {
			if (!search) return null;
			const result = await PosBffApi.getSearchMember({ search });
			return (result || []).filter((member) => !!member.telephone);
		},
	}),
	getByUserId: (userId?: string) => ({
		queryKey: [userId],
		queryFn: async () => {
			if (!userId) return null;
			const memberInfo = await PosBffApi.getUserMember({ userId });
			return memberInfo ? memberInfo : null;
		},
	}),
});

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: searchMemberKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: searchMemberKeys._def });
};

export const SearchMemberState = {
	keys: searchMemberKeys,
	invalidateQueries,
};
