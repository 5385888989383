import { DiscountType } from '@kinderlabs-pos/shared-data-type';
import { NumberBoard } from '@kinderlabs-pos/ui-components';
import { OutlinedInput, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import produce from 'immer';
import { useState } from 'react';

export const CustomDiscountSelect = ({
	discountType,
	lowestPrice,
	setDiscountType,
	amount,
	setAmount,
}: {
	discountType: DiscountType;
	setDiscountType: (input: DiscountType) => void;
	lowestPrice: number;
	amount: number;
	setAmount: (input: number) => void;
}) => {
	const handleChangeNumber = (event: React.ChangeEvent<any>) => {
		const upperLimit = discountType === 'VALUE' ? lowestPrice : 100;
		let changed = +event.target.value;
		if (changed > upperLimit) changed = upperLimit;
		setAmount(changed);
	};

	return (
		<Stack
			direction='column'
			spacing={1}>
			<Stack
				direction='row'
				spacing={1}>
				<OutlinedInput
					type={'number'}
					placeholder='0'
					value={amount}
					onChange={(event) => {
						handleChangeNumber(event);
					}}
					sx={{
						height: '100%',
						fontSize: '2.0rem',
					}}
				/>
				<ToggleButtonGroup
					exclusive
					fullWidth
					onChange={(e, newValue) => {
						if (newValue) setDiscountType(newValue);
					}}
					value={discountType}
					size='small'
					sx={{ flex: 1 }}>
					<ToggleButton
						value={'PERCENTAGE'}
						sx={{ width: 50, fontSize: '2.0rem' }}
						onClick={() => setAmount(0)}>
						%
					</ToggleButton>
					<ToggleButton
						value={'VALUE'}
						sx={{ width: 50, fontSize: '2.0rem' }}
						onClick={() => setAmount(0)}>
						₩
					</ToggleButton>
				</ToggleButtonGroup>
			</Stack>
			<NumberBoard
				minValue={0}
				maxValue={discountType === 'VALUE' ? lowestPrice : 100}
				value={amount}
				setValue={setAmount}
			/>
		</Stack>
	);
};
