import { KitchenOrderAPI } from '@kinderlabs-pos/apis/pos';
import { KitchenOrderMethodType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../QueryClient';

type KitchenOrderListParamsType = Omit<
	Parameters<typeof KitchenOrderAPI.getkitchenOrderInfoListByOffSet>[number],
	'pageable'
> & { page: number; size?: number };

type KitchenOrderInfiniteListParamsType = Omit<
	Parameters<typeof KitchenOrderAPI.getKitchenOrderInfoListByCursor>[number],
	'cursorId' | 'pageable'
> & { sort?: Pageable['sort'] };

const kitchenOrderKeys = createQueryKeys('kitchen-order', {
	list: ({ params }: { params: KitchenOrderListParamsType }) => ({
		queryKey: [params],
		queryFn: async () => {
			return await KitchenOrderAPI.getkitchenOrderInfoListByOffSet({
				from: params.from.startOf('d'),
				to: params.to.endOf('d'),
				storeIdList: params.storeIdList,
				deviceIdList: params.deviceIdList,
				deviceTypeList: params.deviceTypeList,
				status: params.status,
				pageable: Pageable.getPageable(params),
				kitchenSystemId: params.kitchenSystemId,
			});
		},
	}),
	recentCall: ({ kitchenSystemId, date }: { kitchenSystemId?: number; date: string }) => ({
		queryKey: ['list', kitchenSystemId, date],
		queryFn: async () => {
			if (!kitchenSystemId) return [];
			const res = await KitchenOrderAPI.getRecentCalls(kitchenSystemId, date);
			return res;
		},
	}),
	infinite: ({ params }: { params: KitchenOrderInfiniteListParamsType }) => ({
		queryKey: [params],
		queryFn: async ({ pageParam }: { pageParam?: { startCursorId: string | number } }) => {
			const res = await KitchenOrderAPI.getKitchenOrderInfoListByCursor({
				storeIdList: params.storeIdList,
				pageable: Pageable.getPageable({ page: 0, size: 20, sort: params.sort }),
				kitchenSystemId: params.kitchenSystemId,
				statusList: params.statusList,
				deviceTypeList: params.deviceTypeList,
				deviceIdList: params.deviceIdList,
				cursorId: pageParam?.startCursorId,
				search: params.search,
				from: params.from,
				to: params.to,
			});

			return {
				content: res.content,
				isLast: !res.hasMore,
				startCursorId: res.startCursorId,
			};
		},
	}),
});

interface UpdateKitchenOrderStatusType {
	kitchenOrderId: string[];
	kitchenOrderLineIds?: string[];
	targetStatus: KitchenOrderMethodType;
}
const updateKitchenOrderStatus = () => {
	return QueryClient.useMutation(
		async ({ kitchenOrderId, kitchenOrderLineIds, targetStatus }: UpdateKitchenOrderStatusType) => {
			return await KitchenOrderAPI.updateKitchenOrderStatus({
				id: kitchenOrderId,
				orderLineIds: kitchenOrderLineIds,
				targetStatus: targetStatus,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const updateKitchenOrderCall = () => {
	return QueryClient.useMutation(
		async ({ kitchenOrderId }: { kitchenOrderId: string }) => {
			await KitchenOrderAPI.callKitchenOrder(kitchenOrderId);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: kitchenOrderKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: kitchenOrderKeys._def });
};

export const KitchenOrderQueryState = {
	keys: kitchenOrderKeys,
	updateKitchenOrderStatus,
	updateKitchenOrderCall,
	invalidateQueries,
};
