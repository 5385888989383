import { ReactNode, Suspense } from 'react';
import { IXlDialogProps, XlDialog } from './XlDialog';
import { useDialogActions } from './useDialogActions';

export interface IWithDialogProps {
	children: (onClick: () => void) => ReactNode;
	dialog: (open: boolean, closeDialog: () => void, initOpen?: boolean) => ReactNode;
}

type WithDialogType = React.FC<IWithDialogProps> & {
	XlDialog: typeof XlDialog;
};

export type XlDialogProps = Omit<IXlDialogProps, 'children'>;

export const WithDialog: WithDialogType = ({ dialog, children }) => {
	const { open, openDialog, closeDialog } = useDialogActions();

	return (
		<>
			<Suspense>{open && dialog(open, closeDialog)}</Suspense>
			{children(openDialog)}
		</>
	);
};

WithDialog.XlDialog = XlDialog;
