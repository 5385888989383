import { createTheme } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const theme = createTheme();

export const adminTypographyWithFontFamily = (fontFamily: string): TypographyOptions => ({
	htmlFontSize: 10,
	fontFamily: fontFamily,
	allVariants: {
		fontSize: '1.6rem',
		lineHeight: 1.5,
	},
	h1: {
		fontSize: '4.25rem',
		fontWeight: 700,

		[theme.breakpoints.down('md')]: {
			fontSize: '4rem',
			fontWeight: 600,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '3.75rem',
			fontWeight: 500,
		},
	},
	h2: {
		fontSize: '3.5rem',
		fontWeight: 700,
	},

	[theme.breakpoints.down('md')]: {
		fontSize: '3.25rem',
		fontWeight: 600,
	},
	[theme.breakpoints.down('sm')]: {
		fontSize: '3rem',
		fontWeight: 500,
	},
	h3: {
		fontSize: '3rem',
		fontWeight: 700,

		[theme.breakpoints.down('md')]: {
			fontSize: '2.5rem',
			fontWeight: 500,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2.25rem',
			fontWeight: 400,
		},
	},
	h4: {
		fontSize: '2.25rem',
		fontWeight: 700,

		[theme.breakpoints.down('md')]: {
			fontSize: '2rem',
			fontWeight: 500,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.75rem',
			fontWeight: 400,
		},
		// lg: {
		// 	fontSize: '2.25rem',
		// 	fontWeight: 500,
		// },
		// md: {
		// 	fontSize: '2rem',
		// 	fontWeight: 500,
		// },
		// sm: {
		// 	fontSize: '1.75rem',
		// 	fontWeight: 400,
		// },
		// xs: {
		// 	fontSize: '1.5rem',
		// 	fontWeight: 400,
		// },
	},
	h5: {
		fontSize: '1.75rem',
		fontWeight: 400,

		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
			fontWeight: 300,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem',
			fontWeight: 200,
		},
	},
	h6: {
		fontSize: '1.5rem',
		fontWeight: 400,

		[theme.breakpoints.down('md')]: {
			fontSize: '1.35rem',
			fontWeight: 300,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem',
			fontWeight: 200,
		},
	},
	subtitle1: {
		fontSize: '1.4rem',
		fontWeight: 500,

		[theme.breakpoints.down('md')]: {
			fontSize: '1.4rem',
			fontWeight: 500,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.25rem',
			fontWeight: 400,
		},
	},
	subtitle2: {
		fontSize: '1.3rem',
		fontWeight: 600,

		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
			fontWeight: 500,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem',
			fontWeight: 400,
		},
	},
	body1: {
		fontSize: '1.2rem',
		fontWeight: 400,

		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem',
			fontWeight: 500,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.22rem',
			fontWeight: 400,
		},
	},
	body2: {
		fontSize: '1.2rem',
		fontWeight: 400,

		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem',
			fontWeight: 300,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem',
			fontWeight: 200,
		},
	},
	caption: {
		fontSize: '1.5rem',

		[theme.breakpoints.down('md')]: {
			fontSize: '1.3rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
		},
	},
	overline: {
		fontSize: '1.2rem',

		[theme.breakpoints.down('md')]: {
			fontSize: '1rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem',
		},
	},
	button: {
		// textTransform: 'capitalize',
		fontSize: '1.4rem',

		[theme.breakpoints.down('md')]: {
			fontSize: '1.2rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.2rem',
		},
	},
});
