import { 마케팅동의TermsConditions } from './MarketingAgreement';
import { 개인정보이용및수집동의및제3자동의DetailList } from './StoreAgreement';
import { 시설이용전안내사항List } from './VaunceBeforeUse';
type TermsConditionsDetailType = {
	title: string;
	descs?: {
		label: string;
		info?: string[];
	}[];
	table?: {
		tableHeadList: string[];
		tableBodyList: string[][];
	};
};

export type TermsConditionsType = {
	title?: string;
	infos: TermsConditionsDetailType[];
};

export const TCAgreementContents = {
	all: {
		title: '서비스 이용 약관에 모두 동의합니다',
	},
	kinderlabs: {
		isRequired: true,
		title: '개인정보 수집 및 이용 동의',
		list: 개인정보이용및수집동의및제3자동의DetailList,
	},
	store: {
		isRequired: true,
		title: '개인정보 제 3자 정보 제공 동의',
		list: 개인정보이용및수집동의및제3자동의DetailList,
	},
	vaunceBeforeUse: {
		isRequired: true,
		title: '시설 이용 안내 사항',
		list: 시설이용전안내사항List,
	},
	marketing: {
		isRequired: false,
		title: '이벤트 혜택 알림 및 마케팅 정보 수신 동의',
		list: 마케팅동의TermsConditions,
	},
};
