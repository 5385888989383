import { KioskType } from '@kinderlabs-pos/shared-data-type';
import { OrderState } from '@kinderlabs-pos/state';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Stack, Typography } from '@mui/material';
import { useSetAtom } from 'jotai';
import { Suspense, useEffect } from 'react';
import { KioskOnlineTicketPage } from './KIoskOnlineTicketPage';
import { KioskBoardPage } from './KioskBoardPage';
import { KioskBoardPageHeader } from './KioskBoardPage/KioskBoardPageHeader';
import { KioskExitPage } from './KioskExitPage';

export const KioskMainPage = ({ type }: { type: KioskType | 'ONLINE_TICKET' }) => {
	const orderReducer = useSetAtom(OrderState.value);
	useEffect(() => {
		orderReducer({ type: 'CLEAR' });
	}, []);

	return (
		<Stack
			height={'100%'}
			overflow={'auto'}>
			<Suspense fallback={<FullSizeCircularProgress />}>
				<KioskBoardPageHeader />
				<Stack
					flex={1}
					overflow={'auto'}>
					{type === 'FANDB' && <KioskBoardPage />}
					{type === 'TICKETS' && <KioskBoardPage />}
					{type === 'ONLINE_TICKET' && <KioskOnlineTicketPage />}
					{type === 'JUNGSAN' && <KioskExitPage />}
				</Stack>
			</Suspense>
		</Stack>
	);
};
