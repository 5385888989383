import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { ManagerUserType } from '@kinderlabs-pos/shared-data-type';

export const signupAsync =
	(rootUrl: string) =>
	async ({ storeIdList, password, email, name, telephone, authority }: SignupRequestAsyncType) =>
		(
			await posAxiosClient.post<ManagerUserType>(`${rootUrl}/auth-service/auth/signup`, {
				storeIdList,
				telephone,
				email,
				password,
				name,
				authority,
			})
		).data;

type SignupRequestAsyncType = Omit<ManagerUserType, 'authorityLevel' | 'enterpriseInfo'> & {
	password: string;
};

/*

*/
