import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { MultiUseTicketType } from '@kinderlabs-pos/shared-data-type';

export const searchMutListAsync =
	(rootUrl: string) =>
	async ({ search }: SearchMutListAsyncRequestType) =>
		(
			await posAxiosClient.get<MultiUseTicketType[]>(`${rootUrl}/ticket/mut/search`, {
				params: {
					keyword: search,
				},
			})
		).data;

export type SearchMutListAsyncRequestType = {
	search: string;
};
