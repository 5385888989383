import { SalesDeviceInfoType } from './DeviceInfoType';
import { PrinterInfoType, PrintModeType, VanCompanyType } from './PosInfoType';
import { ProductInfoType } from './ProductInfoType';
import { SignUpInfoType } from './WaiverInfoType';

export const AdminKioskTypeArray = ['FANDB', 'TICKETS', 'JUNGSAN', 'CUSTOMER_MAPPING'] as const;
export type KioskType = (typeof AdminKioskTypeArray)[number];
export const KioskTypeLabel: Record<KioskType, string> = {
	FANDB: 'F&B',
	TICKETS: '매표소',
	JUNGSAN: '퇴장정산',
	CUSTOMER_MAPPING: '티켓 정보 입력',
};

export const OptionalTypeArray = ['NONE', 'MANDATORY', 'OPTIONAL'] as const;
export type OptionalType = (typeof OptionalTypeArray)[number];
export const OptionalTypeLabel: Record<OptionalType, string> = {
	NONE: '사용 안함',
	MANDATORY: '필수',
	OPTIONAL: '선택',
};

export type KioskInfoType = SalesDeviceInfoType & {
	deviceType: 'KIOSK';
	pinNumber: string;
	type: KioskType;
	kitchenSystemId?: number;
	kioskBoardId?: number;
	bellOption: OptionalType;
	telephoneOption: OptionalType;
	printMode: PrintModeType;
	vanCompany: VanCompanyType;
	labelPrinterList: PrinterInfoType[];
	receiptPrinterList: PrinterInfoType[];

	bannerImageUrl?: string;
	entryImageUrl?: string;

	bannerImageUrlEng?: string;
	entryImageUrlEng?: string;
	bannerImageUrlJpn?: string;
	entryImageUrlJpn?: string;
	bannerImageUrlChn?: string;
	entryImageUrlChn?: string;

	signupInfo?: SignUpInfoType;

	agreementImageUrl?: string; // JIN: 변수명은 이런데, 회원가입 안내 이미지로 사용!
	agreementImageEngUrl?: string;
};

export type KioskBoardInfoType = {
	id: number;
	name: string;
	storeId: number;
	isActiveNewBoard: boolean;
	isActiveBestBoard: boolean;
	alertMessage?: string;
	usingKioskInfoList: KioskInfoType[];
};

export type KioskBoardMenuInfoType = {
	id: number;
	name: string;
	kioskBoardId: number;
	itemList: KioskBoardItemInfoType[];
	isNewBoard: boolean;
	isBestBoard: boolean;
	isDisabled: boolean;

	i18nName?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};
};

export type KioskBoardMenuInfoForStoreType = Omit<KioskBoardMenuInfoType, 'itemList'> & {
	itemWithDetailList: KioskBoardItemInfoWithDetailType[];
};

export type KioskBoardItemInfoType = {
	productInfoId?: number;
	imageUrl?: string;
	optionList: ProductInfoType['id'][];
	alertMessage: string | null;
	isNew: boolean;
	isBest: boolean;
	isSoldOut: boolean;

	i18nAlertMessage?: {
		eng?: string;
		jpn?: string;
		chn?: string;
	};
};

export type KioskBoardItemInfoWithDetailType = Omit<
	KioskBoardItemInfoType,
	'optionList' | 'productInfoId'
> & {
	productInfo: ProductInfoType;
	productOptionInfoList: ProductInfoType[];
};

const hasBoard: Record<KioskInfoType['type'], boolean> = {
	FANDB: true,
	TICKETS: true,
	CUSTOMER_MAPPING: false,
	JUNGSAN: false,
};
export const KioskInfoTypeRule = {
	hasBoard,
};
