import { rootUrl } from '../../const';

import { BaseInfoType, DisplayerInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const getDisplayerInfoListAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<(DisplayerInfoType & BaseInfoType)[]>(
				`${rootUrl}/store/displayer/list`,
				{
					params: { storeId },
				}
			)
		).data;

export const getDisplayerInfoAsync =
	(rootUrl: string) =>
	async ({ storeId, deviceId }: { storeId: number; deviceId: number }) =>
		(
			await posAxiosClient.get<DisplayerInfoType & BaseInfoType>(`${rootUrl}/store/displayer`, {
				params: { storeId, deviceId },
			})
		).data;

const createDisplayerInfoAsync =
	(rootUrl: string) =>
	async (createDisplayerInfoRequestInfo: Omit<DisplayerInfoType, 'id'> & { ipAddress: string }) =>
		(
			await posAxiosClient.post<DisplayerInfoType & BaseInfoType>(`${rootUrl}/store/displayer`, {
				...createDisplayerInfoRequestInfo,
			})
		).data;

const deleteDisplayerInfoListAsync =
	(rootUrl: string) =>
	async ({ idList }: { idList: number[] }) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/store/displayer/list`, {
				params: {
					idList,
				},
			})
		).data;

const updateDisplayerInfoAsync =
	(rootUrl: string) =>
	async (updateDisplayerInfoRequestInfo: DisplayerInfoType & { ipAddress: string }) =>
		(
			await posAxiosClient.put<DisplayerInfoType & BaseInfoType>(
				`${rootUrl}/store/displayer`,
				{
					...updateDisplayerInfoRequestInfo,
				},
				{
					params: {
						id: updateDisplayerInfoRequestInfo.id,
					},
				}
			)
		).data;

export const AdminDisplayerInfoApis = {
	getDisplayerInfoList: getDisplayerInfoListAsync(rootUrl),
	getDisplayerInfo: getDisplayerInfoAsync(rootUrl),
	createDisplayerInfo: createDisplayerInfoAsync(rootUrl),
	deleteDisplayerInfoList: deleteDisplayerInfoListAsync(rootUrl),
	updateDisplayerInfo: updateDisplayerInfoAsync(rootUrl),
};
