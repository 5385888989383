import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { StockHistoryInfoType, StockHistoryType } from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getStockHistorySearchAsync =
	(rootUrl: string) =>
	async ({ from, to, warehouseId, type }: GetStockHistorySearchReqeuestType) =>
		(
			await posAxiosClient.get<StockHistoryInfoType[]>(`${rootUrl}/stock/history/search`, {
				params: {
					from: from.startOf('d').format('YYYY-MM-DD HH:mm:ss'),
					to: to.endOf('d').format('YYYY-MM-DD HH:mm:ss'),
					warehouseId,
					type,
				},
			})
		).data;

type GetStockHistorySearchReqeuestType = {
	from: Dayjs;
	to: Dayjs;
	warehouseId?: number;
	type?: StockHistoryType;
};
