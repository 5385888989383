import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { rootUrl } from '../../const';
import {
	BaseInfoType,
	KioskBoardInfoType,
	KioskBoardItemInfoType,
	KioskBoardMenuInfoForStoreType,
	KioskBoardMenuInfoType,
} from '@kinderlabs-pos/shared-data-type';

const getKioskBoardInfoList =
	(rootUrl: string) =>
	async ({ storeId }: { storeId: number }) =>
		(
			await posAxiosClient.get<(KioskBoardInfoType & BaseInfoType)[]>(
				`${rootUrl}/kiosk-board/list`,
				{
					params: {
						storeId,
					},
				}
			)
		).data;

const getKioskBoardInfo =
	(rootUrl: string) =>
	async ({ id }: { id: number }) =>
		(
			await posAxiosClient.get<KioskBoardInfoType & BaseInfoType>(`${rootUrl}/kiosk-board`, {
				params: {
					id,
				},
			})
		).data;

type CreateKioskBoardInfo = Omit<KioskBoardInfoType, 'id' | 'usingKioskInfoList'>;
const createKioskBoardInfo =
	(rootUrl: string) =>
	async ({ kioskBoardInfo }: { kioskBoardInfo: CreateKioskBoardInfo }) =>
		(
			await posAxiosClient.post<KioskBoardInfoType & BaseInfoType>(
				`${rootUrl}/kiosk-board`,
				kioskBoardInfo
			)
		).data;

const updateKioskBoardInfo =
	(rootUrl: string) =>
	async ({ id, kioskBoardInfo }: { id: number; kioskBoardInfo: KioskBoardInfoType }) =>
		(
			await posAxiosClient.put<KioskBoardInfoType>(`${rootUrl}/kiosk-board`, kioskBoardInfo, {
				params: {
					id,
				},
			})
		).data;

const deleteKioskBoardInfo =
	(rootUrl: string) =>
	async ({ id }: { id: number }) =>
		(
			await posAxiosClient.delete<KioskBoardInfoType>(`${rootUrl}/kiosk-board`, {
				params: {
					id,
				},
			})
		).data;

type CreateKioskBoardMenu = Omit<KioskBoardMenuInfoType, 'id' | 'itemList'>;
const createKioskBoardMenu =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenu,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenu: CreateKioskBoardMenu;
	}) =>
		(
			await posAxiosClient.post<KioskBoardMenuInfoType & BaseInfoType>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu`,
				kioskBoardMenu
			)
		).data;

const updateKioskBoardMenu =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenu,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenu: KioskBoardMenuInfoType;
	}) =>
		(
			await posAxiosClient.put<KioskBoardMenuInfoType & BaseInfoType>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu`,
				kioskBoardMenu
			)
		).data;

const updateKioskBoardMenuI18N =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenuI18NMap,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenuI18NMap: Record<number, { eng?: string; jpn?: string; chn?: string }>;
	}) =>
		(
			await posAxiosClient.put<KioskBoardMenuInfoType & BaseInfoType>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu/i18n`,
				kioskBoardMenuI18NMap
			)
		).data;

const deleteKioskBoardMenu =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenuId,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenuId: number;
	}) =>
		(
			await posAxiosClient.delete<number>(`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu`, {
				params: {
					id: kioskBoardMenuId,
				},
			})
		).data;

const updateKioskBoardMenuVisibility =
	(rootUrl: string) =>
	async ({
		kioskBoardId,
		kioskBoardMenuId,
		isDisabled,
	}: {
		kioskBoardId: number;
		kioskBoardMenuId: number;
		isDisabled: boolean;
	}) =>
		(
			await posAxiosClient.put<KioskBoardMenuInfoType & BaseInfoType>(
				`${rootUrl}/kiosk-board/${kioskBoardId}/board-menu/${kioskBoardMenuId}/disable`,
				null,
				{
					params: {
						isDisabled,
					},
				}
			)
		).data;

const adjustKioskMenuOrder =
	(rootUrl: string) =>
	async ({ kioskBoardInfoId, boardIdList }: { kioskBoardInfoId: number; boardIdList: number[] }) =>
		(
			await posAxiosClient.put<number[]>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu/adjust-order`,
				undefined,
				{ params: { idList: boardIdList } }
			)
		).data;

const createKioskBoardItem =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenuId,
		kioskBoardItem,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenuId: number;
		kioskBoardItem: KioskBoardItemInfoType;
	}) =>
		(
			await posAxiosClient.post<void>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu/${kioskBoardMenuId}/item`,
				kioskBoardItem
			)
		).data;

const updateKioskBoardItem =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenuId,
		index,
		kioskBoardItem,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenuId: number;
		index: number;
		kioskBoardItem: KioskBoardItemInfoType;
	}) =>
		(
			await posAxiosClient.put<void>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu/${kioskBoardMenuId}/item/${index}`,
				kioskBoardItem
			)
		).data;

const setSoldOutKioskBoardItem =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenuId,
		index,
		soldOut,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenuId: number;
		index: number;
		soldOut: boolean;
	}) =>
		(
			await posAxiosClient.post<void>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu/${kioskBoardMenuId}/item/${index}/action`,
				undefined,
				{ params: { soldOut } }
			)
		).data;

const deleteKioskBoardItem =
	(rootUrl: string) =>
	async ({
		kioskBoardInfoId,
		kioskBoardMenuId,
		index,
	}: {
		kioskBoardInfoId: number;
		kioskBoardMenuId: number;
		index: number;
	}) =>
		(
			await posAxiosClient.delete<void>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu/${kioskBoardMenuId}/item/${index}`
			)
		).data;

const getKioskBoardMenuListInBoard =
	(rootUrl: string) =>
	async ({ kioskBoardInfoId }: { kioskBoardInfoId: number }) =>
		(
			await posAxiosClient.get<KioskBoardMenuInfoForStoreType[]>(
				`${rootUrl}/kiosk-board/${kioskBoardInfoId}/board-menu/list`
			)
		).data;

export const KioskBoardInfoApis = {
	getKioskBoardInfoList: getKioskBoardInfoList(rootUrl),
	getKioskBoardInfo: getKioskBoardInfo(rootUrl),
	createKioskBoardInfo: createKioskBoardInfo(rootUrl),
	updateKioskBoardInfo: updateKioskBoardInfo(rootUrl),
	deleteKioskBoardInfo: deleteKioskBoardInfo(rootUrl),
	getKioskBoardMenuListInBoard: getKioskBoardMenuListInBoard(rootUrl),
	createKioskBoardMenu: createKioskBoardMenu(rootUrl),
	updateKioskBoardMenu: updateKioskBoardMenu(rootUrl),
	updateKioskBoardMenuI18N: updateKioskBoardMenuI18N(rootUrl),
	updateKioskBoardMenuVisibility: updateKioskBoardMenuVisibility(rootUrl),
	deleteKioskBoardMenu: deleteKioskBoardMenu(rootUrl),
	adjustKioskMenuOrder: adjustKioskMenuOrder(rootUrl),
	createKioskBoardItem: createKioskBoardItem(rootUrl),
	updateKioskBoardItem: updateKioskBoardItem(rootUrl),
	setSoldOutKioskBoardItem: setSoldOutKioskBoardItem(rootUrl),
	deleteKioskBoardItem: deleteKioskBoardItem(rootUrl),
};
