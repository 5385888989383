import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CardPaymentType,
	CursorPageType,
	Pageable,
	PageType,
	PaymentInfoType,
	PaymentMethodType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';

export const getInfiniteListAsync =
	(rootUrl: string) =>
	async ({
		storeIdList,
		posIdList,
		method,
		status,
		cursorId,
		from,
		to,
		pageable,
		cardPaymentType,
		keyword,
		isForRefundSearch = false,
	}: GetPaymentListRequestType) =>
		(
			await posAxiosClient.get<CursorPageType<PaymentInfoType>>(
				`${rootUrl}/payment/search/cursor`,
				{
					params: {
						storeIdList,
						posIdList,
						...pageable.getPageableQueryParam(),
						from: from.format('YYYY-MM-DD HH:mm:ss'),
						to: to.format('YYYY-MM-DD HH:mm:ss'),
						cursorId,
						method: method,
						status: status,
						cardPaymentType: cardPaymentType,
						search: keyword,
						isForRefundSearch,
					},
				}
			)
		).data;

export type GetPaymentListRequestType = {
	storeIdList?: number[];
	posIdList?: number[];
	cursorId?: number | string;
	method?: PaymentMethodType;
	status?: 'REFUND' | 'COMPLETE';
	cardPaymentType?: CardPaymentType;
	pageable: Pageable;
	from: Dayjs;
	to: Dayjs;
	keyword?: string;
	isForRefundSearch?: boolean;
};
