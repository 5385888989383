import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { PosBoardInfoType } from '@kinderlabs-pos/shared-data-type';

export const getListCompareTwoStoreAsync =
	(rootUrl: string) =>
	async ({ targetStoreId, sourceStoreId }: { targetStoreId: number; sourceStoreId: number }) =>
		(
			await posAxiosClient.get<PosBoardInfoType[]>(`${rootUrl}/pos-board/list/in-store/compare`, {
				params: {
					targetStoreId,
					sourceStoreId,
				},
			})
		).data;
