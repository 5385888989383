import {
	alpha,
	Box,
	BoxProps,
	Button,
	IconButton,
	InputBase,
	Stack,
	StackProps,
	styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { useRef, useCallback } from 'react';
import { EnterOutlined } from '@ant-design/icons';
import { grey } from '@mui/material/colors';
import { f } from 'msw/lib/glossary-58eca5a8';

export interface ISearchBoxProps extends Omit<BoxProps, 'onChange'> {
	value?: string;
	width?: number;
	height?: number;
	disabled?: boolean;
	onChange?: (val: string) => void;
	onSearch: () => void | Promise<void>;
	hasDeleteKey?: boolean;
	hasSearchButton?: boolean;
	stackProps?: StackProps;
	blurWhenSearch?: boolean;
	searchOnChange?: boolean;
}

export const SearchBox: React.FC<ISearchBoxProps> = ({
	width,
	value,
	height,
	disabled = false,
	placeholder,
	onChange,
	onSearch,
	hasDeleteKey = false,
	hasSearchButton = false,
	stackProps,
	blurWhenSearch = false,
	searchOnChange = false,
	...others
}) => {
	const ref = useRef<HTMLInputElement>();
	const handleDeleteAll = useCallback(() => {
		if (ref?.current) ref.current.value = '';
		if (onChange) onChange('');
	}, [ref]);

	return (
		<Stack
			direction={'row'}
			spacing={1}
			{...stackProps}>
			<Search
				width={width}
				height={height}
				flexGrow={1}
				borderRadius={1}
				{...others}>
				<SearchIconWrapper>
					<SearchIcon />
				</SearchIconWrapper>
				<StyledInputBase
					disabled={disabled}
					autoFocus
					value={value}
					inputRef={ref}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							onSearch();
							if (blurWhenSearch) setTimeout(() => ref.current?.blur(), 10);
						}
					}}
					onChange={
						onChange &&
						((e) => {
							onChange(e.currentTarget.value);
							searchOnChange && onSearch();
						})
					}
					placeholder={placeholder || 'Search…'}
					inputProps={{ 'aria-label': 'search' }}
					withdelete={hasDeleteKey ? 'on' : 'off'}
				/>
				{hasDeleteKey && (
					<DeleteIconWrapper
						sx={{ right: 0, px: 1 }}
						disabled={disabled}
						onClick={async () => {
							await handleDeleteAll();
							ref.current?.focus();
						}}>
						<BackspaceIcon sx={{ color: grey[400] }} />
					</DeleteIconWrapper>
				)}
			</Search>
			{hasSearchButton && (
				<Button
					disabled={disabled}
					color={'inherit'}
					variant='outlined'
					onClick={(e) => {
						e.stopPropagation();
						onSearch();
					}}>
					<EnterOutlined />
				</Button>
			)}
		</Stack>
	);
};

const Search = styled(Box)<{ width?: number; height?: number }>(({ width, height, theme }) => ({
	position: 'relative',
	display: 'flex',
	borderRadius: theme.shape.borderRadius,
	backgroundColor: alpha(theme.palette.common.white, 0.15),
	'&:hover': {
		backgroundColor: alpha(theme.palette.common.white, 0.25),
	},
	marginLeft: 0,
	width: width || '100%',
	height: height || 43,
	// [theme.breakpoints.up('sm')]: {
	//   width: 'auto',
	// },
	border: `1px solid ${grey[400]}`,
	'& .MuiSvgIcon-root': {
		color: grey[400],
	},
	'&:has(.MuiInputBase-root.Mui-focused)': {
		borderColor: theme.palette.primary.main,
		'& .MuiSvgIcon-root': {
			color: theme.palette.primary.main,
		},
	},
}));

const SearchIconWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(0, 1),
	height: '100%',
	position: 'absolute',
	pointerEvents: 'none',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const DeleteIconWrapper = styled(IconButton)(({ theme }) => ({
	padding: theme.spacing(2),
	height: '100%',
	position: 'absolute',
	right: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)<{ withdelete: 'on' | 'off' }>(
	({ withdelete, theme }) => ({
		color: 'inherit',
		flexGrow: 1,
		height: '100%',
		'& .MuiInputBase-input': {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(3)})`,
			paddingRight: withdelete === 'on' ? `calc(1em + ${theme.spacing(3)})` : ``,
			transition: theme.transitions.create('width'),
			width: '100%',
		},
	})
);
