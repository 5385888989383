import { useQuery } from '@tanstack/react-query';
import { QueryClient } from '../QueryClient';
import { OnlineTicketAPI } from '@kinderlabs-pos/apis/pos';
import { createQueryKeys } from '@lukemorales/query-key-factory';

export const onlineTicketKeys = createQueryKeys('online-tickets', {
	list: ({ storeId, search }: { storeId: number; search: string }) => ({
		queryKey: [storeId, search],
		queryFn: async () => {
			const res = await OnlineTicketAPI.search({ storeId, search });
			return res;
		},
	}),
});

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: onlineTicketKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: onlineTicketKeys._def });
};

export const OnlineTicketQueryState = {
	keys: onlineTicketKeys,
	invalidateQueries,
};
