interface Options {
	unit?: string;
	prefix?: string;
}
export const numberWithCommas = (x?: number | string, options?: Options) => {
	if (!x && x !== 0) return '';
	else if (typeof x === 'string')
		return `${options?.prefix ? `${options?.prefix} ` : ''}${(+x).toLocaleString()} ${
			options?.unit || ''
		}`;
	else
		return `${options?.prefix ? `${options?.prefix} ` : ''}${x.toLocaleString()} ${
			options?.unit || ''
		}`;
};

export const numberWithCommasAndWon = (x?: number | string) => {
	return numberWithCommas(x, { prefix: '₩' });
};

export const numberWithCommasAnd원 = (
	x: number | string,
	i18n: 'ko' | 'eng' | 'jpn' | 'chn' = 'ko'
) => {
	if (i18n !== 'ko') return numberWithCommasAndWon(x);
	return numberWithCommas(x, { unit: ' 원' });
};

export const numberWithCommasAnd원PlusMinus = (
	x: number,
	i18n: 'ko' | 'eng' | 'jpn' | 'chn' = 'ko'
) => {
	return `${x >= 0 ? '+' : '-'} ${numberWithCommas(Math.abs(x), { unit: ' 원' })}`;
};

export const colorsWithNumber = (x?: number): 'error' | 'primary' | 'text.secondary' => {
	return x === undefined || x === 0 ? 'text.secondary' : x > 0 ? 'primary' : 'error';
};

export const getCountLabel = (count: number | null) => {
	return count ? `(${numberWithCommas(count)}건)` : '( - )';
};

export const getNewCountLabel = (count: number | null) => {
	return count ? `${numberWithCommas(count)} 건` : ' - ';
};
