import { Card, CardActionArea, CardActionAreaProps, Typography } from '@mui/material';

export interface IKioskBoardDesignBaseProps extends CardActionAreaProps {
	borderColor?: string;
	opacity?: number;
}
export const KioskBoardDesignBase: React.FC<IKioskBoardDesignBaseProps> = ({
	onClick,
	children,
	borderColor = 'rgba(0,0,0,0)', // 투명
	opacity = 1,
	...otherProps
}) => {
	return (
		<Card
			sx={(theme) => ({
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				border: `2px solid ${borderColor}`,
				borderRadius: 4,
				overflow: 'hidden',
				boxShadow: 'none',
				aspectRatio: '1/1.6',
				bgcolor: 'rgba(0,0,0,0)',
				opacity,
			})}>
			<CardActionArea
				onClick={onClick}
				disableRipple
				disableTouchRipple
				sx={{ width: '100%', height: '100%', fontSize: '1rem' }}>
				{children}
			</CardActionArea>
		</Card>
	);
};
