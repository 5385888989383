import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const deleteMutInfoListAsync =
	(rootUrl: string) =>
	async ({ mutList }: DeleteMutInfoListRequestType) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/mut/list`, {
				params: {
					idList: mutList,
				},
			})
		).data;

export type DeleteMutInfoListRequestType = { mutList: number[] };
