import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CommutationTicketType } from '@kinderlabs-pos/shared-data-type';

export const useCmtAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, memberId, orderId, ticketId }: UseCmtAsyncRequestType) =>
		(
			await posAxiosClient.post<CommutationTicketType>(
				`${rootUrl}/ticket/cmt/store/${storeId}/pos/${posId}/use`,
				{
					memberId,
					orderId,
				},
				{
					params: {
						ticketId,
					},
				}
			)
		).data;

export type UseCmtAsyncRequestType = {
	ticketId: number;
	orderId: string;
	memberId: number;
	storeId: number;
	posId: number;
};
