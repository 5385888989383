import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	WarehouseOrderMethodType,
	WarehouseOrderRequestType,
	WarehouseOrderResponseType,
} from '@kinderlabs-pos/shared-data-type';

export const updateWarehouseOrderStatusAsync =
	(rootUrl: string) =>
	async ({
		id,
		data,
		methodType,
	}: {
		id: number;
		data: WarehouseOrderRequestType | null;
		methodType: WarehouseOrderMethodType;
	}) =>
		(
			await posAxiosClient.put<WarehouseOrderResponseType>(
				`${rootUrl}/warehouse-order/${methodType}`,
				data,
				{
					params: {
						id,
					},
				}
			)
		).data;
