import { PosBoardInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const createPosBoardInfoAsync =
	(rootUrl: string) =>
	async ({ index, ...others }: CreatePosBoardInfoRequestType) =>
		(
			await posAxiosClient.post<PosBoardInfoType>(
				`${rootUrl}/pos-board/`,
				{
					...others,
				},
				{
					params: {
						index,
					},
				}
			)
		).data;

export type CreatePosBoardInfoRequestType = Pick<PosBoardInfoType, 'name'> & {
	storeId: number;
	index?: number;
};
