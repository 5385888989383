import { rootUrl } from '../../const';
import { createCmtInfoAsync } from './createCmtInfo';
import { deleteCmtInfoListAsync } from './deleteCmtInfoListAsync';
import { getCmtInfoDeatilAsync } from './getCmtInfoDetailAsync';
import { getCmtInfoHistoryAsync } from './getCmtInfoHistoryAsync';
import { getCmtInfoListInStoreAsync } from './getCmtInfoListInStoreAsync';
import { getCmtInfoListAsync } from './getCmtInfoListAsync';
import { updateCmtInfoAsync } from './updateCmtInfoAsync';


export const AdminCmtInfoApis = {
  getList : getCmtInfoListAsync(rootUrl),
  getListInStore: getCmtInfoListInStoreAsync(rootUrl),
  getListDetail : getCmtInfoDeatilAsync(rootUrl),
  getListHistory : getCmtInfoHistoryAsync(rootUrl),
  createInfo : createCmtInfoAsync(rootUrl),
  deleteInfoList : deleteCmtInfoListAsync(rootUrl),
  updateInfo : updateCmtInfoAsync(rootUrl)
};
