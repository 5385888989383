import { BaseInfoType, KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import {
	AdminKioskInfoState,
	KioskElectronStoreState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

export const KioskI18NPanel = () => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId: storeInfo.id, kioskId: savedKioskInfo.id }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (!kioskInfo || !kioskInfo.kioskBoardId)
		return (
			<Stack>
				<Typography variant='h6'>키오스크 보드가 없습니다.</Typography>
			</Stack>
		);
	return <KioskI18NPanelInternal kioskInfo={kioskInfo} />;
};

const KioskI18NPanelInternal = ({ kioskInfo }: { kioskInfo: KioskInfoType & BaseInfoType }) => {
	const localSettings = KioskElectronStoreState.useKioskMainPageLocalSettings().data;
	const saveLocalSettingsMutation = KioskElectronStoreState.useSaveKioskLocalSettings();

	return (
		<Stack spacing={1}>
			<Stack
				width='100%'
				flexDirection={'row'}
				alignItems={'center'}>
				<FormControlLabel
					control={
						<Switch
							checked={localSettings?.i18n.eng}
							onChange={(e) =>
								localSettings &&
								saveLocalSettingsMutation.mutate({
									...localSettings,
									i18n: {
										...localSettings.i18n,
										eng: e.target.checked,
									},
								})
							}
						/>
					}
					label={<Typography variant={'subtitle1'}>영어 사용</Typography>}
				/>
			</Stack>
			<Stack
				width='100%'
				flexDirection={'row'}
				alignItems={'center'}>
				<FormControlLabel
					control={
						<Switch
							checked={localSettings?.i18n.jpn}
							onChange={(e) =>
								localSettings &&
								saveLocalSettingsMutation.mutate({
									...localSettings,
									i18n: {
										...localSettings.i18n,
										jpn: e.target.checked,
									},
								})
							}
						/>
					}
					label={<Typography variant={'subtitle1'}>일본어 사용 (준비중입니다)</Typography>}
				/>
			</Stack>
			<Stack
				width='100%'
				flexDirection={'row'}
				alignItems={'center'}>
				<FormControlLabel
					control={
						<Switch
							checked={localSettings?.i18n.chn}
							onChange={(e) =>
								localSettings &&
								saveLocalSettingsMutation.mutate({
									...localSettings,
									i18n: {
										...localSettings.i18n,
										chn: e.target.checked,
									},
								})
							}
						/>
					}
					label={<Typography variant={'subtitle1'}>중국어 사용 (준비중입니다)</Typography>}
				/>
			</Stack>
		</Stack>
	);
};
