import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { WarehouseOrderResponseType } from '@kinderlabs-pos/shared-data-type';

export const getDetailWarehouseOrderInfoAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<WarehouseOrderResponseType>(`${rootUrl}/warehouse-order`, {
			params: {
				id,
			},
		})
	).data;
