import { TermsConditionsType } from '.';

export const 마케팅동의TermsConditions: TermsConditionsType[] = [
	{
		infos: [
			{
				title:
					'해당 매장의 상품 안내, 이벤트 행사 관련 정보 안내 목적으로 SMS, APP PUSH, EMAIL을 통한 상품 및 이벤트 정보 송신을 위해 이용하고자 합니다. 마케팅 및 홍보에 활용을 원하지 않을 경우 동의하시지 않으셔도 됩니다.',
				table: {
					tableHeadList: ['수집 · 이용 항목', '수집 • 이용 목적', '보유 기간'],
					tableBodyList: [
						[
							'고객의 동의를 통해 수집된 필수 및 선택 정보, 서비스 이용 기록',
							'매장에서 제공하는 우대 행사 · 프로모션 정보 · 상품 안내 제공 및 홍보',
							'동의 철회 또는 탈퇴 시 지체 없이 파기',
						],
					],
				},
			},
			{
				title:
					'위의 개인정보 수집 · 이용에 대한 동의를 거부하실 권리가 있습니다. 단, 동의를 거부하실 경우 혜택 및 이벤트에 대한 APP PUSH, LMS 홍보 안내를 받으실 수 없습니다.',
			},
		],
	},
];
