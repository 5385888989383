import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CartType,
	OrderType,
	OrderTypeWithDdizisAndExchanges,
	PaymentInfoType,
	ReceiptInfoType,
} from '@kinderlabs-pos/shared-data-type';
import dayjs, { Dayjs } from 'dayjs';

export const kyulsanOrderAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, payment, receipt, cart, targetDate }: OrderKyulsanRequestType) => {
		return (
			await posAxiosClient.post<{
				order: OrderTypeWithDdizisAndExchanges;
				payment: PaymentInfoType;
			}>(`${rootUrl}/order/store/${storeId}/pos/${posId}/order/kyulsan`, {
				cart,
				targetDate: targetDate.format('YYYY-MM-DD'),
				createPaymentRequest: payment
					? {
							payment,
							receipt: receipt
								? {
										...receipt,
										approvalDate: dayjs(receipt.approvalDate).format(`YYYY-MM-DDTHH:mm:ss`),
								  }
								: undefined,
					  }
					: undefined,
			})
		).data;
	};

export type OrderKyulsanRequestType = {
	storeId: number;
	posId: number;
	payment?: Omit<PaymentInfoType, 'id' | 'orderId'>;
	receipt?: ReceiptInfoType;
	cart: CartType;
	targetDate: Dayjs;
};
