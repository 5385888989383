import { TermsConditionsType } from '.';

export const 개인정보이용및수집동의및제3자동의DetailList: TermsConditionsType[] = [
	{
		title: ' 목적',
		infos: [
			{
				title:
					'개인정보란 생존하는 개인에 관한 정보로서 당해 정보에 포함되어 있는 성명, 주민등록번호 등의 사항에 의하여 당해 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함합니다.)를 말합니다.',
			},
			{
				title:
					'(주)킨더랩스(Kinder Labs Inc.)(이하 "회사"라 함)은 귀하의 개인정보보호를 매우 중요시하며, 『개인정보보호법』상의 개인정보보호규정을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.',
			},
			{
				title:
					'개인정보처리방침은 정부의 법령이나 지침의 변경, 또는 보다 나은 서비스의 제공을 위하여 그 내용이 변경될 수 있습니다. 이 경우 회사는 웹 사이트에 이를 게시하여 공지하고 있습니다.',
			},
			{
				title:
					'회사는 개인정보처리방침을 홈페이지 등 사이트 첫 화면 또는 첫 화면과의 연결화면을 통해 공개함으로써 이용자가 언제나 용이하게 보실 수 있도록 조치하고 있습니다.',
			},
			{
				title:
					'회사는 개인정보처리방침의 지속적인 개선을 위하여 개인정보처리방침을 개정하는데 필요한 절차를 정하고 있습니다.',
			},
		],
	},
	{
		title: '개인정보 수집에 대한 동의 및 수집 방법',
		infos: [
			{
				title:
					'회사는 이용자께서 회사의 동의문 또는 이용약관의 내용에 대해 ‘동의함’ 또는 ‘동의하지 않음’을 클릭할 수 있는 절차를 마련하여 ‘동의함’을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다. 단, 회사는 다음 각 호의 어느 하나에 해당하는 경우에는 동의 없이 이용자의 개인정보를 수집∙이용할 수 있습니다.',
				descs: [
					{
						label:
							'정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한 개인정보로서 경제적∙기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우',
					},
					{ label: '정보통신서비스 제공에 따른 요금정산을 위하여 필요한 경우' },
					{ label: '그 밖에 법률에 특별한 규정이 있는 경우' },
					{
						label:
							'계약을 이행하기 위하여 필요한 개인정보로서 경제적ᆞ기술적인 사유로 통상적인 동의를 받는 것이 뚜렷하게 곤란한 경우',
						info: ['서비스이용기록, IP 주소, 접속로그, 브라우저 버전'],
					},
					{
						label:
							'수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않으며 안전성 확보 조치를 적용한 경우',
						info: [
							'당초 수집 목적과 관련성',
							'수집 정황 또는 처리 관행에 비춰봤을때 개인정보의 추가적인 이용에 대한 예측 가능성',
							'정보주체의 이익 침해 여부',
							'가명처리 또는 암호화 등 안전성 확보에 필요한 조치 적용 등의 사항을 모두 고려하여 판단',
						],
					},
				],
			},
			{
				title:
					'회사가 개인정보를 수집하는 경우에는 반드시 사전에 이용자에게 해당 사실을 알리고 동의를 구하고 있으며, 아래와 같은 방법을 통해 개인정보를 수집합니다.',
				descs: [
					{
						label:
							'회원 가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우',
					},
					{ label: '제휴 서비스 또는 단체 등으로부터 개인정보를 제공받는 경우' },
					{ label: '고객센터를 통한 상담 과정에서 카카오톡, 전화 등을 통하는 경우' },
					{ label: '온∙오프라인에서 진행되는 이벤트∙행사 등을 통한 경우' },
				],
			},
		],
	},
	{
		title: '개인정보의 수집 • 이용 목적 및 수집항목 등',
		infos: [
			{
				title:
					'회원가입 및 서비스 이용 과정에서 회원으로부터 수집 • 이용하는 개인정보는 아래와 같습니다.',
				table: {
					tableHeadList: ['분류', '수집항목', '수집 • 이용 목적', '보유기간'],
					tableBodyList: [
						[
							'필수정보',
							'이름\n휴대폰 번호\n생년월일\n성별\n거주 지역\n입장객 정보중 매장에서 필수적으로 수집을 원하는 정보',
							'1. 회원 가입 및 회원 서비스 제공\n2. 서비스 접수, 중개, 이용, 상담\n3. 부정이용 확인 및 방지\n4. 서비스 제공에 관한 계약 이행\n5. 서비스 제공에 따른 요금 정산, 비상시 연락\n6. 컨텐츠 제공 및 개인 맞춤 서비스 제공\n7. 본인 인증, 물품 구매 및 요금 결제\n8. 사고 발생 시 이용 확인 및 보험 보상 업무\n9. 서비스 이용실적 정보 통계 분석\n10. 신규 서비스 개발 및 서비스 개선',
							'동의 철회 또는 탈퇴 시 지체 없이 파기\n단, 관계 법령에 따라 보존이 필요한 경우\n 해당 법령에서 요구하는 기한까지 보관하며,\n회원가입 남용, 서비스 부정사용 확인 및 방지를 위해 CI(고유식별번호) 정보는 회원탈퇴 후 90일까지 보관',
						],
					],
				},
			},
			{
				title: '법령에 의해 수집 이용되는 회원 정보는 아래와 같습니다.',
				table: {
					tableHeadList: ['보유정보', '보유기간', '근거법률'],
					tableBodyList: [
						[
							'계약 또는 청약철회 등에 관한 기록',
							'5년',
							'전자상거래 등에서의 소비자보호에 관한 법률',
						],
						[
							'대금결제 및 재화 등의 공급에 관한 기록',
							'5년',
							'전자상거래 등에서의 소비자보호에 관한 법률',
						],
						[
							'소비자의 불만 또는 분쟁처리에 관한 기록',
							'5년',
							'전자상거래 등에서의 소비자보호에 관한 법률',
						],
						['표시, 광고에 관한 기록', '6개월', '전자상거래 등에서의 소비자보호에 관한 법률'],
						['웹사이트 방문 기록', '3개월', '통신비밀보호법'],
					],
				},
			},
			{
				title:
					'이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 의료정보 등)는 수집하지 않습니다.',
			},
			{
				title:
					'이용자의 개인정보는 서비스를 이용하는 시점부터 서비스를 제공하는 기간 동안에만 제한적으로 이용하고 있습니다. 이용자가 회원탈퇴(동의철회)를 요청하거나 제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 수집 및 이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 이용자의 개인정보는 지체 없이 파기 됩니다.',
			},
			{
				title:
					'이용자의 동의를 받아 보유하고 있는 거래정보 등을 이용자께서 열람을 요구하시는 경우 회사는 지체 없이 그 내역을 확인할 수 있도록 조치합니다.',
				descs: [
					{
						label: '종이에 출력된 개인 정보 : 분쇄기로 분쇄하거나 소각',
					},
					{
						label:
							'전자적 파일 형태로 저장된 개인 정보 : 기록을 재사용할 수 없는 기술적 방법을 사용하여 삭제',
					},
				],
			},
			{
				title:
					'본 플랫폼은 아동(내국인의 경우 만14세 미만, 외국인의 경우 만16세 미만)을 대상으로 하지 않습니다. 당사가 아동으로부터 개인정보를 수집한 사실을 인지하게 되는 경우, 당사는 이러한 정보를 삭제할 것입니다. 당사가 아동으로부터 정보를 수집하였다고 생각되는 경우 [help@kinderlabs.co.kr]로 연락 주시기 바랍니다.',
			},
		],
	},
	{
		title: '개인정보의 제3자 제공에 대한 동의',
		infos: [
			{
				title:
					'회사는 회원의 개인정보를 제 3 조(개인정보수집∙이용 목적 및 수집항목 등)에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 다만, 아래와 같이 서비스 제공을 위해 개인정보를 제공 받는 자, 제공목적, 제공 항목, 이용 및 보유기간을 회원에게 고지하여 동의를 구한 후 제3자에게 제공합니다.',
				table: {
					tableHeadList: ['제공받는 자', '제공목적', '제공항목', '보유,이용기간'],
					tableBodyList: [
						[
							'킨더랩스 서비스 가입 및 이용 매장',
							'1. 계약의 이행 및 서비스 제공\n2. 이용자 및 이용정보 확인\n3. 민원처리 등 소비자 분쟁 해결\n4. 이용 통계 및 매출 통계 분석\n5. 신규 서비스 개발 및 서비스 개선',
							'고객의 동의를 통해 수집된 필수 및 선택 정보,서비스 이용 기록 등',
							'동의 철회 또는 탈퇴 시 지체 없이 파기\n단, 관계 법령에 따라 보존이 필요한 경우 해당 법령에서 요구하는 기한까지 보관',
						],
					],
				},
			},
			{
				title: '전항에도 불구하고, 다음의 경우에는 예외로 합니다.',
				descs: [
					{ label: '법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우' },
					{
						label:
							'정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우',
					},
					{ label: '정보통신서비스의 제공에 따른 요금정산을 위하여 필요한 경우' },
					{
						label:
							'수집 목적과 합리적인 관련성이 있고 정보주체에게 불이익이 발생하지 않으며 안전성 확보 조치를 적용한 경우',
						info: [
							'당초 수집 목적과 관련성',
							'수집 정황 또는 처리 관행에 비춰봤을때 개인정보의 추가적인 이용∙제공에 대한 예측 가능성',
							'정보주체의 이익 침해 여부',
							'가명처리 또는 암호화 등 안전성 확보에 필요한 조치 적용 등의 사항을 모두 고려하여 판단',
						],
					},
				],
			},
		],
	},
	{
		title: '개인정보의 열람, 정정',
		infos: [
			{
				title:
					'이용자는 언제든지 등록되어 있는 본인의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 정정을 하고자 할 경우에는 개인정보보호책임자에게 전화 또는 E-mail 로 연락하시면 지체 없이 조치하겠습니다.',
			},
			{
				title:
					'이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.',
			},
			{
				title:
					'잘못된 개인정보를 제 3 자에게 이미 제공한 경우에는 정정 처리결과를 제 3 자에게 지체 없이 통지하여 정정하도록 조치하겠습니다.',
			},
		],
	},
	{
		title: '동의 철회',
		infos: [
			{
				title:
					'회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 회원이 동의한 내용을 회원은 언제든지 철회하실 수 있습니다. ',
			},
			{
				title:
					'동의철회는 개인정보보호책임자에게 연락하거나, 킨더랩스 서비스 가입 및 이용 매장의 담당자에게 요청하시면 개인정보의 삭제 등 필요한 조치를 하겠습니다. 동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는 그 사실을 귀하께 지체 없이 통지하도록 하겠습니다.',
			},
			{
				title:
					'회사는 개인정보의 수집에 대한 동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록 필요한 조치를 취하겠습니다.',
			},
		],
	},
	{
		title: '개인정보보호를 위한 기술적ㆍ관리적 대책',
		infos: [
			{
				title:
					'회사는 회원의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조, 또는 훼손되지 않도록 안전성 확보를 위하여 합리적인 조치를 취할 수 있으며, 이를 위한 기술적 대책으로는 다음과 같은 것이 있습니다.',
				descs: [
					{
						label:
							'회원의 개인정보는 비밀번호 및 암호화된 정보에 의해 보호되고 있습니다. 그러나 회원의 비밀번호나 개인정보는 공공장소에서의 인터넷사용 등 여러 방법으로 타인에게 알려질 가능성이 높으므로 이의 보호를 철저히 하는 것이 무엇보다 중요합니다. 그러므로 회원께서도 개인의 정보를 타인에게 유출시키거나 제공하여서는 안 되며, 자신의 개인정보를 책임 있게 관리하여야 합니다. 이러한 회원 개인의 실수나 기본적인 인터넷의 위험성 때문에 일어나는 문제에 대해서 회사는 책임을 지지 않습니다.',
					},
					{
						label:
							'회원의 개인정보는 기본적으로 비밀번호 및 암호화된 정보에 의해 보호되며, 파일 및 전송 데이터를 암호화하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.',
					},
					{
						label:
							'회사는 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있으며 백신프로그램은 주기적으로 업데이트됩니다.',
					},
					{
						label:
							'회사는 해킹 및 바이러스 등에 의하여 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해, 외부로부터의 침입탐지 및 침입차단 시스템을 두어 24시간 모니터링하며 관리하고 있습니다.',
					},
				],
			},
			{
				title:
					'회사는 회원의 개인정보보호의 중요성을 인식하고 있으며 이를 위해 개인정보처리직원을 합리적으로 제한하고 있으며 개인정보보호책임자가 처리직원을 대상으로 교육을 주기적으로 실시하여 개인정보보호를 위해 최선을 다하고 있습니다. 또한 본 정책에 명시된 이행사항 및 관련 직원의 준수여부를 정기적으로 점검하여 위반내용이 있는 경우 이를 시정 또는 개선하고 기타 필요한 조치를 취하도록 하고 있습니다.',
			},
		],
	},
	{
		title: '링크 사이트',
		infos: [
			{
				title:
					'회사는 이용자들께 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 회사는 외부사이트 및 자료에 대한 아무런 통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에 대해 책임질 수 없으며 보증할 수 없습니다. 회사가 포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의 페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은 회사와 무관하므로 새로 방문한 사이트의 정책을 검토해 보시기 바랍니다.',
			},
		],
	},
	{
		title: '개인정보의 위탁처리',
		infos: [
			{
				title:
					'회사는 서비스 향상을 위해서 이용자의 개인정보를 외부에 수집/처리/관리 등을 위탁하여 처리할 수 있습니다.',
			},
			{
				title:
					'개인정보의 처리를 위탁하는 경우에는 위탁기관 및 그 사실을 홈페이지를 통해 미리 이용자께 고지하겠습니다. 다만, 재화 또는 서비스를 홍보하거나 판매를 권유하는 업무를 위탁하는 경우에는 이용자에게 개별적으로 이메일 주소 등을 통해 이용자에게 개별 통지하되, 회사가 과실 없이 서면, 전자우편 등의 방법으로 위탁하는 업무의 내용과 수탁자를 귀하에게 알릴 수 없는 경우에 해당사항을 홈페이지에 30 일 이상 게시하겠습니다.',
			},
			{
				title:
					'개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제 3 자 제공의 금지 및 사고시의 책임부담, 위탁기간, 처리 종료후의 개인정보의 반환 또는 파기 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.',
			},
			{
				title: '회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.',
				table: {
					tableHeadList: ['수탁업체명', '위탁한 업무 내용'],
					tableBodyList: [
						['카카오, 슈어엠주식회사, (주)엘지유플러스', '메시지 발송관련 서비스'],
						[
							'한국정보통신(주), 나이스정보통신(주), KIS정보통신(주), NICE페이먼츠(주)',
							'결제 및 전자결제 관련 서비스',
						],
					],
				},
			},
		],
	},
	{
		title: '이용자의 권리와 의무',
		infos: [
			{
				title:
					'이용자의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.',
			},
			{
				title:
					'이용자는 개인정보를 보호받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를 포함한 귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시에는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』, 『개인정보보호법』 등에 의해 처벌받을 수 있습니다.',
			},
		],
	},
	{
		title: '의견수렴 및 불만처리',
		infos: [
			{
				title:
					'회사는 이용자의 의견을 소중하게 생각하며, 이용자는 의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.',
			},
			{
				title:
					'회사는 이용자와의 원활한 의사소통을 위한 창구를 운영하고 있으며 연락처는 아래와 같습니다.',
				descs: [
					{ label: '전자우편 : help@kinderlabs.co.kr' },
					{ label: '전화번호 : 02-6410-0117' },
				],
			},
			{
				title:
					'기타 개인정보에 관한 상담이 필요한 경우에는 회사의 위 전자우편으로 문의하실 수 있으며, 국가기관에 신고나 상담이 필요한 경우에는 아래의 연락처에 문의하셔서 도움을 받으실 수 있습니다.',
				descs: [{ label: '개인정보침해신고센터(http://privacy.kisa.or.kr / 국번 없이 118)' }],
			},
		],
	},
	{
		title: '개인정보 보호책임자',
		infos: [
			{
				title:
					'회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 회사는 개인정보보호책임자를 두고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호책임자에게 연락 주시기 바랍니다. 회원의 문의사항에 신속하고 성실하게 답변해드리겠습니다.',
				table: {
					tableHeadList: [],
					tableBodyList: [
						['성명', '김요나'],
						['전화번호', '02-6410-0117'],
						['이메일', 'help@kinderlabs.co.kr'],
					],
				},
			},
		],
	},
	{
		title: '개인정보처리방침의 고지 및 변경',
		infos: [
			{
				title:
					'개인정보 처리방침을 변경하는 경우 회사는 홈페이지 첫 화면 하단에 공개하여 이용자가 언제든지 변경된 사항을 쉽게 알아볼 수 있도록 조치하겠습니다.',
			},
		],
	},
	{
		title: '부칙',
		infos: [
			{
				title: '제1조 (시행일)',
				descs: [{ label: '이 개인정보처리방침은 2024년 06월 01일부터 시행됩니다.' }],
			},
		],
	},
];
