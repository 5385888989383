import { AdminMutInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { MutInfoListFilterType, MutInfoType } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtomValue } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { QueryClient } from '../QueryClient';

const mutInfo = 'mutInfo';
const searchOptionAtom = atom<MutInfoListFilterType>({
	page: 0,
	size: 10,
	search: undefined,
	sort: undefined,
	blocks: undefined,
	ddiziInfoId: undefined,
});
const selectedIdiceAtom = atomWithReset<number[]>([]);

const useMutListQuery = (param: MutInfoListFilterType) => {
	const resetSelection = useResetAtom(selectedIdiceAtom);

	const result = useQuery({
		queryKey: [mutInfo, 'list', param],
		queryFn: async () => {
			const res = await AdminMutInfoApis.getList(param);
			return res;
		},
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		resetSelection();
	}, [result.data]);

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useMutInfoListInStoreQuery = () => {
	const resetSelection = useResetAtom(selectedIdiceAtom);

	const result = useQuery({
		queryKey: [mutInfo, 'list', 'in-store'],
		queryFn: async () => {
			const res = await AdminMutInfoApis.getListInStore();
			return res;
		},
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useMutInfoDetailQuery = (id?: number) => {
	const result = useQuery({
		queryKey: [mutInfo, 'detail', id],
		queryFn: async () => {
			if (!id) return undefined;
			const res = await AdminMutInfoApis.getListDetail(id);
			return res;
		},
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useMutInfoDetailHistoryQuery = (id?: number) => {
	const result = useQuery({
		queryKey: [mutInfo, 'detail', 'history', id],
		queryFn: async () => {
			if (!id) return undefined;
			const res = await AdminMutInfoApis.getListHistory(id);
			return res;
		},
		refetchOnWindowFocus: true,
	});

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useCreateMutInfo = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: Omit<MutInfoType, 'id'>) => {
			await AdminMutInfoApis.createInfo(input);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([mutInfo]);
			},
		}
	);
};

const useUpdateMutInfo = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: MutInfoType) => {
			await AdminMutInfoApis.updateInfo(input);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([mutInfo, 'detail']);
			},
		}
	);
};

const useDeleteMutInfoList = () => {
	const queryClient = QueryClient.useQueryClient();

	return QueryClient.useMutation(
		async (input: number[]) => {
			await AdminMutInfoApis.deleteInfo({ mutList: input });
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([mutInfo]);
			},
		}
	);
};

export const AdminMutInfoState = {
	searchOption: searchOptionAtom,
	selectedIdice: selectedIdiceAtom,
	useListPageQuery: useMutListQuery,
	useListInStoreQuery: useMutInfoListInStoreQuery,
	useDeatilQuery: useMutInfoDetailQuery,
	useDetailHistoryQeury: useMutInfoDetailHistoryQuery,
	useCreate: useCreateMutInfo,
	useUpdate: useUpdateMutInfo,
	useDelete: useDeleteMutInfoList,
};
