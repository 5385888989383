import { StoreInfoState, authState } from '@kinderlabs-pos/state';
import { WithDialog } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { KioskLocalAdminPinNumberDialog } from '../dialog/KioskLocalAdminPinNumberDialog';
import { useKioskRouter } from './useKioskRouter';

export const KioskAutoStoreTemplate = () => {
	const user = useAtomValue(authState.user);
	return (
		<>
			<Stack
				className={'pin-button'}
				position={'absolute'}
				right={40}
				top={10}>
				<Suspense>
					<KioskLocalAdminButtonWrapper />
					{user && <authState.InfiniteAuthRefresh />}
				</Suspense>
			</Stack>
			<Outlet />
		</>
	);
};

const KioskLocalAdminButtonWrapper = () => {
	const location = useLocation();
	const { navigate로컬어드민 } = useKioskRouter();

	const savedKioskInfo = StoreInfoState.useKioskLoginInfo();
	const isOnUserInfoPage = location.pathname.includes('userInfo');
	return (
		<WithDialog
			dialog={(open, closeDialog) =>
				savedKioskInfo?.storeId &&
				savedKioskInfo.deviceId && (
					<KioskLocalAdminPinNumberDialog
						storeId={savedKioskInfo.storeId}
						kioskId={savedKioskInfo.deviceId}
						onSuccess={navigate로컬어드민}
						open={open}
						closeDialog={closeDialog}
					/>
				)
			}>
			{(openDialog) => (
				<Button
					disableRipple
					sx={{ width: 80, height: 80, zIndex: 1000 }}
					onClick={(e) => {
						e.stopPropagation();

						if (savedKioskInfo) {
							if (isOnUserInfoPage) return;
							openDialog();
						} else {
							navigate로컬어드민();
						}
					}}
				/>
			)}
		</WithDialog>
	);
};
