import { KioskType, OrderType } from '@kinderlabs-pos/shared-data-type';
import { AdminKioskInfoState, StoreInfoState } from '@kinderlabs-pos/state';
import { FullSizeCircularProgress } from '@kinderlabs-pos/ui-components';
import { Card, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { atom, useSetAtom } from 'jotai';
import { PropsWithChildren, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { useKioskRouter } from '../../utils/useKioskRouter';
import { UserAddressPage } from './UserAddressPage';
import { UserBirthdayPage } from './UserBirthdayPage';
import { UserGenderPage } from './UserGenderPage';
import { UserInfoPageHeader } from './UserInfoPageHeader';
import { UserNamePage } from './UserNamePage';
import { UserTelephoneCheckPage } from './UserTelephoneCheckPage';
import { UserTermsConditionsPage } from './UserTermsConditionsPage';
import { StepType, useFunnelStepHandler, useFunnelStepsForKioskSignup } from './steps';
import { useKioskUserInfoSignupFormik } from './useUserInfoFormik';

const UserInfoStepTypeArray = ['휴대전화', '이용약관', '생년월일', '이름', '성별'] as const;
export type UserInfoStepType = (typeof UserInfoStepTypeArray)[number];

export const KioksUserInfoPage = () => {
	return (
		<Stack
			flex={1}
			sx={{ overflow: 'auto', p: 10 }}>
			<Suspense fallback={<FullSizeCircularProgress />}>
				<Internal />
			</Suspense>
		</Stack>
	);
};

const Internal = () => {
	const { type } = useParams();
	const kioskType = type as KioskType | 'ONLINE-TICKETS';
	const { navigate홈으로, navigateKioskBoardPageByKioskType } = useKioskRouter();
	const savedKioskInfo = StoreInfoState.useKioskLoginInfo();
	const setFinalUser = useSetAtom(KioksUserInfoPage.finalUserInfo);
	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({
			storeId: savedKioskInfo?.storeId,
			kioskId: savedKioskInfo?.deviceId,
		}),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (!kioskInfo) throw Error('유효한 키오스크 정보가 아닙니다.');

	const formik = useKioskUserInfoSignupFormik({
		storeId: kioskInfo?.storeId,
		kioskType,
	});

	const funnelSteps = useFunnelStepsForKioskSignup({
		kioskSignupInfo: kioskInfo.signupInfo,
		isTicketKiosk: kioskInfo.type === 'TICKETS',
	});

	const { curStep, goNextStep, goPrevStep } = useFunnelStepHandler({
		funnelSteps,
		beforeStart: () => {
			formik.resetForm();
			navigate홈으로();
		},
		onFinish: () => {
			formik.handleSubmit();
		},
	});

	const funnelStepProps = {
		formik,
		goNextStep,
		goPrevStep,
	};

	return (
		<Card
			sx={{ flex: 1, overflow: 'auto', maxHeight: kioskType !== 'FANDB' ? '75%' : '100%', p: 5 }}>
			<Stack
				flex={1}
				sx={{ overflow: 'auto', height: '100%' }}>
				<UserInfoPageHeader
					curStep={curStep}
					kioskType={kioskType}
				/>
				<Funnel curStep={curStep}>
					<Funnel.step name={'전화번호'}>
						<UserTelephoneCheckPage
							isFnBKiosk={kioskType === 'FANDB'}
							signupImageUrl={kioskInfo.agreementImageUrl}
							telephoneProps={{
								...funnelStepProps,
								telephone: formik.values.telephone,
								setAlreadyMember: (member) => {
									setFinalUser({
										memberId: member.id,
										name: member.name,
										telephone: member.telephone,
									});
									navigateKioskBoardPageByKioskType(kioskType);
								},
								setTelephone: (telephone) =>
									formik.setValues((prev) => ({
										storeId: prev.storeId,
										telephone,
										storeAgreement: false,
										kinderlabsAgreement: false,
										marketingAgreement: false,
									})),
							}}
						/>
					</Funnel.step>
					<Funnel.step name={'이용약관'}>
						<UserTermsConditionsPage
							props={{
								...funnelStepProps,
								agreement: {
									storeAgreement: formik.values.storeAgreement,
									kinderlabsAgreement: formik.values.kinderlabsAgreement,
									marketingAgreement: formik.values.marketingAgreement,
								},
								setAgreement: (agreement) =>
									formik.setValues((prev) => ({
										...prev,
										kinderlabsAgreement: agreement.kinderlabsAgreement,
										marketingAgreement: agreement.marketingAgreement,
										storeAgreement: agreement.storeAgreement,
									})),
							}}
						/>
					</Funnel.step>
					<Funnel.step name={'생년월일'}>
						<UserBirthdayPage
							props={{
								...funnelStepProps,
								birthday: formik.values.birthday,
								setBirthday: async (birthday: string) => {
									await formik.setValues((prev) => ({ ...prev, birthday }));
								},
							}}
						/>
					</Funnel.step>
					<Funnel.step name={'이름'}>
						<UserNamePage
							props={{
								...funnelStepProps,
								name: formik.values.name,
								setName: async (name: string) => {
									await formik.setValues((prev) => ({ ...prev, name }));
								},
							}}
						/>
					</Funnel.step>
					<Funnel.step name={'주소'}>
						<UserAddressPage
							props={{
								...funnelStepProps,
								address: formik.values.address,
								setAddress: async (address) => {
									await formik.setValues((prev) => ({ ...prev, address }));
								},
							}}
						/>
					</Funnel.step>
					<Funnel.step name={'성별'}>
						<UserGenderPage
							props={{
								...funnelStepProps,
								gender: formik.values.gender,
								setGender: async (gender) => {
									await formik.setValues((prev) => ({ ...prev, gender }));
								},
							}}
						/>
					</Funnel.step>
				</Funnel>
			</Stack>
		</Card>
	);
};

interface FunnelType {
	curStep: StepType;
	children: React.ReactElement<
		{ name: UserInfoStepType },
		string | React.JSXElementConstructor<{ name: UserInfoStepType }>
	>[];
}

const Funnel = ({ curStep, children }: FunnelType) => {
	return <>{children.filter((c) => c.props.name === curStep)}</>;
};

const Step = ({ children }: { name: StepType } & PropsWithChildren) => {
	return <form style={{ flex: 1, overflowY: 'auto' }}>{children}</form>;
};

Funnel.step = Step;

KioksUserInfoPage.finalUserInfo = atom<OrderType['guestMemberInfo'] | undefined>(undefined);
