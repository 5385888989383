import { SubmitButton } from '@kinderlabs-pos/ui-atoms';
import { Button, ButtonProps, styled, Typography } from '@mui/material';
import React from 'react';

interface IButtonProps extends Omit<ButtonProps, 'variant'> {}

const RoundedButton: React.FC<IButtonProps> = ({ children, ...others }) => {
	return (
		<RoundedStyledButtonn
			variant='contained'
			{...others}>
			<Typography variant='h3'>{children}</Typography>
		</RoundedStyledButtonn>
	);
};

const RoundedStyledButtonn = styled(Button)(() => ({
	borderRadius: '40px',
	height: 58,
}));

const RoundedButtonTaller: React.FC<IButtonProps> = ({ children, ...others }) => {
	return (
		<TallerRoundedStyledButton
			variant='contained'
			{...others}>
			<Typography variant='h3'>{children}</Typography>
		</TallerRoundedStyledButton>
	);
};

const TallerRoundedStyledButton = styled(Button)(() => ({
	borderRadius: '40px',
	height: 80,
}));

interface IRoundedSubmitButtonProps extends IButtonProps {
	isSubmitting: boolean;
	text: string;
}
const RoundedSubmitButton: React.FC<IRoundedSubmitButtonProps> = ({
	isSubmitting,
	text,
	sx,
	...others
}) => {
	return (
		<SubmitButton
			sx={{ borderRadius: '40px', height: 58, flex: 1, ...sx }}
			isSubmitting={isSubmitting}
			variant={'contained'}
			{...others}>
			<Typography variant='h4'>{text}</Typography>
		</SubmitButton>
	);
};

export { RoundedButton, RoundedButtonTaller, RoundedSubmitButton };
