import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { AuthorityType, ManagerUserType } from '@kinderlabs-pos/shared-data-type';

export const getManagerInfoDetailAsync =
	(rootUrl: string) =>
	async ({ authority, email }: DetailRequestManagerType) =>
		(
			await posAxiosClient.get<ManagerUserType>(
				`${rootUrl}/auth-service/manager/member/${authority}/id/${email}`
			)
		).data;

export type DetailRequestManagerType = {
	authority: AuthorityType;
	email: string;
};
