import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, ProductDiscountMgInfoType } from '@kinderlabs-pos/shared-data-type';

export const getProductDiscountDetailInfoAsync =
	(rootUrl: string) =>
	async ({ id }: GetProductDiscoountInfoRequestType) =>
		(
			await posAxiosClient.get<ProductDiscountMgInfoType & BaseInfoType>(
				`${rootUrl}/product-discount`,
				{
					params: {
						id,
					},
				}
			)
		).data;

type GetProductDiscoountInfoRequestType = {
	id: number;
};
