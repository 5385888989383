import { RightOutlined } from '@ant-design/icons';
import { KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { getUuidV4 } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { Button, Stack, Typography, alpha } from '@mui/material';
import { grey } from '@mui/material/colors';
import { KioskNumberBoard } from 'apps/kiosk-main/src/components/KioskNumberBoard';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';
import { useState } from 'react';

export const KioskBellSection = ({
	open,
	closeDialog,
	kioskInfo,
	handleGoNextStep,
}: {
	kioskInfo: KioskInfoType;
	handleGoNextStep: () => void;
} & XlDialogProps) => {
	const { t, kioskI18N } = useKioskTranslation();
	const cartDispatcher = OrderState.cart.useDispatcher();
	const [bellNumber, setBellNumber] = useState('');
	const clearBellNumber = () => {
		setBellNumber('');
	};

	const addBellNumber = (value: number) => {
		if (bellNumber.length < 3) setBellNumber(`${bellNumber}${value}`);
	};

	const isValidBellNumber = kioskInfo.bellOption === 'MANDATORY' ? bellNumber !== '' : true;

	const handleClickOnNextStep = () => {
		cartDispatcher({
			type: 'BELL',
			subAction: { type: 'ADD_BELL', bellNumber: bellNumber, cartLineId: getUuidV4() },
		});

		handleGoNextStep();
	};

	return (
		<Stack height={'100%'}>
			<Stack alignItems={'center'}>
				<Typography variant='h5'>{t('order.bellTitle')}</Typography>
			</Stack>
			<Stack
				flex={1}
				spacing={5}
				alignItems={'center'}>
				<Stack flex={1} />
				<Stack
					alignItems={'center'}
					spacing={5}>
					<Typography
						variant='subtitle1'
						color={'text.secondary'}>
						{t('order.bellDescription')}
					</Typography>
					<Stack
						width={'100%'}
						alignItems={'center'}
						sx={(theme) => ({
							bgcolor: alpha(theme.palette.primary.main, 0.1),
							p: 3,
						})}>
						<Typography
							variant='h1'
							fontWeight={700}>
							{bellNumber || 'ㅤ'}
						</Typography>
					</Stack>
				</Stack>
				<Stack
					width={428}
					pb={10}
					alignItems={'center'}>
					<KioskNumberBoard
						onClickNumber={(value) => {
							if (value === 'clear') {
								clearBellNumber();
							} else if (value === 'delete') {
								setBellNumber(bellNumber.slice(0, -1));
							} else {
								addBellNumber(value);
							}
						}}
					/>
				</Stack>
				<Stack width={'100%'}>
					{kioskInfo.bellOption === 'OPTIONAL' && (
						<Stack
							width={'100%'}
							alignItems={'flex-end'}
							sx={{ color: grey[400] }}>
							<Button
								color={'inherit'}
								onClick={handleGoNextStep}
								endIcon={<RightOutlined />}>
								<Typography variant='body1'>{t('order.skipBell')}</Typography>
							</Button>
						</Stack>
					)}
					<Stack
						width={'100%'}
						direction={'row'}
						py={3}
						spacing={3}>
						<Button
							variant='outlined'
							fullWidth
							onClick={closeDialog}>
							{t('common.cancel')}
						</Button>
						<Button
							variant='contained'
							fullWidth
							disabled={!isValidBellNumber}
							onClick={handleClickOnNextStep}>
							{t('payment.action')}
						</Button>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
