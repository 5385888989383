import { PlusOutlined, ProfileOutlined, WarningOutlined } from '@ant-design/icons';
import { OptionGroupListType } from '@kinderlabs-pos/shared-data-type';
import { getI18nString, numberWithCommasAnd원PlusMinus } from '@kinderlabs-pos/shared-util';
import { OrderState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	CardActionArea,
	DialogActions,
	DialogContent,
	Stack,
	Typography,
	alpha,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useMemo } from 'react';
import { TFunction } from 'react-i18next';
import { useKioskTranslation } from '../../../../const/useKioskTranslation';
import { KioskBoardItemOptionSelectButton } from './components/KioskBoardItemOptionSelectButton';

export const KioskBoardItemOptionSection = ({
	selectedOptionIdList,
	setSelectedOptionIdList,
	optionGroup,
	isValid,
	옵션이너무많음,
}: {
	selectedOptionIdList: number[];
	setSelectedOptionIdList: (optionList: number[]) => void;
	optionGroup: OptionGroupListType;
	isValid: boolean;
	옵션이너무많음: boolean;
}) => {
	const { t, kioskI18N } = useKioskTranslation();
	const getExclusiveIdListWithLIFO = OrderState.cart.utils.useLIFOIdList({
		maxSelect: optionGroup.maxSelect,
		selectedIdList: selectedOptionIdList,
	});

	const handleToggleSelection = (optionId: number) => {
		if (!selectedOptionIdList.includes(optionId)) {
			setSelectedOptionIdList(getExclusiveIdListWithLIFO(optionId));
		} else {
			setSelectedOptionIdList(
				selectedOptionIdList.filter((selectedOptionId) => selectedOptionId !== optionId)
			);
		}
	};

	const optionGroupHelperText = useMemo(
		() => getOptionHelperText(optionGroup, kioskI18N, t),
		[optionGroup]
	);

	return (
		<Stack
			width={'100%'}
			spacing={1}>
			<Stack width={'100%'}>
				<Stack flex={1}>
					<Stack
						width={'100%'}
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
						spacing={1}>
						<Typography
							variant='h6'
							color={isValid ? 'black' : 'error'}>
							{getI18nString({
								ko: optionGroup.name,
								i18nString: optionGroup.i18nName,
								i18n: kioskI18N,
							})}
						</Typography>
						<Typography
							color={isValid ? 'black' : 'error'}
							variant='body2'>
							{optionGroupHelperText}
						</Typography>
					</Stack>
				</Stack>
				<Typography
					variant='body2'
					color={'text.secondary'}>
					{getI18nString({
						ko: optionGroup.description,
						i18nString: optionGroup.i18nDescription,
						i18n: kioskI18N,
					})}
				</Typography>
			</Stack>
			<Stack
				width={'100%'}
				pt={2}>
				{!옵션이너무많음 && (
					<Stack
						direction={'row'}
						flexWrap={'wrap'}
						width={'100%'}
						rowGap={3}
						columnGap={3}>
						{optionGroup.optionList.map((option, idx) => {
							return (
								<KioskBoardItemOptionSelectButton
									key={idx}
									selected={selectedOptionIdList.some((selectedId) => option.id === selectedId)}
									label={
										getI18nString({
											ko: option.name,
											i18nString: option.i18nName,
											i18n: kioskI18N,
										}) ?? ''
									}
									price={option.price}
									onClick={() => handleToggleSelection(option.id)}
								/>
							);
						})}
					</Stack>
				)}
				{옵션이너무많음 && (
					<WithDialog
						dialog={(open, closeDialog) => (
							<OptionSelectDialogWhenToMany
								optionGroupHelperText={optionGroupHelperText}
								selectedOptionIdList={selectedOptionIdList}
								handleToggleSelection={handleToggleSelection}
								optionGroup={optionGroup}
								open={open}
								closeDialog={closeDialog}
							/>
						)}>
						{(openDialog) => (
							<CardActionArea
								onClick={openDialog}
								sx={(theme) => ({
									p: 3,
									display: 'flex',
									alignItems: 'center',
									color: isValid ? 'primary' : 'error',
									backgroundColor: isValid
										? alpha(theme.palette.primary.light, 0.1)
										: alpha(theme.palette.error.light, 0.1),
								})}>
								{selectedOptionIdList.length > 0 ? (
									<Stack
										width={'100%'}
										flexDirection={'row'}
										flexWrap={'wrap'}
										rowGap={3}
										columnGap={3}>
										{optionGroup.optionList
											.filter((option) =>
												selectedOptionIdList.some((selectedId) => option.id === selectedId)
											)
											.map((option, idx) => (
												<Typography
													key={idx}
													variant={'body2'}
													color={'inherit'}>
													<ProfileOutlined />
													{` ${getI18nString({
														ko: option.name,
														i18nString: option.i18nName,
														i18n: kioskI18N,
													})} (${numberWithCommasAnd원PlusMinus(option.price, kioskI18N)})`}
												</Typography>
											))}
									</Stack>
								) : (
									<Typography
										variant={'body2'}
										color={isValid ? 'primary' : 'error'}>
										<PlusOutlined />
										{/* {` 옵션 선택  (${optionGroupHelperText})`} */}
										{` ${t('cart.options')}`}
									</Typography>
								)}
							</CardActionArea>
						)}
					</WithDialog>
				)}
			</Stack>
		</Stack>
	);
};

// 두번째 컴포넌트 다이얼로그
// 두번째 컴포넌트 다이얼로그
// 두번째 컴포넌트 다이얼로그
// 두번째 컴포넌트 다이얼로그
// 두번째 컴포넌트 다이얼로그

const OptionSelectDialogWhenToMany = ({
	open,
	optionGroup,
	closeDialog,
	selectedOptionIdList,
	handleToggleSelection,
	optionGroupHelperText,
}: XlDialogProps & {
	optionGroup: OptionGroupListType;
	selectedOptionIdList: number[];
	handleToggleSelection: (optionId: number) => void;
	optionGroupHelperText: string;
}) => {
	const { t, kioskI18N } = useKioskTranslation();

	const isValid =
		selectedOptionIdList.length <= optionGroup.maxSelect &&
		selectedOptionIdList.length >= optionGroup.minSelect;

	return (
		<WithDialog.XlDialog
			maxWidth={'xl'}
			dialogTitle={
				<Stack
					spacing={3}
					alignItems={'center'}
					direction={'row'}>
					<Typography variant='h4'>
						{t('common.select', {
							what:
								getI18nString({
									ko: optionGroup.name,
									i18nString: optionGroup.i18nName,
									i18n: kioskI18N,
								}) ?? '',
						})}
					</Typography>
				</Stack>
			}
			open={open}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 824, aspectRatio: '1/1.4', overflow: 'auto' }}>
				<Stack
					spacing={5}
					height={'100%'}
					overflow={'auto'}>
					<Typography
						variant='body1'
						color={'text.secondary'}>
						{getI18nString({
							ko: optionGroup.description,
							i18nString: optionGroup.i18nDescription,
							i18n: kioskI18N,
						})}
					</Typography>
					<Stack flex={1}>
						<Grid2
							container
							width={'100%'}
							rowGap={3}
							columnGap={3}
							overflow={'auto'}>
							{optionGroup.optionList.map((option, idx) => {
								return (
									<Grid2 xs={5.8}>
										<KioskBoardItemOptionSelectButton
											key={idx}
											selected={selectedOptionIdList.some((selectedId) => option.id === selectedId)}
											label={
												getI18nString({
													ko: option.name,
													i18nString: option.i18nName,
													i18n: kioskI18N,
												}) ?? ''
											}
											price={option.price}
											onClick={() => handleToggleSelection(option.id)}
										/>
									</Grid2>
								);
							})}
						</Grid2>
					</Stack>
					{!isValid && (
						<Typography
							color={'error'}
							variant='h5'>
							<WarningOutlined />
							{t('common.select', {
								what: optionGroupHelperText,
							})}
						</Typography>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<Stack
					direction={'row'}
					width={'100%'}
					spacing={3}>
					<Button
						style={{ flex: 1, borderWidth: 2, color: 'gray' }}
						fullWidth
						onClick={closeDialog}
						color={'inherit'}
						variant='outlined'>
						<Typography variant={'h6'}>{t('common.close')}</Typography>
					</Button>
					<Button
						style={{ flex: 1 }}
						fullWidth
						disabled={!isValid}
						onClick={closeDialog}
						color={'primary'}
						variant='contained'>
						<Typography variant={'h6'}>{t('common.apply')}</Typography>
					</Button>
				</Stack>
			</DialogActions>
		</WithDialog.XlDialog>
	);
};

const getOptionHelperText = (
	optionGroup: OptionGroupListType,
	i18n: 'ko' | 'eng' | 'jpn' | 'chn',
	t: TFunction<'ko' | 'eng' | 'jpn' | 'chn', undefined>
) => {
	if (optionGroup.minSelect === optionGroup.maxSelect)
		return t('common.required', { number: optionGroup.maxSelect });
	else {
		const resultList = [];

		if (optionGroup.minSelect !== 0)
			resultList.push(t('common.required', { number: optionGroup.minSelect }));
		if (optionGroup.maxSelect !== optionGroup.optionList.length)
			resultList.push(t('common.max', { number: optionGroup.maxSelect }));

		return resultList.join(' ~ ');
	}
};
