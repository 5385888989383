import {
	CartLineInfoType,
	CartLineType,
	CartLineTypeRules,
	PaymentInfoType,
	ProductDiscountInfoType,
	calculateVAT,
	getAggregate,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
// import { customProductAtom } from './CartLineCustomProduct';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { OrderStateType } from '../..';

export type CartBoardActionType =
	// | { type: 'LOAD'; cartLines: CartLineInfoType[] }
	| { type: 'ADD'; cartLineType: CartLineType; cartLineId: string }
	| { type: 'MINUS'; cartLineType: CartLineType; cartLineId: string }
	| { type: 'DELETE'; cartLineId: string }
	| {
			type: 'CHANGE_QUANTITIY';
			cartLineType: CartLineType;
			cartLineId: string;
			quantity: number;
	  }
	| {
			type: 'CHANGE_DISCOUNT';
			cartLineType: CartLineType;
			cartLineId: string;
			newDiscountInfo?: Omit<ProductDiscountInfoType, 'evaluatedValue'>;
	  };

const cartBoardReducer = (
	prev: CartLineInfoType[],
	action: CartBoardActionType
): CartLineInfoType[] => {
	const cartLine = prev.find((cl) => cl.id === action.cartLineId);
	switch (action.type) {
		case 'ADD':
			return produce(prev, (draft) => {
				if (!cartLine) return prev;

				if (
					!CartLineTypeRules.canChangeQuantity({
						cartLine: cartLine,
						cartLines: prev,
					})
				)
					return prev;

				const idx = draft.findIndex((cl) => cl.id === action.cartLineId);

				if (idx >= 0) {
					// 옵션이 아닌 상품을 추가할 때 옵션이 붙어있다면
					draft.forEach((optionCl, optionIndex) => {
						if (optionCl.targetCartLineId === draft[idx].id) draft[optionIndex].quantity++;
					});
					draft[idx].quantity++;
				}
				return draft;
			});
		case 'MINUS':
			if (CartLineTypeRules.isOptionsInCart[action.cartLineType]) return prev;
			return produce(prev, (draft) => {
				const idx = draft.findIndex((cl) => cl.id === action.cartLineId);
				if (idx >= 0) draft[idx].quantity--;

				const targetCartLine = draft.find((cl) => cl.id === action.cartLineId);

				// 타겟의 옵션 수량 조절
				if (targetCartLine && CartLineTypeRules.canApplyOption[targetCartLine.type]) {
					옵션수량조절({ editableDraft: draft, targetCartLine, cartLineId: action.cartLineId });
				}

				return draft;
			}).filter((cl) => cl.quantity !== 0);

		case 'DELETE':
			// 선택된 것 제거
			const result = prev.filter((cl) => cl.id !== action.cartLineId);
			// 옵션들 다 제거
			return result.filter((cl) => cl.targetCartLineId !== action.cartLineId);
		case 'CHANGE_QUANTITIY':
			if (!cartLine) return prev;

			if (
				!CartLineTypeRules.canChangeQuantity({
					cartLine: cartLine,
					cartLines: prev,
				})
			)
				return prev;
			// throw new Error(
			// 	`[DEV] ${action.cartLineType} 는 수량을 변경할 수 없으니 개발 코드를 수정하세요.`
			// );
			return produce(prev, (draft) => {
				const idx = draft.findIndex((cl) => cl.id === action.cartLineId);
				// 개수 설정
				if (idx >= 0) {
					if (CartLineTypeRules.isOptionsInCart[draft[idx].type]) {
						// 옵션 수량 조절시 quantity 조절. 옵션의 타겟의 quantity 를 넘지 못하게
						const targetCartLineByOption = draft.find(
							(cl) => cl.id === draft[idx].targetCartLineId
						);
						if (targetCartLineByOption && targetCartLineByOption.quantity < action.quantity)
							draft[idx].quantity = targetCartLineByOption.quantity;
						else draft[idx].quantity = action.quantity;
					} else {
						draft[idx].quantity = action.quantity;
					}
				}

				const targetCartLine = draft[idx];
				// 옵션 수량 조절
				if (targetCartLine && CartLineTypeRules.canApplyOption[targetCartLine.type]) {
					옵션수량조절({ editableDraft: draft, targetCartLine, cartLineId: action.cartLineId });
				}

				return draft;
			}).filter((cl) => cl.quantity !== 0);
		case 'CHANGE_DISCOUNT':
			if (!CartLineTypeRules.canChangeDiscount[action.cartLineType]) return prev;
			// throw new Error(
			// 	`[DEV] ${action.cartLineType} 는 할인을 변경할 수 없으니 개발 코드를 수정하세요.`
			// );

			const idx = prev.findIndex((cl) => cl.id === action.cartLineId);
			if (idx < 0) throw new Error();

			const newCartLines = produce(prev, (draft) => {
				draft[idx].productDiscountInfo = action.newDiscountInfo
					? {
							...action.newDiscountInfo,
							// evaluatedValue: getUnitDiscount({
							// 	price: draft[idx].productInfo.price,
							// 	productDiscountInfo: action.newDiscountInfo,
							// }),
					  }
					: undefined;

				return draft;
			});

			// 새로운 할인 + 상품이름으로 같은 항목이 존재하면 합쳐줄 것
			return _mergeDuplicated(newCartLines, idx);
		default:
			throw new Error('No Action Type');
	}
};

const _mergeDuplicated = (cartLines: CartLineInfoType[], targetIdx: number) => {
	const targetCartLine = cartLines[targetIdx];
	const targetCartLineUniqueKey = CartLineTypeRules.getCartLineInfoTypeUniqueKey(targetCartLine);
	let newQuantity = 0;

	const result = cartLines.filter((cl) => {
		const isDuplicated =
			CartLineTypeRules.getCartLineInfoTypeUniqueKey(cl) === targetCartLineUniqueKey;
		if (isDuplicated) newQuantity += cl.quantity;

		return !isDuplicated;
	});

	return result.length === cartLines.length
		? cartLines
		: [...result, { ...cartLines[targetIdx], quantity: newQuantity }];
};
// const cartLinesAtom = atomWithReducer([], cartLineReducer);

const getUnitDiscount = ({
	price,
	productDiscountInfo,
}: {
	price: number;
	productDiscountInfo?: Pick<ProductDiscountInfoType, 'type' | 'value'>;
}) => {
	if (!productDiscountInfo) return 0;
	return productDiscountInfo.type === 'PERCENTAGE'
		? (productDiscountInfo.value * price) / 100
		: productDiscountInfo.value;
};

const getDiscountLabel = (
	discount: Pick<ProductDiscountInfoType, 'type' | 'value'> & {
		name?: ProductDiscountInfoType['name'];
	}
) =>
	`${discount.name ? `${discount.name} : ` : ''}` +
	(discount.type === 'PERCENTAGE'
		? `${discount.value} % 할인`
		: `-${numberWithCommasAnd원(discount.value)} 할인`) +
	'';

const isDiscountItem = (cartLine: CartLineInfoType) =>
	cartLine.type === 'KL_COUPON_DISCOUNT' ||
	cartLine.type === 'VAUNCE_APP_DISCOUNT' ||
	(cartLine.type === 'CUSTOM' && cartLine.productDiscountInfo);

export const getTargetCartLineFromOption = ({
	cartLines,
	targetCartLineId,
}: {
	cartLines: CartLineInfoType[];
	targetCartLineId: string;
}) => {
	let targetCartLineIndex = cartLines.findIndex((cl) => cl.id === targetCartLineId);

	// 타겟이 없다면 에러임
	if (targetCartLineIndex < 0) throw Error('타겟 카트라인 버그 발생 ( 개발자에게 제보해주세요 )');
	let targetCartLine = cartLines[targetCartLineIndex];

	// 만약 targeCartLine이 옵션이었다면, 옵션이 가리키고 있는 targetCartLine 을 targetCartLine 삼도록 한다.
	if (CartLineTypeRules.isOptionsInCart[targetCartLine.type]) {
		targetCartLineIndex = cartLines.findIndex((cl) => cl.id === targetCartLine.targetCartLineId);
		if (targetCartLineIndex < 0) throw Error('타겟 카트라인 버그 발생 ( 개발자에게 제보해주세요 )');
		targetCartLine = cartLines[targetCartLineIndex];
	}

	return {
		targetCartLine,
		targetCartLineIndex,
	};
};

export const calculate분할결제를고려한_실결제상청구할VAT = ({
	paymentAmount,
	order,
}: {
	paymentAmount: number;
	// order State Type 이라는 것!
	order: OrderStateType;
}) => {
	const { totalToPay, totalTaxFreePay, totalTax } = getAggregate(order.cart);
	const 취소되지않은Payments = order.payments.filter((p) => !p.refunded);
	// 일단 마지막 결제인지 확인
	const 남은금액 = totalToPay - 취소되지않은Payments.reduce((agg, pay) => agg + pay.amount, 0);
	const is마지막결제 = 남은금액 === paymentAmount;

	if (is마지막결제) {
		// 마지막결제
		const 여태까지낸총면세금액 = 취소되지않은Payments.reduce(
			(agg, payment) => agg + payment.taxFreeAmount,
			0
		);
		const 여태까지낸VAT = 취소되지않은Payments.reduce((agg, payment) => agg + payment.tax, 0);

		return {
			taxFreeAmount: totalTaxFreePay - 여태까지낸총면세금액,
			tax: totalTax - 여태까지낸VAT,
		};
	} else {
		if (취소되지않은Payments.length > 0) {
			// 이어하는 결제
			const 지난번결제 = 취소되지않은Payments[취소되지않은Payments.length - 1];

			const 지난번결제에서책정했어야할면세금액소수점값 =
				(지난번결제.amount / totalToPay) * totalTaxFreePay;
			const 이번면세금액은어떻게 =
				지난번결제에서책정했어야할면세금액소수점값 > 지난번결제.taxFreeAmount ? '올림' : '내림';

			const 이번결제에서부담할면세금액소수점 = (paymentAmount / totalToPay) * totalTaxFreePay;
			const 이번결제면세금액 =
				이번면세금액은어떻게 === '올림'
					? Math.ceil(이번결제에서부담할면세금액소수점)
					: Math.floor(이번결제에서부담할면세금액소수점);

			const 과세금액 = paymentAmount - 이번결제면세금액;
			const 지난번결제에서원래나갔어야할VAT소수점값 = calculateVAT({
				revenue: 지난번결제.amount - 지난번결제.taxFreeAmount,
				taxFree: false,
			});
			const 이번VAT는어떻게 =
				지난번결제에서원래나갔어야할VAT소수점값 > 지난번결제.tax ? '올림' : '내림';
			const 이번VAT소수점 = Math.round(calculateVAT({ revenue: 과세금액, taxFree: false }));
			const 이번결제VAT =
				이번VAT는어떻게 === '올림' ? Math.ceil(이번VAT소수점) : Math.floor(이번VAT소수점);

			return {
				taxFreeAmount: 이번결제면세금액,
				tax: 이번결제VAT,
			};
		} else {
			// 최초결제
			const 이번결제에서부담할면세금액 = Math.round((paymentAmount / totalToPay) * totalTaxFreePay);
			const 과세금액 = paymentAmount - 이번결제에서부담할면세금액;

			return {
				taxFreeAmount: 이번결제에서부담할면세금액,
				tax: Math.round(calculateVAT({ revenue: 과세금액, taxFree: false })),
			};
		}
	}
};

export const CartLineState = {
	reducer: cartBoardReducer,
	utils: {
		getUnitDiscount,
		getDiscountLabel,
		isDiscountItem,
		isSameCartLine: (from: CartLineInfoType, target: CartLineInfoType) =>
			CartLineTypeRules.getCartLineInfoTypeUniqueKey(from) ===
			CartLineTypeRules.getCartLineInfoTypeUniqueKey(target),
		getTargetCartLineFromOption,
		calculate분할결제를고려한_실결제상청구할VAT,
	},
};

const 옵션수량조절 = ({
	editableDraft,
	targetCartLine,
	cartLineId,
}: {
	editableDraft: WritableDraft<CartLineInfoType>[];
	targetCartLine: CartLineInfoType;
	cartLineId: string;
}) => {
	editableDraft.forEach((cl, idx) => {
		if (cl.targetCartLineId === cartLineId) {
			editableDraft[idx].quantity = targetCartLine.quantity;
		}
	});
};
