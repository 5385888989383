import { KDSInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { KDSInfoType } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../QueryClient';

const kdsInfoKeys = createQueryKeys('KDSInfo', {
	list: (storeId: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			const res = await KDSInfoApis.getInfoList(storeId);
			return res;
		},
	}),
	info: ({ storeId, KDSId }: { storeId: number; KDSId: number }) => ({
		queryKey: [storeId, KDSId],
		queryFn: async () => {
			const res = await KDSInfoApis.getInfo({ storeId, KDSId });
			return res;
		},
	}),
});

const useCreateKDSInfo = () => {
	return QueryClient.useMutation(
		async (data: Omit<KDSInfoType, 'id'>) => {
			await KDSInfoApis.create({ data });
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const useUpdateKDSInfo = () => {
	return QueryClient.useMutation(
		async (data: KDSInfoType) => {
			await KDSInfoApis.update({ data });
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const useDeleteKDSInfo = () => {
	return QueryClient.useMutation(
		async (idList: number[]) => {
			await KDSInfoApis.delete(idList);
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const invalidateQuries = () => QueryClient.origin.invalidateQueries({ queryKey: kdsInfoKeys._def });

export const KDSInfoState = {
	keys: kdsInfoKeys,
	create: useCreateKDSInfo,
	delete: useDeleteKDSInfo,
	update: useUpdateKDSInfo,
};
