import { rootUrl } from '../../const';
import { createVendorInfoAsync } from './createVendorInfoAsync';
import { deleteVendorInfoAsync } from './deleteVendorInfoAsync';
import { getVendorDetailInfoAsync } from './getVendorDetailInfoAsync';
import { getVendorInfoListAsync } from './getVendorInfoListAsync';
import { updateVendorInfoAsync } from './updateVendorInfoAsync';

export const AdminVendorInfoApis = {
	getVendorInfoList: getVendorInfoListAsync(rootUrl),
	getVendorDetailInfo: getVendorDetailInfoAsync(rootUrl),
	createVendorInfo: createVendorInfoAsync(rootUrl),
	updateVendorInfo: updateVendorInfoAsync(rootUrl),
	deleteVendorInfo: deleteVendorInfoAsync(rootUrl),
};
