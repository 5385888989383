import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { b2CMainThemeOption } from './b2cMainThemeOption';
import { b2cDarkPaletteOptions, b2cPaletteOptions } from './b2cPaletteOptions';

export const getDisplayerTheme = (mode: 'light' | 'dark') =>
	createTheme(
		deepmerge(b2CMainThemeOption, {
			palette:
				mode === 'dark'
					? { ...b2cDarkPaletteOptions, mode: 'dark' }
					: { ...b2cPaletteOptions, mode: 'light' },
		})
	);

// JIN 240816 : 삭제해야함
export const displayerThemeV1 = createTheme(deepmerge(b2CMainThemeOption, {}));
