import {
	BaseInfoType,
	Pageable,
	PageType,
	StoreInfoListFilterType,
	StoreInfoType,
} from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const getStoreInfoListPageAsync =
	(rootUrl: string) =>
	async ({
		page,
		size,
		sort,
		storeIdList,
		...options
	}: StoreInfoListFilterType & { storeIdList?: number[] }) =>
		(
			await posAxiosClient.get<PageType<StoreInfoType & BaseInfoType>>(
				`${rootUrl}/store/list/search`,
				{
					params: {
						storeIdList,
						...options,
						...Pageable.getPageable({
							page,
							size,
							sort,
						}).getPageableQueryParam(),
					},
				}
			)
		).data;
