import { vaunceColors } from '@kinderlabs-pos/shared-const';
import { PaletteOptions } from '@mui/material';

export const kioskPaletteOptions: PaletteOptions = {
	common: {
		black: vaunceColors.navy,
	},
	text: {
		primary: vaunceColors.navy,
	},
	primary: {
		main: vaunceColors.navy,
		contrastText: '#FFFFFF',
	},
	secondary: {
		main: '#ff5722',
	},
	navy: {
		main: vaunceColors.navy,
		// dark : '#b2b2b2',
		contrastText: '#FFFFFF',
	},
	paleWhite: {
		main: '#FFFFFF',
		contrastText: vaunceColors.navy,
	},
	lightGray: {
		main: vaunceColors.lightGray,
		contrastText: vaunceColors.navy,
	},
	darkGray: {
		//kiosk Button
		main: '#1F2026',
		contrastText: vaunceColors.navy,
	},
	info: {
		// yellow
		main: '#FCC24F',
	},
	success: {
		// navy
		main: '#282B5F',
	},
	background: {
		default: '#fff',
	},
};
