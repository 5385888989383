import { ReactPosMainPageIPC } from '@kinderlabs-pos/ipc/react';
import {
	CartLineExcessChargeInfoType,
	CartLineOnlineTicketOnoffmixInfoType,
	CartLineProductInfoType,
	CartLineTicketUsageInfoType,
	CartType,
	OrderDetailType,
	OrderType,
	PaymentAggregateType,
	PaymentInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithReducer } from 'jotai/utils';
import { PaymentState } from '../../PaymentState';
import { PaymentReducerActionType, paymentReducer } from '../PaymentReducer';
import { CartActionType, CartState } from './CartState';
import { CartLineState } from './CartState/CartLineBoardState';
import { DdiziPrintingState } from './DdiziPrintingState';
import { ExchangeCouponPrintingState } from './ExchangeCouponPrintingState';
import { OrderActions } from './OrderActions';
import { OrderUtil } from './OrderUtil';

export type OrderStateType = Omit<Partial<OrderDetailType>, 'aggregate'> & {
	payments: PaymentInfoType[];
	cart: CartType;
	// ddizis: DdiziType[];
	// exchangeCoupons: ExchangeCouponType[];
	// guestUsersInfo?: {
	// 	visitId: number;
	// 	mainUserId?: number;
	// 	mainUserName: string;
	// 	mainUserTelephone: string;
	// 	users: string[];
	// };:
};

const initialPartialOrder: OrderStateType = {
	payments: [],
	cart: {
		lines: [],
	},
	// ddizis: [],
	// exchangeCoupons: [],
};

type ActionType =
	| {
			type: 'SET_START';
			order: OrderType;
	  } // order Submit 이 처음 성공했을떄 작업
	| {
			type: 'SET_COMPLETE';
			order: OrderType;
			onCompleteOrder: () => void;
	  } // order complete 이 성공했을떄 작업
	| { type: 'SET_GUEST_USERS_INFO'; guestMemberInfo: OrderType['guestMemberInfo'] }
	| { type: 'LOAD'; order: OrderDetailType }
	| { type: 'CLEAR' }
	| { type: 'CHANGE_MEMBER_USER_ID'; memberUserId: string }
	| { type: 'PAYMENTS'; subAction: PaymentReducerActionType }
	| { type: 'CART'; subAction: CartActionType }
	| { type: 'ADD_TELEPHONE'; telephone?: string }
	| { type: 'COPY_ORDER_FOR_SUBPAGE'; order: OrderDetailType };

const reducer = (prev: OrderStateType, action: ActionType) => {
	switch (action.type) {
		case 'SET_START':
			return {
				...prev,
				...action.order,
			};
		case 'SET_COMPLETE': {
			action.onCompleteOrder();
			const { cart, ...others } = action.order as OrderDetailType;
			return {
				...prev,
				...others,
			};
		}
		case 'CHANGE_MEMBER_USER_ID':
			return { ...prev, pointMemberUserId: action.memberUserId };
		case 'SET_GUEST_USERS_INFO':
			return { ...prev, guestMemberInfo: action.guestMemberInfo };
		case 'LOAD': {
			const { order } = action;
			// aggregate 만 제외하고 다 넣기.

			const { aggregate, ...otherOrderValues } = order;
			return otherOrderValues;
		}
		case 'CLEAR':
			return initialPartialOrder;
		case 'PAYMENTS':
			return {
				...prev,
				payments: paymentReducer(prev.payments, action.subAction),
			};
		case 'CART':
			return {
				...prev,
				cart: CartState.reducer(prev.cart, action.subAction),
			};
		case 'ADD_TELEPHONE':
			return { ...prev, kioskUserTelephone: action.telephone };
		case 'COPY_ORDER_FOR_SUBPAGE':
			return action.order;
		default:
			return prev;
	}
};

const posOrderAtomWithReducer = atomWithReducer(
	initialPartialOrder,
	(prev: OrderStateType, action: ActionType) => {
		const result = reducer(prev, action);

		ReactPosMainPageIPC.sendSubPageMessage({
			key: 'order',
			args: result,
		});

		return result;
	}
);

const cartAggregateAtom = atom((get) =>
	CartState.utils.getAggregate(get(posOrderAtomWithReducer).cart)
);
const paymentAgregateAtom = atom<PaymentAggregateType>((get) => {
	const totalPaid = PaymentState.utils.getTotal(get(posOrderAtomWithReducer).payments);

	return {
		totalPaid,
		remainingPay: get(cartAggregateAtom).totalToPay - totalPaid,
	};
});

const cartSelector = atom((get) => get(posOrderAtomWithReducer).cart);

const useCartDispatcher = () => {
	const dispatcher = useSetAtom(posOrderAtomWithReducer);
	return (action: CartActionType) => dispatcher({ type: 'CART', subAction: action });
};

const useGuestUsersInfoSelector = ({ mode }: { mode: OrderType['type'] }) => {
	return useAtomValue(posOrderAtomWithReducer).guestMemberInfo;
};

export const OrderState = {
	value: posOrderAtomWithReducer,
	aggregate: {
		cart: cartAggregateAtom,
		payments: paymentAgregateAtom,
	},
	actions: OrderActions,
	cart: {
		utils: CartState.utils,
		selector: cartSelector,
		useDispatcher: useCartDispatcher,
		board: CartLineState,
		lines: {
			boardUtils: CartLineState.utils,
			productSelector: atom(
				(get) =>
					get(cartSelector).lines.filter((cl) => cl.type === 'PRODUCT') as CartLineProductInfoType[]
			),
			excessChargeSelector: atom(
				(get) =>
					get(cartSelector).lines.filter(
						(cl) => cl.type === 'EXCESS_CHARGE'
					) as CartLineExcessChargeInfoType[]
			),
			ticketUsageSelector: atom(
				(get) =>
					get(cartSelector).lines.filter(
						(cl) =>
							cl.type === 'VAUNCE_APP_CMT' ||
							cl.type === 'VAUNCE_APP_MUT' ||
							cl.type === 'VAUNCE_APP_OTT' ||
							cl.type === 'VAUNCE_APP_DISCOUNT' ||
							cl.type === 'VAUNCE_APP_FREE_COUPON'
					) as CartLineTicketUsageInfoType[]
			),
			onlineTicketUsageSelector: atom(
				(get) =>
					get(cartSelector).lines.filter(
						(cl) => cl.type === 'ONLINE_TICKET_ONOFFMIX'
					) as CartLineOnlineTicketOnoffmixInfoType[]
			),
		},
	},
	guestUsersInfo: {
		useSelector: useGuestUsersInfoSelector,
	},
	selector: {
		ddizis: atom((get) => get(posOrderAtomWithReducer).ddizis),
		exchangeCoupons: atom((get) => get(posOrderAtomWithReducer).exchangeCoupons),
		payments: atom((get) => get(posOrderAtomWithReducer).payments),
	},
	// util 들은 전부 Domain 화 시킬 것
	utils: OrderUtil,
	printing: {
		ddizis: DdiziPrintingState,
		exchangeCoupons: ExchangeCouponPrintingState,
	},
};
