import XLSX from 'xlsx-js-style';

export const printExcelData = ({
	columns,
	data,
	fileName,
}: {
	columns: { key: string; label: string }[];
	data: Record<string, string | number>[];
	fileName: string;
}) => {
	const wb = XLSX.utils.book_new();

	const row = [...columns.map((col) => ({ v: col.label, t: 's', s: columnCommonProps }))];
	const res = [row];

	converToArray(data, columns)
		.map((infoArr) =>
			infoArr.map((info) => ({
				v: info.label,
				t: typeof info.label === 'number' ? 'n' : 's',
				s: dataCommonProps,
			}))
		)
		.forEach((data) => {
			res.push(data as any);
		});

	const ws = XLSX.utils.aoa_to_sheet(res);
	ws['!cols'] = columns.map(() => ({ wch: 20 }));

	XLSX.utils.book_append_sheet(wb, ws, `${fileName}`);

	XLSX.writeFile(wb, `${fileName}.xlsx`);
};

const converToArray = (data: Record<string, string | number>[], columns: { key: string }[]) => {
	return data.map((row) => columns.map((column) => ({ label: row[column.key] ?? '' })));
};

const borderProps = { style: 'thin', color: { rgb: '000000' } };
const columnCommonProps = {
	font: { sz: 15 },
	border: {
		top: borderProps,
		bottom: borderProps,
		left: borderProps,
		right: borderProps,
	},
	alignment: { vertical: 'center', horizontal: 'center' },
	fill: {
		fgColor: { rgb: 'E9E9E9' },
	},
};

const dataCommonProps = {
	border: {
		top: { style: 'thin', color: { rgb: '000000' } },
		bottom: { style: 'thin', color: { rgb: '000000' } },
		left: { style: 'thin', color: { rgb: '000000' } },
		right: { style: 'thin', color: { rgb: '000000' } },
	},
	alignment: { vertical: 'center', horizontal: 'center' },
};
