import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { OrderDetailType, OrderType } from '@kinderlabs-pos/shared-data-type';

export const cancelOrderAsync =
	(rootUrl: string) =>
	async ({ storeId, posId, orderId }: { storeId: number; posId: number; orderId: string }) =>
		(
			await posAxiosClient.post<OrderDetailType>(
				`${rootUrl}/order/store/${storeId}/pos/${posId}/order/cancel`,
				null,
				{
					params: {
						orderId: orderId,
					},
				}
			)
		).data;
