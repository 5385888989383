import { rootUrl } from '../../const';
import { countingStockQtyAsync } from './countingStockQtyAsync';
import { createStockInfoAsync } from './createStockInfoAsync';
import { deleteStockInfoAsync } from './deleteStockInfoAsync';
import { disposalStockQtyAsync } from './disposalStockQtyAsync';
import { getStockDetailInfoAsync } from './getStockDetailInfoAsync';
import { getStockHistoryDetailInfoAsync } from './getStockHistoryDetailInfoAsync';
import { getStockHistorySearchAsync } from './getStockHistorySearchAsync';
import { getStockInfoForWarehouseAsync } from './getStockInfoForWarehouseAsync';
import { getStockInfoListAsync } from './getStockInfoListAsync';
import { rollbackStockHistoryAsync } from './rollbackStockHistoryAsync';
import { updateStockInfoAsync } from './updateStockInfoAsync';

export const AdminStockInfoApis = {
	getDetailInfo: getStockDetailInfoAsync(rootUrl),
	getInfoList: getStockInfoListAsync(rootUrl),
	getHistoryList: getStockHistorySearchAsync(rootUrl),
	getDetailHistoryInfo: getStockHistoryDetailInfoAsync(rootUrl),
	getStockInfoForWarehouse: getStockInfoForWarehouseAsync(rootUrl),
	createBulkInfo: createStockInfoAsync(rootUrl),
	deleteInfo: deleteStockInfoAsync(rootUrl),
	updateInfo: updateStockInfoAsync(rootUrl),
	countingStock: countingStockQtyAsync(rootUrl),
	disposalStock: disposalStockQtyAsync(rootUrl),
	rollbackHistory: rollbackStockHistoryAsync(rootUrl),
};
