import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, DeviceStatusType, KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { rootUrl } from '../../const';

const getKioskInfoListAsync =
	(rootUrl: string) =>
	async ({ storeId }: { storeId?: number }) =>
		(
			await posAxiosClient.get<(KioskInfoType & BaseInfoType)[]>(`${rootUrl}/store/kiosk/list`, {
				params: { storeId },
			})
		).data;

const getKioskInfoAsync =
	(rootUrl: string) =>
	async ({ storeId, kioskId }: { storeId?: number; kioskId: number }) =>
		(
			await posAxiosClient.get<KioskInfoType & BaseInfoType>(`${rootUrl}/store/kiosk`, {
				params: { storeId, id: kioskId },
			})
		).data;

export type CreateKioskInfoRequestType = Omit<KioskInfoType, 'id' | 'status' | 'deviceType'>;
const createKioskInfoAsync =
	(rootUrl: string) => async (createPosInfoRequestInfo: CreateKioskInfoRequestType) =>
		(
			await posAxiosClient.post<KioskInfoType & BaseInfoType>(`${rootUrl}/store/kiosk`, {
				...createPosInfoRequestInfo,
			})
		).data;

const updateKioskInfoAsync =
	(rootUrl: string) =>
	async (updateKioskInfoRequestInfo: Omit<KioskInfoType, 'status' | 'deviceType'>) =>
		(
			await posAxiosClient.put<KioskInfoType & BaseInfoType>(
				`${rootUrl}/store/kiosk`,
				{
					...updateKioskInfoRequestInfo,
				},
				{
					params: {
						id: updateKioskInfoRequestInfo.id,
					},
				}
			)
		).data;

export type DeleteKioskInfoRequestType = { idList: number[] };
const deleteKioskInfoAsync =
	(rootUrl: string) =>
	async ({ idList }: DeleteKioskInfoRequestType) =>
		(
			await posAxiosClient.delete<number>(`${rootUrl}/store/kiosk/list`, {
				params: {
					idList,
				},
			})
		).data;
export type DeleteKioskInfoListRequestType = { kioskIdList: number[] };
const deleteKioskInfoListAsync =
	(rootUrl: string) =>
	async ({ kioskIdList }: DeleteKioskInfoListRequestType) =>
		(
			await posAxiosClient.delete<number[]>(`${rootUrl}/store/kiosk/list`, {
				params: {
					idList: kioskIdList,
				},
			})
		).data;

const changeKioskInfoStatusAsync =
	(rootUrl: string) =>
	async ({ status, kioskId }: { status: DeviceStatusType; kioskId: number }) =>
		(
			await posAxiosClient.put<KioskInfoType & BaseInfoType>(
				`${rootUrl}/store/kiosk/change-status`,
				null,
				{
					params: {
						id: kioskId,
						status,
					},
				}
			)
		).data;

export const AdminKioskInfoApis = {
	getKioskInfoList: getKioskInfoListAsync(rootUrl),
	getKioskInfo: getKioskInfoAsync(rootUrl),
	createKioskInfo: createKioskInfoAsync(rootUrl),
	deleteKioskInfo: deleteKioskInfoAsync(rootUrl),
	deleteKioskInfoList: deleteKioskInfoListAsync(rootUrl),
	updateKioskInfo: updateKioskInfoAsync(rootUrl),
	changeKioskInfoStatus: changeKioskInfoStatusAsync(rootUrl),
};
