import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { LoginResultType, ManagerUserType } from '@kinderlabs-pos/shared-data-type';

/**
 * Reissue 를 호출하지만 Verify
 * @param rootUrl
 * @returns
 */
export const verifyAsync = (rootUrl: string) => async () => {
	const accessToken = localStorage.getItem('accessToken');
	const refreshToken = localStorage.getItem('refreshToken');

	if (accessToken && refreshToken) {
		try {
			const result = await posAxiosClient.post<LoginResultType<ManagerUserType | undefined>>(
				`${rootUrl}/authenticate/auth/token/reissue`
			);

			localStorage.setItem('accessToken', result.data.accessToken);

			return {
				userInfo: result.data.content,
				enterpriseInfo: result.data.enterpriseInfo,
			};
		} catch {
			return undefined;
		}
	} else {
		return undefined;
	}
};
