import { AdminKitchenSystemInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { KitchenSystemInfoType } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { QueryClient } from '../QueryClient';

const kitchenSystemInfoKeys = createQueryKeys('kitchenSystemInfo', {
	list: ({
		storeIdList,
		kitchenSystemIdList,
	}: {
		storeIdList?: number[];
		kitchenSystemIdList?: number[];
	}) => ({
		queryKey: [storeIdList, kitchenSystemIdList],
		queryFn: async () => {
			const res = await AdminKitchenSystemInfoApis.getKitchenSystemInfoList({
				storeIdList,
				kitchenSystemIdList,
			});
			return res;
		},
	}),
	detail: (systemId?: number) => ({
		queryKey: [systemId],
		queryFn: async () => {
			if (!systemId) return null;
			const res = await AdminKitchenSystemInfoApis.getKitchenSystemInfo({ systemId });
			return res;
		},
	}),
	canDelete: (id: number) => ({
		queryKey: [id],
		queryFn: async () => {
			return await AdminKitchenSystemInfoApis.canDeleteKitchenSystem(id);
		},
	}),
});

const useCreateKitchenSystemInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<KitchenSystemInfoType, 'id'>) => {
			return await AdminKitchenSystemInfoApis.createKitchenSystem({ data: input });
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const useUpdateKitchenSystemInfo = () => {
	return QueryClient.useMutation(
		async (input: KitchenSystemInfoType) => {
			await AdminKitchenSystemInfoApis.updateKitchenSystem({ data: input });
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const useDeleteKitchenSystemInfo = () => {
	return QueryClient.useMutation(
		async (idList: number[]) => {
			await AdminKitchenSystemInfoApis.deleteKitchenSystem(idList);
		},
		{
			onSuccess: () => {
				invalidateQuries();
			},
		}
	);
};

const invalidateQuries = () =>
	QueryClient.origin.invalidateQueries({ queryKey: kitchenSystemInfoKeys._def });

export const AdminKitchenSystemInfoState = {
	keys: kitchenSystemInfoKeys,
	useCreate: useCreateKitchenSystemInfo,
	useUpdate: useUpdateKitchenSystemInfo,
	useDelete: useDeleteKitchenSystemInfo,
};
