import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
} from '@mui/material';
import { KioskSettingsItem } from './commonKioskSettingsItem';
import { ReactLPIPC } from '@kinderlabs-pos/ipc/react';
import { LabelRequestDummy } from '@kinderlabs-pos/shared-data-type';
import { useState } from 'react';
import { PrintReceiptState, StoreInfoState } from '@kinderlabs-pos/state';
import { useAtomValue } from 'jotai';

export const KioskLabelPrinterTabPanel = () => {
	const [selected, setSelected] = useState<1 | 2>(1);
	const selectLP1 = (event: React.ChangeEvent<HTMLInputElement>) => {};
	const selectLP2 = (event: React.ChangeEvent<HTMLInputElement>) => {};

	const curDeviceInfo = StoreInfoState.useCurDeviceInfoForOrder();
	const labelPrinterList = curDeviceInfo.labelPrinterList;
	const 띠지프린터라벨출력 = PrintReceiptState.LabelPrinter.usePrintLabelPrinter({
		labelPrinterList,
	});

	return (
		<Stack
			width={'100%'}
			flex={1}
			spacing={10}>
			<KioskSettingsItem
				label={'라벨 프린터 1 디자인'}
				innerElement={
					<FormControl>
						<RadioGroup
							row
							defaultValue={'new'}
							onChange={selectLP1}>
							<FormControlLabel
								value='old'
								control={<Radio />}
								label='구형'
							/>
							<FormControlLabel
								value='new'
								control={<Radio />}
								label='신형'
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<KioskSettingsItem
				label={'라벨 프린터 2 디자인'}
				innerElement={
					<FormControl>
						<RadioGroup
							row
							defaultValue={'new'}
							onChange={selectLP2}>
							<FormControlLabel
								value='old'
								control={<Radio />}
								label='구형'
							/>
							<FormControlLabel
								value='new'
								control={<Radio />}
								label='신형'
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<KioskSettingsItem
				label={'라벨 프린터 명령'}
				innerElement={
					<Stack
						direction={'row'}
						spacing={3}>
						<Select
							defaultValue='1'
							onChange={(e) => {
								//@ts-ignore
								setSelected(parseInt(e.target.value));
							}}>
							<MenuItem value='1'>라벨 프린터 1</MenuItem>
							<MenuItem value='2'>라벨 프린터 2</MenuItem>
						</Select>
						<Button
							variant='outlined'
							onClick={async () => {
								console.log(
									await 띠지프린터라벨출력({
										...LabelRequestDummy,
										deviceType: 'KIOSK',
										deviceNum: selected === 1 ? 'LabelPrinter1' : 'LabelPrinter2',
										newDdizi: true,
									})
								);
							}}>
							샘플 라벨 출력
						</Button>
					</Stack>
				}
			/>
		</Stack>
	);
};
