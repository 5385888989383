import { rootUrl } from '../../const';
import { canDeleteProductInfoAsync } from './canDeleteProductInfoAsync';
import { canDeleteProductVariantInfoAsync } from './canDeleteProductVariantsInfoAsync';
import {
	canDeleteCategoryInfoAsync,
	changeParentCategoryInfo,
	createCategoryInfo,
	deleteCategoryInfo,
	getCategoryAllListAsync,
	getCategoryInfoDetailAsync,
	getCategoryInfoListAsync,
	getCategoryInfoOneAsync,
	getCategoryTopListAsync,
	updateCategoryInfo,
	updateMultiProductsCategory,
	updateProductCategoryInfo,
} from './category/categoryApis';
import { createProductInfoAsync } from './createProductInfo';
import { deleteProductInfoListAsync } from './deleteProductInfoListAsync';
import { deleteUnusedProductVariantsAsync } from './deleteUnusedProductVariantsAsync';
import { getAllProductInfoList } from './getAllProductInfoAsync';
import { getDetailProductVariantsInfoAsync } from './getDetailProductVariantsInfoAsync';
import { getListInfiniteAsync } from './getListInfiniteAsync';
import { getProductInfoDetailAsync } from './getProductInfoDetatilAsync';
import { getProductInfoHistoryAsync } from './getProductInfoHistoryAsync';
import { getProductInfoListAsync } from './getProductInfoListAsync';
import { getProductInfoListInStoreAsync } from './getProductInfoListInStoreAsync';
import { getStockManagedProductInfoListAsync } from './getStockManagedProductInfoListAsync';
import { updateProductInfoAsync, updateProductInfoBulkAsync } from './updateProductAsync';

export const AdminProductInfoApis = {
	getProductInfoDetail: getProductInfoDetailAsync(rootUrl),
	updateProductInfo: updateProductInfoAsync(rootUrl),
	updateProductInfoBulk: updateProductInfoBulkAsync(rootUrl),
	createProductInfo: createProductInfoAsync(rootUrl),
	getProductInfoList: getProductInfoListAsync(rootUrl),
	getListInfinite: getListInfiniteAsync(rootUrl),
	deleteProductInfoList: deleteProductInfoListAsync(rootUrl),
	deleteUnusedProductVariants: deleteUnusedProductVariantsAsync(rootUrl),
	canDelete: canDeleteProductInfoAsync(rootUrl),
	canDeleteVariants: canDeleteProductVariantInfoAsync(rootUrl),
	getProductInfoHistory: getProductInfoHistoryAsync(rootUrl),
	getListInStore: getProductInfoListInStoreAsync(rootUrl),
	getStockManagedProductList: getStockManagedProductInfoListAsync(rootUrl),
	getCompareVariantsList: getDetailProductVariantsInfoAsync(rootUrl),
	//category
	createCategoryInfo: createCategoryInfo(rootUrl),
	getCategoryInfoOne: getCategoryInfoOneAsync(rootUrl),
	updateCategoryInfo: updateCategoryInfo(rootUrl),

	deleteCategoryInfo: deleteCategoryInfo(rootUrl),
	updateCategoryGroupSetInfo: updateCategoryInfo(rootUrl),
	deleteCategoryGroupSetInfo: deleteCategoryInfo(rootUrl),
	canDeleteCategory: canDeleteCategoryInfoAsync(rootUrl),
	getCategoryInfoList: getCategoryInfoListAsync(rootUrl),
	getCategoryInfoDetail: getCategoryInfoDetailAsync(rootUrl),
	updateProductCategoryInfo: updateProductCategoryInfo(rootUrl),
	changeParentCategoryInfo: changeParentCategoryInfo(rootUrl),
	updateMulitProductCategoryInfo: updateMultiProductsCategory(rootUrl),
	getCategoryAllList: getCategoryAllListAsync(rootUrl),
	getTopCategoryList: getCategoryTopListAsync(rootUrl),
};
