import { Stack, StackProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export const UserInfoPageTemplate = ({ children, ...others }: PropsWithChildren & StackProps) => {
	return (
		<Stack
			spacing={others.spacing}
			flex={1}
			height={'100%'}
			sx={{
				overflowY: 'auto',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			{children}
		</Stack>
	);
};
