import { StoreInfoState, authState } from '@kinderlabs-pos/state';
import { Tab, Tabs, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { ReactNode } from 'react';
import { KioskSelectionState } from '../../utils/KioskSelectionState';

export const KioskLocalAdminTab = ({
	tabValue,
	setTabValue,
}: {
	tabValue: number;
	setTabValue: (tabValue: number) => void;
}) => {
	const auth = useAtomValue(authState.user);
	const savedKioskInfo = StoreInfoState.useKioskLoginInfo();

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	return (
		<Tabs
			sx={{
				pl: 2,
				py: 2,
				width: 300,
				flex: 1,
				borderRight: 2,
				borderColor: 'divider',
				'& .MuiTabs-flexContainerVertical': {
					gap: 2,
				},
			}}
			TabIndicatorProps={{
				sx: {
					width: 4,
				},
			}}
			orientation='vertical'
			onChange={handleChange}
			value={tabValue}>
			{KioskLocalAdminTab.TabArray.map((val, idx) => (
				<Tab
					label={KioskLocalAdminTab.TypeLabel[val]}
					key={idx}
					disabled={!savedKioskInfo?.deviceId}
				/>
			))}
		</Tabs>
	);
};

KioskLocalAdminTab.TabArray = [
	'SETTINGS',
	'SALES',
	'PAYMENT_MODULES',
	'LABEL_PRINTER',
	'RECEIPT_PRINTER',
	'INFORMATION',
	'AUTOMATION',
	'SOLD_OUT',
	'I18N',
] as const;
type KioskLocalAdminTabType = (typeof KioskLocalAdminTab.TabArray)[number];

KioskLocalAdminTab.TypeIndex = {
	SETTINGS: 0,
	SALES: 1,
	PAYMENT_MODULES: 2,
	LABEL_PRINTER: 3,
	RECEIPT_PRINTER: 4,
	INFORMATION: 5,
	AUTOMATION: 6,
	SOLD_OUT: 7,
	I18N: 8,
} as Record<KioskLocalAdminTabType, number>;

KioskLocalAdminTab.TypeLabel = {
	SETTINGS: <Typography variant='h6'> 키오스크 설정</Typography>,
	SALES: <Typography variant='h6'> 영업 설정</Typography>,
	PAYMENT_MODULES: <Typography variant='h6'> 결제 모듈</Typography>,
	LABEL_PRINTER: <Typography variant='h6'> 라벨 프린터</Typography>,
	RECEIPT_PRINTER: <Typography variant='h6'> 영수증 프린터</Typography>,
	INFORMATION: <Typography variant='h6'> 소프트웨어 정보</Typography>,
	AUTOMATION: <Typography variant='h6'> 자동화</Typography>,
	SOLD_OUT: <Typography variant='h6'>품절 처리</Typography>,
	I18N: <Typography variant='h6'>다국어 처리</Typography>,
} as Record<KioskLocalAdminTabType, ReactNode>;
