import { ReactNode } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';

export interface IFilterWithSelectProps {
  label: ReactNode
  selectOptions: (string | { label: string, value: string })[];
  value?: string
  onChangeValue: (val: string) => void
}
export const FilterWithSelect: React.FC<IFilterWithSelectProps> = ({
  label,
  selectOptions,
  value,
  onChangeValue
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    onChangeValue(event.target.value);
  };

  return (
    <FormControl fullWidth margin={"dense"} sx={{ m: 0 }}>
      <InputLabel id={`select-id`}>{label}</InputLabel>
      <Select
        labelId={`select-id`}
        id={"select"}
        label={label}
        value={value}
        onChange={handleChange}
        sx={{
          fontSize: 16, // 이후에 테마로 엮을 것
          "& .MuiSelect-select": {
            py: "10px"
          }
        }}
      >

        {selectOptions.map((selectOption, idx) =>
          typeof selectOption === "string"
            ? <StyledMenuItem key={idx} value={selectOption}>{selectOption}</StyledMenuItem>
            : <StyledMenuItem key={idx} value={selectOption.value}>{selectOption.label}</StyledMenuItem>)
        }
      </Select>
    </FormControl>
  )
};

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 16, // 이후에 테마로 엮을 것
}))