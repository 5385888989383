import { AdminManagerInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { AuthorityType, ManagerUserType, Pageable } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useAtomValue } from 'jotai';
import { authState } from '../AuthState';
import { QueryClient } from '../QueryClient';

type ManagerInfoListParamsType = {
	authority?: AuthorityType;
	storeIdList?: number[];
	targetAuthority?: AuthorityType;
	search?: string;
	page: number;
	size?: number;
	sort?: Pageable['sort'];
};

const managerKeys = createQueryKeys('managerInfo', {
	list: ({ param }: { param: ManagerInfoListParamsType }) => {
		const storeIdList = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [param],
			queryFn: async () => {
				const res = await AdminManagerInfoApis.getManagerInfoList({
					...param,
					storeIdList: param.storeIdList || storeIdList,
					authority: param.authority || 'STOREMANAGER',
				});
				return res;
			},
		};
	},
	detail: ({ email, authority }: { email?: string; authority?: AuthorityType }) => ({
		queryKey: [email, authority],
		queryFn: async () => {
			if (!authority || !email) return undefined;
			const res = await AdminManagerInfoApis.getManagerDetailInfo({
				email: email,
				authority: authority,
			});
			return res;
		},
	}),
	history: ({ myAuthority, email }: { myAuthority?: AuthorityType; email?: string }) => ({
		queryKey: [email, myAuthority],
		queryFn: async () => {
			if (!myAuthority) return undefined;
			const res = await AdminManagerInfoApis.getManagerInfoHistory({
				myAuthority: myAuthority,
				email: email,
			});
			return res;
		},
	}),
});

type UpdateInfoPropsType = {
	myAuthority?: AuthorityType;
	data?: Omit<ManagerUserType, 'authority' | 'authorityLevel' | 'enterpriseInfo'>;
};
const useManagerInfoUpdateQuery = () => {
	return QueryClient.useMutation(
		async ({ data, myAuthority }: UpdateInfoPropsType) => {
			if (!data || !myAuthority) return undefined;
			else
				return await AdminManagerInfoApis.updateManagerInfo({
					data: data,
					myAuthority: myAuthority,
				});
		},
		{
			onSuccess: () => {
				invalidateQuires();
			},
		}
	);
};

type UpdateAuthorityPropsType = {
	myAuthority?: AuthorityType;
	data?: { email: string; authority: AuthorityType; storeIdList?: number[] };
};
const useManagerAuthorityUpdateQuery = () => {
	return QueryClient.useMutation(
		async ({ myAuthority, data }: UpdateAuthorityPropsType) => {
			if (!data || !myAuthority) return undefined;
			else
				return await AdminManagerInfoApis.updateAuthority({ data: data, myAuthority: myAuthority });
		},
		{
			onSuccess: () => {
				invalidateQuires();
			},
		}
	);
};

type FindManagerInfoByEmailType = {
	myAuthority?: AuthorityType;
	email?: string[];
};
const useDeleteManagerInfo = () => {
	return QueryClient.useMutation(
		async ({ myAuthority, email }: FindManagerInfoByEmailType) => {
			if (!email || !myAuthority) return undefined;
			else
				return await AdminManagerInfoApis.deleteManagerInfo({
					authority: myAuthority,
					idList: email,
				});
		},
		{
			onSuccess: () => {
				invalidateQuires();
			},
		}
	);
};

const invalidateQuires = () => {
	QueryClient.origin.invalidateQueries({ queryKey: managerKeys._def });
};

export const AdminManagerInfoState = {
	keys: managerKeys,

	useUpdateInfo: useManagerInfoUpdateQuery,
	useUpdateAuthority: useManagerAuthorityUpdateQuery,
	useDeleteInfo: useDeleteManagerInfo,
};
