import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { OrderDetailType } from '@kinderlabs-pos/shared-data-type';

export const getDetailAsync =
	(rootUrl: string) =>
	async ({ orderId }: GetOrderDetailRequestType) =>
		(
			await posAxiosClient.get<OrderDetailType>(`${rootUrl}/order/detail`, {
				params: {
					id: orderId,
				},
			})
		).data;

export type GetOrderDetailRequestType = {
	orderId: string;
};
