import { BaseInfoType, PosInfoType } from '@kinderlabs-pos/shared-data-type';

import { posAxiosClient } from '@kinderlabs-pos/apis/axios';

export const createPosInfoAsync =
	(rootUrl: string) => async (createPosInfoRequestInfo: CreatePosInfoRequestType) =>
		(
			await posAxiosClient.post<PosInfoType & BaseInfoType>(`${rootUrl}/store/pos`, {
				...createPosInfoRequestInfo,
			})
		).data;

export type CreatePosInfoRequestType = Omit<PosInfoType, 'id' | 'status' | 'deviceType'>;
