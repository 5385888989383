export const backendApiRootUrl = process.env['NX_VAUNCE_APP_API_URL'] || 'http://localhost.com';

export const frontendRootURL = process.env['NX_VAUNCE_APP_FRONTEND_URL'] || 'http://localhost:4220';
export const rootUrl = `${backendApiRootUrl}/api/v1`;

export const KAKAO_API_KEY = process.env['NX_VAUNCE_APP_KAKAO_KEY'];
export const KAKAO_MAP_API_KEY =
	process.env['NX_VAUNCE_APP_KAKAO_MAP_KEY'] || 'c3b7c9091be60f30bf7dea4f94b72b23';
export const NAVER_API_KEY = process.env['NX_VAUNCE_APP_NAVER_KEY'];

export const NAVER_CSRF_STATE =
	process.env['NX_VAUNCE_APP_NAVER_CSRF_STATE'] || 'kinderlabs_vaunce';

export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize`;
export const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize`;
export const APPLE_AUTH_URL = `https://appleid.apple.com/auth/authorize`;
export const APPLE_REDIRECT_URL =
	process.env['NX_VAUNCE_APP_APPLE_BACKEND_URL'] || 'https://vaunce-app0.xyz/appleRedirect';
export const APPLE_CLIENT_ID = process.env['NX_VAUNCE_APP_APPLE_CLIENT_ID'];
export const VAUNCE_APP_SENTRY_DSN = process.env['NX_VAUNCE_APP_SENTRY_DSN'];
export const FIREBASE_KEY = process.env['NX_VAUNCE_APP_FIREBASE_KEY'];
export const SENTRY_USE = process.env['NX_SENTRY_USE'] === 'true' || false;
export const SENTRY_ENVIRONMENT = process.env['NX_SENTRY_ENVIRONMENT'] || 'dev';

export const techLabsUrl = 'https://receiver.vaunceweb.net/rest/logs';
export const techLabsCUID =
	process.env['NX_TECKLABS_CUID'] || '7d035d3d-f77c-4dab-b50e-16446136c1fe';
