import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	WarehouseOrderModifyLineType,
	WarehouseOrderModifyMethodType,
	WarehouseOrderResponseType,
} from '@kinderlabs-pos/shared-data-type';

export const updateWarehouseOrderModifyStatusAsync =
	(rootUrl: string) =>
	async ({
		id,
		data,
		methodType,
	}: {
		id: number;
		data: WarehouseOrderModifyLineType[] | null;
		methodType?: WarehouseOrderModifyMethodType;
	}) =>
		(
			await posAxiosClient.put<WarehouseOrderResponseType>(
				`${rootUrl}/warehouse-order/modify/${methodType || ''}`,
				data,
				{
					params: {
						id,
					},
				}
			)
		).data;
