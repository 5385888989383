import { ProductInfoType } from '@kinderlabs-pos/shared-data-type';
import { getI18nString, numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { Stack, Typography } from '@mui/material';
import { ProductImageInKioskBoard } from '../../ProductImageInKioskBoard';
import { useAtomValue } from 'jotai';
import { useKioskTranslation } from '../../../../../const/useKioskTranslation';

export const KioskBoardItemProductInfoSection = ({
	imageUrl,
	productInfo,
}: {
	imageUrl?: string;
	productInfo: ProductInfoType;
}) => {
	const { t, kioskI18N } = useKioskTranslation();

	return (
		<Stack
			width={'100%'}
			direction={'row'}
			alignItems={'center'}
			spacing={5}>
			<ProductImageInKioskBoard
				backgroundImage={imageUrl}
				size={140}
			/>
			<Stack
				direction={'row'}
				flex={1}
				spacing={1}>
				<Stack>
					<Stack
						flex={1}
						alignItems={'flex-start'}>
						<Typography variant={productInfo.name.length > 15 ? 'h6' : 'h5'}>
							{getI18nString({
								ko: productInfo?.name,
								i18nString: productInfo?.i18nName,
								i18n: kioskI18N,
							}) ?? ''}
						</Typography>
						<Typography
							variant={'body1'}
							color='text.secondary'>
							{getI18nString({
								ko: productInfo?.description,
								i18nString: productInfo?.i18nDescription,
								i18n: kioskI18N,
							})}
						</Typography>
					</Stack>
					<Stack
						direction={'row'}
						alignItems={'center'}
						justifyContent={'space-between'}
						spacing={5}>
						<Typography
							variant='subtitle1'
							color='text.secondary'>
							{numberWithCommasAnd원(productInfo.price, kioskI18N)}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	);
};
