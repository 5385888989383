import { atom } from 'jotai';
import { useTranslation } from 'react-i18next';

export const KioskI18nSelectAtom = atom<'ko' | 'eng' | 'jpn' | 'chn'>('eng');
export const useKioskTranslation = () => {
	const { t, i18n } = useTranslation<'ko' | 'eng' | 'jpn' | 'chn'>();
	return { t, kioskI18N: i18n.language as 'ko' | 'eng' | 'jpn' | 'chn' };
};

export const useKioskChangeLanguague = () => {
	const { t, i18n } = useTranslation<'ko' | 'eng' | 'jpn' | 'chn'>();
	return (lang: 'ko' | 'eng' | 'jpn' | 'chn') => {
		i18n.changeLanguage(lang);
	};
};
