import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	ImageInfoType,
	Pageable,
	PageType,
	StoreInfoListFilterType,
} from '@kinderlabs-pos/shared-data-type';

export const getImageListAsync =
	(rootUrl: string) =>
	async ({ page, size, sort, ...options }: StoreInfoListFilterType) =>
		(
			await posAxiosClient.get<PageType<ImageInfoType>>(`${rootUrl}/store/file/list`, {
				params: {
					...Pageable.getPageable({
						page,
						size,
						sort,
					}).getPageableQueryParam(),
				},
			})
		).data;
