import { AdminKioskInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { DeviceStatusType, KioskInfoType } from '@kinderlabs-pos/shared-data-type';
import { QueryClient } from '@kinderlabs-pos/state';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const kioskInfoKeys = createQueryKeys('kioskInfo', {
	list: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			const res = await AdminKioskInfoApis.getKioskInfoList({ storeId: storeId });
			return res;
		},
	}),
	detail: ({ storeId, kioskId }: { storeId?: number; kioskId?: number }) => ({
		queryKey: [storeId, kioskId],
		queryFn: async () => {
			if (!kioskId) return null;
			const res = await AdminKioskInfoApis.getKioskInfo({ storeId, kioskId });
			return res;
		},
	}),
});

const useCreateKioskInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<KioskInfoType, 'id' | 'status' | 'deviceType'>) => {
			return await AdminKioskInfoApis.createKioskInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateKioskInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<KioskInfoType, 'status' | 'deviceType'>) => {
			return await AdminKioskInfoApis.updateKioskInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useKioskInfoChangeStatus = () => {
	return QueryClient.useMutation(
		async ({ kioskId, status }: { kioskId: number; status: DeviceStatusType }) => {
			return await AdminKioskInfoApis.changeKioskInfoStatus({
				kioskId,
				status,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteKioskInfo = () => {
	return QueryClient.useMutation(
		async (kioskId: number) => {
			await AdminKioskInfoApis.deleteKioskInfo({ idList: [kioskId] });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () =>
	QueryClient.origin.invalidateQueries({ queryKey: kioskInfoKeys._def });

export const AdminKioskInfoState = {
	keys: kioskInfoKeys,
	useCreateKioskInfo,
	useUpdateKioskInfo,
	useDeleteKioskInfo,
	useKioskInfoChangeStatus,
};
