import { OrderAPI } from '@kinderlabs-pos/apis/pos';
import {
	CartLineOnlineTicketOnoffmixInfoType,
	OrderType,
	Pageable,
} from '@kinderlabs-pos/shared-data-type';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { QueryClient } from '../QueryClient';
import { StoreInfoState } from '../StoreInfoState';
import { useSortedCartLines } from '@kinderlabs-pos/feature/pos/cart-table';
import produce from 'immer';
import { authState } from '../AuthState';
import { OnlineTicketQueryState } from '../OnlineTicketQueryState';
import { createQueryKeys } from '@lukemorales/query-key-factory';

type OrderListParamsType = {
	storeIdList?: number[];
	posIdList?: number[];
	page: number;
	size?: number;
	search?: string;
	from: Dayjs;
	to: Dayjs;
	sort?: Pageable['sort'];
	status?: OrderType['status'];
	type?: OrderType['type'];
};

const orderKeys = createQueryKeys('order', {
	detailByOrderId: (orderId?: string) => {
		const sortCartLines = useSortedCartLines();
		return {
			queryKey: [{ orderId }],
			queryFn: async () => {
				if (!orderId) return null;
				const res = await OrderAPI.getDetail({ orderId });

				return produce(res, (draft) => {
					draft.cart.lines = sortCartLines(draft.cart.lines);

					// TODO 방어로직 하나 추가
					if (!draft.exchangeCoupons) draft.exchangeCoupons = [];
				});
			},
		};
	},
	list: ({ params }: { params: OrderListParamsType }) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);
		return {
			queryKey: [params],
			queryFn: async () => {
				const res = await OrderAPI.getList({
					...params,
					storeIdList: params.storeIdList || storeIdListForManager,
					posIdList: params.posIdList,
					pageable: Pageable.getPageable(params),
					from: params.from.startOf('d'),
					to: params.to.endOf('d'),
					keyword: params.search,
				});

				return res;
			},
		};
	},
	infinite: ({
		params,
		pageable = Pageable.getPageable({ page: 0, size: 20 }),
	}: {
		params: Pick<
			OrderListParamsType,
			'storeIdList' | 'posIdList' | 'status' | 'type' | 'from' | 'to'
		> & { keyword?: string };
		pageable?: Pageable;
	}) => ({
		queryKey: [params],
		queryFn: async ({ pageParam }: { pageParam?: { startCursorId: string | number } }) => {
			const res = await OrderAPI.getListInfinite({
				...params,
				storeIdList: params.storeIdList,
				posIdList: params.posIdList,
				pageable,
				cursorId: pageParam?.startCursorId,
				from: params.from,
				to: params.to,
			});

			return {
				content: res.content,
				isLast: !res.hasMore,
				startCursorId: res.startCursorId,
			};
		},
	}),
});

const useCancelOnlineTicketMutation = () => {
	const storeAndDevice = useAtomValue(StoreInfoState.curStoreAndDevice);

	return QueryClient.useMutation(
		async ({
			orderId,
			cartLine,
		}: {
			orderId: string;
			cartLine: CartLineOnlineTicketOnoffmixInfoType;
		}) => {
			return await OrderAPI.cancelOnlineTicket({
				storeId: storeAndDevice.storeId,
				posId: storeAndDevice.deviceId,
				orderId,
				onlineTicketCartLine: cartLine,
			});
		},
		{
			onSuccess: () => {
				invalidateQueries();
				OnlineTicketQueryState.invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: orderKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: orderKeys._def });
};

export const OrderQueryState = {
	keys: orderKeys,
	invalidateQueries,
	useCancelOnlineTicketMutation,
};
