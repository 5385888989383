import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { CashSalesType } from '@kinderlabs-pos/shared-data-type';

export const getCashSalesAsync = (rootUrl: string) => {
	return async ({ posId, from, to }: { posId: number; from: string; to: string }) =>
		(
			await posAxiosClient.get<CashSalesType>(`${rootUrl}/vault-cash/cash-amount`, {
				params: {
					posId,
					from,
					to,
				},
			})
		).data;
};
