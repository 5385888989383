import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { Dayjs } from 'dayjs';
import { 결제수단별매출PaymentAndCardRevenueInfo, 캘린더DataType } from './SalesStatistics';
import { rootUrl } from '../../const';
import { StatisticsApis } from '.';
import { 일자별시간대별PaymentAndCardRevenueInfo } from '@kinderlabs-pos/shared-data-type';

const get매장별결제수단별Statistics =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		posIdList,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
	}) =>
		(
			await posAxiosClient.get<{
				paymentAndCardRevenueList: 결제수단별매출PaymentAndCardRevenueInfo[];
			}>(`${rootUrl}/statistics/payment/revenue/lambda/store`, {
				params: {
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					storeIdList,
					posIdList: posIdList,
				},
			})
		).data;

const get일자별결제수단별Statistics =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		posIdList,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
	}) =>
		(
			await posAxiosClient.get<{
				paymentAndCardRevenueList: 결제수단별매출PaymentAndCardRevenueInfo[];
			}>(`${rootUrl}/statistics/payment/revenue/lambda/date`, {
				params: {
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					storeIdList,
					posIdList,
				},
			})
		).data;

const get월별결제수단별Statistics =
	(rootUrl: string) =>
	async ({
		fromMonth,
		toMonth,
		storeIdList,
		posIdList,
	}: {
		fromMonth: string;
		toMonth: string;
		storeIdList?: number[];
		posIdList?: number[];
	}) =>
		(
			await posAxiosClient.get<{
				paymentAndCardRevenueList: 결제수단별매출PaymentAndCardRevenueInfo[];
			}>(`${rootUrl}/statistics/payment/revenue/lambda/month`, {
				params: {
					fromMonth,
					toMonth,
					storeIdList,
					posIdList,
				},
			})
		).data;

export const get시간별결제수단별Statistics =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		posIdList,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
	}) =>
		(
			await posAxiosClient.get<{
				paymentAndCardRevenueList: 일자별시간대별PaymentAndCardRevenueInfo[];
			}>(`${rootUrl}/statistics/payment/revenue/lambda/time`, {
				params: {
					storeIdList,
					from: from.format('YYYY-MM-DD'),
					to: to.format('YYYY-MM-DD'),
					fromTime: StatisticsApis.salesStatisticsStartTime,
					toTime: StatisticsApis.salesStatisticsEndTime,
					posIdList: posIdList,
				},
			})
		).data;

const getSalesCalendarStatistics =
	(rootUrl: string) =>
	async ({
		from,
		to,
		storeIdList,
		posIdList,
	}: {
		from: Dayjs;
		to: Dayjs;
		storeIdList?: number[];
		posIdList?: number[];
	}) =>
		(
			await posAxiosClient.get<캘린더DataType>(
				`${rootUrl}/statistics/payment/revenue/lambda/calendar`,
				{
					params: {
						storeIdList,
						from: from.format('YYYY-MM-DD'),
						to: to.format('YYYY-MM-DD'),
						posIdList: posIdList,
					},
				}
			)
		).data;

export const PaymentMethodStatisticsApis = {
	get일자별PaymentMethod: get일자별결제수단별Statistics(rootUrl),
	get월별PaymentMethod: get월별결제수단별Statistics(rootUrl),
	get일자별시간대별: get시간별결제수단별Statistics(rootUrl),
	get매장별PaymentMethod: get매장별결제수단별Statistics(rootUrl),
	get일자별캘린더환불: getSalesCalendarStatistics(rootUrl),
};
