import { EnterpriseInfoType } from '../EnterpriseInfoType';
import { Pageable } from '../PageType';
import { StoreInfoType } from '../admin/StoreInfoType';

/**순서중요 */
export const ManagerTypeArray = [
	'SUPERUSER',
	'ADMIN',
	'HEADQUARTERS',
	'SUPERVISOR',
	'STOREMANAGER',
] as const;

export type AuthorityType = (typeof ManagerTypeArray)[number];

export type LoginResultType<T> = {
	content: T;
	accessToken: string;
	refreshToken: string;
	enterpriseInfo: EnterpriseInfoType;
};
export type ManagerUserType = {
	email: string;
	storeIdList?: number[];
	name: string;
	telephone?: string;
	authority: AuthorityType;
	authorityLevel: number;
};

export type AdminManagerInfoListFilterType = {
	authority?: AuthorityType;
	storeIdList?: number[];
	targetAuthority?: AuthorityType;
	search?: string;
	page: number;
	size?: number;
	sort?: Pageable['sort'];
};

export const ManagerTypeLabel: Record<AuthorityType, string> = {
	SUPERUSER: '총책임자',
	ADMIN: '관리자',
	HEADQUARTERS: '본사직원 (조회용)',
	SUPERVISOR: '슈퍼바이저',
	STOREMANAGER: '센터장',
};

export const MangerAuthorityLevel: Record<AuthorityType, number> = {
	SUPERUSER: 0,
	ADMIN: 1,
	HEADQUARTERS: 2,
	SUPERVISOR: 3,
	STOREMANAGER: 4,
};

type ValidationType = Record<AuthorityType, boolean>;

export const CanBeRead: ValidationType & { notRelated: boolean } = {
	SUPERUSER: false,
	ADMIN: true,
	HEADQUARTERS: true,
	SUPERVISOR: true,
	STOREMANAGER: true,
	notRelated: true,
};

const hasCommnadRole: ValidationType = {
	SUPERUSER: true,
	ADMIN: true,
	HEADQUARTERS: false,
	SUPERVISOR: false,
	STOREMANAGER: false,
};

const CanCreate = hasCommnadRole;
const CanUpdate = hasCommnadRole;
const CanDelete = hasCommnadRole;
const CanCheck = hasCommnadRole;

const getStoreList = ({
	allStoreInfoList,
	user,
}: {
	allStoreInfoList: StoreInfoType[];
	user?: ManagerUserType;
}) => {
	return user?.storeIdList
		? user.storeIdList.length > 0
			? allStoreInfoList.filter((item) => user.storeIdList?.includes(item.id))
			: allStoreInfoList
		: allStoreInfoList;
};

const canUpdatePosBoard = ({
	authority,
	storeIdList,
	storeId,
}: {
	authority: AuthorityType;
	storeId: number;
	storeIdList?: number[];
}) => {
	if (authority === 'ADMIN' || authority === 'SUPERUSER') return true;
	// 임시
	// else return false;
	// 포스보드 편집 기능 활성화시 아래 코드로 적용
	else return storeIdList ? storeIdList.includes(storeId) : true;
};

export type AuthorityAndPermissionType =
	| {
			curAuthoity: 'SUPERUSER' | 'ADMIN' | 'HEADQUARTERS';
	  }
	| {
			curAuthoity: 'SUPERVISOR' | 'STOREMANAGER';
			hasPermission: boolean;
	  };

const createAuthorityAndPermission = (
	authority?: AuthorityType,
	hasPermission?: boolean
): AuthorityAndPermissionType => {
	if (!authority)
		return {
			curAuthoity: 'STOREMANAGER',
			hasPermission: false,
		};
	if (authority === 'SUPERVISOR' || authority === 'STOREMANAGER')
		return {
			curAuthoity: authority,
			hasPermission: hasPermission || false,
		};
	else
		return {
			curAuthoity: authority,
		};
};

const hasPermissionCheck = (authorityAndPermission: AuthorityAndPermissionType) =>
	authorityAndPermission.curAuthoity === 'SUPERVISOR' ||
	authorityAndPermission.curAuthoity === 'STOREMANAGER'
		? authorityAndPermission.hasPermission
		: true;

const hasStoreIdRestiction: Record<AuthorityType, boolean> = {
	SUPERUSER: false,
	ADMIN: false,
	HEADQUARTERS: false,
	SUPERVISOR: true,
	STOREMANAGER: true,
};

export const checkCommandRole = {
	canCreate: CanCreate,
	canBeRead: CanBeRead,
	canDelete: CanDelete,
	canUpdate: CanUpdate,
	canCheck: CanCheck,
	canUpdatePosBoard,
};

export const ManagerUserTypeRule = {
	getStoreList,
	Level: MangerAuthorityLevel,
	hasStoreIdRestiction,
};

export const ManagerAuthorityAndPermissionCheck = {
	createAuthorityAndPermission,
	hasPermissionCheck,
};
