import { VaultCashAPI } from '@kinderlabs-pos/apis/pos';
import { Pageable, VaultCashCheckType } from '@kinderlabs-pos/shared-data-type';
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { authState } from '../AuthState';
import { QueryClient } from '../QueryClient';
import { VaultCashUtils } from './VaulCashUtils';
import { VaultCashActions } from './VaultCashActions';

type PosHistoryParamType = {
	storeIdList?: number[];
	posId?: number;
	vaultCashCheckType?: VaultCashCheckType;
	from: Dayjs;
	to: Dayjs;
	page?: number;
	size?: number;
	sort?: Pageable['sort'];
};

const vaultCashKeys = createQueryKeys('vault-cash', {
	posStatus: (posId?: number) => ({
		queryKey: [posId],
		queryFn: async () => {
			if (!posId) return null;
			return await VaultCashAPI.getPosStatus({ posId });
		},
	}),
	posHistory: ({ params }: { params: PosHistoryParamType }) => {
		const storeIdListForManager = useAtomValue(authState.storeIdListForManager);

		return {
			queryKey: [params],
			queryFn: async () => {
				return await VaultCashAPI.getPosHistory({
					...params,
					storeIdList: params.storeIdList || storeIdListForManager,
					vaultCashType: params.vaultCashCheckType,
					from: params.from,
					to: params.to,
					pageable: Pageable.getPageable(params),
				});
			},
		};
	},
	storeStatus: (storeId?: number) => ({
		queryKey: [storeId],
		queryFn: async () => {
			if (!storeId) return null;
			return await VaultCashAPI.getStoreStatus({ storeId });
		},
	}),
	getCashSales: ({ posId, from, to }: { posId: number; from: string; to: string }) => ({
		queryKey: [posId, from, to],
		queryFn: async () => {
			if (!posId || !from || !to) return null;
			return await VaultCashAPI.getCashSales({ posId, from, to });
		},
	}),
});

const invalidateStatusQueries = () => {
	QueryClient.origin.cancelQueries({ queryKey: vaultCashKeys._def });
	QueryClient.origin.invalidateQueries({ queryKey: vaultCashKeys._def });
};

export const VaultCashState = {
	keys: vaultCashKeys,
	invalidateStatus: invalidateStatusQueries,
	actions: VaultCashActions,
	utils: VaultCashUtils,
};
