import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export const TitleWithDesc = ({ title, desc }: { title: ReactNode; desc?: ReactNode }) => {
	return (
		<Stack
			spacing={1}
			alignItems={'center'}>
			<Typography
				variant='h5'
				textAlign={'center'}
				sx={{ whiteSpace: 'pre-line' }}>
				{title}
			</Typography>
			<Typography
				variant='subtitle1'
				color={'text.secondary'}
				textAlign={'center'}
				sx={{ whiteSpace: 'pre-line' }}>
				{desc}
			</Typography>
		</Stack>
	);
};
