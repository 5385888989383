import { CartLineInfoType, CartLineTypeRules } from '@kinderlabs-pos/shared-data-type';
import { useCallback, useMemo } from 'react';

export const useSortedCartLines = () => {
	return useCallback(
		(cartLines: CartLineInfoType[]) =>
			cartLines
				.filter((cl) => !CartLineTypeRules.isOptionsInCart[cl.type])
				// option 붙이기
				.map((cl) =>
					CartLineTypeRules.hasOptionsInCart[cl.type]
						? [
								cl,
								...cartLines
									.filter(
										// 옵션먼저 필터링해서 몇개 뽑아낸 후
										(optionCl) =>
											CartLineTypeRules.isOptionsInCart[optionCl.type] &&
											optionCl.targetCartLineId === cl.id
									)
									// 우선순위 적용
									.sort((cl, nextCl) => {
										if (!nextCl) return 0;

										return CartLineTypeRules.cartLineSort우선순위[cl.type] <
											CartLineTypeRules.cartLineSort우선순위[nextCl.type]
											? -1
											: 1;
									}),
						  ]
						: [cl]
				)
				.flatMap((cl) => cl)
				.sort((cl, nextCl) => {
					if (cl.type === 'BELL') {
						return 1;
					} else if (nextCl.type === 'BELL') {
						return -1;
					} else {
						return 0;
					}
				}),
		[]
	);
};
