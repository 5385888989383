import { AdminStoreInfoApis } from '@kinderlabs-pos/apis/storeinfo';
import { StoreInfoType } from '@kinderlabs-pos/shared-data-type';
import { useQuery } from '@tanstack/react-query';
import { QueryClient } from '../../QueryClient';
import { createQueryKeys } from '@lukemorales/query-key-factory';

const storeInfoKeys = createQueryKeys('storeInfo', {
	all: () => ({
		queryKey: ['list'],
		queryFn: async () => {
			const res = await AdminStoreInfoApis.getStoreInfoList();
			return res;
		},
	}),
	detail: (id?: number) => ({
		queryKey: [id],
		queryFn: async () => {
			if (!id) throw Error('storeId is not valid');
			const res = await AdminStoreInfoApis.getStoreInfoDetail({ storeId: id });
			return res;
		},
	}),
	simple: (id?: number) => ({
		queryKey: [id],
		queryFn: async () => {
			if (!id) throw Error('storeId is not valid');
			const res = await AdminStoreInfoApis.getStoreInfoSimple({ storeId: id });
			return res;
		},
	}),
	canDelete: (id: number) => ({
		queryKey: [id],
		queryFn: async () => {
			return await AdminStoreInfoApis.cnaDelete({ storeId: id });
		},
	}),
	addressInfo: () => ({
		queryKey: ['address'],
		queryFn: async () => {
			return await AdminStoreInfoApis.getAddressInfo();
		},
	}),
});

const useCanDeleteStoreInfo = (input: number) => {
	const result = useQuery(storeInfoKeys.canDelete(input));

	if (result.isSuccess) {
		return result.data;
	} else {
		throw Error;
	}
};

const useCreateStoreInfo = () => {
	return QueryClient.useMutation(
		async (input: Omit<StoreInfoType, 'id'>) => {
			return await AdminStoreInfoApis.createStoreInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useUpdateStoreInfo = () => {
	return QueryClient.useMutation(
		async (input: StoreInfoType) => {
			return await AdminStoreInfoApis.updateStoreInfo(input);
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const useDeleteStoreInfo = () => {
	return QueryClient.useMutation(
		async (storeId: number) => {
			await AdminStoreInfoApis.deleteStoreInfoList({ storeId: storeId });
		},
		{
			onSuccess: () => {
				invalidateQueries();
			},
		}
	);
};

const invalidateQueries = () => {
	QueryClient.origin.invalidateQueries({ queryKey: storeInfoKeys._def });
};

export const AdminStoreInfoState = {
	keys: storeInfoKeys,
	useCreateStoreInfo: useCreateStoreInfo,
	useUpdateStoreInfo: useUpdateStoreInfo,
	useDeleteStoreInfo: useDeleteStoreInfo,
	useCanDelete: useCanDeleteStoreInfo,
};
