import { OnlineTicketAPI } from '@kinderlabs-pos/apis/pos';
import { OnlineTicketType } from '@kinderlabs-pos/shared-data-type';
import { StoreInfoState } from '@kinderlabs-pos/state';
import { Box, Stack, Typography, lighten, styled } from '@mui/material';
import barcodeImagePng from 'assets/barcode.png';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export const KioskOnlineTicketBarcodeReaderSection = ({
	formik,
}: {
	formik: ReturnType<
		typeof useFormik<{
			tickets: OnlineTicketType[];
			submitMessage: string;
		}>
	>;
}) => {
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const changeSubmitMessage = (submitMessage: string) =>
		formik.setValues((prev) => ({
			...prev,
			submitMessage,
		}));

	useBarcodeSearchHandler({
		handleSearchOnlineTicket: async (keyword: string) => {
			try {
				const res = await OnlineTicketAPI.search({ storeId: storeInfo.id, search: keyword });

				if (res.length > 0) {
					const ticket = res[0];

					if (ticket.refund) {
						changeSubmitMessage(`티켓은 이미 환불된 티켓입니다. (#${ticket.externalTicketId})`);
					} else if (ticket.klStoreId !== storeInfo.id) {
						changeSubmitMessage(
							`${storeInfo.name} 의 티켓이 아닙니다. (#${ticket.externalTicketId})`
						);
					} else if (ticket.ticketUseInfo) {
						changeSubmitMessage(`티켓은 이미 사용한 티켓입니다. (#${ticket.externalTicketId})`);
					} else if (dayjs().isAfter(dayjs(ticket.expiration).endOf('d'))) {
						changeSubmitMessage(`티켓의 유효기간이 지났습니다. (#${ticket.externalTicketId})`);
					} else if (
						formik.values.tickets.some((p) => p.externalTicketId === ticket.externalTicketId)
					) {
						changeSubmitMessage(
							`티켓이 이미 담겨있습니다. 발권을 진행해주세요. (#${ticket.externalTicketId})`
						);
					} else {
						formik.setValues((prev) => {
							return { tickets: [...prev.tickets, ticket], submitMessage: '' };
						});
					}
				} else {
					changeSubmitMessage(`해당 티켓이 없습니다. (${keyword})`);
				}
			} catch (e) {
				// ERROR 01
				Sentry.withScope((scope) => {
					// scope.setTransactionName('키오스크 온라인권 오류');
					scope.setTags({
						Type: '온라인권',
						Where: 'KIOSK',
					});
					Sentry.captureException(
						`키오스크 온라인권 검색 실패 KEYWORD : ${keyword} (Store ID : ${storeInfo.id}) // ` +
							JSON.stringify(e)
					);
				});

				changeSubmitMessage('서버 통신 오류입니다. 잠시 후 다시 시도해주세요.');
			}
		},
	});

	return (
		<Stack
			component={'section'}
			width='100%'
			p={3}
			mb={3}
			spacing={3}
			justifyContent={'center'}
			alignItems={'center'}
			sx={(theme) => ({ border: `2px solid ${lighten(theme.palette.primary.main, 0.1)}` })}>
			<OnlineTicketBarcodeBox
				width={360}
				height={100}
			/>
			<Typography color={'black'}>키오스크에 온라인권 바코드 / QR코드를 인식해주세요</Typography>
		</Stack>
	);
};

const OnlineTicketBarcodeBox = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${barcodeImagePng})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
}));

let keyboardEvent: ((event: KeyboardEvent) => void) | undefined = undefined;
// 03. QR 코드인식기능 활용
const useBarcodeSearchHandler = ({
	handleSearchOnlineTicket,
}: {
	handleSearchOnlineTicket: (keyword: string) => Promise<void>;
}) => {
	let inputStrings: string[] = [];

	useEffect(() => {
		keyboardEvent = (event: KeyboardEvent) => {
			// 지우지말고 각자 테스트할때 사용합시다!
			// if (event && event.key === 'a') handleSearchOnlineTicket('922299045487');
			// if (event && event.key === 'b') handleSearchOnlineTicket('922703942636');

			if (!event || event.key === 'Escape' || event.key === 'Shift' || event.key === 'CapsLock')
				return;
			else if (event.key === 'Enter') {
				const joinnedText = inputStrings.join('');
				inputStrings = [];

				handleSearchOnlineTicket(joinnedText);
			} else {
				inputStrings.push(event.key as string);
			}
		};

		window.addEventListener('keydown', keyboardEvent);
		return () => {
			keyboardEvent && window.removeEventListener('keydown', keyboardEvent);
		};
	}, [handleSearchOnlineTicket]);
};
