import {
	Button,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { KioskSettingsItem } from './commonKioskSettingsItem';
import { useState } from 'react';
import {
	PaymentResponseType,
	VanCompanyType,
	calculateVAT,
} from '@kinderlabs-pos/shared-data-type';
import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';

export const KioskPaymentModuleTabPanel = () => {
	const [methodIndex, setMethodIndex] = useState(0);
	let payAmount = 10;
	let payOption = '0';
	let paymentResponse: PaymentResponseType;
	const [vanCompany, setVanCompany] = useState<VanCompanyType>('NICE');

	const requestBin = async () => {
		paymentResponse = await ReactPMIPC.invoke카드BIN요청({
			vanCompany: vanCompany,
		});
	};

	const requestApproval = async () => {
		methodIndex === 0
			? (paymentResponse = await ReactPMIPC.invoke현금영수증승인({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					customerId: payOption,
					isEnterprise: false,
					isSignPadUsed: false,
			  }))
			: (paymentResponse = await ReactPMIPC.invoke카드IC승인({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					installment: parseInt(payOption),
			  }));
		console.log(paymentResponse);
	};

	const requestCancel = async () => {
		methodIndex === 0
			? (paymentResponse = await ReactPMIPC.invoke현금영수증취소({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					customerId: payOption,
					isEnterprise: false,
					isSignPadUsed: false,
					approvalDate: paymentResponse.approvalDate,
					approvalId: paymentResponse.approvalId,
			  }))
			: (paymentResponse = await ReactPMIPC.invoke카드IC취소({
					vanCompany: vanCompany,
					amount: payAmount,
					tax: calculateVAT({ revenue: payAmount, taxFree: false }),
					installment: parseInt(payOption),
					approvalDate: paymentResponse.approvalDate,
					approvalId: paymentResponse.approvalId,
			  }));
		console.log(paymentResponse);
	};

	const requestForcedCancel = async () => {
		await ReactPMIPC.invoke결제강제취소({ vanCompany: vanCompany });
	};

	return (
		<Stack
			width={'100%'}
			flex={1}
			spacing={10}>
			<KioskSettingsItem
				label={'VAN 설정'}
				innerElement={
					<FormControl>
						<RadioGroup
							row
							defaultValue={'NICE'}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setVanCompany(event.target.value as VanCompanyType);
							}}>
							<FormControlLabel
								value='NICE'
								control={<Radio />}
								label='NICE'
							/>
							<FormControlLabel
								value='NICE_PAYMENTS'
								control={<Radio />}
								label='NICE_PAYMENTS'
								disabled
							/>
							<FormControlLabel
								value='KICC'
								control={<Radio />}
								label='KICC'
								disabled
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<KioskSettingsItem
				label={'테스트 결제 수단'}
				innerElement={
					<FormControl>
						<RadioGroup
							row
							defaultValue='cash'
							onChange={(e) => {
								e.target.value === 'cash' ? setMethodIndex(0) : setMethodIndex(1);
							}}>
							<FormControlLabel
								value='cash'
								control={<Radio />}
								label='현금영수증 발급'
							/>
							<FormControlLabel
								value='card'
								control={<Radio />}
								label='신용/체크카드'
							/>
							<FormControlLabel
								value='easypay'
								control={<Radio />}
								label='간편결제'
								disabled
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<KioskSettingsItem
				label={'결제 금액'}
				innerElement={
					<TextField
						id='payAmount'
						variant='outlined'
						placeholder='최소결제금액 : 10원'
						onChange={(e) => {
							payAmount = parseInt(e.target.value);
						}}
					/>
				}
			/>
			<KioskSettingsItem
				label={'소비자 사업자 번호'}
				innerElement={
					<TextField
						id='amount'
						variant='outlined'
						placeholder='010-1234-1234'
						onChange={(e) => {
							payOption = e.target.value.replace(/[^0-9]/g, '');
						}}
					/>
				}
			/>
			<KioskSettingsItem
				label={'결제 형태'}
				innerElement={
					<Stack
						direction={'row'}
						spacing={3}>
						<Button
							variant='outlined'
							onClick={requestApproval}>
							<Typography variant='subtitle1'>결제 승인</Typography>
						</Button>
						<Button
							variant='outlined'
							onClick={requestCancel}>
							<Typography variant='subtitle1'>직전 결제 취소</Typography>
						</Button>
					</Stack>
				}
			/>
			<KioskSettingsItem
				label={'기타 기능'}
				innerElement={
					<Stack
						direction={'row'}
						spacing={3}>
						<Button
							variant='outlined'
							onClick={requestForcedCancel}>
							결제 강제 취소
						</Button>
						<Button
							variant='outlined'
							onClick={requestBin}>
							카드 BIN 요청
						</Button>
						<Button
							variant='outlined'
							disabled>
							결제 모듈 상태
						</Button>
					</Stack>
				}
			/>
		</Stack>
	);
};
