import { DdiziInfoType } from './admin/DdiziInfoType';
import { ProductInfoType } from './admin/ProductInfoType';
import { DayCalendarFilterOptionType } from './CalendarFilterOptionType';
import { Pageable } from './PageType';

export type AdminDdiziMgFilterType = {
	storeIdList?: number[];
	targetPosId?: number;
	page: number;
	size?: number;
	search?: string;
	calendarOption: DayCalendarFilterOptionType;
	sort?: Pageable['sort'];
	status?: DdiziType['status'];
};

export const DdiziStatusTypeArray = [
	'PUBLISHED',
	'USING',
	'EXITED',
	'CANCELED',
	'NOTJUNGSAN',
] as const;
type DdiziStatusType = (typeof DdiziStatusTypeArray)[number];
export const DdiziStatusTypeLabel: Record<DdiziStatusType, string> = {
	PUBLISHED: '입장 전',
	USING: '체류 중',
	EXITED: '퇴장 완료',
	CANCELED: '취소',
	NOTJUNGSAN: '미정산',
};

export const DdiziSourceTypeArray = [
	'POS',
	'KIOSK',
	'ONETIME',
	'MULTIUSE',
	'COMMUTATION',
	'COUPON',
	'ONLINE_TICKET_ONOFFMIX',
] as const;
type DdiziSourceType = (typeof DdiziSourceTypeArray)[number];
export const DdiziSourceTypeLabel: Record<DdiziSourceType, string> = {
	POS: 'POS',
	KIOSK: 'KIOSK',
	ONETIME: '일회권',
	MULTIUSE: '다회권',
	COMMUTATION: '정기권',
	COUPON: '포스 쿠폰',
	ONLINE_TICKET_ONOFFMIX: '온라인티켓',
};

export interface DdiziType {
	id: string;
	status: DdiziStatusType;
	name: string;
	multiple: number;
	available: DdiziInfoType['available'];
	// hoursType: DdiziInfoType['hours'];
	type: DdiziInfoType['ddiziType'];
	minutes: DdiziInfoType['minutes'];
	hasNoAdditionalCharge: DdiziInfoType['hasNoAdditionalCharge'];
	publishInfo: {
		published: Date;
		guestVisitId?: number;
		storeId: number;
		posId: number;
		orderId: string;
		sourceType: DdiziSourceType;
		sourceId: string;
		productInfoId: string;
		userName?: string;
		userTelephone?: string;
	};
	started?: Date;
	expiration?: Date;
	exited?: Date;
	additionalPaymentId?: number;
	canceledInfo?: {
		canceled: Date;
		storeId: number;
		posId: number;
	};
}

export type DdiziTableDataType = Omit<
	DdiziType,
	'started' | 'expiration' | 'exited' | 'available' | 'type' | 'status'
> & {
	started: string;
	expiration: string;
	exited: string;
	available: string;
	type: string;
	status: string;
};

export type DdiziTypeToJungsan = DdiziType & {
	leftMinute: number | null;
	// 만료시간을 초과한 시간(분)
	overMinute: number;
	// 퇴장시간을 초과한 시간(분))
	overMinuteAfterExit?: number;
	// 초과요금이 몇배로 붙어야하는지
	// 이후 여기에 초과요금이나 상품가격을 곱해서 초과요금이 나옵니다.
	overTimeFeeMultiplier: number;
};

export type JungsanInfoType =
	| {
			type: 'PRODUCT';
			productInfo: ProductInfoType;
	  }
	| {
			type: 'POLICY';
			overtimeFeePolicy: number;
			overtimeUnit: number;
	  };
export const getJungsanPrice = (jungsanInfo: JungsanInfoType) => {
	return jungsanInfo.type === 'POLICY'
		? jungsanInfo.overtimeFeePolicy
		: jungsanInfo.productInfo.price;
};
