import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	BaseInfoType,
	CmtHistoryInfoType,
	PageType,
	RevisionType,
} from '@kinderlabs-pos/shared-data-type';

export const getCmtInfoHistoryAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<PageType<RevisionType<CmtHistoryInfoType & BaseInfoType>>>(
			`${rootUrl}/cmt/history`,
			{
				params: { id, revisionType: 'ALL' },
			}
		)
	).data;
