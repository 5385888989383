import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { BaseInfoType, MutInfoType } from '@kinderlabs-pos/shared-data-type';

export const updateMutInfoAsync =
	(rootUrl: string) => async (updateRequestedMutInfo: UpdateMutInfoRequestType) =>
		(
			await posAxiosClient.put<MutInfoType & BaseInfoType>(
				`${rootUrl}/mut`,
				{
					...updateRequestedMutInfo,
				},
				{
					params: {
						id: updateRequestedMutInfo.id,
					},
				}
			)
		).data;

export type UpdateMutInfoRequestType = MutInfoType;
