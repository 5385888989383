import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import { VaunceMemberInfoType } from '@kinderlabs-pos/shared-data-type';

export const getSearchMemberAsync = (rootUrl: string) => {
	return async ({ search }: { search: string }) =>
		(
			await posAxiosClient.get<VaunceMemberInfoType[] | null>(`${rootUrl}/pos-bff/user/search`, {
				params: {
					search,
				},
			})
		).data;
};
