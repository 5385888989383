import { useEffect } from "react";

let barcodeValue = "";

export interface IBarcodeReadHandlerProps {
  onEnter: (key: string) => void
}
export const BarcodeReadHandler: React.FC<IBarcodeReadHandlerProps> = ({ onEnter }) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    const isKey = e.code.startsWith("Key") || e.code.startsWith("Digit");

    if (e.key === "Enter") {
      onEnter(barcodeValue)
      
      barcodeValue = "";
    } else if (isKey) {
      barcodeValue = barcodeValue + e.key
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [])

  return (
    null
  )
};