import { OrderTypeWithDdizisAndExchanges } from '@kinderlabs-pos/shared-data-type';
import {
	AdminKioskInfoState,
	ExitQueryState,
	OrderState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { Divider, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useMemo } from 'react';
import { DdiziListSection } from './DdiziListSection';
import { QRNotificationSection } from './QRNotificationSection';

export const KioskExitPage = () => {
	// 퇴장 키오스크에서 초과시간을 고정하기 위한 수단
	const fixedTime = useMemo(() => new Date(), []);

	return (
		<Suspense>
			<KioskExitPageInternal fixedTime={fixedTime} />
		</Suspense>
	);
};

const KioskExitPageInternal = ({ fixedTime }: { fixedTime: Date }) => {
	const orderReducer = useSetAtom(OrderState.value);
	const setSelectedOrderList = useSetAtom(KioskExitPage.selectedOrderListAtom);

	useEffect(() => {
		return () => {
			setSelectedOrderList([]);
			orderReducer({ type: 'CLEAR' });

			//Unmount 될 때 update를 실시해서 Sync 를 맞춤.
			ExitQueryState.invalidateDdiziQueries();
			ExitQueryState.invalidateOrderQueries();
		};
	}, []);

	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const savedKioskInfo = StoreInfoState.useCurDeviceInfoForOrder();

	const { data: kioskInfo } = useQuery({
		...AdminKioskInfoState.keys.detail({ storeId: storeInfo.id, kioskId: savedKioskInfo.id }),
		keepPreviousData: true,
		refetchOnWindowFocus: true,
	});

	if (!savedKioskInfo || !kioskInfo) throw new Error();

	return (
		<>
			<QRNotificationSection fixedTime={fixedTime} />
			<Divider />
			<DdiziListSection
				kioskInfo={kioskInfo}
				fixedTime={fixedTime}
			/>
		</>
	);
};

KioskExitPage.selectedOrderListAtom = atom<OrderTypeWithDdizisAndExchanges[]>([]);
