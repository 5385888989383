import { PaymentRefundActions } from './PaymentRefundActions';
import { PaymentSubmitActions } from './PaymentSubmitActions';
import { HandlePaymentArgsType as _HandlePaymentArgsType } from './PaymentSubmitActions/handlePayment';
import { PaymentFormikType as _PaymentFormikType } from './PaymentSubmitActions/types';
import { PaymentUtil } from './PaymentUtils';

export const PaymentState = {
	actions: {
		submit: PaymentSubmitActions,
		refund: PaymentRefundActions,
	},
	utils: PaymentUtil,
};
export type HandlePaymentArgsType = _HandlePaymentArgsType;
export type PaymentFormikType = _PaymentFormikType;
