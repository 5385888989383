import { CartLineOnlineTicketOnoffmixInfoType } from '@kinderlabs-pos/shared-data-type';
import {
	AdminProductInfoState,
	KioskElectronStoreState,
	OrderQueryState,
	PrintReceiptState,
	StoreInfoState,
} from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import {
	Button,
	DialogActions,
	DialogContent,
	List,
	ListItem,
	Stack,
	Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CircularTimeoutButton } from 'apps/kiosk-main/src/components/CircularTimeoutButton';
import { useKioskTranslation } from 'apps/kiosk-main/src/const/useKioskTranslation';
import { useKioskRouter } from 'apps/kiosk-main/src/utils/useKioskRouter';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

export const KioskOnlineTicketResultDialog = ({
	open,
	closeDialog,
	orderId,
}: { orderId: string } & XlDialogProps) => {
	const { t } = useKioskTranslation();
	const { navigate홈으로 } = useKioskRouter();

	const [isPrinting, setIsPrinting] = useState(true);
	const storeId = useAtomValue(StoreInfoState.curStoreInfo).id;
	const { data, isSuccess } = useQuery({
		...OrderQueryState.keys.detailByOrderId(orderId),
		refetchOnWindowFocus: true,
	});

	useEffect(() => {
		(async () => {
			if (data?.ddizis) {
				for (const ddizi of data.ddizis) {
					await print영수증띠지Async({
						ddizi,
					});
				}
			}

			setIsPrinting(false);
		})();
	}, [isSuccess]);

	const { print영수증띠지Async } = PrintReceiptState.useReceiptDdiziPrinter({
		storeId,
		deviceType: 'KIOSK',
	});

	const onlineTicketCartLines = (data?.cart.lines || []).filter(
		(cl) => cl.type === 'ONLINE_TICKET_ONOFFMIX'
	) as CartLineOnlineTicketOnoffmixInfoType[];

	return (
		<WithDialog.XlDialog
			open={open}
			dialogTitle={t('onlineTicket.publishTitle')}
			closeDialog={closeDialog}>
			<DialogContent sx={{ width: 824, aspectRatio: '1/1.3', overflow: 'auto' }}>
				<Stack
					flex={1}
					spacing={1}>
					<List>
						{onlineTicketCartLines.map((cl) => (
							<ListItem key={cl.id}>
								<Stack>
									<Typography variant='h5'>{cl.name}</Typography>
									<Typography
										variant='body1'
										color={'text.secondary'}>
										{`#${cl.ticketUsageInfo.ticketUsageId}`}
									</Typography>
								</Stack>
							</ListItem>
						))}
					</List>
				</Stack>
			</DialogContent>
			<DialogActions>
				{isPrinting ? (
					<Button
						disabled={isPrinting}
						variant='outlined'
						fullWidth
						onClick={() => {
							navigate홈으로();
						}}>
						{'출력 중'}
					</Button>
				) : (
					<Button
						variant='outlined'
						fullWidth
						endIcon={
							<CircularTimeoutButton
								timeout={30}
								onTimeoutCallback={navigate홈으로}
							/>
						}
						onClick={() => {
							navigate홈으로();
						}}>
						{'돌아가기'}
					</Button>
				)}
			</DialogActions>
		</WithDialog.XlDialog>
	);
};
