import { captureException, setContext, setTag } from '@sentry/react';

const setRequestContext = (param: any) => {
	setContext('API Request Detail', { ...param, accessToken: localStorage.getItem('accessToken') });
};

const setCaptureException = (error: unknown) => {
	captureException(`Catched Error : ${error}`);
};

const setLoggingTag = (type: string, msg: string) => {
	setTag(type, msg);
};

export const SentryApiLogging = {
	setRequestContext: setRequestContext,
	setCaptureException: setCaptureException,
	setTag: setLoggingTag,
};
