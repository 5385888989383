import { AxiosInstance } from 'axios';

type ResponseEntityType<T> = {
	headers: {
		[key: string]: string;
	};
	body: T;
	statusCode: 'OK' | string;
	statusCodeValue: number;
};

type ErrorData = {
	code: string;
	message: string;
};

export const setAxiosResponseInterceptor = (axiosInstance: AxiosInstance) => {
	axiosInstance.interceptors.response.use(
		(response) => {
			if ((response.data as ResponseEntityType<any>).statusCode === 'OK')
				response.data = response.data.body;

			return response;
		},
		(error) => {
			if (error.response.data) return Promise.reject(error.response.data as ErrorData);
			// 401 처리
			else if (error.code === 'ERR_BAD_REQUEST')
				return Promise.reject({
					code: 'DEV_ERROR',
					message: '서버와의 연결이 끊어졌습니다.',
				});
			// 네트워크 에러 처리
			else if (error.code === 'ERR_NETWORK')
				return Promise.reject({
					code: 'NETWORK',
					message: '네트워크 에러입니다.',
				});
			else
				return Promise.reject({
					code: error.code,
					message: error.message,
				});
		}
	);
};
