import { kioskTheme } from '@kinderlabs-pos/ui/themes';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { ReactCommonCommandIPC } from '@kinderlabs-pos/ipc/react';
import { KIOSK_SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_USE } from '@kinderlabs-pos/shared-const';
import { QueryClient } from '@kinderlabs-pos/state';
import { ConfirmProvider } from '@kinderlabs-pos/ui-components';
import {
	Breadcrumbs,
	BrowserTracing,
	Dedupe,
	GlobalHandlers,
	LinkedErrors,
	defaultStackParser,
	makeFetchTransport,
} from '@sentry/browser';
import * as Sentry from '@sentry/react';
import packageJson from 'package.json';
import * as ReactDOM from 'react-dom/client';
import { MemoryRouter } from 'react-router-dom';
import App from './app/app';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
Sentry.init({
	dsn: SENTRY_USE ? KIOSK_SENTRY_DSN : '',
	transport: makeFetchTransport,
	stackParser: defaultStackParser,
	release: `${packageJson.version}`,
	environment: SENTRY_ENVIRONMENT,
	integrations: [
		new Breadcrumbs({ console: true }),
		new BrowserTracing(),
		new GlobalHandlers(),
		new LinkedErrors(),
		new Dedupe(),
	],
});
Sentry.addBreadcrumb({ category: 'KIOSK' });

root.render(
	<ThemeProvider theme={kioskTheme}>
		<CssBaseline />
		<ReactCommonCommandIPC.ElectronDebugToggle />
		<MemoryRouter initialEntries={['/']}>
			<QueryClient.Provider>
				<App />
			</QueryClient.Provider>
		</MemoryRouter>
	</ThemeProvider>
);
