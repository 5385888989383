import { Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PrevNextButton } from '../../components/UserInfoPageComponents/PrevNextButton';
import { TitleWithDesc } from '../../components/UserInfoPageComponents/TitleWithDesc';
import { UserInfoPageTemplate } from './UserInfoPageTemplate';

type BirthdayCheckProps = {
	birthday?: string;
	setBirthday: (birthday: string) => Promise<void>;
	goNextStep: () => void;
	goPrevStep: () => void;
};

export const UserBirthdayPage = ({ props }: { props: BirthdayCheckProps }) => {
	const { birthday, setBirthday, goNextStep, goPrevStep } = props;
	const { values, handleChange, handleBlur, touched, errors, isValid, handleSubmit } = useFormik({
		initialValues: { birthday: birthday ?? '' },
		onSubmit: async ({ birthday }) => {
			await setBirthday(birthday);
			goNextStep();
		},
		validationSchema: Yup.object({
			birthday: Yup.string()
				.nullable()
				.min(7, '생년월일은 7자리 이상이어야 합니다.')
				.max(8, '생년월일은 8자를 넘을 수 없습니다.')
				.required('생년월일을 입력해주세요')
				.matches(
					/^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
					'생년월일 형식에 맞지 않습니다.'
				),
		}),
		validateOnMount: true,
		enableReinitialize: true,
	});
	return (
		<UserInfoPageTemplate>
			<Stack
				sx={{ width: '100%' }}
				spacing={5}>
				<TitleWithDesc
					title={'생년월일 8자리 입력'}
					// desc={'생년월일을 입력해주세요. \n각종 이벤트 및 쿠폰 등의 혜택이 있어요.'}
					desc={'생년월일을 입력해주세요.'}
				/>
				<TextField
					fullWidth
					type={'number'}
					name={'birthday'}
					value={values.birthday}
					onChange={handleChange}
					onBlur={handleBlur}
					error={!!(touched.birthday && errors.birthday)}
					helperText={(touched.birthday && errors.birthday) || 'ㅤ'}
					placeholder={'예)19900101'}
					// autoFocus
				/>
				<PrevNextButton
					nextDisabled={!isValid}
					prevFn={goPrevStep}
					nextFn={handleSubmit}
				/>
			</Stack>
		</UserInfoPageTemplate>
	);
};
