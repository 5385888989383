import { ThemeOptions } from '@mui/material';
import { createMuiCssBaseline } from './createMuiCssBaseline';

export const baseThemeWithFontFamily = (fontFamily: string, fontSize = 10): ThemeOptions => ({
	breakpoints: {
		values: {
			xs: 0,
			mini: 375.5, // iphone 12 mini = 360 / iphone se = 320
			sm: 428.5, // iphone 12 pro max
			galaxytab: 601, // galaxytab 601 x 968
			md: 800.5, // ipad mini : 768 but galaxy tab A8 800px 임을 반영
			lg: 1200, // ipad pro : 1024
			xl: 1536, // maximum container
		},
	},
	components: {
		MuiCssBaseline: createMuiCssBaseline(fontFamily, fontSize),
	},
	typography: {
		htmlFontSize: fontSize,
		fontFamily: fontFamily,
	},
});

declare module '@mui/material/styles' {
	interface BreakpointOverrides {
		mini: true;
		galaxytab: true;
	}
}
