export const createMuiCssBaseline = (fontFamily: string, fontSize: number) => ({
	styleOverrides: {
		html: {
			fontSize,
		},
		'@global': {
			'@fontFace': fontFamily,
		},
		// 참고 :  https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
		'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
			{
				// 아래 처럼 쓰면 kebab-case warning 발생
				// '-webkit-appearance': 'none',
				WebkitAppearance: 'none',
				margin: 0,
			},
		'input[type=number]': {
			// 아래 처럼 쓰면 kebab-case warning 발생
			// '-moz-appearance': 'textfield',
			MozAppearance: 'textfield',
		},
	},
});
